import React, { useEffect, memo, useState } from "react";

/**
 * @desc the dynamic component is used to render various component dynamically
 * @params props: {
 *    useDefaultPath: this indicates that the component to be used is in the components folder if set to true else you would have to pass in a different component
 *    is: if `useDefaultPath` is true, you pass in the name of the component file or the path to the component in the component folder eg: NewComponent or BaseUI/NewComponent
 *    ...rest: the props to be passed into the new component
 * }
 */
const DynamicComponent = ({ is, useDefaultPath = true, thing, agentInput, onThingReport, ...rest }) => {

  const [agentName, setAgentName] = useState();

  useEffect(() => {
    console.log("DynamicComponent is", is);

try {
    var m = require(`./${is}.js`);
    // do stuff
} catch (error) {
console.log("DynamicComponent error", error);
setAgentName('Null');
return;
}



    setAgentName(is);
  }, [is]);

//useEffect(()=>{
//console.log("DynamicComponent agentName", agentName);
//}, [agentName]);


  if (agentName == null) {
    return null;
  }

  return React.createElement(
//<<<<<<< Updated upstream
    useDefaultPath ? require(`./${agentName}.js`).default : agentName,
    {thing, agentInput, onThingReport,
//=======
//    useDefaultPath ? import(`./${is}.js`): import(is),
////    useDefaultPath ? require(`./${is}.js`).default : is,
//    {
//>>>>>>> Stashed changes
      ...rest,
    }
  );
};

// https://hygraph.com/blog/react-memo
export default DynamicComponent;


/*

Review below.

import React from 'react';

const useDefaultPath = true; // You can set this condition as needed
const is = 'MyComponent'; // Replace with your component name or path
const rest = {
  // Your component props
};

const dynamicImport = useDefaultPath
  ? import(`./${is}.js`)
  : import(is);

dynamicImport
  .then((module) => {
    const DynamicComponent = module.default || module; // Check if there's a default export
    const renderedComponent = <DynamicComponent {...rest} />;
    // Render the component in your React application as needed
  })
  .catch((error) => {
    console.error('Error while dynamically importing component:', error);
  });
*/
