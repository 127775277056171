import React, { memo, useState, useEffect, useCallback } from "react";
import { devFlag, debugFlag } from "../util/dev.js";

import VisibilitySensor from "react-visibility-sensor";

import { toast } from "react-toastify";

import { Card } from "./Card.js";
import { Grid, Box } from "@mui/material";
import update from "immutability-helper";
import { useDrop } from "react-dnd";
import { ItemTypes } from "./ItemTypes.js";
import { FullscreenExitTwoTone } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";

import useThings from "../useThings.js";
import useThing from "../useThing.js";

import useToken from "../useToken.js";
import useIdentity from "../useIdentity.js";

import useHybridEffect from "../useHybridEffect.js";
import {
  scoreThings,
  sortThingsByScore,
  sortThingsByAge,
  hasText,
} from "../util/text.js";

import { createThing } from "../util/database.js";

import { useNavigate } from "react-router-dom";

import Button from "./Button.js";
import Flag from "./Flag.js";

import LazyLoad from "react-lazyload";

//var { stack, thing } = require("/home/nick/codebase/stack/stack.js");
//import { stack, thing } from "stack";

const maximumStackThings = 11;

const style = {
  //minHeight: 200,
  // maxWidth: 800,
  //width: '100%',
  //  display: 'flex',
  // spacing:'1'
};

function flattenObject(obj, flattenObj) {
  var key;

  // Check to see if we have flatten obj or not
  flattenObj = flattenObj || {};

  // Loop over all the object keys and process them
  for (key in obj) {
    // Check that we are running on the object key
    if (obj.hasOwnProperty(key)) {
      // Check to see if the current key is in the "black" list or not
      //           if (KEYS_TO_REMOVE.indexOf(key) === -1) {

      // Process the inner object without this key
      flattenObj[key] = flattenObject(obj[key], flattenObj[key]);
      //            } else {
      //                flattenObject(obj[key], flattenObj);
      //            }
    }
  }

  return flattenObj;
}

const defaultWebPrefix = process.env.REACT_APP_WEB_PREFIX;

export const ThingContainer = ({ thing }) => {
  const webPrefix = process.env.REACT_APP_WEB_PREFIX;

  const navigate = useNavigate();

  //  const { thing } = props;
  const { token, isValidToken } = useToken();
  const { identity, setIdentity, deleteIdentity } = useIdentity();

  const [flag, setFlag] = useState();

  const [lens, setLens] = useState();

  const {
    findThing,
    moveThing,
    flipThing,
    openThing,
    foldThing,
    deleteThing,
    spawnThing,
    forgetThing,
  } = useThing(thing);

  const [scoredThings, setScoredThings] = useState();

  const [filteredScoredThings, setFilteredScoredThings] = useState();

  // Association things with the current thing.
  const [associations, setAssociations] = useState();
  const [piledFilteredScoredThings, setPiledFilteredScoredThings] = useState();

  const { things, getThings, setThings } = useThings(thing);

  const [countPriorityThings, setCountPriorityThings] = useState();

  useHybridEffect(() => {
    if (filteredScoredThings == null) {
      return;
    }

    if (
      Array.isArray(filteredScoredThings) &&
      filteredScoredThings.length === 0
    ) {
      console.log("ThingContainer getThings request");
      getThings(thing?.subject, "ThingContainer filteredScoredThings");
      return;
    }
  }, [filteredScoredThings]);

  useEffect(() => {
    console.log("ThingContainer things", things);
  }, [things]);

  useHybridEffect(() => {
    if (thing == null) {
      return;
    }

    if (typeof thing.associations !== undefined) {
      var newAssociations = thing.associations;
      if (
        Array.isArray(thing.associations) &&
        !thing.associations.includes(thing.uuid)
      ) {
        newAssociations = thing.associations.push(thing.uuid);
      }

      setAssociations(newAssociations);
    }

    console.log("ThingContainer thing", thing);
  }, [thing]);

  const deleteThing3 = (e) => {
    console.log("ThingContainer deleteThing3 e", e);
    deleteThing(e);
  };

  const deleteThing2 = useCallback(
    (id, atIndex) => {
      if (things.length <= 1) {
        return;
      }
      // Zap it super-quick
      const { thing, index } = findThing(id);

      setThings(
        update(things, {
          $splice: [[index, 1]],
        })
      );
      console.log("ThingContainer deleteThing2", id);

      //     deleteThing(id)
      forgetThing(id)
        .then((result) => {
          console.log("ThingContainer deleteThing2 result", result);
        })
        .catch((error) => {
          console.error("ThingContainer deleteThing2 error", error);
        });
    },
    [things]
  );

  useHybridEffect(() => {
    console.log("ThingContainer things thing effect triggered");

    if (things == null) {
      return;
    }

    if (thing == null) {
      return;
    }

    if (Array.isArray(things) && things.length === 0) {
      return;
    }

    console.log("ThingContainer scoredThings prescore", thing, thing.subject);

    const s = scoreThings(things, thing.subject);

    console.log("ThingContainer scoredThings s", s);

    setScoredThings(s);
  }, [things, thing]);

  useHybridEffect(() => {
    console.debug("ThingContainer chooseThings");
    chooseThings();
  }, [scoredThings, lens, things]);

  function chooseThings() {
    if (scoredThings == null) {
      return;
    }

    console.debug("ThingContainer chooseThings thing", thing);
    console.debug("ThingContainer chooseThings scoredThings", scoredThings);

    const priorityThings = scoredThings.filter((t) => {
      if (t.priority == null) {
        return false;
      }
      if (t.priority === "priority") {
        return true;
      }
      return false;
    });

    setCountPriorityThings(priorityThings.length);

    console.debug("ThingContainer priorityThings", priorityThings);

    const f = scoredThings.filter((t) => {
      /*
if (JSON.stringify(t).toLowerCase().includes(thing.subject.toLowerCase())) {
return true;
}
*/

      var collectionHaystack = JSON.stringify(t);

      //        console.log("ThingContainer collectionHaystack", collectionHaystack);

      if (hasText(collectionHaystack, thing.subject)) {
        return true;
      }

      if (typeof t.priority !== "undefined" && t.priority === "priority") {
        return false;
      }
      if (t.from === "stack") {
        return true;
      }

      if (t.uuid === thing.uuid) {
        return false;
      }

      if (thing.subject == null) {
        return true;
      }

      if (thing.subject === "things") {
        return true;
      }
      return true;
      return t.score > 0;
    });
    //      .slice(0, maximumStackThings);

    var s = sortThingsByScore(f);

    if (lens === "sort new") {
      s = sortThingsByAge(f, "ascending");
    }

    if (lens === "sort old") {
      s = sortThingsByAge(f, "descending");
    }

    if (lens === "sort score") {
      s = sortThingsByScore(f);
    }

    // Make sure all associated items are always shown in collection
    var a = s;

    if (Array.isArray(s) && Array.isArray(associations)) {
      const ass = associations
        .map((association) => {
          const m = things.find((t) => t.uuid === association);
          return m;
        })
        .filter((a) => {
          const m = things.find((t) => t.uuid === a);
          if (m == null) {
            return false;
          }
          return true;
        });

      a = [...ass, ...s];
    }

    //    a = [...a.slice(0, maximumStackThings), ...priorityThings];

    //    a = [...a.slice(0,maximumStackThings), ...priorityThings];

    //console.log("ThingContainer a", a);

    //was this//setFilteredScoredThings(a);
    //setFilteredScoredThings(scoredThings);
    setFilteredScoredThings(things);
  }

  // Put into 'stacks'/'decks' if the same subject
  useHybridEffect(() => {
    if (filteredScoredThings == null) {
      return;
    }

    const temp = filteredScoredThings.map((fsThing) => {
      const uuids = getDuplicates(fsThing, things).map((x) => {
        return x.uuid;
      });

      //var hasDuplicates = false;
      //if (uuids.length > 0) {
      // hasDuplicates = true;
      //}

      return { ...fsThing, duplicates: uuids };

      //return {...fsThing, duplicates:uuids}
      //return {...fsThing, associations: {...associations, uuids}}
    });
    console.log("ThingContainer piledFilteredScoredThings", temp);
    setPiledFilteredScoredThings(temp);
  }, [filteredScoredThings]);

  function isLatestDuplicate(thing, things) {}

  function getDuplicates(thing, things) {
    return things.filter((t) => {
      if (t.subject === thing.subject) {
        return true;
      }
      return false;
    });
  }

  const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }));

  function handleUpdateThing(t) {
    console.log("ThingContainer handleUpdateThing", t.variables);

    // Somesort of getTHings trigger
    getThings(thing?.subject, "ThingContainer handleUpdateThing");
  }
  /*
  function handleThing(t) {
    console.debug("ThingContainer handleThing t", t);
    if (t.variables.flag) {
      setLens(t.variables.flag);
    }
  }
*/

  function handleThingReport(t) {
    t.preventDefault();

    const datagram = { ...thing };
    console.log("ThingPage tokent", token);
    createThing(defaultWebPrefix, datagram, token, "P").then((result) => {
      console.log("ThingContainer handleClick createThing result", result);

      if (result.hasOwnProperty("error")) {
        // toast here

        if (result && result.error && result.error.message) {
          toast(result.error.message);
        } else {
          toast("Could not add Thing.");
        }

        return;
      }

      navigate("/" + "thing/" + result.uuid);
    });
  }

  const contexts = ["sort new", "sort old", "sort score"];

  return (
    <>
      <div ref={drop} style={{ ...style, width: "100%" }}>
        {debugFlag && <>THING CONTAINER</>}
        {/* {datagram && datagram.associations && Array.isArray(datagram.associations) && datagram.associations.join(' ')} */}
        {thing && isValidToken && (
          <Button
            //          thing={{ subject: ("thing/"+ (uuid ==null ? "" : uuid)), agentInput: "Add Thing" }}
            //          thing={{ subject: "add-thing", agentInput: "Add Thing" }}
            thing={{
              ...thing,
              //              subject: "thing/" + thing.uuid + "/",
              //              agentInput: "Add Thing",
            }}
            agentInput={{
              text: "Add Thing",
              link: null,
              //              link: webPrefix + "thing/" + thing.uuid + "/",
            }}
            onThingReport={(t) => handleThingReport(t)}
          />
        )}
        {filteredScoredThings > 8 && (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {contexts.map((context) => {
              const capturedLens = lens;
              return (() => {
                return (
                  <Flag
                    key={"flag_" + thing.uuid + "_" + context}
                    thing={thing}
                    agentInput={{
                      channel: "button",
                      //flag:{flag:context},
                      flag: capturedLens,
                      text: context,
                      texts: [context],
                      //         lens: {text: capturedLens},
                    }}
                    updateThing={(t) => handleUpdateThing(t)}
                  />
                );
              })();
            })}
          </div>
        )}
        <p />
        {debugFlag && <div>LENS {lens}</div>}
        <p />
        {/*    <Grid container spacing={3} direction="row"> */}
        {debugFlag && filteredScoredThings && (
          <div>
            Aware of {things.length} Things. Saw{" "}
            {filteredScoredThings && filteredScoredThings.length}
            {" unstacked "}
            Things. Showing{" "}
            {piledFilteredScoredThings && piledFilteredScoredThings.length}
            {" stacked "}
            Things. Saw {countPriorityThings} Priority Things.
            <br />
          </div>
        )}
        <>
          {false &&
            things &&
            things.map((thing) => {
              return (
                <>
                  {thing.subject}
                  <br />
                </>
              );
            })}
        </>

        <Grid container spacing={3} direction="row" justify="flex-start">
          {" "}
          {/* Added justify="flex-start" */}
          {piledFilteredScoredThings &&
            piledFilteredScoredThings.map((t) => (
              <Card
                key={"card_" + t.uuid}
                id={`${t.index}`}
                //datagram={datagram}
                //                card={{ ...t, associations: associations }}
                card={{ ...t }}
                text={t && thing.text}
                flipCard={flipThing}
                openCard={openThing}
                foldCard={foldThing}
                moveCard={moveThing}
                deleteCard={deleteThing2}
                //deleteCard={forgetThing}
                updateCard={handleUpdateThing}
                spawnCard={spawnThing}
                findCard={findThing}
                token={token}
              />
            ))}
        </Grid>
      </div>
    </>
  );
};

export default ThingContainer;
