import React from "react";


function Test({ thing, agentInput }) {

/*
  return (
    <div>
Test
    </div>
  );
*/
}

export default Test;
