import { useState, useEffect } from "react";

import { getWebJson } from "./util/database.js";
import { humanTime, zuluTime } from "./util/time.js";

import useHybridEffect from "./useHybridEffect.js";
//import useDeepCompareEffect from "use-deep-compare-effect";

//export default function useToken(inputToken) {


//  } = useThingReport(url, thingPollInterval, {datagram, visible:thing?.card?.visible});


export default function useThingReport(input, inputThingReportPollInterval, thing) {
  //  const to = input;

  const [url, setUrl] = useState();

  const [thingReportInterval, setThingReportInterval] = useState();

  const [flag, setFlag] = useState();


  const [isVisible, setIsVisible] = useState();


  const [thingReport, setThingReport] = useState({
    uuid: null,
    thing: { uuid: "X", subject: url },
    thingReport: { sms: "No response. Yet." },
  });

const [run, setRun] = useState();

  const [thingReportGetTime, setThingReportGetTime] = useState();
  const [thingReportResults, setThingReportResults] = useState([]);
  const [sequentialErrorCount, setSequentialErrorCount] = useState();

  useEffect(() => {
    if (!inputThingReportPollInterval) {
      return;
    }

    console.debug(
      "useThingReport inputThingReportPollInterval",
      inputThingReportPollInterval
    );

    setThingReportInterval(inputThingReportPollInterval);
  }, [inputThingReportPollInterval]);


  useHybridEffect(() => {

    if (thing == null) {
      return;
    }

if (thing?.poll?.interval) {

if (thing.poll.interval > 0) {
    setThingReportInterval(thing.poll.interval);
}
}
  }, [thing]);



  useEffect(() => {
    console.debug("useThingReport input", url, thingReportInterval);
    //    if (thingReportInterval == ,null) {
    //      return;
    //    }

    //
    if (url == null) {
      return;
    }

    //    getThingReport();

getThingReport();

    if (thingReportInterval == null) {
      return;
    }

    if (thingReportInterval === false) {
      return;
    }

    console.debug("useThingReport url", url);
    console.debug("useThingReport thingReportInterval", thingReportInterval);



//    getThingReport();

    const interval = setInterval(() => {
      console.debug(
        "useThingReport setInterval called input",
        url,
        thingReportInterval
      );
      getThingReport();
    }, thingReportInterval); // 20 Hz was 200.

    return () => clearInterval(interval);
  }, [url, thingReportInterval]);



// Factor this out.
useEffect(()=>{

console.log("useThingReport thing visible", thing?.visible,thing?.uuid);

if (thing?.visible == null) {return;}

if (thing.visible === true) {setIsVisible(true);}
if (thing.visible === false) {setIsVisible(false);}


}, [thing?.visible]);

useEffect(()=>{

//console.log("useThingReport thing visible", thing?.visible,thing?.uuid);
if (thing?.variables?.visible == null) {return;}

if (thing.variables.visible === true) {setIsVisible(true);}
if (thing.variables.visible === false) {setIsVisible(false);}


}, [thing?.variables?.visible]);


useEffect(()=>{

console.log("useThingReport thing card visible", thing?.card?.visible,thing?.uuid);
if (thing?.card?.visible == null) {return;}

if (thing.card.visible === true) {setIsVisible(true);}
if (thing.card.visible === false) {setIsVisible(false);}

}, [thing?.card?.visible]);

useEffect(()=>{

console.log("useThingReport run", run);

}, [run]);


useEffect(()=>{
if (isVisible == null) {return;}
console.log("useThingReport isVisible", thing?.subject, isVisible);
getThingReport();


}, [isVisible]);


//const test = thing?.variables?.card?.visible;
//thing.variables.card.visble






  useEffect(() => {
    if (input == null) {
      return;
    }
    if (input == "") {
      return;
    }
    setUrl(input);

    console.log("useThingReport input changed", input);

if (input?.variables?.visible) {
 setRun(input?.variables.visible);

}

    // Some conditions here where a thing report should not be called.
    //if (input == null) {return;}
    //if (input && input.endsWith('\/.json')) {return;}

    //    getThingReport();
  }, [input]);

  useEffect(() => {
if (url == null) {return;}
    console.log("useThingReport url", url);
    getThingReport();
  }, [url]);

  /*
useEffect(() =>{

console.log("useThingReport to", to);

}, [to]);
*/
  useHybridEffect(() => {
    if (thingReport == null) {
      console.log("useThingReport thingReport undefined");
      return;
    }

    console.log("useThingReport thingReport", thingReport);

    if (thingReportResults == null) {
      console.log("useThingReport thingReportResults undefined");
      setThingReportResults([{ ...thingReport, thingReportAt: zuluTime() }]);

      return;
    }

    console.log("useThingReport thingReport", thingReport);

    const count = 0;

    for (const thingReportResult of thingReportResults.reverse()) {
      if (thingReportResult.error) {
        count += 1;
      }
      if (!thingReport.error) {
        break;
      }
      console.log(thingReportResult);
    }
    setSequentialErrorCount(count);
  }, [thingReport]);

  useEffect(() => {
    console.log("sequentialErrorCount", sequentialErrorCount);
  }, [sequentialErrorCount]);

  function getThingReport() {

console.log("useThingReport getThingReport thing subject", thing?.subject);
/*
let isVisible = false;

// //  } = useThingReport(url, thingPollInterval, {datagram, visible:thing?.card?.visible});
//export default function useThingReport(input, inputThingReportPollInterval, thing) {

//console.log("useThingReport getThingReport url visible",thing?.visible);

// Yeah so here the effort is not to get things that can't be seen.

console.log("useThingReport getThingReport url", url);

if (thing?.variables?.visible && thing.variables.visible === true) {
isVisible = true;
console.log("useThingReport getThingReport thing variables visible", thing?.variables?.visible);

}

if (thing?.visible && thing.visible === true) {

// do nothing.
console.log("useThingReport getThingReport thing visible", thing?.visible);
isVisible = true;


}

*/



// doing nothing means we should render?


if (!isVisible) {
console.log("useThingReport getThingReport thing is not visible thing subject", thing?.subject);
return Promise.resolve({error:{message:"Thing is not visible"}});

}

//      setThingReportResults([{ ...thingReport, thingReportAt: zuluTime() }]);
if (thingReportResults?.thingReportAt) {

const ageMilliseconds = new Date() - new Date(thingReportResults?.thingReportAt);
console.log("useThingReport ageMilliseconds", ageMilliseconds);
const minimumClientPollInterval = 60000;
if (ageMilliseconds < minimumClientPollInterval) {

      return Promise.resolve({ error: { message: "Too soon" } });

}

}



console.log("useThingReport getThingReport thing visible subject", thing?.subject);


    console.debug("useThingReport getThingReport");
    const startTime = new Date();
    if (flag === "red") {
      console.log("useThingReport getThingReport flag", flag);
      return Promise.resolve({ error: { message: "Flag is red" } });
      //return;
    }

    //   console.log("useThingReport getThingReport url", to);
    //   const url = to;

    if (url == null) {
      console.error("useThingReport getThingReport no url seen");
      return Promise.resolve({ error: { message: "No url" } });
    }
    if (url && url.endsWith("/.json")) {
      console.error("useThingReport getThingReport malformed url");
      return Promise.resolve({ error: { message: "Malformed url" } });
    }

    setFlag("red");

    console.debug("useThingReport getThingReport url", url);
const urlConditioned = url.replace('/history-','/history/')



    return getWebJson(urlConditioned, "")
      .then((result) => {
        console.log("useThingReport getThingReport url result", url, result);

        if (!result) {
          console.error("useThingReport getThingReport url  not result", url);

          return true;
        }

        if (result && result.thingReport === false) {
          console.error(
            "useThingReport getThingReport url result.thingReport false",
            url
          );

          // No thing report. Do not update snapshot.
          return false;
        }

        if (result && result.thingReport) {
          console.log(
            "useThingReport getThingReport setThingReport result thingReport",
            result.thingReport
          );
          setThingReport(result.thingReport);
        } else {
          // Failback situation where thingReport format not found.
          console.log("useThingReport getThingReport fail to result", result);
console.log(result?.error?.message);


if (result?.error) {}
// Do not update the thingReport. Something odd has happened.
          setThingReport((prevThingReport)=> ({...prevThingReport, error:result?.error}));


        }
        // dev flag available not available
        console.debug("useThingReport getThingReport setFlag green");
        setFlag("green");
        const endTime = new Date();
        setThingReportGetTime(endTime - startTime);
        return result;
      })
      .catch((error) => {
        console.error("useThingReport getThingReport error", error);
        setFlag("yellow");
        return { ...thingReport, error };
        //return;
      });
  }

  const saveThingReport = (userThingReport) => {
    console.log(
      "useThingReport saveThingReport userThingReport",
      userThingReport
    );
    setThingReport(userThingReport);
  };

  const deleteThingReport = (userThingReport) => {
    // Leave no rubbish behind.
    setThingReport(false);
  };

  return {
    //    deleteThingReport: deleteThingReport,
    //    setThingReport: saveThingReport,
    getThingReport,
    thingReport,
    flag,
    thingReportGetTime,
    thingReportInterval,
    run,
  };
}
