import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

function validateSeries(data) {
  const t = data.filter((d) => {
    //console.log("PolarChart validate d", d.x, d.y);
    return !isValidData(d);
    /*
if (d == null) {return true;}
if (d.x == null) {return true;}
if (d.y == null) {return true;}

if (isNaN(d.x)) {return true;}
if (isNaN(d.y)) {return true;}

return false;
*/
  });

  console.log("PolarChart validate t", t);
  console.log("PolarChart validate t length", t.length);

  if (t.length !== 0) {
    return false;
  }

  return true;
}

function isValidData(d) {
  if (d == null) {
    return false;
  }
  if (d.x == null) {
    return false;
  }
  if (d.y == null) {
    return false;
  }

  if (isNaN(d.x)) {
    return false;
  }
  if (isNaN(d.y)) {
    return false;
  }

  return true;
}

function conditionSeries(data) {
  //return data;
  // Remove null... because this makes the whole svg line not shwo

  const t = data.filter((d) => {
    //console.log("PolarChart validate d", d.x, d.y);
    return isValidData(d);
  });

  console.log("PolarChart validate t", t);
  console.log("PolarChart validate t length", t.length);

  return t;
}

const PolarChart = ({ agentInput }) => {
  const { data, colors, strokeWidth, circleData, wedges, subwedges } =
    agentInput;
  const chartRef = useRef(null);

const [fontSize, setFontSize] = useState("14px");

  useEffect(() => {
if (data == null) {return;}
    console.debug("PolarChart data", data);
    const chartContainer = d3.select(chartRef.current);

    // Function to update chart dimensions
    const updateChartDimensions = () => {
      // Get the width of the container element
      const containerWidth = chartContainer
        .node()
        .getBoundingClientRect().width;

      // Set up chart dimensions
      const width = containerWidth;
      const height = containerWidth;
      const radius = Math.min(width, height) / 2;

if (width < 600) {
setFontSize("11px");
} else if (width < 1200) {
setFontSize("14px");
} else {
setFontSize("14px");
}


      // Remove any existing chart
      chartContainer.select("svg").remove();

      // Create the chart SVG element
      const svg = chartContainer
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        //      .style('width', "100%")
        //      .style('height', "100%")
        .style("max-width", "100%")
        .append("g")
        .attr("transform", `translate(${width / 2},${height / 2})`);

      const maxValue = d3.max(data.flat(), (d) => d.y);
      const minValue = d3.min(data.flat(), (d) => d.y);

      // Determine the minimum and maximum timestamps in the data
      const minTimestamp = d3.min(data.flat(), (d) => d.x);
      const maxTimestamp = d3.max(data.flat(), (d) => d.x);

      console.log("PolarChart minValue", minValue);
      console.log("PolarChart maxValue", maxValue);
      console.log("PolarChart minTimeStamp", minTimestamp);
      console.log("PolarChart maxTimeStamp", maxTimestamp);

      // Get the current local timezone offset in minutes
      const timezoneOffset = new Date().getTimezoneOffset();

      // Calculate the offset in milliseconds from the start of the day
      const offset = new Date(minTimestamp).setUTCHours(
        timezoneOffset / 60,
        timezoneOffset % 60,
        0,
        0
      );
      //const wedges = 365;
      //const subwedges = 24;
      // Convert POSIX timestamp to angle in radians
      const angleScale = d3
        .scaleTime()
        .domain([
          new Date(offset),
          new Date(offset + wedges * subwedges * 60 * 60 * 1000),
        ]) // 24 hours in milliseconds
        .range([0, Math.PI * 2]);

      const minRadius = radius / 4;

      console.log("PolarChart minradius", minRadius);

      // Create concentric circles
      const radiusScale = d3
//        .scaleSqrt()
        .scaleLinear()
        .domain([minValue, maxValue])
        .range([minRadius, radius]);
      //const circleData = [10, 11, 12, 13, 14, 15, 16];

      svg
        .selectAll(".circle")
        .data(circleData)
        .enter()
        .append("circle")
        .attr("class", "circle")
        .attr("cx", 0)
        .attr("cy", 0)
        .attr("r", (d) => radiusScale(d))
        .style("fill", "none")
        .style("stroke", "#ccc")
        .style("opacity", 0.5);

      // Create the radial axes
      const axes = svg
        .selectAll(".axis")
        .data(d3.range(0, wedges)) // Create an array from 0 to 23
        .enter()
        .append("g")
        .attr("class", "axis");

      axes
        .append("line")
        .attr("x1", (d) => {
          const angle = angleScale(
            new Date(offset + d * subwedges * 60 * 60 * 1000)
          );
          const stopRadius = 0.05 * radius; // Subtract strokeWidth to stop short of the hub
          return stopRadius * Math.cos(angle);
        })
        .attr("y1", (d) => {
          const angle = angleScale(
            new Date(offset + d * subwedges * 60 * 60 * 1000)
          );
          const stopRadius = 0.05 * radius; // Subtract strokeWidth to stop short of the hub
          return stopRadius * Math.sin(angle);
        })

        .attr("x2", (d) => {
          const angle = angleScale(
            new Date(offset + d * subwedges * 60 * 60 * 1000)
          );
          const stopRadius = radius; // Subtract strokeWidth to stop short of the hub
          return stopRadius * Math.cos(angle);
        })
        .attr("y2", (d) => {
          const angle = angleScale(
            new Date(offset + d * subwedges * 60 * 60 * 1000)
          );
          const stopRadius = radius; // Subtract strokeWidth to stop short of the hub
          return stopRadius * Math.sin(angle);
        })
        .style("stroke", "#ccc");




      // Plot the data

      //console.log("Polarchart data validate", validateData(data));

      data.forEach((series, seriesIndex) => {
        console.log("PolarChart series", series);
        console.log("PolarChart seriesIndex", seriesIndex);
        console.log(
          "PolarChart series colors[seriesIndex]",
          colors[seriesIndex]
        );
        console.log("PolarChart series validate", validateSeries(series));

        const color = colors[seriesIndex];

        const conditionedSeries = conditionSeries(series);

        const line = d3
          .lineRadial()
          .angle((d, i) => angleScale(new Date(d.x)))
          .radius((d) => radiusScale(d.y));

        svg
          .append("path")
          .datum(conditionedSeries)
          .attr("class", "line")
          .attr("d", line)
          //          .style("stroke", "#b4b4b4")
          .style("stroke", color)
          .style("fill", "none")
          .style("stroke-width", strokeWidth);
      });
      //  };
/*
      circleData.map((m) => {
        var label = svg
          .append("text")
          //  .attr("x", 4)
          .attr("dx", "0.7em")
          .attr("y", -1 * radiusScale(m))
           .attr("dy", "-0.3em")
          .style("stroke", "#999")
          .text(m);
      });
*/

circleData.map((m) => {
  // Append the text element
  var label = svg
    .append("text")
    .attr("dx", "0.7em")
  .style("font-size", fontSize) // Adjust font size as needed
   .style("stroke", "#aaa") 
   .text(m)

  // Get the bounding box of the text
  var bbox = label.node().getBBox();

  // Compute the y position to center the text
  var centerY = -1 * radiusScale(m) + bbox.height / 2;

  // Set the y position
  label.attr("y", centerY);
});

/*
circleData.map((m) => {
  // Append the text element
  var label = svg
    .append("text")
    .attr("dx", "0.7em")
    .text(m)
    .style("stroke", "#999");

  // Get the bounding box of the text
  var bbox = label.node().getBBox();

  // Compute the y position to center the text
  var centerY = -1 * radiusScale(m) + (bbox.height / 2) - bbox.y;

  // Set the y position
  label.attr("y", centerY);
});
*/
/*
circleData.map((m) => {
  // Append the text element
  var label = svg
    .append("text")
    .attr("dx", "0.7em")
    .text(m)
    .style("stroke", "#999");

  // Get the bounding box of the text
  var bbox = label.node().getBBox();

  // Compute the y position to center the text
  var centerY = -1 * radiusScale(m) - (bbox.height / 2);

  // Set the y position
  label.attr("y", centerY);
});
*/

const timeLabelRadiusOffset = 20;
const skipNth = 6;
const numberOfLabels = 24;
const timeSpokeLabels = ["24:00",
"1AM","2AM","3AM","4AM",
"5AM","6AM","7AM","8AM","9AM","10AM","11AM","12:00","1PM","2PM",
"3PM","4PM","5PM","6PM", "7PM", "8PM", "9PM", "10PM","11PM"];
// Add text labels on each of the axes
axes
  .selectAll("text")
  .data(d3.range(0, numberOfLabels)) // Assuming numberOfLabels is defined
  .enter()
  .filter((d) => d % skipNth === 0) // Skip every Nth label
  .append("text")
  .attr("x", (d) => {
    const angle = angleScale(new Date(offset + d * subwedges * 60 * 60 * 1000)) - Math.PI / 2;
    const labelRadius = radius - timeLabelRadiusOffset; // Adjust the label position slightly outside the axis
    return labelRadius * Math.cos(angle);
  })
  .attr("y", (d) => {
    const angle = angleScale(new Date(offset + d * subwedges * 60 * 60 * 1000)) - Math.PI / 2;
    const labelRadius = radius - timeLabelRadiusOffset; // Adjust the label position slightly outside the axis
    return labelRadius * Math.sin(angle);
  })
  .text((d) => timeSpokeLabels[d]) // Replace with your desired label text
  .attr("text-anchor", "middle") // Center the text horizontally
  .attr("dominant-baseline", "middle") // Center the text vertically
  .style("font-size", fontSize) // Adjust font size as needed
  .style("fill", "#aaa"); // Set text color



    };
    // Update chart dimensions on initial render
    updateChartDimensions();

    // Update chart dimensions on window resize
    window.addEventListener("resize", updateChartDimensions);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateChartDimensions);
    };
  }, [data, colors]);

  return <div ref={chartRef} />;
};

export default PolarChart;
