import React, { useState, useEffect } from "react";

import "../index.css";
import {
  Typography,
  //  Avatar,
  //  ListItemAvatar,
  Box,
} from "@mui/material";

import useHybridEffect from "../useHybridEffect.js";

import {
  Button,
  TextField,
  IconButton,
  ListItem,
  ListItemText,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";

import {
  //AddCircleOutlineRounded,
  //DeleteOutlineRounded,
  Edit,
} from "@mui/icons-material";

import Forget from "../components/Forget.js";
import Trace from "../components/Trace.js";
import Stream from "../components/Stream.js";
import BubbleLevel from "../components/BubbleLevel.js";
import Inclinometer from "../components/Inclinometer.js";

import ThingButton from "../components/Button.js";

import Magnetometer from "../components/Magnetometer.js";

import MotionReference from "../components/MotionReference.js";

import Ping from "../components/Ping.js";
import Quantity from "../components/Quantity.js";

import History from "../components/History.js";

//import { getSnapshot } from "../util/database.js";

//import useSnapshot from "../useSnapshot.js";

import { isText, extractUuid, extractNuuid, getSlug } from "../util/text.js";

import { devFlag, debugFlag } from "../util/dev.js";

//import { useSwipeable } from "react-swipeable";

const { REACT_APP_SNAPSHOT } = process.env;

// refactor as
// Snapshot(thing, agentInput)

const engineState = process.env.REACT_APP_ENGINE_STATE;
const defaultSnapshotInterval = process.env.REACT_APP_SNAPSHOT_INTERVAL;

function Periods({ thing, agentInput }) {
  //const visibleStreams = ['speed_in_knots','speed_in_cables','transducer-throllad0'];

  const { visible: visibleStreams } = agentInput;

  const datagram = thing;

  const agent_input = agentInput;
  const webPrefix = agentInput;
  //const [flag, setFlag] = useState();
  //const [requestedAt, setRequestedAt] = useState();
  const [reply, setReply] = useState("");
  const [snapshotInterval, setSnapshotInterval] = useState(
    defaultSnapshotInterval
  );

  const defaultToSnapshot = REACT_APP_SNAPSHOT;
  const [toSnapshot, setToSnapshot] = useState(defaultToSnapshot);

  //  const { snapshot, flag, snapshotRunTime } = useSnapshot(toSnapshot);

  //const [data, setData] = useState({
  //  thing: { uuid: "X" },
  //  thingReport: { sms: "No response. Yet." },
  //});
  const [data, setData] = useState();

  const [open, setOpen] = useState(false);

  const replyAgentDialog = (thing) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useHybridEffect(() => {
    if (agentInput.periods == null) {
      return;
    }

    setData({ periods: agentInput.periods });
  }, [agentInput && agentInput.periods]);

  useHybridEffect(() => {
    if (agentInput.data == null) {
      return;
    }

    setData(agentInput.data);
  }, [agentInput && agentInput.data]);

  function humanTime(timestamp) {
    const ts = new Date();
    return ts.toISOString();
  }

  function fromName() {
    if (datagram === undefined) {
      return "Agent";
    }

    if (datagram && datagram.from === undefined) {
      return "Agent";
    }

    return datagram.from;
  }

  function timeStamp() {
    var date = Date.now();
    return date.toString();
  }

  const startTime = new Date();

  function handleChangeStream(c) {
    console.log("Snapshot handleChangeStream c", c);
  }

  useEffect(() => {
    console.log("Snapshot data", data);
  }, [data]);

  function callBack() {
    console.log("Agent callBack called.");
  }

  function stripPeriod(text) {
    //const parts = text.split("-");
    //return text;

    console.log("History extractPeriod text", text);
    if (text == null) {
      return null;
    }
    const x = text.split(".json");

    var parts = x[0].split("-");
    var result = parts[parts.length - 1]; // Or parts.pop();

    const y = text.replace("-" + result, "");

    return y;
  }

  function periodLink(t, period) {
    const transducer = agentInput?.transducer?.sensor_id;
    //console.log("periodLink agentInput", agentInput);
    //if (transducer == null) {return "/transducers-" + period;}

    if (t == null) {
      return "/snapshot";
    }

    if (t?.subject == null) {
      return (
        "/history/" + "transducers-" + transducer.toLowerCase() + "-" + period
      );
    }

    return "/" + stripPeriod(t.subject) + "-" + period;

    if (t?.uuid == null) {
      //      return "/history/" + "transducers-" + transducer.toLowerCase() + "-" + period;
      return "/" + t.subject + " " + period;
    }

    return (
      "/history/" +
      t.uuid +
      "-snapshot-transducers-" +
      transducer.toLowerCase() +
      "-" +
      period
    );
  }

  function transducerAmount(t) {
    if (t && t.amount) {
      return t.amount;
    }
    return t;
  }

  return (
    <>
      {/* Handle the transducers field special */}
      PERIODS
      <br />
      {data && data.periods && (
        <>
          {Object.keys(data.periods).map((period, index) => {
            // console.log("Snapshot transducer", transducer);
            const amountFlag = false;
            var amountText = "";
            if (amountFlag) {
              //amountText = transducerAmount(data.transducers[transducer]);
            }
            //         if (visibleStreams.includes(transducer)) {

            var periodText = period;
            if (Array.isArray(data.periods)) {
              periodText = data.periods[index];
            }

            /*
transducer.sensor_id
*/

            return (
              <>
                {/*PERIODLINK{" "}{periodLink(thing, periodText)}<br />
{JSON.stringify(thing)}*/}
                <ThingButton
                  thing={thing}
                  //            agentInput={{ disabled: false, text: transducer + amountText, link: "/history/" + thing.uuid + "-snapshot-transducers-" + transducer.toLowerCase() + "-1d" }}
                  agentInput={{
                    disabled: false,
                    text: periodText,
                    link: periodLink(thing, periodText),
                  }}
                  onThingReport={() => {}}
                />
              </>
            );
          })}
        </>
      )}
    </>
  );
}

export default Periods;
