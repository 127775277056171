import React, { useState, useEffect, useRef } from "react";
import { IconButton, Typography, Box } from "@mui/material";
import useThing from "../useThing.js";

import Visible from "../components/Visible.js";
import Not from "../components/Not.js";
import ThingButton from "../components/Button.js";
import ThingSwitch from "../components/Switch.js";

var style = {
  whiteSpace: "pre-line",
  fontWeight: "500",
  color: "#ffffff",
  background: "#0c387194",
  borderRadius: "7px",
  padding: "5px 10px 5px 10px",
  marginBottom: "2px",
};

export default function Event({thing:inputThing, agentInput, thingReport}) {
  //  var { message } = props;

  const { thing,updateThing } = useThing(inputThing);

  return (<>
{/* JSON.stringify({"hey":"hey"}) */}
{JSON.stringify(agentInput?.event) }<br/><br/>
<Visible thing={thing} agentInput={agentInput} />
<Not thing={thing} agentInput={agentInput} />
                <ThingButton
                  thing={thing}
                  //            agentInput={{ disabled: false, text: transducer>
                  agentInput={{
                    disabled: false,
                    text: "texty",
                    link: "https://stackr.ca",
                  }}
                  onThingReport={() => {}}
                />
switchee
{agentInput?.event?.uuid}
                <ThingSwitch
                  thing={{uuid:agentInput?.event?.uuid}}
                  //            agentInput={{ disabled: false, text: transducer>
                  agentInput={{disabled:false}}
                  updateThing={updateThing}

                  onThingReport={() => {}}
                />
switcher



</>);
}

/*
                <ThingSwitch
                  thing={{uuid:agentInput?.event?.uuid}}
                  //            agentInput={{ disabled: false, text: transducer>
                  agentInput={{
                    disabled: false,
                    text: "texty",
                    link: "https://stackr.ca",
                  }}
                  updateThing={updateThing}

                  onThingReport={() => {}}
                />
*/
