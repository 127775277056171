import React, { useEffect, useState } from "react";
//import './Login.css';
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import UpdateIcon from "@mui/icons-material/Update";

import { humanTime, humanAge, humanRuntime } from "./../util/time.js";

export default function ToGoTime({ thing, agentInput, toGoTime, onRefresh }) {

const [hasToGoTime, setHasToGoTime] = useState();

  useEffect(() => {
    if (toGoTime == null) {
      return;
    }

if (toGoTime > 0 && hasToGoTime == null) {
setHasToGoTime(true);
}

//    console.debug("ToGoTime toGoTime", thing.uuid, toGoTime);

    if (toGoTime < 0 && hasToGoTime === true) {
setHasToGoTime(false);
//      onRefresh();
    }

    if (toGoTime >= 0 && hasToGoTime === false) {
setHasToGoTime(true);
      //onRefresh();
    }


  }, [toGoTime]);

useEffect(()=>{
console.log("ToGoTime hasToGoTime", hasToGoTime);
onRefresh();

},[hasToGoTime]);

//function handleRefresh() {

//onRefresh;

//}


  return (
    <>
      <UpdateIcon onClick={onRefresh}>REFRESH</UpdateIcon>
      TOGOTIME {humanRuntime(toGoTime)}
      <br />
    </>
  );
}

//ToGoTime.propTypes = {
  //  token: PropTypes.func.isRequired
//};
