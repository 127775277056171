import React, {useEffect} from "react";
import Button from "@mui/material/Button";
import useToken from "../useToken.js";
import { useNavigate } from "react-router-dom";

import { devFlag, debugFlag } from "../util/dev.js";

import useThing from "../useThing.js";

import { zuluTime } from "../util/time.js";
import { toast } from "react-toastify";


//import './Login.css';
import PropTypes from "prop-types";

//async function logoutUser(token) {
//setThing(thing.uuid, {variables:{done:{flag:false, refreshedAt:zuluTime()}}});
//}


export default function Logout({thing:inputThing, agentInput}) {

const {thing, setThing} = useThing(inputThing);
const { token, deleteToken, isValidToken } = useToken();
  const navigate = useNavigate();

  //  const [username, setUserName] = useState();
  //  const [password, setPassword] = useState();

async function logoutUser(token) {
  //deleteTokenRocky(token);
  /*
 return fetch('http://localhost:8080/login', {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json'
   },
   body: JSON.stringify(credentials)
 })
   .then(data => data.json())
*/


setThing(thing.uuid, {variables:{done:{flag:false, refreshedAt:zuluTime()}}});


}

useEffect(() =>{

if (token === false) {toast("Logged out.");}

}, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    //    const token = await loginUser({
    //      username,
    //      password
    //    });
    console.log("Logout handleSubmit");
    const token = false;
    deleteToken(token);
    logoutUser(token);

//    window.history.replaceState(null, null, "/");
navigate("/logout");

  };

  return (
    <div className="logout-wrapper">
{debugFlag && (<>LOGOUT</>)}
{isValidToken && (<>
      <form onSubmit={handleSubmit}>
        <div>
          <Button variant="action" type="submit">Logout</Button>
        </div>
      </form>
</>)}

    </div>
  );
}

//Logout.propTypes = {
//  deleteToken: PropTypes.func.isRequired,
//};
