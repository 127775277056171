import React from 'react';

const HierarchicalTable = ({ data }) => {
  // Check if data is defined and is an object
  if (!data || typeof data !== 'object') {
    return <div>No data available</div>;
  }

  // Function to build a nested structure from the transducer keys
  const buildTree = (keys) => {
    const tree = {};

    keys.forEach((key) => {
      const parts = key.split(':');
      let currentLevel = tree;

      parts.forEach((part) => {
        if (!currentLevel[part]) {
          currentLevel[part] = {};
        }
        currentLevel = currentLevel[part];
      });
    });

    return tree;
  };

  // Build the tree from the transducer keys
  const transducerKeys = Object.keys(data);
  const tree = buildTree(transducerKeys);

  // Function to flatten the tree into rows for the table
  const flattenTree = (tree, parentKeys = []) => {
    let rows = [];

    Object.keys(tree).forEach((key) => {
      const currentKeys = [...parentKeys, key];
      const currentRow = {
        level1: currentKeys[0],
        level2: currentKeys[1],
        level3: currentKeys[2],
        json: JSON.stringify(data[currentKeys.join(':')]) || '{}',
      };

      rows.push(currentRow);

      // Recursively flatten the next level
      rows = rows.concat(flattenTree(tree[key], currentKeys));
    });

    return rows;
  };

  const rows = flattenTree(tree);

  return (
    <table>
      <thead>
        <tr>
          <th>Level 1</th>
          <th>Level 2</th>
          <th>Level 3</th>
          <th>Data</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr key={index}>
            <td>{row.level1}</td>
            <td>{row.level2}</td>
            <td>{row.level3}</td>
            <td>{row.json}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default HierarchicalTable;
