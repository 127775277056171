import React, {useEffect, useState} from 'react';
import { Button, Card, CardContent, Typography, Grid } from '@mui/material';

const Invite = ({ thing, agentInput }) => {

  const handleButtonClick = (url) => {
    // Open the URL in a new tab
    window.open(url, '_blank');
  };




const styles = {
  card: {
    width: '100%',
    maxWidth: 600,
    margin: 0,
    padding: 16,
    boxShadow: 'none',
    border: '2px solid transparent', // Default border
    transition: 'border-color 0.3s ease', // Smooth transition for border color
  },
  cardHover: {
    border: '2px solid orange', // Border color on hover
  },
  cardContent: {},
};


  if (agentInput?.invite == null) {
    return (<></>);
  }

const inviteLabel = agentInput?.invite?.slug;
const l = inviteLabel.slice(0,-5);
const m = l.replace(/-/g,' ');

return (<>
            <Button
              variant="contained"
              onClick={() => handleButtonClick("https://stackr.ca/subscriptions/" + agentInput?.invite?.slug)}
              sx={{
                backgroundColor: 'orange',
                color: 'white',
                border: 'none',
                boxShadow: 'none',
                padding: '10px 15px', // Adjust padding for proportionality
                marginRight: '10px', // Space out buttons
                '&:hover': {
                  backgroundColor: 'darkorange',
                },
              }}
            >
{m}
   {/*           {agentInput?.invite?.slug} */}
            </Button>
</>
);

};

export default Invite;
