import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { requestManager } from '../util/database.js'; // Adjust the path as necessary

const Requests = () => {
  const [isRequestsEnabled, setIsRequestsEnabled] = useState(true);
  const [allowRepeatRequests, setAllowRepeatRequests] = useState(false);
  const [url, setUrl] = useState('https://api.example.com/data'); // Default URL

  useEffect(() => {
    // Disable requests after 10 seconds
    requestManager.disableRequestsAfter(null);
//    requestManager.setRequestState(true);
//    requestManager.setAllowRepeatRequests(false);
  }, []);

  const testRequest = async () => {
    if (requestManager.canMakeRequest(url)) {
      try {
        const response = await axios.get(url);
        console.log(response.data);
        requestManager.incrementRequestCount(url); // Increment request count for the URL
      } catch (error) {
        console.error('Error making request:', error);
      }
    } else {
      console.log('Requests are turned off or repeat requests are not allowed for this URL.');
    }
  };

  const toggleRequests = () => {
    const newState = !isRequestsEnabled;
    setIsRequestsEnabled(newState);
    requestManager.setRequestState(newState);
  };

  const toggleAllowRepeatRequests = () => {
    const newState = !allowRepeatRequests;
    setAllowRepeatRequests(newState);
    requestManager.setAllowRepeatRequests(newState);
  };

return (<></>);

  return (
    <div>
      <h1>REST Request Example</h1>
      <input
        type="text"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        placeholder="Enter request URL"
      />
      <button onClick={testRequest}>Test Request</button>
      <p>{requestManager.canMakeRequest(url) ? 'Requests are allowed.' : 'Requests are turned off or repeat requests are not allowed for this URL.'}</p>
      <label>
        <span>Enable Requests:</span>
        <input
          type="checkbox"
          checked={isRequestsEnabled}
          onChange={toggleRequests}
        />
      </label>
      <label>
        <span>Allow Repeat Requests:</span>
        <input
          type="checkbox"
          checked={allowRepeatRequests}
          onChange={toggleAllowRepeatRequests}
        />
      </label>
    </div>
  );
};

export default Requests;
