import React, { useState, useEffect, useRef } from "react";
import { IconButton, Typography, Box } from "@mui/material";

import { devFlag, debugFlag } from "../util/dev.js";
/*
var style = {
  whiteSpace: "pre-line",
  fontWeight: "500",
  color: "#ffffff",
  background: "#0c387194",
  borderRadius: "7px",
  padding: "5px 10px 5px 10px",
  marginBottom: "2px",
  lineHeight: "1.4", // Adjust this value as needed
};
*/
var style = {
  whiteSpace: "pre-line",
  fontWeight: "500",
  color: "#ffffff",
  background: "#0c387194", // Semi-transparent background
  borderRadius: "7px", // More rounded corners for a bubble effect
  padding: "10px 10px 10px 10px", // Increased padding for top and bottom
  marginBottom: "10px", // Space between messages
  lineHeight: "1.4", // Adjust line height for readability
  fontSize: "14px", // Smaller font size for SMS-like appearance
//  maxWidth: "90%", // Limit the width of the bubble
//  alignSelf: "flex-start", // Align to the left (for incoming messages)
//  display: "inline-block", // Make it behave like a block element
};

export default function Message(props) {
  var { message, agentInput } = props;

//const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

const uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i

const conditionedMessage = message && message.subject
    ? message.subject
        .replace("|", "\n") // Replace '|' with a newline
        .split(' ') // Split the message into tokens
        .filter(token => !uuidRegex.test(token)) // Filter out tokens that match the UUID regex
        .join(' ') // Join the remaining tokens back into a string
    : 'meep'; // Default to an empty string if message or subject is not defined



  return (
    <>
{debugFlag && (<>
MESSAGE
<br /></>)}
      {message && message.subject && (
        <>
          <Typography variant="subtitle1">
            <div style={style}>
              <div>
                {" "}
{conditionedMessage}
              </div>
              {/*<div>{humanAge(message.createdAt)}</div>*/}
            </div>
          </Typography>
          <Typography
            variant="body2"
            style={{
              padding: "0px 10px 0px 10px",
              textAlign: "right",
              marginBottom: "10px",
            }}
          >
            {/*message.id*/}
            {/*timeText*/}
          </Typography>
        </>
      )}
    </>
  );
}
