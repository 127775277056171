import React, { useState, useEffect } from "react";

import DynamicComponent from "../components/DynamicComponent.js";
import useHybridEffect from "../useHybridEffect.js";

import "../index.css";
import {
  Typography,
  //  Avatar,
  //  ListItemAvatar,
  Box,
} from "@mui/material";

import {
  Button,
  TextField,
  IconButton,
  ListItem,
  ListItemText,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";

import {
  //AddCircleOutlineRounded,
  //DeleteOutlineRounded,
  Edit,
} from "@mui/icons-material";

import ExpanderCollapser from "../components/ExpanderCollapser.js";


import Forget from "../components/Forget.js";
import Trace from "../components/Trace.js";
import Stream from "../components/Stream.js";
import BubbleLevel from "../components/BubbleLevel.js";
import Inclinometer from "../components/Inclinometer.js";

import Magnetometer from "../components/Magnetometer.js";

import MotionReference from "../components/MotionReference.js";

import Ping from "../components/Ping.js";
import Transducers from "../components/Transducers.js";
import DataTable from "../components/DataTable.js";

import Transducer from "../components/Transducer.js";
import HierarchicalTable from "../components/HierarchicalTable.js";


//import { getSnapshot } from "../util/database.js";

import useSnapshot from "../useSnapshot.js";

import {
  isText,
  extractUuid,
  extractNuuid,
  getSlug,
  capitalizeFirstLetter,
} from "../util/text.js";

import {
  deepDiffMapper,
  getChangedLeaves,
} from "../util/data.js";

//  function usePrior(value) {


import { devFlag, debugFlag } from "../util/dev.js";

//import { useSwipeable } from "react-swipeable";

const { REACT_APP_SNAPSHOT } = process.env;

// refactor as
// Snapshot(thing, agentInput)

const engineState = process.env.REACT_APP_ENGINE_STATE;
const defaultSnapshotInterval = process.env.REACT_APP_SNAPSHOT_INTERVAL;


function Snapshot({ thing, agentInput }) {
  const visibleStreams = [
    "speed_in_knots",
    "speed_in_cables",
    "transducer-throllad0",
  ];

  const datagram = thing;

  const agent_input = agentInput;
  const webPrefix = agentInput;
  //const [flag, setFlag] = useState();
  //const [requestedAt, setRequestedAt] = useState();
  const [reply, setReply] = useState("");
  const [snapshotInterval, setSnapshotInterval] = useState(
    defaultSnapshotInterval
//1000
  );

  const defaultToSnapshot = REACT_APP_SNAPSHOT;
  const [toSnapshot, setToSnapshot] = useState(defaultToSnapshot);

  const { snapshot, priorSnapshot, flag, snapshotRunTime,snapshotRunAt } = useSnapshot(toSnapshot, snapshotInterval, thing);

 //   snapshotRunAt:  snapshotRunAt: nowRunAt,nowRunAt,


  useHybridEffect(() => {
    if (thing == null) {
      return;
    }
    if (thing.subject == null) {
      return;
    }

    const uuidPathname = extractUuid(thing.subject);

    if (uuidPathname === true) {
      return;
    }
    if (uuidPathname === false) {
      return;
    }

    setToSnapshot("https://stackr.ca/snapshot/" + uuidPathname + "/hey-snapshot.json");
  }, [thing]);

  useEffect(() => {
    console.log("Snapshot toSnapshot", toSnapshot);
  }, [toSnapshot]);

  //const [data, setData] = useState({
  //  thing: { uuid: "X" },
  //  thingReport: { sms: "No response. Yet." },
  //});
  const [data, setData] = useState();

  const [open, setOpen] = useState(false);

  const replyAgentDialog = (thing) => {
    setOpen(true);
  };
  /*
  const config = {
    delta: 10, // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: false, // prevents scroll during swipe (*See Details*)
    trackTouch: true, // track touch input
    trackMouse: false, // track mouse input
    rotationAngle: 0, // set a rotation angle
    swipeDuration: Infinity, // allowable duration of a swipe (ms). *See Notes*
    touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
  };

  const handlers = useSwipeable({
    onSwiped: (eventData) => console.log("User Swiped!", eventData),
    ...config,
  });
*/
  const handleClose = () => {
    setOpen(false);
  };

  useHybridEffect(() => {
if (snapshot == null) {return;}

if (priorSnapshot == null) {return;}
//    console.log("deepDiffMapper", deepDiffMapper([snapshot, priorSnapshot]));

//  const differences = snapshot && priorSnapshot ? getChangedLeaves(deepDiffMapper.map(snapshot, priorSnapshot)) : null;
//  const isDifferencesEmpty = differences && Object.keys(differences).length === 0;
//if (isDifferencesEmpty) {return;}

    console.log("Snapshot thing snapshot", thing, snapshot);

    if (snapshot && snapshot.thingReport) {
      console.log("Snapshot thing thingReport", snapshot.thingReport);
      if (snapshot.thingReport.snapshot) {
        console.log(
          "Snapshot setData snapshot.thingReport.snapshot",
          snapshot.thingReport.snapshot
        );
        setData(snapshot.thingReport.snapshot);
        return;
      }
    }

    if (snapshot?.thingReport == null) {
      console.log("Snapshot setData snapshot", snapshot);
      setData(snapshot);
      return;
    }
    // Or perhaps don't refresh snapshot.Snapshot thing snapshot

//    setData(true);

  }, [snapshot, priorSnapshot]);

  function humanTime(timestamp) {
    const ts = new Date();
    return ts.toISOString();
  }

  useEffect(() => {
    if (thing == null) {
      return;
    }
    console.log("Snapshot data", toSnapshot, data);
  }, [data]);


  useHybridEffect(() => {
    if (thing == null) {
      return;
    }

    console.log("Snapshot thing", toSnapshot, thing.uuid, thing);
  }, [thing]);


  function handleThingReport(r) {
r.preventDefault();


}

  function fromName() {
    if (datagram === undefined) {
      return "Agent";
    }

    if (datagram && datagram.from === undefined) {
      return "Agent";
    }

    return datagram.from;
  }

  function timeStamp() {
    var date = Date.now();
    return date.toString();
  }

  const [ampDataPointer, setAmpDataPointer] = useState(0);
  const [ampPoints, setAmpPoints] = useState([]);
  const startTime = new Date();
  const [voltPoints, setVoltPoints] = useState([]);
  const [tracePeriod, setTracePeriod] = useState();






  function handleChangeStream(c) {
    console.log("Snapshot handleChangeStream c", c);
  }

  useEffect(() => {
    console.log("Snapshot data", data);
  }, [data]);

  function callBack() {
    console.log("Agent callBack called.");
  }

  const deleteButton = (
    <Forget uuid={datagram && datagram.uuid} callBack={callBack} />
  );

//  const differences = snapshot && priorSnapshot ? getChangedLeaves(deepDiffMapper.map(snapshot, priorSnapshot)) : null;

  return (
    <>
{/*SNAPSHOT SIZE{' '}{JSON.stringify(snapshot, null, 2).length}{' bytes'}
<br />
*/}
{/*<pre>{JSON.stringify(differences, null, 2)}</pre>*/}

      {debugFlag && (
        <>
          <div>SNAPSHOT</div>
        </>
      )}

        {snapshotRunTime}ms {"now"} {Math.round(1000 / snapshotRunTime, 1)}Hz
        {" size "}
        {snapshot && JSON.stringify(snapshot).length}
        {" runAt "}
        {snapshotRunAt}
        <br />


<ExpanderCollapser>
<pre>snapshot{' '}{JSON.stringify(snapshot, null,2)}</pre>
</ExpanderCollapser>


      {debugFlag && (
        <>
          <div>URL {toSnapshot}</div>
        </>
      )}
      <div>
        {debugFlag && (
          <>
            FLAG {flag} COLOUR
            <br />
          </>
        )}
        {debugFlag && (
          <>
            GET TIME {snapshotRunTime}ms {Math.round(1000 / snapshotRunTime, 1)}
            Hz
            <br />
          </>
        )}

        {data && data.ping && <Ping ping={data.ping} />}
{/* In development */}
{false ? 
(<HierarchicalTable data={data} />) : (<></>)}
        {data && (
          <>
{/*<DataTable data={data} />*/}
            {Object.keys(data).map((transducer, index) => {
              //console.log("Snapshot transducer", transducer);
return (<Transducer agentInput={{transducer:transducer, index:index, data:data}} />);

            })}
          </>
        )}

{/*
        {true && data && data.alarms && (
          <>
            ALARMS
            <br />
            <pre>{JSON.stringify(data.alarms, null, 2)}</pre>
          </>
        )}
*/}
        {/* Handle the transducers field special */}
{/*
        {false && (
          <>
            TRANSDUCERS
            <br />
            <Transducers
              thing={thing}
              agentInput={{
                visible: visibleStreams,
                transducers: data && data.transducers,
              }}
            />
          </>
        )}
*/}


      </div>
    </>
  );
}





export default Snapshot;
