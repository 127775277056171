import React, { useState, useEffect } from "react";

import DynamicComponent from "../components/DynamicComponent.js";
import useHybridEffect from "../useHybridEffect.js";

import VisibilitySensor from "react-visibility-sensor";

import { styled } from "@mui/material/styles";

//import Button from "@mui/material/Button";

import Collapse from "@mui/material/Collapse";
//import Card from "@mui/material/Card";
//import CardHeader from "@mui/material/CardHeader";
//import CardMedia from "@mui/material/CardMedia";

import { IconButtonProps } from "@mui/material/IconButton";

import { createThing } from "../util/database.js";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "../index.css";
import {
  Typography,
  //  Avatar,
  //  ListItemAvatar,
  Box,
} from "@mui/material";

import {
  Button,
  TextField,
  IconButton,
  ListItem,
  ListItemText,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";

import {
  //AddCircleOutlineRounded,
  //DeleteOutlineRounded,
  Edit,
} from "@mui/icons-material";

import Forget from "../components/Forget.js";
import Trace from "../components/Trace.js";
import Stream from "../components/Stream.js";

import BubbleLevel from "../components/BubbleLevel.js";
import Inclinometer from "../components/Inclinometer.js";

import Magnetometer from "../components/Magnetometer.js";

import MotionReference from "../components/MotionReference.js";

import Ping from "../components/Ping.js";
import Transducers from "../components/Transducers.js";

//import { getSnapshot } from "../util/database.js";

import useToken from "../useToken.js";



import {
  isText,
  extractUuid,
  extractNuuid,
  getSlug,
  capitalizeFirstLetter,
} from "../util/text.js";

import { devFlag, debugFlag } from "../util/dev.js";

import {
  compressText,
  cleanUrl,
  extractUrl,
  extractUrls,
} from "stack-js/src/util/text";

function extractUrlsTest(text) {
  if (text == null) {
    return [];
  }

  let words = text.split(/\s+/); // Using a regex to split by whitespace
  let urls = []; // Initialize url to null

  for (let word of words) {
    try {
if (word.endsWith(":")) {continue;}
      let potentialUrl = new URL(word);
      const url = potentialUrl.href;
      urls.push(url);
      //        break;
    } catch (e) {
      // If the word is not a valid URL, it will throw an error which we ignore
    }
  }

  return urls;
}

function extractUrlsTest2(text) {
  //  var re = /https?:\/\/[^\s]+/;
  //  var re =
  //    "/\b(https?|ftp|file://)?[-A-Z0-9+&@#/%?=~_|$!:,.;]*[A-Z0-9+&@#/%=~_|$]/i";

  var re =
    "/\b(https?|ftp|file://)?[-A-Z0-9+&@#/%?=~_|$!:,.;]*[A-Z0-9+&@#/%=~_|$]/g";

  console.log("extractUrls text", text);
  const x = text.match(re);
  console.log("extractUrls x", x);
  if (x == null) {
    return false;
  }
  console.log("extractUrls text match", text.match(re));
  const url = text.match(re)[0];

  console.debug("extractUrls matches", url);
  return url;
}
/*
const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} size="large" />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface WebExpandMoreProps extends IconButtonProps {
  webExpand: boolean;
}

const WebExpandMore = styled((props: WebExpandMoreProps) => {
  const { webExpand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, webExpand }) => ({
  transform: !webExpand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
*/

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} size="large" />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const WebExpandMore = styled((props) => {
  const { webExpand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, webExpand }) => ({
  transform: !webExpand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const defaultWebPrefix = process.env.REACT_APP_WEB_PREFIX;

function sizeText(text) {
  return text.length;
}

//import { useSwipeable } from "react-swipeable";

const { REACT_APP_SNAPSHOT } = process.env;

// refactor as
// Snapshot(thing, agentInput)

const engineState = process.env.REACT_APP_ENGINE_STATE;
const defaultSnapshotInterval = process.env.REACT_APP_SNAPSHOT_INTERVAL;

function Snippet({ thing, agentInput }) {
  const visibleStreams = [
    "speed_in_knots",
    "speed_in_cables",
    "transducer-throllad0",
  ];
  const { token, isValidToken } = useToken();

  const datagram = thing;

  const agent_input = agentInput;
  const webPrefix = agentInput;
  //const [flag, setFlag] = useState();
  //const [requestedAt, setRequestedAt] = useState();
  const [reply, setReply] = useState("");
  const [snapshotInterval, setSnapshotInterval] = useState(
    defaultSnapshotInterval
  );

  const [web, setWeb] = useState();
  const [snippet, setSnippet] = useState();
  const [compressedSnippet, setCompressedSnippet] = useState();

  const [webExpanded, setWebExpanded] = useState(false);
  const [isWebExpandable, setIsWebExpandable] = useState();

  const handleWebExpandClick = () => {
    console.log("Thing handleWebExpandClick webExpanded", webExpanded);
    setWebExpanded(!webExpanded);
  };

function stripExternalImgTags(htmlString, currentDomain) {
    // Create a temporary DOM element to parse the HTML string
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;

    // Get all <img> tags
    const imgTags = tempDiv.getElementsByTagName('img');

    // Convert HTMLCollection to an array for easier manipulation
    const imgArray = Array.from(imgTags);

    // Loop through the imgArray and remove external images
    imgArray.forEach(img => {
        const imgSrc = img.getAttribute('src');
        const imgDomain = new URL(imgSrc).hostname;

        // Check if the image domain is different from the current domain
        if (imgDomain !== currentDomain) {
            img.parentNode.removeChild(img);
        }
    });

    // Return the modified HTML string
    return tempDiv.innerHTML;
}

// Example usage
//const htmlString = '<div><img src="https://external.com/image.jpg"><img src="https://currentdomain.com/image.jpg"></div>';
const currentDomain = window.location.hostname; // 

  useEffect(() => {
    // Still working on this.
    // Needs to write state of the thing (expanded or not) to the thing.
    // But first need to tame the PUT requests.

    setIsWebExpandable(false);
  });

  useEffect(() => {
    console.log("Thing webExpanded", webExpanded);
  }, [webExpanded]);
  /*
  useEffect(() => {
    if (thing == null) {
      return;
    }
    if (thing.subject == null) {
      return;
    }

    const uuidPathname = extractUuid(thing.subject);

    if (uuidPathname === true) {
      return;
    }
    if (uuidPathname === false) {
      return;
    }

    setToSnapshot("https://stackr.ca/snapshot/" + uuidPathname + "/hey.json");
  }, [thing]);
*/
  const [divList, setDivList] = useState([]);

  useHybridEffect(() => {
    //console.log("Snippet");

    if (agentInput == null) {
      return;
    }
    console.log("Snippet agentInput", agentInput);

    const d = agentInput;
    console.log("Web divList d", d);
    //  const d2 = extractUrl(d);
    //const s = sizeText(d);
    //const t = compressText(d);
    var flag = false;
    var text = d?.text;
    var compressText = d?.compressText;
    //var url = extractUrl(d?.text);

    //var cleanedUrl = cleanUrl(url);
    if (d?.size > 1000) {
      flag = true;
      text = d?.compressText;
    }

    //const cleanText = text.replace(/<\/[^>]+(>|$)/g, "");
    /*
var html = text;
var div = document.createElement("div");
div.innerHTML = html;

const cleanText = div.innerText; // Hello, World
*/
    //var cleanGoogleUrl = cleanUrl(d?.url);

    if (d?.cleanText === "") {
      return;
    }
    if (d?.cleanText == null) {
      return;
    }

    var cleanText = d?.cleanText;
    console.log("cleanText", cleanText);
    const urls2 = extractUrlsTest(cleanText);

    console.log("Snippet urls2", urls2);

    urls2.map((u) => {
      console.log("Snippet u", u);
      const l = '<a href="' + u + '" >' + u + "</a>";
      cleanText = cleanText.replace(u, l);
    });

cleanText = stripExternalImgTags(cleanText, currentDomain);


    setCompressedSnippet(compressText);
//if (cleanText.includes("—-")) {console.log("merp");}
    //setSnippet(agentInput?.text);
    setSnippet(cleanText);
    return;
  }, [agentInput]);

  const [data, setData] = useState();

  const [open, setOpen] = useState(false);

  const replyAgentDialog = (thing) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function humanTime(timestamp) {
    const ts = new Date();
    return ts.toISOString();
  }

  function handleThingReport(r) {
    r.preventDefault();
  }

  function handleSnippetVisible(c, d) {
    console.log("Web handleSnippetVisible c", c);
    console.log("Web handleSnippetVisible d", d);
if (isValidToken == null) {return;}
if (isValidToken === false) {return;}
//console.log("Snippet token", isValidToken );
//    return;

    createThing(
      defaultWebPrefix,
      { subject: "dateline " + snippet },
      token,
      "Web"
    )
      .then((r) => {
        console.log("Web createThing r", r);
      })
      .catch((e) => {
        console.error("Web createThing e", e);
      });
  }

  function fromName() {
    if (datagram === undefined) {
      return "Agent";
    }

    if (datagram && datagram.from === undefined) {
      return "Agent";
    }

    return datagram.from;
  }

  function timeStamp() {
    var date = Date.now();
    return date.toString();
  }

  function handleChangeStream(c) {
    console.log("Snapshot handleChangeStream c", c);
  }

  function callBack() {
    console.log("Agent callBack called.");
  }

  const deleteButton = (
    <Forget uuid={datagram && datagram.uuid} callBack={callBack} />
  );
  /*
  return (
    <>
      <>
{snippet}
      </>
    </>
  );
*/

  //return( <>{agentInput}</>);
  const flag = false;
  return (
    <>
      <br />
      {debugFlag && (
        <>
          {" "}
          {flag ? "MORE" : "LESS"} {agentInput?.size}
        </>
      )}

      <VisibilitySensor
        onChange={(isVisible) => {
          handleSnippetVisible(isVisible, agentInput);
        }}
      >
        <>
    <Typography
      component="div"
      sx={{
   //     fontFamily: 'Lato, sans-serif',
  //      fontSize: '16px',
          lineHeight: 1.3,
//          letterSpacing: -0.1,
        //  color: '#333',
//marginBottom:'1em',
      }}
    >
          {!isWebExpandable && (
            <div dangerouslySetInnerHTML={{ __html: snippet }} />
          )}

          {isWebExpandable && (
            <div>
              {/* Note */}
              <WebExpandMore
                webExpand={webExpanded}
                onClick={handleWebExpandClick}
                aria-expanded={webExpanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </WebExpandMore>
              <Collapse in={webExpanded} timeout="auto" unmountOnExit>
                <div dangerouslySetInnerHTML={{ __html: snippet }} />
              </Collapse>

              {!webExpanded ? (
                <div dangerouslySetInnerHTML={{ __html: compressedSnippet }} />
              ) : (
                "See less"
              )}

              {debugFlag && (
                <div>UUID {data && data.thing && data.thing.uuid}</div>
              )}
            </div>
          )}
</Typography>
</>
      </VisibilitySensor>
    </>
  );

  return (
    <>
      <>
        <div dangerouslySetInnerHTML={{ __html: web }} />
      </>
    </>
  );
}

export default Snippet;
/*

var googleUrl = "...";
var start = googleUrl.indexOf("&url=") + 5;
var end = googleUrl.indexOf("&", start)
var encodedUrl = googleUrl.substring(start, end);
//var encodedUrl = googleUrl.substr(start, end - start); //OK too
var url = decodeURIComponent(encodedUrl); //this what you want


*/
