import React, { useEffect, useState } from "react";
//import './Login.css';
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import UpdateIcon from "@mui/icons-material/Update";

import { humanTime, humanAge, humanRuntime } from "./../util/time.js";

export default function PollInterval({ thing, agentInput, toGoTime, onRefresh }) {

const [pollInterval, setPollInterval] = useState();

  useEffect(() => {
    if (agentInput == null) {
      return;
    }

if (agentInput.hasOwnProperty('pollInterval')) {

setPollInterval(agentInput.pollInterval);

}

  }, [agentInput]);


  return (
    <>
      POLL INTERVAL {humanRuntime(pollInterval)}
      <br />
    </>
  );
}

//ToGoTime.propTypes = {
  //  token: PropTypes.func.isRequired
//};
