import { useState, useEffect, useMemo } from "react";
import {
  getThings as getThingies,
  createThing,
  makeObservable,
//} from "./util/database.js";
} from "stack-js/src/util/database";

import {getSlug} from "stack-js/src/util/text";

import useHybridEffect from "./useHybridEffect.js";
import {zuluTime} from "./util/time.js";

import { v4 as uuidv4 } from "uuid";

import useToken from "./useToken.js";

//import sha256 from "crypto-js";
import crypto from 'crypto';

const userThings = makeObservable({ things: [], count: 0 });

const apiPrefix = process.env.REACT_APP_API_PREFIX;
const webPrefix = process.env.REACT_APP_WEB_PREFIX;

// Basically a list of the minimum suite for your 'application'
// to run.

// Took out the first one to isolate the new item creation behaviour.

//Thing.js:                poll={{ interval: pollInterval, aggressive: aggressivePoll }}
//Thing.js:<PollInterval thing={thing} agentInput={{pollInterval:thingPollInterval}} />

const defaultThings = [
  /*
  {
    to: "localhost",
    subject: window.location.pathname.replace(/^\/|\/$/g, ""),
    createdAt: Date.now(),
    uuid: uuidv4(),
    input: null,
  },

  {
    index: 20,
    to: "localhost",
    from: "stack",
    subject: "Log Out",
    createdAt: Date.now(),
    uuid: uuidv4(),
    input: "Logout",
    poll:{interval:0},
  },

  {
    index: 20,
    to: "localhost",
    from: "stack",
    subject: "Token",
    createdAt: Date.now(),
    uuid: uuidv4(),
    input: "Token",
    poll:{interval:60000},
  },

  {
    index: 20,
    to: "localhost",
    from: "stack",
    subject: "Data Monitor",
    createdAt: Date.now(),
    uuid: uuidv4(),
    input: "DataMonitor",
  },
*/
/*
  {
    index: 20,
    to: "localhost",
    from: "stack",
    subject: "Please Log In",
    createdAt: Date.now(),
    uuid: uuidv4(),
    input: "Login",
    poll:{interval:0},
  },
*/
/*
  {
    index: 21,
    to: "localhost",
    from: "stack",
    subject: "Reauthorize",
    createdAt: Date.now(),
    uuid: uuidv4(),
    input: "Reauthorize",
    poll:{interval:0},
  },
  {
    index: 21,
    to: "localhost",
    from: "stack",
    subject: "Please Sign Up",
    createdAt: Date.now(),
    uuid: uuidv4(),
    input: "Signup",
    poll:{interval:60000},
  }
*/
  {
    index: 21,
    to: "localhost",
    from: "stack",
    subject: "Activate",
    createdAt: Date.now(),
    uuid: uuidv4(),
    input: "Activate",
    poll:{interval:0},
    forget:false,
  },
  {
    index: 21,
    to: "localhost",
    from: "stack",
    subject: "Privacy",
    createdAt: Date.now(),
    uuid: uuidv4(),
    input: "Privacy",
    poll:{interval:0},
    forget:false,
  },
  {
    index: 21,
    to: "localhost",
    from: "stack",
    subject: "Terms of Use",
    createdAt: Date.now(),
    uuid: uuidv4(),
    input: "TermsOfUse",
    poll:{interval:0},
    forget:false
  },
];

const errorThing = {
  index: 20,
  to: "localhost",
  from: "stack",
  subject: "Error",
  createdAt: Date.now(),
  uuid: uuidv4(),
  input: "Error",
};

function hashFunction(obj) {
  //return "hello";
  const jsonString = JSON.stringify(obj);
  //const hash = sha256(jsonString);

  const hash = crypto.createHash('sha256');
  
  hash.update(jsonString);
  return hash.digest('hex');
//  return hash.toString(crypto.enc.Hex);
}

export default function useThings(thing) {
  const { token } = useToken();
  const [count, setCount] = useState();

  const [things, setThings] = useState();

  useEffect(() => {
    console.log("useThings token", token, zuluTime());
    getThings();
  }, [token]);

  const getThings = () => {
    console.log("useThings getThings token", token);


    if (token == null) {
      // If the token is null, it means there is no access
      // to the forward stack pointer.

      // No need to set default.
      // But need to establish a hook to monitor changes in [things].

      setThings(defaultThings);
      console.log("useThings getThings saw null token");
      return;
    }

if (thing?.variables?.visible !== true) {

return;

}

    const tempThings = userThings.get().things;
    //setThings(tempThings);

    getThingies(apiPrefix, {subject:"*"}, token, 1000)
      .then((result) => {
        console.log("useThings getThings getThingies things", things);
        console.log("useThings getThings getThingies result", result);

        if (result && result.error) {
          console.error("useThings getThings getThingies result", result);
          return;
        }

        // This does a straight drop of any duplicated uuids.
        // This is a problem because a more sophicated merge
        // is required based on change history.

        // Consider also. The server does not need to send stale things.
        // Only things which have changed in the prior interval.
        // Otherwise can send uuid as placeholder.

        if (result.hasOwnProperty("error")) {
          console.error("useThings getThings getThingies error", result.error);
        }

        const addedThings = [];
        const removedThings = [];

        // Compare result.things with tempThings

        if (result && result.things) {
          for (const thing of result.things) {
 //           if (!tempThings.includes(thing)) {
   if (!tempThings.some(tempThing => tempThing.uuid === thing.uuid)) {
             // If the thing is in result.things but not in tempThings, it's added.
              addedThings.push(thing);
            }
          }

/*
          for (const thing of tempThings) {
            if (!result.things.includes(thing)) {
              // If the thing is in tempThings but not in result.things, it's removed.
              removedThings.push(thing);
            }
          }
*/
        }
//console.log("useThings getThings MERP");
        // Create hashes for each thing in result.things and tempThings
/*
        const tempHashes = tempThings.map((thing) => hashFunction(thing));
        const resultHashes = result.things.map((thing) => hashFunction(thing));

        // Find the indices of changed things
        const changedThingIndices = [];

        for (let i = 0; i < resultHashes.length; i++) {
          if (tempHashes.indexOf(resultHashes[i]) === -1) {
            // The thing at index i has changed
            changedThingIndices.push(i);
          }
        }

        // Create an array of the changed things based on the indices
        const changedThings = changedThingIndices.map(
          (index) => result.things[index]
        );

        console.debug("useThings getThings changedThings", changedThings);
        // Not used these.
        // Because need to consider how to merge two things together.

console.log("useThings addedThings", addedThings);
*/


        var combinedThings = [];
        if (result && result.things && result.things.length !== 0) {

/*
          combinedThings = mergeObjectsInUnique(
//            [...tempThings, ...result.things],
       //     [...things, ...result.things],
[...things, ...addedThings],
            "uuid"
          );
*/
combinedThings = [...things,...addedThings];



        }

        if (combinedThings.length === 0) {
          combinedThings = defaultThings;
        } else {
}

// Is there a token thing?
if ( combinedThings.find((c)=>{
       if (getSlug(c?.subject) === "token") {return true;}
       return false;
   }) ) {
} else {
          combinedThings.push({
            index: 21,
            to: "localhost",
            subject: "Token",
            createdAt: Date.now(),
            uuid: uuidv4(),
            input: "Token",
          });

}

if ( combinedThings.find((c)=>{
       if (getSlug(c?.subject) === "logout") {return true;}
       return false;
   }) ) {
} else {
          combinedThings.push({
            index: 21,
            to: "localhost",
            subject: "Logout",
            createdAt: Date.now(),
            uuid: uuidv4(),
            input: "Logout",
          });

}


 //       }
/*
        const uuids = combinedThings.map((o) => o.uuid);
        const deduplicatedThings = combinedThings.filter(
          ({ uuid }, index) => !uuids.includes(uuid, index + 1)
        );

        const conditionedThings = deduplicatedThings.map((thing, index) => {
          return { ...thing, index: index };
        });
*/

        const conditionedThings = combinedThings.map((thing, index) => {
          return { ...thing, index: index };
        });


        //const deduplicatedThings = combinedThings.filter((value, index, self) =>
        //  index === self.findIndex((t) => (
        //    t.uuid === value.uuid
        //  ))
        //)

        console.debug(
          "useThings getThings conditionedThings",
          conditionedThings
        );

        setThings(conditionedThings);
        //return conditionedThings;
      })
      .catch((error) => {
        // Add an error card in. Up front and center?
        //setThings(defaultThings);
//          const conditionedThings = deduplicatedThings.map((thing, index) => {
//          return { ...thing, index: index };
//        });

//          const conditionedThings = combinedThings.map((thing, index) => {
//          return { ...thing, index: index };
//        });
  



    //webPrefix, defaultThings[1], token
        console.log("useThings getThings apiPrefix token", apiPrefix, token);
        createThing(webPrefix, errorThing, token, "A");
        console.error("useThings getThings error", error);
      });

    //    const things = getThings();
    //    return things;
  };

  useEffect(() => {
    return userThings.subscribe(setThings);
  }, []);

  useHybridEffect(() => {
    if (things == null) {
      return;
    }
    console.debug("useThings things", things);
  }, [things]);

  useEffect(() => {
    getThings();
  }, []);

  const actions = useMemo(() => {
    return {
      setThings: (ts) => userThings.set({ ...things, ts }),
      incrementCount: () =>
        userThings.set({ ...things, count: things.count + 1 }),
      decrementCount: () =>
        userThings.set({ ...things, count: things.count - 1 }),
    };
  }, [things]);

  const saveThings = (userThings) => {
    if (userThings.length === 0) {
      return;
    } // never allow zero cards.
    console.log("useThings saveThings userThings", userThings);
    setThings(userThings);
  };
/*
  function mergeObjectsInUnique<T>(array: T[], property: any): T[] {
    const newArray = new Map();

    array.forEach((item: T) => {
      const propertyValue = item[property];
      newArray.has(propertyValue)
        ? newArray.set(propertyValue, {
            ...item,
            ...newArray.get(propertyValue),
          })
        : newArray.set(propertyValue, item);
    });

    return Array.from(newArray.values());
  }
*/


function mergeObjectsInUnique(array, property) {
  const newArray = new Map();

  array.forEach((item) => {
    const propertyValue = item[property];
    if (newArray.has(propertyValue)) {
      newArray.set(propertyValue, {
        ...item,
        ...newArray.get(propertyValue),
      });
    } else {
      newArray.set(propertyValue, item);
    }
  });

  return Array.from(newArray.values());
}



  return {
    setThings: saveThings,
    getThings: getThings,
    things,
  };
}
