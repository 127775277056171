import React, { useState, useEffect } from "react";

import "../index.css";
import {
  Typography,
  //  Avatar,
  //  ListItemAvatar,
  Box,
} from "@mui/material";

import useHybridEffect from "../useHybridEffect.js";

import {
  Button,
  TextField,
  IconButton,
  ListItem,
  ListItemText,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";

import {
  //AddCircleOutlineRounded,
  //DeleteOutlineRounded,
  Edit,
} from "@mui/icons-material";

import Forget from "../components/Forget.js";
import Trace from "../components/Trace.js";
import Stream from "../components/Stream.js";
import BubbleLevel from "../components/BubbleLevel.js";
import Inclinometer from "../components/Inclinometer.js";

import ThingButton from "../components/Button.js";

import Magnetometer from "../components/Magnetometer.js";

import MotionReference from "../components/MotionReference.js";

import Ping from "../components/Ping.js";
import Quantity from "../components/Quantity.js";


import DataTable from "../components/DataTable.js";


import Transducer from "../components/Transducer.js";

import History from "../components/History.js";

import ExpanderCollapser from "../components/ExpanderCollapser.js";


//import { getSnapshot } from "../util/database.js";

import useSnapshot from "../useSnapshot.js";

import {
  isText,
  extractUuid,
  extractNuuid,
  extractPeriod,
  getSlug,
  //transducerLink
} from "../util/text.js";

import { devFlag, debugFlag } from "../util/dev.js";

import { useLocation } from "react-router-dom";

//import { useSwipeable } from "react-swipeable";

const { REACT_APP_SNAPSHOT } = process.env;

// refactor as
// Snapshot(thing, agentInput)

const engineState = process.env.REACT_APP_ENGINE_STATE;
const defaultSnapshotInterval = process.env.REACT_APP_SNAPSHOT_INTERVAL;

function Transducers({ thing, agentInput }) {
  //const visibleStreams = ['speed_in_knots','speed_in_cables','transducer-throllad0'];

  const location = useLocation();

  const { visible: visibleStreams } = agentInput;

  const datagram = thing;

  const [transducers, setTransducers] = useState();

  const agent_input = agentInput;
  const webPrefix = agentInput;
  //const [flag, setFlag] = useState();
  //const [requestedAt, setRequestedAt] = useState();
  const [reply, setReply] = useState("");
  const [snapshotInterval, setSnapshotInterval] = useState(
    defaultSnapshotInterval
  );

  const defaultToSnapshot = REACT_APP_SNAPSHOT;
  const [toSnapshot, setToSnapshot] = useState(defaultToSnapshot);

  const { snapshot, flag, snapshotRunTime } = useSnapshot(
    toSnapshot,
    null,
    thing
  );

  //const [data, setData] = useState({
  //  thing: { uuid: "X" },
  //  thingReport: { sms: "No response. Yet." },
  //});
  const [data, setData] = useState();

  const [open, setOpen] = useState(false);

  const replyAgentDialog = (thing) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useHybridEffect(() => {
    if (agentInput.transducers == null) {
      return;
    }

    setData({ transducers: agentInput.transducers });
  }, [agentInput && agentInput.transducers]);

  useHybridEffect(() => {
    if (agentInput.data == null) {
      return;
    }

    setData(agentInput.data);
  }, [agentInput && agentInput.data]);
  /*
useEffect(()=>{
if (data?.transducers == null) {return;}
window.alert(JSON.stringify(data?.transducers));
setTransducers(data?.transducers);


},[data?.transducers]);
*/
  /*
useEffect(() => {
    if (data?.transducers == null) {
        return;
    }

    // Get the current transducers from state (assuming you have a state variable for it)
    setTransducers(prevTransducers => ({
        ...prevTransducers, // Keep existing transducers
        ...data.transducers // Merge with new transducers
    }));

    // Optionally, you can alert the new merged transducers
//    window.alert(JSON.stringify({
//        ...prevTransducers,
//        ...data.transducers
//    }));
}, [data?.transducers]);
*/

  useEffect(() => {
    if (data?.transducers == null) {
      return;
    }

    // Merge the previous transducers with the new ones
    setTransducers((prevTransducers) => {
      const mergedTransducers = {
        ...prevTransducers,
        ...data.transducers,
      };

      // Sort the keys alphabetically
      const sortedTransducers = Object.keys(mergedTransducers)
        .sort() // Sort the keys
        .reduce((acc, key) => {
          acc[key] = mergedTransducers[key]; // Build a new object with sorted keys
          return acc;
        }, {});

      return sortedTransducers; // Return the sorted object
    });
  }, [data?.transducers]);

  function humanTime(timestamp) {
    const ts = new Date();
    return ts.toISOString();
  }

  function fromName() {
    if (datagram === undefined) {
      return "Agent";
    }

    if (datagram && datagram.from === undefined) {
      return "Agent";
    }

    return datagram.from;
  }

  function timeStamp() {
    var date = Date.now();
    return date.toString();
  }

  const [ampDataPointer, setAmpDataPointer] = useState(0);
  const [ampPoints, setAmpPoints] = useState([]);
  const startTime = new Date();
  const [voltPoints, setVoltPoints] = useState([]);
  const [tracePeriod, setTracePeriod] = useState();

useHybridEffect(()=>{
if (snapshot == null) {return;}
console.log("Transducers snapshot", snapshot);
if (snapshot?.transducers) {

setData(snapshot);

}

},[snapshot]);

  function handleChangeStream(c) {
    console.log("Snapshot handleChangeStream c", c);
  }

  useEffect(() => {
    console.log("Snapshot data", data);
  }, [data]);

  function callBack() {
    console.log("Agent callBack called.");
  }

  function transducerLink(t, transducer, url) {
    const r = magicTransducerLink(t, transducer, url);
    console.log("Transducers transducerLink r", r);
    return r;
  }

  function magicTransducerLink(t, transducer, url) {
    //console.log("Transducers transducerLink t", t);
    //console.log("Transducers transducerLink transducer", transducer);
    //console.log("Transducers transducerLink url", url);

    //  if (t == null) {
    //    return "/snapshot";
    //  }

    const uuid = extractUuid(url);
    //console.log("Transducers transducerLink uuid", uuid);
    if (uuid == null || uuid === false) {
      //   if (t?.uuid == null) {
      //console.log("Transducers transducerLink null uuid");
      return "/history/" + "transducers-" + transducer.toLowerCase() + "-1d";
    }

    if (uuid) {
      if (url.toLowerCase().includes("-snapshot-transducers-")) {
        return (
          "/history/" +
          uuid +
          //     t.uuid +
          "-snapshot-transducers-" +
          transducer.toLowerCase() +
          "-1d"
        );
      }

      if (url.toLowerCase().includes("-snapshot-")) {
        return (
          "/history/" +
          uuid +
          //     t.uuid +
          "-snapshot-" +
          transducer.toLowerCase() +
          "-1d"
        );
      }

      console.log(
        "Transducers transducerLink url transducer no uuid not matched",
        url,
        transducer
      );
    }

    console.log(
      "Transducers transducerLink url transducer not matched",
      url,
      transducer
    );
  }

  function transducerAmount(t) {
    if (t && t.amount) {
      return t.amount;
    }
    return t;
  }
  /*
          <ThingButton
            thing={thing}
            agentInput={{ text: "Open", link: "/thing/" + uuid }}
            onThingReport={() => {}}
          />

*/

// React style?
const [conditionedTransducers, setConditionedTransducers] = useState({});

useEffect(()=>{
if (transducers == null) {return;}
          const r = Object.keys(transducers).map((transducer, index) => {

const tl = transducerLink(thing, transducer, location?.pathname);
//const amountText = transducerAmount(transducers[transducer]);

return {transducer:transducer, link:tl};
          });
setConditionedTransducers(r);
},[transducers]);



const [hasRendered, setHasRendered] = useState();

useEffect(()=>{

if (transducers == null) {return;}
//if (Array.isArray(data?.transducers) && data?.transducers.length == 0) {return;}

console.log("StrategyBenchmarkChat transducers", transducers);
setHasRendered(true);

},[transducers]);

if (hasRendered == null) {return (<></>);}





  return (
    <>
      {/* Handle the transducers field special */}
{/*      TRANSDUCERS */}

{snapshot?.error?.message ? (<div>snapshot?.error?.message</div>) : (<div>TRANSDUCERS OK</div>)}
{/*toSnapshot{' '}{toSnapshot}*/}
{transducers && (
<ExpanderCollapser>
<pre>transducers{' '}{JSON.stringify(transducers, null,2)}</pre>
</ExpanderCollapser>)}
{/* {JSON.stringify(data)} */}
{/*<pre>{JSON.stringify(conditionedTransducers)}</pre>*/}
      <br />
      {transducers && (
        <>
          {Object.keys(transducers).map((transducer, index) => {
            // console.log("Snapshot transducer", transducer);

            if (!(visibleStreams == null)) {
              if (!visibleStreams.includes(transducer)) {
                return;
              }
            }

            const amountFlag = false;
            var amountText = "";
            if (amountFlag) {
              amountText = transducerAmount(transducers[transducer]);
            }

            var disabledFlag = false;
            if (
              location.pathname.toLowerCase().includes(transducer.toLowerCase())
            ) {
              disabledFlag = true;
            }

            return (
              <>
                <ThingButton
                  thing={thing}
                  //            agentInput={{ disabled: false, text: transducer + amountText, link: "/history/" + thing.uuid + "-snapshot-transducers-" + transducer.toLowerCase() + "-1d" }}
                  agentInput={{
                    // disabled: false,
                    disabled: disabledFlag,
                    text: transducer + amountText,
                    link: transducerLink(thing, transducer, location?.pathname),
                  }}
                  onThingReport={() => {}}
                />
              </>
            );
          })}
        </>
      )}


      {agentInput?.flavour !== "buttons" && (
        <>
{/*
<ExpanderCollapser>
<pre>{JSON.stringify(transducers, null, 2)}</pre>
</ExpanderCollapser>
*/}
 
<DataTable data={transducers} />
          {false &&transducers && (
            <>
              {Object.keys(transducers).map((transducer, index) => {
                //return (<Transducer agentInput={{flavour:"buttons", transducer:transducer, index:index}} />);

                if (!(visibleStreams == null)) {
                  if (!visibleStreams.includes(transducer)) {
                    return;
                  }
                }

                {
                  /*<Transducer agentInput={{transducer:transducer, index:index}} />*/
                }

                //                console.log("Snapshot transducer", transducer);
                const q =
                  transducer + ":" + transducerAmount(transducers[transducer]);
                var per = "now";
                const p = extractPeriod(thing?.subject);

                if (p === true || p === false || p == null) {
                  per = "4h";
                } else {
                  per = p;
                }

                //             if (visibleStreams.includes(transducer)) {
                return (
                  <div key={"transducer_" + transducer + "_" + index}>
                    <Quantity agentInput={{ text: q }} />
                    {/* {JSON.stringify(visibleStreams)}{" "}{transducer} */}
                    {thing?.subject.includes("chart") && (
                      <>
                        <History
                          thing={{
                            subject:
                              "history/" +
                              "transducers-" +
                              transducer +
                              "-" +
                              per,
                          }}
                          agentInput={{ flavour: "line" }}
                        />{" "}
                        <br />
                      </>
                    )}
                  </div>
                );
                //       }

                if (visibleStreams.includes(transducer)) {
                  return (
                    <div key={"transducer_" + transducer + "_" + index}>
                      {transducer} not a visible stream
                      <br />
                    </div>
                  );
                }

                return (
                  <Stream
                    key={transducer}
                    hide={true}
                    quantity={{
                      units: "A",
                      amount:
                        data &&
                        data.transducers &&
                        data.transducers[transducer] &&
                        data.transducers[transducer].amount,
                    }}
                    period={snapshotInterval}
                    transducer={data.transducers[transducer]}
                    agentInput={{
                      snapshot: { interval: snapshotInterval, period: false },
                      visible: visibleStreams.includes(transducer),
                    }}
                  />
                );
              })}
            </>
          )}

          {thing?.subject.includes("motion-reference") && (
            <>
              {data && data.transducers && (
                <>
                  <MotionReference
                    data={{
                      displacement: {
                        y:
                          data &&
                          data.transducers &&
                          data.transducers.thdisyxm1 &&
                          data.transducers.thdisyxm1.amount,
                        x:
                          data &&
                          data.transducers &&
                          data.transducers.thdisxxm0 &&
                          data.transducers.thdisxxm0.amount,
                        z:
                          data &&
                          data.transducers &&
                          data.transducers.thdiszxm2 &&
                          data.transducers.thdiszxm2.amount,
                      },

                      velocity: {
                        y:
                          data &&
                          data.transducers &&
                          data.transducers.thvelyxm1 &&
                          data.transducers.thvelyxm1.amount,
                        x:
                          data &&
                          data.transducers &&
                          data.transducers.thvelxxm0 &&
                          data.transducers.thvelxxm0.amount,
                        z:
                          data &&
                          data.transducers &&
                          data.transducers.thvelzxm2 &&
                          data.transducers.thvelzxm2.amount,
                      },

                      acceleration: {
                        y:
                          data &&
                          data.transducers &&
                          data.transducers.thaccyxx1 &&
                          data.transducers.thaccyxx1.amount,
                        x:
                          data &&
                          data.transducers &&
                          data.transducers.thaccxxx0 &&
                          data.transducers.thaccxxx0.amount,
                        z:
                          data &&
                          data.transducers &&
                          data.transducers.thacczxx2 &&
                          data.transducers.thacczxx2.amount,
                      },
                    }}
                  />
                  <br />
                </>
              )}

              {thing?.subject.includes("magnetometer") && (
                <>
                  <Magnetometer
                    vector={{
                      z: data && data.transducers && data.transducers.thmagzxx2,
                      y: data && data.transducers && data.transducers.thmagyxx1,
                      x: data && data.transducers && data.transducers.thmagxxx0,
                    }}
                  />

                  {/* <Magnetometer vector={{z:1, 
y:1, 
x:1}} /> */}
                  <br />
                </>
              )}

              {thing?.subject.includes("bubble") && (
                <>
                  BUBBLE LEVEL
                  <BubbleLevel
                    data={{
                      y:
                        data &&
                        data.transducers &&
                        data.transducers.thptchad1 &&
                        data.transducers.thptchad1.amount,
                      x:
                        data &&
                        data.transducers &&
                        data.transducers.throllad0 &&
                        data.transducers.throllad0.amount,
                      z:
                        data &&
                        data.transducers &&
                        data.transducers.thyawad2 &&
                        data.transducers.thyawad2.amount,
                      dotZ:
                        data &&
                        data.transducers &&
                        data.transducers.thgyrzax2 &&
                        data.transducers.thgyrzax2.amount,
                    }}
                  />
                  PITCH:{" "}
                  <Inclinometer
                    data={{
                      x:
                        data &&
                        data.transducers &&
                        data.transducers.thptchad1 &&
                        data.transducers.thptchad1.amount,
                    }}
                  />
                  {data &&
                    data.transducers &&
                    data.transducers.thptchad1 &&
                    data.transducers.thptchad1.amount}{" "}
                  degrees
                  <br />
                  ROLL:{" "}
                  <Inclinometer
                    data={{
                      x:
                        data &&
                        data.transducers &&
                        data.transducers.throllad0 &&
                        data.transducers.throllad0.amount,
                    }}
                  />
                  {data &&
                    data.transducers &&
                    data.transducers.throllad0 &&
                    data.transducers.throllad0.amount}{" "}
                  degrees
                  <br />
                  YAW:{" "}
                  {data &&
                    data.transducers &&
                    data.transducers.thyawax2 &&
                    data.transducers.thyawax2.amount}{" "}
                  <br />
                </>
              )}

              {thing?.subject.includes("heading") && (
                <>
                  MAGNETIC HEADING: {data && data.magnetic_heading_in_degrees}{" "}
                  <br />
                  TRUE HEADING: {data && data.true_heading_in_degrees} <br />
                  RATE OF TURN: {data && data.rate_of_turn} <br />
                </>
              )}
            </>
          )}
        </>
      )}
      {/*
MRU<br />
ACCZ: {data && data.transducers && data.transducers.thacczax2 && data.transducers.thacczax2.amount} m<br />
ACCZ: {data && data.transducers && data.transducers.thacczax2 && data.transducers.thacczax2.amount} m<br />
ACCZ: {data && data.transducers && data.transducers.thacczax2 && data.transducers.thacczax2.amount} m<br />
*/}
    </>
  );
}

export default Transducers;
