import React, { useState, useEffect } from "react";

import DynamicComponent from "../components/DynamicComponent.js";

import "../index.css";
import {
  Typography,
  //  Avatar,
  //  ListItemAvatar,
  Box,
} from "@mui/material";

import {
  Button,
  TextField,
  IconButton,
  ListItem,
  ListItemText,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";

import {
  //AddCircleOutlineRounded,
  //DeleteOutlineRounded,
  Edit,
} from "@mui/icons-material";

import Forget from "../components/Forget.js";
import Trace from "../components/Trace.js";
import Stream from "../components/Stream.js";
import BubbleLevel from "../components/BubbleLevel.js";
import Inclinometer from "../components/Inclinometer.js";

import Magnetometer from "../components/Magnetometer.js";

import MotionReference from "../components/MotionReference.js";

import Ping from "../components/Ping.js";
import Transducers from "../components/Transducers.js";
import Quantity from "../components/Quantity.js";

//import { getSnapshot } from "../util/database.js";

import useSnapshot from "../useSnapshot.js";

import {
  isText,
  extractUuid,
  extractNuuid,
  getSlug,
  capitalizeFirstLetter,
} from "../util/text.js";

import { devFlag, debugFlag } from "../util/dev.js";

//import { useSwipeable } from "react-swipeable";

const { REACT_APP_SNAPSHOT } = process.env;

// refactor as
// Snapshot(thing, agentInput)

const engineState = process.env.REACT_APP_ENGINE_STATE;
const defaultSnapshotInterval = process.env.REACT_APP_SNAPSHOT_INTERVAL;

function Dashboard({ thing, agentInput }) {
  const visibleStreams = [
"rate_of_turn",
"true_heading_in_degrees",
"magnetic_heading_in_degrees",
    "speed_in_knots",
    "speed_in_cables",
"throllad0",
"thptchad1",
"thyawad2",
"thvlt0ax1",
"thvlt1ax1",
"thclb0ax2",
"thamp0ax0",
"thtmpetc1",
"transducers",
  ];

  const datagram = thing;

  const agent_input = agentInput;
  const webPrefix = agentInput;
  //const [flag, setFlag] = useState();
  //const [requestedAt, setRequestedAt] = useState();
  const [reply, setReply] = useState("");
  const [snapshotInterval, setSnapshotInterval] = useState(
    defaultSnapshotInterval
  );

  const defaultToSnapshot = REACT_APP_SNAPSHOT;
  const [toSnapshot, setToSnapshot] = useState(defaultToSnapshot);

  const { snapshot, flag, snapshotRunTime } = useSnapshot(toSnapshot);

  useEffect(() => {
    if (thing == null) {
      return;
    }
    if (thing.subject == null) {
      return;
    }

    const uuidPathname = extractUuid(thing.subject);

    if (uuidPathname === true) {
      return;
    }
    if (uuidPathname === false) {
      return;
    }

    setToSnapshot("https://stackr.ca/snapshot/" + uuidPathname + "/hey.json");
  }, [thing]);

  useEffect(() => {
    console.log("Snapshot toSnapshot", toSnapshot);
  }, [toSnapshot]);

  //const [data, setData] = useState({
  //  thing: { uuid: "X" },
  //  thingReport: { sms: "No response. Yet." },
  //});
  const [data, setData] = useState();

  const [open, setOpen] = useState(false);

  const replyAgentDialog = (thing) => {
    setOpen(true);
  };
  /*
  const config = {
    delta: 10, // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: false, // prevents scroll during swipe (*See Details*)
    trackTouch: true, // track touch input
    trackMouse: false, // track mouse input
    rotationAngle: 0, // set a rotation angle
    swipeDuration: Infinity, // allowable duration of a swipe (ms). *See Notes*
    touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
  };

  const handlers = useSwipeable({
    onSwiped: (eventData) => console.log("User Swiped!", eventData),
    ...config,
  });
*/
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    console.log("Snapshot thing snapshot", thing, snapshot);

    if (snapshot && snapshot.thingReport) {
      if (snapshot.thingReport.snapshot) {
        console.log(
          "Snapshot setData snapshot.thingReport.snapshot",
          snapshot.thingReport.snapshot
        );
        setData(snapshot.thingReport.snapshot);
        return;
      }
    }

    if (snapshot.thingReport == null) {
      console.log("Snapshot setData snapshot", snapshot);
      setData(snapshot);
      return;
    }
    // Or perhaps don't refresh snapshot.Snapshot thing snapshot
    setData(true);
  }, [snapshot]);

  function humanTime(timestamp) {
    const ts = new Date();
    return ts.toISOString();
  }

  useEffect(() => {
    if (thing == null) {
      return;
    }
    console.log("Snapshot data", toSnapshot, data);
  }, [data]);

  useEffect(() => {
    if (thing == null) {
      return;
    }

    console.log("Snapshot thing", toSnapshot, thing.uuid, thing);
  }, [thing]);

  function handleThingReport(r) {
r.preventDefault();


}

  function fromName() {
    if (datagram === undefined) {
      return "Agent";
    }

    if (datagram && datagram.from === undefined) {
      return "Agent";
    }

    return datagram.from;
  }

  function timeStamp() {
    var date = Date.now();
    return date.toString();
  }

  const [ampDataPointer, setAmpDataPointer] = useState(0);
  const [ampPoints, setAmpPoints] = useState([]);
  const startTime = new Date();
  const [voltPoints, setVoltPoints] = useState([]);
  const [tracePeriod, setTracePeriod] = useState();

  function handleChangeStream(c) {
    console.log("Snapshot handleChangeStream c", c);
  }

  useEffect(() => {
    console.log("Snapshot data", data);
  }, [data]);

  function callBack() {
    console.log("Agent callBack called.");
  }

  const deleteButton = (
    <Forget uuid={datagram && datagram.uuid} callBack={callBack} />
  );

  //if (true) {
  if (false) {
    return (
      <>
        <div>DASHBOARD</div>
        <div>URL {toSnapshot}</div>
        <div>
          FLAG {flag} COLOUR
          <br />
          GET TIME {snapshotRunTime}ms {Math.round(1000 / snapshotRunTime, 1)}Hz
          <br />
          {data && data.ping && (
            <>
              PING
              <br />
            </>
          )}
          {data && data.ping && <Ping ping={data.ping} />}
          {data && data.transducers && (
            <>
              {Object.keys(data.transducers).map((transducer, index) => {
                //console.log("Snapshot transducer", transducer);
                return (
                  <Stream
                    key={"snapshot_transducer_" + transducer + "_" + index}
                    hide={true}
                    quantity={{
                      units: "A",
                      amount:
                        data &&
                        data.transducers &&
                        data.transducers[transducer] &&
                        data.transducers[transducer].amount,
                    }}
                    transducer={data.transducers[transducer]}
                    onChangeStream={(c) => {
                      handleChangeStream(c);
                    }}
                  />
                );
              })}
            </>
          )}
        </div>
      </>
    );
  }

  return (
    <>
      DASHBOARD
{/* {thing?.subject} */}
      {debugFlag && (
        <>
          <div>URL {toSnapshot}</div>
        </>
      )}
      <div>
        {debugFlag && (
          <>
            FLAG {flag} COLOUR
            <br />
          </>
        )}
        {debugFlag && (
          <>
            GET TIME {snapshotRunTime}ms {Math.round(1000 / snapshotRunTime, 1)}
            Hz
            <br />
          </>
        )}

        {data && data.ping && <Ping ping={data.ping} />}

        {data && (
          <>
            {debugFlag && (
              <>
                {" "}
                SNAPSHOT TRANSDUCER
                <br />
              </>
            )}
            {Object.keys(data).map((transducer, index) => {
              //console.log("Snapshot transducer", transducer);
/*
              if (visibleStreams.includes(transducer)) {
                return (
                  <div key={"transducer_" + transducer + "_" + index}>
                    {transducer} not a visible stream
                    <br />
                  </div>
                );
                //    return;
              }
*/

if (!visibleStreams.includes(transducer)) {
return (<></>);
}


/*
              if (visibleStreams.includes(transducer)) {
                return (
                  <Stream
                    key={transducer}
                    hide={true}
                    quantity={{
                      units: "A",
                      amount: data && data[transducer],
                    }}
                    transducer={data[transducer]}
                    agentInput={{
                      visible: visibleStreams.includes(transducer),
                    }}
                  />
                );
              }
*/
              if (visibleStreams.includes(transducer) && data && data[transducer] && !isNaN(data[transducer])) {
                return (
                  <div key={"transducer_" + transducer + "_" + index}>
{/*                  {transducer} : {data[transducer]} */}
                  <Quantity agentInput={{text: (transducer +" : " + data[transducer])  } } />

                    <br />
                  </div>
                );
                //    return;
              }

//if (visibleStreams.includes("transducer")) {
              if (visibleStreams.includes(transducer) && data && data[transducer] && isText(data[transducer])) {
                return (
                  <div key={"transducer_" + transducer + "_" + index}>
                  <Quantity agentInput={{text: (transducer +" : " + data[transducer])  } } />
                    <br />
                  </div>
                );
                //    return;
              }
//}
/*
              if (visibleStreams.includes(transducer) && thing?.subject.includes("charts")) {
                return (
                  <Stream
                    key={transducer}
                    hide={true}
                    quantity={{
                      units: "A",
                      amount: data && data[transducer],
                    }}
                    transducer={data[transducer]}
                    agentInput={{
                     // visible: visibleStreams.includes(transducer),
                      visible:visibleStreams,
                    }}
                  />
                );
              }
*/


//return (<></>);

//              if (true && data && data[transducer]) {
                return (
                  <>

                    DASHBOARD DYNAMIC COMPONENT {transducer}
                    <br />
                    <DynamicComponent
                      is={capitalizeFirstLetter(transducer)}
                      channel={"image"}
                      user={null}
                      //thing={data.thing}

                      thing={thing}
                      //agentInput={agentInput}
                      onThingReport={(r) => handleThingReport(r)}
                      datagram={thing}
                      agentInput={{ ...agentInput, data: data, visible:visibleStreams }}
                    />
                  </>
                );


//              }

              return (
                <div key={"transducer_" + transducer + "_" + index}>
                  {transducer} not readable - {JSON.stringify(data[transducer])}
                  <br />
                </div>
              );
            })}
          </>
        )}
        {true && data && data.alarms && (
          <>
            ALARMS
            <br />
            {JSON.stringify(data.alarms)}
          </>
        )}

        {/* Handle the transducers field special */}
        {false && (
          <>
            TRANSDUCERS
            <br />
            <Transducers
              thing={thing}
              agentInput={{
                visible: visibleStreams,
                transducers: data && data.transducers,
              }}
            />
          </>
        )}
      </div>
    </>
  );
}

export default Dashboard;
