import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import {
  getThing as getThingy,
  forgetThing as forgetThingy,
  setThing as setThingy,
  createThing as createThingy,
  makeObservable,
} from "./util/database.js";

import { getSlug } from "./util/text.js";
import useHybridEffect from "./useHybridEffect.js";
import { useNavigate } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";
import useToken from "./useToken.js";
import useThings from "./useThings.js";

import update from "immutability-helper";

const userThing = makeObservable({ thing: [], count: 0 });

const apiPrefix = process.env.REACT_APP_API_PREFIX;

const webPrefix = process.env.REACT_APP_WEB_PREFIX;

const defaultThing = {
  index: 20,
  to: "localhost",
  subject: "Default",
  createdAt: Date.now(),
  uuid: uuidv4(),
  input: "Default",
};

export default function useThing(datagram) {
  const { token } = useToken();
  const { navigate } = useNavigate();
  const priorDatagram = usePrior(datagram);

  //  const [thing, setThing] = useState();
  const [thing, setThing] = useState(userThing.get().thing);

const [isVisible, setIsVisible] = useState();

  const { things, getThings, setThings } = useThings();

  const [flag, setFlag] = useState();
  //const [subject, setSubject] = useState();
  /*
  useEffect(() => {
    if (things == null) {return;}
    console.log("useThing things", things);
  }, [things]);
*/
  const uuid = datagram && datagram.uuid;

useHybridEffect(()=>{
if (datagram == null) {return;}
//if (datagram.uuid == "33fd9620-4d35-4f8e-b273-6d5754054486") {
console.log("useThing datagram merp", datagram, priorDatagram);
getThing();
//}


}, [datagram]);

useHybridEffect(()=>{
if (thing== null) {return;}


let v = false;

if (thing?.visible && thing.visible === true) {
v = true;

}

if (thing?.card?.visible && thing.card.visible === true) {
v = true;

}
//thing?.card?.visible
if (thing?.variables?.visible && thing.variables.visible === true) {
v = true;
}

setIsVisible(v);

},[thing]);

  useEffect(() => {
    if (uuid == null) {
      return;
    }
console.log("useThing uuid", uuid);
//getThing();

    if (isNotObject(thing)) {
      console.log("useThing uuid getThing called");
      getThing();
    }

    //console.log("useThing datagram", thing,datagram)
    //getThing();
  }, [uuid]);

  function isNotObject(obj) {
    return obj !== null && typeof obj !== "object" && !Array.isArray(obj);
  }

  const getThing = () => {

console.log("useThing getThing datagram", datagram);
//    if (datagram?.variables?.card?.visible !== true) {
//      return;
//    }




    if (token == null) {
      return;
    }

    if (flag === "red") {
      return;
    }

    if (datagram == null) {
      return;
    }

    console.log("useThing getThing token datagram", token, datagram);

    // If there is a uuid then get that Thing, returning false if not found.
    if (datagram && datagram.uuid && datagram.uuid !== null) {
      console.log("useThing getThing datagram uuid", datagram.uuid);
      setFlag("red");

      getThingy(datagram, token)
        .then((result) => {

          console.log("useThing getThing getThingy uuid", datagram.uuid);

          console.log("useThing getThing getThingy result", result);
          console.log(
            "useThing getThing getThingy result data thing",
            datagram.uuid,
            result.data.thing
          );
          console.log("useThing getThing getThingy  thing", thing);

          if (result.data == null) {
            return;
          }

          var combinedThing = thing;

          console.log("useThing getThing getThingy result data thing", result?.data?.thing);

          if (result && result.data && result.data.thing) {

//const iterableArr = Array.from(result.data.thing);
if (typeof result.data.thing !== "object" || result.data.thing === null) {
console.error("useThing getThing getThingy problem", result.data.thing);
} else {

// Need to develop the merging. For now
// assume request to get thing, means that we want it.
          setFlag("green");
          setThing(result.data.thing);
return;



            console.log("useThing getThing getThingy result.data.thing", result.data.thing);
            combinedThing = mergeObjectsInUnique(
     //         [...thing, ...iterableArr],
              [...thing, result.data.thing],
              "uuid"
            );
            console.log("useThing getThing getThingy combinedThing", combinedThing);
}
          }

console.log("useThing getThing getThingy thing.subject",thing.subject);
console.log("useThing getThing getThingy result.data.thing.subject", result.data.thing.subject);

          if (result && result.data && result.data.error) {
            combinedThing = mergeObjectsInUnique(
              [...thing, ...result.data.error],
              "uuid"
            );
          }

          //const uuids = combinedThing.wsuuid;
          const conditionedThing = combinedThing;
          console.log("useThing getThing conditionedThing", conditionedThing);
          setFlag("green");
          setThing(conditionedThing);
          //setSubject(conditionedThing.subject);
        })
        .catch((error) => {
          setFlag("yellow");
          // Add an error card in. Up front and center?

          //          setThing(false);
          console.error("useThing getThing getThingy " + datagram.uuid + " error", error);
        });
      return;
    }

    console.log("useThing createThing no uuid datagram", datagram);

    console.log("useThing createThings things length", things.length);

    if (things && things.length) {
      return;
    }

    // Don't create duplicates

    const x = getSlug(datagram.subject);
    const subjects = things.map((t) => {
      return getSlug(t.subject);
    });

    console.log("useThing createThing subjects", subjects);

    if (subjects.includes(x)) {
      return;
    }

    if (datagram.to === "stack") {
      return;
    }

    // Do not create a thing.
    // Only create a thing on a button click. tbd.
    return;
    // No uuid provided. SO create thing.
    setFlag("red");
    const doNotWait = createThingy(webPrefix, datagram, token, "I")
      .then((result) => {
        setFlag("green");
        console.log("useThing createThingy datagram result", datagram, result);

        const newThing = datagram;
        newThing.associations = {
          ...newThing.associations,
          uuid: result.uuid,
        };

        setThing(newThing);
        console.log("useThing createThingy setThing newThing");
        setThings(
          update(things, {
            $splice: [[0, 0, newThing]],
          })
        );
        console.log("useThing createThingy setThings");
        //getThings();
        //          props.onCollectionChange(things);
      })
      .catch((error) => {
        setFlag("yellow");
        console.log("spawnThing createThing error", error);
      });
  };

  var deepDiffMapper = (function () {
    return {
      VALUE_CREATED: "created",
      VALUE_UPDATED: "updated",
      VALUE_DELETED: "deleted",
      VALUE_UNCHANGED: "unchanged",
      map: function (obj1, obj2) {
        if (this.isFunction(obj1) || this.isFunction(obj2)) {
          throw "Invalid argument. Function given, object expected.";
        }
        if (this.isValue(obj1) || this.isValue(obj2)) {
          return {
            type: this.compareValues(obj1, obj2),
            data: obj1 === undefined ? obj2 : obj1,
          };
        }

        var diff = {};
        for (var key in obj1) {
          if (this.isFunction(obj1[key])) {
            continue;
          }

          var value2 = undefined;
          if (obj2[key] !== undefined) {
            value2 = obj2[key];
          }

          diff[key] = this.map(obj1[key], value2);
        }
        for (var key in obj2) {
          if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
            continue;
          }

          diff[key] = this.map(undefined, obj2[key]);
        }

        return diff;
      },
      compareValues: function (value1, value2) {
        if (value1 === value2) {
          return this.VALUE_UNCHANGED;
        }
        if (
          this.isDate(value1) &&
          this.isDate(value2) &&
          value1.getTime() === value2.getTime()
        ) {
          return this.VALUE_UNCHANGED;
        }
        if (value1 === undefined) {
          return this.VALUE_CREATED;
        }
        if (value2 === undefined) {
          return this.VALUE_DELETED;
        }
        return this.VALUE_UPDATED;
      },
      isFunction: function (x) {
        return Object.prototype.toString.call(x) === "[object Function]";
      },
      isArray: function (x) {
        return Object.prototype.toString.call(x) === "[object Array]";
      },
      isDate: function (x) {
        return Object.prototype.toString.call(x) === "[object Date]";
      },
      isObject: function (x) {
        return Object.prototype.toString.call(x) === "[object Object]";
      },
      isValue: function (x) {
        return !this.isObject(x) && !this.isArray(x);
      },
    };
  })();

  function usePrior(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {
    if (priorDatagram == null) {
      return;
    }
    if (datagram == null) {
      return;
    }

    var hasDatagramChanged = false;
    if (priorDatagram.subject !== datagram.subject) {
      hasDatagramChanged = true;
    }

    if (priorDatagram.to !== datagram.to) {
      hasDatagramChanged = true;
    }

    if (priorDatagram.from !== datagram.from) {
      hasDatagramChanged = true;
    }

    if (priorDatagram.agentInput !== datagram.agentInput) {
      hasDatagramChanged = true;
    }

    if (hasDatagramChanged === false) {
      return;
    }

    console.log(
      "useThing getThing datagram changed",
      priorDatagram,
      datagram,
      deepDiffMapper.map([priorDatagram, datagram])
    );

    getThing();
  }, [datagram, priorDatagram]);

  const findThing = useCallback(
    (id) => {
      const thing = things.filter((c) => `${c.index}` === id)[0];
      return {
        thing,
        index: things.indexOf(thing),
      };
    },
    [things]
  );

  const moveThing = useCallback(
    (id, atIndex) => {
      const { thing, index } = findThing(id);
      setThings(
        update(things, {
          $splice: [
            [index, 1],
            [atIndex, 0, thing],
          ],
        })
      );

      things.map((t, index) => {
        const newT = { ...t, index: index };

        setThingy(t.uuid, newT, token).then((result) => {
          console.log(result);
        });
      });

      // dev?
    },
    [findThing, things, setThings]
  );

  const foldThing = useCallback(
    (id, atIndex) => {
      const { thing, index } = findThing(id);

      const newThing = { ...thing };

      newThing.open = "folded";

      setThings(
        update(things, {
          $splice: [[index, 1, newThing]],
        })
      );

      // dev?
      setThingy(newThing.uuid, newThing, token).then((result) => {
        console.log(result);
      });

      //props.onCollectionChange(things);
    },
    [things]
  );

  const openThing = useCallback(
    (id, atIndex) => {
      const { thing, index } = findThing(id);

      //           setThings(update(things, {
      //               $splice: [[index,1]],
      ///           }));
      const newThing = { ...thing };

      //          newThing.associations = {
      //            ...newThing.associations,
      //            uuid: result.uuid,
      //          };

      newThing.open = "open";

      setThings(
        update(things, {
          $splice: [[index, 1, newThing]],
        })
      );

      // dev?
      setThingy(newThing.uuid, newThing, token).then((result) => {
        console.log("ThingContainer setThing result", result);
      });

      navigate("/" + "thing" + "/" + newThing.uuid + "/");

      //props.onCollectionChange(things);
    },
    [things]
  );

  useEffect(() => {
    getThings();
    return userThing.subscribe(setThing);
  }, []);


useEffect(()=>{

console.log("useThing thing", thing);

}, [thing]);
  const actions = useMemo(() => {
    return {
      setThing: (ts) => userThing.set({ ...thing, ts }),
    };
  }, [thing]);

  // Expect part of a thing.
  const updateThing = (t) => {

    console.log("useThing updateThing uuid", t.uuid);

    console.log("useThing updateThing thing variables", thing.variables);

console.log("useThing updateThing visible", datagram?.variables?.card?.visible);
    if (datagram?.variables?.card?.visible === false) {
      return Promise.resolve({error:{message:"Not visible"}});
    }


 //   if (thing?.variables?.card?.visible) {
//if (thing.variables.card.visible !== true) {
//      return Promise.resolve({error:{message:"Not visible"}});
//}
//    }


    //  const updateThing = useCallback(
    //    (id, atIndex) => {
    //console.log("deleteCard id", id);
    //console.log("deleteCard atIndex", atIndex);
    //      const { thing, index } = findThing(id);

    console.log("useThing updateThing t", t);
    console.debug("useThing updateThing token", token);
    console.debug("useThing updatething thing", thing);
    const newThing = { ...thing, ...t };

console.log("useThing updateThing newThing visible", newThing?.variables?.card?.visible);

// This seems to stop it pulling things	

//if ((newThing?.variables?.card?.visible == null) || (newThing?.variables?.card?.visible !== true)) {

//return Promise.resolve({error:{message:"Thing not visible"}});

//}



    console.debug(
      "useThing updateThing " + thing.uuid + " request saveThing",
      newThing
    );


if (newThing?.uuid ==null) {
return Promise.resolve({error:{message:"No uuid"}});
}

    return saveThing(newThing)
      .then((result) => {
        console.debug("useThing updateThing " + thing.uuid + " result", result);
        return result;
      })
      .catch((error) => {
        console.error("useThing updateThing " + thing.uuid + " error", error);
        return Promise.reject("Could not update thing");
      });

    //    return Promise.resolve(true);
  };

  const saveThing = (t) => {
if (t == null) {

return Promise.reject("Null thing provided.");

}

if (t?.uuid == null) {

return Promise.reject("No uuid provided.");

}


    console.debug(
      "useThing saveThing " + (t && t.uuid) + "userThing t token",
      t,
      token
    );
    setThing(t);
    return setThingy(t.uuid, t, token)
      .then((result) => {
        console.debug("useThing saveThing " + t.uuid + " result", result);
        return result;
      })
      .catch((error) => {
        console.error("useThing saveThing " + t.uuid + " error", error);
        return Promise.reject("Could not save thing.");
      });
  };

  const deleteThing = useCallback(
    (id, atIndex) => {
      if (things.length <= 1) {
        return;
      }

      console.debug("useThing deleteThing id", id);
      console.debug("useThing deleteThing atIndex", atIndex);
      const { thing, index } = findThing(id);

      console.debug("useThing deleteThing thing index", thing, index);

      setThings(
        update(things, {
          $splice: [[index, 1]],
        })
      );

      // Call delete Thing api
      return forgetThingy(thing, token)
        .then((res) => {
          console.log("useThing deleteThing " + thing.uuid, res);
          //getThings();
          //          props.onCollectionChange(things);
        })
        .catch((error) => {
          console.error("useThing deleteThing " + thing.uuid + " error", error);
        });
      //      props.onCollectionChange(things);
    },
    [things]
  );

  const flipThing = useCallback(
    (id, atIndex) => {
      //console.log("deleteCard id", id);
      //console.log("deleteCard atIndex", atIndex);
      const { thing, index } = findThing(id);

      const newThing = { ...thing };

      //          newThing.associations = {
      //            ...newThing.associations,
      //            uuid: result.uuid,
      //          };
      if (newThing && newThing.side === "back") {
        newThing.side = "front";
      } else if (newThing && newThing.side === "front") {
        newThing.side = "back";
      } else {
        // broken
        newThing.side = "front";
      }

      setThings(
        update(things, {
          $splice: [[index, 1, newThing]],
        })
      );

      // dev?
      setThingy(newThing.uuid, newThing, token).then((result) => {
        console.log("useThing flipThing " + thing.uuid + " result", result);
      });

      //           setThings(update(things, {
      //               $splice: [[index,1]],
      ///           }));
      //      props.onCollectionChange(things);
    },
    [things]
  );

  function testThing() {
    console.log("useThing testThing");
  }

  const spawnThing = useCallback(
    (id, atIndex) => {
      //console.log("deleteCard id", id);
      //console.log("deleteCard atIndex", atIndex);
      //const { thing, index } = findThing(id);
      const newThing = { ...thing };
      const uuid = uuidv4();
      newThing.uuid = uuid;
      console.debug("ThingContainer spawnThing thing", thing);
      console.debug("ThingContainer spawnThing token", token);

      // Spawn thing on designated stack.

      const doNotWait = createThingy(webPrefix, thing, token)
        .then((result) => {
          console.log(
            "spawnThing " + thing.uuid + " createThing result",
            result
          );

          newThing.associations = {
            ...newThing.associations,
            uuid: result.uuid,
          };

          setThings(
            update(things, {
              $splice: [[0, 0, newThing]],
            })
          );
          //getThings();
          //          props.onCollectionChange(things);
        })
        .catch((error) => {
          console.log("spawnThing createThing error", error);
        });
    },
    [things]
  );

  // We need a function in the client to merge things client side.

function mergeObjectsInUnique(array, property) {
  const newArray = new Map();

  array.forEach((item) => {
    const propertyValue = item[property];
    newArray.has(propertyValue)
      ? newArray.set(propertyValue, {
          ...item,
          ...newArray.get(propertyValue),
        })
      : newArray.set(propertyValue, item);
  });

  return Array.from(newArray.values());
}


  //  const deleteIdentity = (userIdentity) => {
  // Leave no rubbish behind.
  //    setIdentity(false);
  //  };

  return {
    //    deleteIdentity: deleteIdentity,
    //    state: thing,
    //    saveThing: setThing,
    //    saveThing:saveThing,
    updateThing: updateThing,
    setThing: saveThing,
    testThing: testThing,
    //    setThing: setThing,
    getThing: getThing,
    findThing: findThing,
    flipThing: flipThing,
    deleteThing: deleteThing,
    forgetThing: deleteThing,
    spawnThing: spawnThing,
    openThing: openThing,
    foldThing: foldThing,
    moveThing: moveThing,
    flag: flag,
    thing,
isVisible:isVisible,
  };
}
