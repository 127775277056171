import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";

import crypto from "crypto";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import { createThing, forgetThing } from "../util/database.js";
import useThings from "../useThings.js";

import { devFlag, debugFlag } from "../util/dev.js";


const { REACT_APP_CLIENT_SECRET } = process.env;
const { REACT_APP_API_PREFIX } = process.env;

const defaultWebPrefix = process.env.REACT_APP_WEB_PREFIX;

async function signupUser(credentials) {
  const { REACT_APP_CLIENT_SECRET } = process.env;
  const { REACT_APP_API_PREFIX } = process.env;

  console.log("Signup signupUser credentials", credentials);

  return fetch(REACT_APP_API_PREFIX + "auth/signup", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((data) => {
      //   return data.json();
      //  });
      //    .then((data) => {
      console.log("Signup signupUser data", data);
      return data.json();
    })
    .catch((error) => {
      console.error("Signup signupUser error", error);

      if (error && error.code) {
        return { message: error.code };
      }
      return { data: null, error: { message: error } };
    });
}

export default function Signup({thing:inputThing, agentInput}) {
  const webPrefix = defaultWebPrefix;

  const { things, getThings } = useThings(inputThing);
  const { navigate } = useNavigate();
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [from, setFrom] = useState();

  const [status, setStatus] = useState();

  const [response, setResponse] = useState();
  const [message, setMessage] = useState();

  useEffect(() => {
    setStatus("idle");
  }, []);

  useEffect(() => {
    console.log("Signup from password username", from, password, username);
/*
    if (
      ((from == null ||
      from == "") &&
      (password == null ||
      password == "") &&
      (username == null ||
      username == ""))
    ) {
      setStatus("idle");
      return;
    }
*/

    if (
      from == null ||
      from == "" ||
      password == null ||
      password == "" ||
      username == null ||
      username == ""
    ) {
      setStatus("awaiting");
      return;
    }

    setStatus("idle");
  }, [from, password, username]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("submitting");
    console.log("Signup username", username);
    console.log("Signup password", password);

    // Client salted hash
    const hash = crypto.createHmac(
      "sha256",
      process.env.REACT_APP_CLIENT_SECRET
    );
    // Salted hash of username and password.
    // Using client provided salt.
    var data = hash.update(username + password);
    //Creating the hash in the required format
    var gen_hash = data.digest("hex");
    //Printing the output on the console
    //console.log("Login hash : " + gen_hash);

    const pass = "";

    const t = await signupUser({
      username: gen_hash,
      password: pass,
      email: from,
    });

    console.log("Signup handleSubmit", t);

    setMessage(t.message);

    if (t && t.message) {
      setMessage(t.message);
      console.log("Signup token message", t.message);

      setMessage("Got token message. " + t.message);
    } else if (t && t.message == null) {
      console.log("Signup message Null message reponse.");

      setMessage("Got a null response.");

      //toast("Logged in");
      setResponse((response) => {
        return response + "Null response. ";
      });
    } else {
      console.log("Signup No message t", t);

      //setLogin("yes");
      // Change window location here... route.
      console.log("Signup change window location");
      //window.location.href = "http://localhost:3000/" + "thing";
      //window.history.replaceState(null, null, /product/${this.props.product.id});

      //window.history.replaceState(null, null, /thing/);

      const defaultThings = [
        {
          index: 20,
          to: "localhost",
          from: "stack",
          subject: "Log Out",
          priority: "priority",
          createdAt: Date.now(),
          //          uuid: uuidv4(),
          input: "Logout",
        },
        {
          index: 21,
          to: "localhost",
          from: "stack",
          subject: "Token",
          priority: "priority",
          createdAt: Date.now(),
          //          uuid: uuidv4(),
          input: "Token",
        },
      ];

      // This is analaguous to thing-react-ash.
      // The client creates things and manages them.
      // createThing getThing forgetThing setThing
      createThing(webPrefix, defaultThings[0], t, "G");
      createThing(webPrefix, defaultThings[1], t, "H");

//      getThings(t);
      // Get things to forget
/*
      const forgetStrings = ["sign in", "log in"];
      if (things && things.length > 0) {
        const thingsToBeForgotten = things.filter((t) => {
          var found = false;
          forgetStrings.forEach((forgetString) => {
            if (t.subject.toLowerCase().includes(forgetString.toLowerCase())) {
              found = true;
            }
          });
          return !found;
        });
        console.log("Login thingsToBeForgotten", thingsToBeForgotten);

        thingsToBeForgotten.map((thingToBeForgotten) => {
          console.log("Login thingToBeForgotten", thingToBeForgotten.subject);
          forgetThing(thingToBeForgotten, t);
          return;
        });
      }
*/
      //getThings(t);

      setMessage("Signed up.");
      toast("Signed up");
      setResponse((response) => {
        return response + "Signed up. ";
      });
      navigate("/" + "signup");
    }

    //setIdentity(token);
    //setToken(token);
    setStatus("idle");
  };

  return (
    <div className="signup-wrapper">
      <h1>Please Sign Up</h1>
      <form onSubmit={handleSubmit}>
        <label>
          <p>Username</p>
          <input type="text" onChange={(e) => setUserName(e.target.value)} />
        </label>
        <label>
          <p>Password</p>
          <input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <p>From(email)</p>
          <input type="channel" onChange={(e) => setFrom(e.target.value)} />
        </label>
        <div>
          <Button
            disabled={status == "idle" ? false : true}
            variant="action"
            type="submit"
          >
            Submit
          </Button>
        </div>
      </form>
{debugFlag && (<>      <br />
      STATUS
      <br />
      {status}
      <br />
      RESPONSE
      <br />
      {response}
      <br />
</>)}
    </div>
  );
}

Signup.propTypes = {
  //  setToken: PropTypes.func.isRequired,
};
