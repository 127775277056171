import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom";
import Transducer from "../components/Transducer.js";

const DataTable = ({ data }) => {
  const location = useLocation();
  const [columns, setColumns] = useState(2);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        const newColumns = Math.floor(width / 200); // Adjust the divisor for column width
        setColumns(newColumns > 0 ? newColumns : 1); // Ensure at least 1 column
      }
    };

    handleResize(); // Set initial columns
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Check if data is undefined or not an object
  if (!data || typeof data !== 'object') {
    return <div>No data available</div>; // Display a message if data is undefined
  }

  const entries = Object.entries(data);
  const totalCells = entries.length;
  const rows = Math.ceil(totalCells / columns); // Calculate the number of rows based on total cells and columns

  return (
    <div ref={containerRef} style={{ width: '100%', overflowX: 'auto' }}>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <tbody>
          {Array.from({ length: rows }, (_, rowIndex) => (
            <tr key={rowIndex}>
              {Array.from({ length: columns }, (_, colIndex) => {
                const index = rowIndex * columns + colIndex;
                if (index < entries.length) {
                  const [key, value] = entries[index];
                  let amount;
                  if (value?.amount) {
                    amount = value.amount;
                  }

                  return (
                    <td key={colIndex} style={{ border: '1px solid #ccc', padding: '8px', width: `${100 / columns}%` }}>
                      <Transducer agentInput={{ data: data, flavour: "bland", transducer: key, index: index }} />
                    </td>
                  );
                }
                return <td key={colIndex} style={{ border: '1px solid #ccc' }}></td>; // Empty cell if no data
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
