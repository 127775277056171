import React, { useState, useEffect, useRef } from "react";
import { IconButton, Typography, Box } from "@mui/material";

import { devFlag, debugFlag } from "../util/dev.js";

var style = {
  whiteSpace: "pre-line",
  fontWeight: "500",
  color: "#ffffff",
  background: "#0c387194",
  borderRadius: "7px",
  padding: "5px 10px 5px 10px",
  marginBottom: "2px",
};

export default function Variables(props) {
  var { thing } = props;

const [variables, setVariables] = useState({});

useEffect(()=>{
if (thing == null) {return;}
if (thing.variables == null) {return;}

setVariables(thing.variables);
//console.log("Variables thing", thing.variables);

},[thing.variables]);

  return (
    <>

<br />

<>{Object.keys(variables).map((k,i)=>{
return (<div key={k}>{k}{' '}{variables[k].flag ? "RED" : "GREEN"}
{' '}{variables[k][k]}
<br /></div>);
})}
</>

<br />
<>
{variables && variables.weekday && variables.weekday.weekdays && variables.weekday.weekdays.join(' ')}
</>
<br />
    </>
  );
}
