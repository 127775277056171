import React, { useState, useEffect } from "react";

import "../index.css";
import {
  Typography,
  //  Avatar,
  //  ListItemAvatar,
  Box,
} from "@mui/material";

import {
  Button,
  TextField,
  IconButton,
  ListItem,
  ListItemText,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";

import {
  //AddCircleOutlineRounded,
  //DeleteOutlineRounded,
  Edit,
} from "@mui/icons-material";

import useHybridEffect from "../useHybridEffect.js";

import Forget from "../components/Forget.js";
import Agent from "../components/Agent.js";

import { devFlag, debugFlag } from "../util/dev.js";

import { getSlug } from "../util/text.js";

const recognizedSlugs = [
  "activate",
  "dashboard",
  "alarms",
  "alerts",
  "magnetometer",
  "history",
  "snapshot",
  "transducers",
  "ping",
  "error",
  "text-snapshot",
  "global-positioning-system",
  "barometer",
  "weather",
  "motion-reference",
  "inertial-reference",
  "power",
  "messages",
  "temperature-humidity",
  "humidity-temperature",
  "log-in",
  "sign-up",
  "token",
  "log-out",
  "events",
];

function slugAgent(slug) {
  const parts = slug.split("-");

  const capitalizedParts = parts.map((part) => {
    const capitalized = part.charAt(0).toUpperCase() + part.slice(1);
    return capitalized;
  });

  return capitalizedParts.join("");
}

// Refactor to pass this in thing as variables.
const engineState = process.env.REACT_APP_ENGINE_STATE;

function Agents({ thing, agentInput, onThingReport }) {
  const agent_input = agentInput; // remove

  const [agents, setAgents] = useState();

  const [flag, setFlag] = useState();
  const [reply, setReply] = useState("");

  const [data, setData] = useState({
    thing: { uuid: "X" },
    thingReport: { status: "loading", sms: "No response. Yet." },
  });

  function handleThingReport(t) {
    onThingReport(t);
  }

  const replyAgentDialog = (thing) => {
    //    setOpen(true);
  };

  function humanTime(timestamp) {
    const ts = new Date();
    return ts.toISOString();
  }

  function fromName() {
    if (thing === undefined) {
      return "Agent";
    }

    if (thing && thing.from === undefined) {
      return "Agent";
    }

    return thing.from;
  }

  useHybridEffect(() => {
    if (thing == null) {
      return;
    }
    console.log("Agents thing agentInput", thing, agentInput);
    console.log("Agents test", agentInput, recognizedSlugs);
    if (typeof thing.subject !== "undefined") {
      console.log("Agents thing.subject", thing.subject);
      const matchedSlugs = recognizedSlugs.filter((recognizedSlug) => {
        console.debug(
          "Agents recognizedSlug agentInput[recognizedSlug agentInput",
          recognizedSlug,
          agentInput[recognizedSlug],
          agentInput
        );

        if (
          recognizedSlug in agentInput &&
          agentInput[recognizedSlug] === false
        ) {
          console.log("Agents recognizedSlug in agentInput as false");
          return false;
        }

        if (thing.subject.toLowerCase().indexOf(recognizedSlug) !== -1) {
          return true;
        }

        /*
        if (getSlug(thing.subject).indexOf(recognizedSlug) !== -1) {
          return true;
        }
*/

        return false;
      });

      // Test
      if (thing.subject.toLowerCase() === "please log in") {
        matchedSlugs.push("login");
      }

      if (thing.subject.toLowerCase() === "please sign up") {
        matchedSlugs.push("signup");
      }

      if (thing.subject.toLowerCase() === "log out") {
        matchedSlugs.push("logout");
      }

      if (thing.subject.toLowerCase() === "data monitor") {
        matchedSlugs.push("datamonitor");
      }

      //      console.log("Agents thing matchedSlugs", matchedSlugs);

      setAgents(matchedSlugs);

      {
      }
    }
  }, [thing]);

  useEffect(() => {
    console.debug("Agents agents", agents);
  }, [agents]);

  // Used??
  const editAgents = () => {
    const datagram = {
      comment: reply,
      to: "merp",
      from: thing.from,
      //from: user_name,
      association: thing.uuid,
    };
    console.debug("Datagram");
    console.debug(datagram);
  };

  function timeStamp() {
    var date = Date.now();
    return date.toString();
  }

  return (
    <>
      {debugFlag && <div>AGENTS</div>}
      {/*
      {devFlag && (
        <>
          DEV FLAG
          {thing &&
            thing.variables &&
            thing.variables.flag &&
            thing.variables.flag.dev}
        </>
      )}
*/}
      {thing &&
        agents &&
        agents.map((agent, index) => {
          return (
            <div key={"agents"+ "_"+ agent+"_"+index}>
              {debugFlag && (<>{thing && thing.uuid}{' '}{agent}<br /></> ) }
              <Agent
                key={"agents_" + agent + "_" + index}
                thing={thing}
                agentInput={{ ...agentInput, agent: agent }}
                onThingReport={(t) => handleThingReport(t)}
              />
            </div>
          );
        })}
    </>
  );
}

export default Agents;
