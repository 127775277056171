import React, { useState, useEffect } from "react";

import DynamicComponent from "../components/DynamicComponent.js";
import useHybridEffect from "../useHybridEffect.js";

import "../index.css";
import {
  Typography,
  //  Avatar,
  //  ListItemAvatar,
  Box,
} from "@mui/material";

import {
  Button,
  TextField,
  IconButton,
  ListItem,
  ListItemText,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";

import {
  //AddCircleOutlineRounded,
  //DeleteOutlineRounded,
  Edit,
} from "@mui/icons-material";

import Forget from "../components/Forget.js";
import Trace from "../components/Trace.js";
import Stream from "../components/Stream.js";
import BubbleLevel from "../components/BubbleLevel.js";
import Inclinometer from "../components/Inclinometer.js";

import Magnetometer from "../components/Magnetometer.js";

import MotionReference from "../components/MotionReference.js";

import Ping from "../components/Ping.js";
import Transducers from "../components/Transducers.js";
import DataTable from "../components/DataTable.js";

import AhrefLink from "../components/AhrefLink.js";
import ThingButton from "../components/Button.js";

//import { getSnapshot } from "../util/database.js";

import useSnapshot from "../useSnapshot.js";

import { useLocation } from "react-router-dom";

import {
  isText,
  extractUuid,
  extractNuuid,
  getSlug,
  capitalizeFirstLetter,
  transducerLink,
} from "../util/text.js";

import { deepDiffMapper, getChangedLeaves } from "../util/data.js";

//  function usePrior(value) {

import { devFlag, debugFlag } from "../util/dev.js";

//import { useSwipeable } from "react-swipeable";

const { REACT_APP_SNAPSHOT } = process.env;

// refactor as
// Snapshot(thing, agentInput)

const engineState = process.env.REACT_APP_ENGINE_STATE;
const defaultSnapshotInterval = process.env.REACT_APP_SNAPSHOT_INTERVAL;

function Transducer({ thing, agentInput }) {
  const visibleStreams = [
    "speed_in_knots",
    "speed_in_cables",
    "transducer-throllad0",
  ];

  const location = useLocation();

  const [transducer, setTransducer] = useState();
  const [index, setIndex] = useState();
  const [data, setData] = useState();

  const datagram = thing;

  const agent_input = agentInput;
  const webPrefix = agentInput;
  //const [flag, setFlag] = useState();
  //const [requestedAt, setRequestedAt] = useState();
  const [reply, setReply] = useState("");
  const [snapshotInterval, setSnapshotInterval] = useState(
    defaultSnapshotInterval
    //1000
  );

  const defaultToSnapshot = REACT_APP_SNAPSHOT;
  const [toSnapshot, setToSnapshot] = useState(defaultToSnapshot);

  const { snapshot, priorSnapshot, flag, snapshotRunTime } = useSnapshot(
    toSnapshot,
    null,
    thing
  );

  function transducerAmount(t) {
    if (t && t.amount) {
      return t.amount;
    }
    return t;
  }

  useHybridEffect(() => {
    if (thing == null) {
      return;
    }
    if (thing.subject == null) {
      return;
    }

    const uuidPathname = extractUuid(thing.subject);

    if (uuidPathname === true) {
      return;
    }
    if (uuidPathname === false) {
      return;
    }

    setToSnapshot("https://stackr.ca/snapshot/" + uuidPathname + "/hey.json");
  }, [thing]);

  useEffect(() => {
    console.log("Snapshot toSnapshot", toSnapshot);
  }, [toSnapshot]);

  //const [data, setData] = useState({
  //  thing: { uuid: "X" },
  //  thingReport: { sms: "No response. Yet." },
  //});
  //const [data, setData] = useState();

  const [open, setOpen] = useState(false);

  const replyAgentDialog = (thing) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function humanTime(timestamp) {
    const ts = new Date();
    return ts.toISOString();
  }

  function deprecatetransducerLink(t, transducer) {
    if (transducer == null) {
      return;
    }
    console.log("Transducer transducerLink t", t);
    // http://localhost:3000/history/8afaf195-a30f-4c6f-ab16-316680e118c3-snapshot-throttled-1d

    //const updatedUrl = location.pathname.replace(/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})-snapshot-[a-zA-Z0-9]+/, `$1-snapshot-${transducer}`);

    const url = location.pathname;

    //const agents = "snapshot"; // This can be any string
    const agents = ["snapshot", "transducers"];

    // Use a regular expression to find the pattern and replace the last part with the value of transducer
    //const updatedUrl = url.replace(/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})-(.+?)-[a-zA-Z0-9]+/, `$1-${agents}-${transducer.toLowerCase()}`);

    // Create a regex pattern to match any combination of the agents
    const agentsPattern = agents.join("|"); // Join agents with | to create a regex OR pattern

    // Use a regular expression to find the pattern and replace it
    const updatedUrl = url.replace(
      new RegExp(
        `([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})-(${agentsPattern})(?:-(${agentsPattern}))?-[a-zA-Z0-9]+`
      ),
      (match, uuid, agentsPart) => {
        // Return the UUID, the matched agents, and the new transducer
        return `${uuid}-${agentsPart}-${transducer.toLowerCase()}`;
      }
    );

    return updatedUrl;

    // Define the agents array
    //    const agents = ["snapshot", "transducers"];

    /*
    // Create a regex pattern to match any combination of the agents
    const agentsPattern = agents.join('|'); // Join agents with | to create a regex OR pattern

    // Use a regular expression to find the pattern and replace it
    const updatedUrl = url.replace(
        /([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})-(?:snapshot|transducers)(?:-(?:snapshot|transducers))?-[a-zA-Z0-9]+/,
        `$1-${agents[0]}-${transducer.toLowerCase()}`
    );

    return updatedUrl;
*/

    if (t == null) {
      return "/snapshot";
    }

    if (t?.uuid == null) {
      return "/history/" + "transducers-" + transducer.toLowerCase() + "-1d";
    }

    return (
      "/history/" +
      t.uuid +
      "-snapshot-transducers-" +
      transducer.toLowerCase() +
      "-1d"
    );
  }

  useHybridEffect(() => {
    if (thing == null) {
      return;
    }

    console.log("Snapshot thing", toSnapshot, thing.uuid, thing);
  }, [thing]);

  useHybridEffect(() => {
    if (agentInput == null) {
      return;
    }

    if (agentInput?.transducer) {
      setTransducer(agentInput?.transducer);
    }
    if (agentInput?.index) {
      setIndex(agentInput?.index);
    }

    if (agentInput?.data) {
console.log("Transducer data", data);
      setData(agentInput?.data);
    }

    console.log("Snapshot agentInput", agentInput);
  }, [agentInput]);

  function handleThingReport(r) {
    r.preventDefault();
  }

  function fromName() {
    if (datagram === undefined) {
      return "Agent";
    }

    if (datagram && datagram.from === undefined) {
      return "Agent";
    }

    return datagram.from;
  }

  function timeStamp() {
    var date = Date.now();
    return date.toString();
  }

  const [ampDataPointer, setAmpDataPointer] = useState(0);
  const [ampPoints, setAmpPoints] = useState([]);
  const startTime = new Date();
  const [voltPoints, setVoltPoints] = useState([]);
  const [tracePeriod, setTracePeriod] = useState();

  function handleChangeStream(c) {
    console.log("Snapshot handleChangeStream c", c);
  }
  /*
  useEffect(() => {
    console.log("Snapshot data", data);
  }, [data]);
*/
  function callBack() {
    console.log("Agent callBack called.");
  }

  const deleteButton = (
    <Forget uuid={datagram && datagram.uuid} callBack={callBack} />
  );

  //return (<>TRANSDUCER</>);
  //return (<>{transducer}x</>);
  if (transducer == null) {
    return <></>;
  }
  //return (<>{transducerLink(thing,transducer)}</>);
  if (agentInput?.flavour && agentInput?.flavour.includes("button")) {
    const amountFlag = false;
    var amountText = "";
    //         if (amountFlag) {
    //          amountText = transducerAmount(transducers[transducer]);
    //        }

    var disabledFlag = false;
    if (location.pathname.toLowerCase().includes(transducer.toLowerCase())) {
      disabledFlag = true;
    }

    return (
      <>
        <ThingButton
          thing={thing}
          //            agentInput={{ disabled: false, text: transducer + amountText, link: "/history/" + thing.uuid + "-snapshot-transducers-" + transducer.toLowerCase() + "-1d" }}
          agentInput={{
            // disabled: false,
            disabled: disabledFlag,
            text: transducer + amountText,
            link: transducerLink(thing, transducer, location?.pathname),
          }}
          onThingReport={() => {}}
        />
      </>
    );
  }


  if (visibleStreams.includes(transducer)) {
    return (
      <div key={"transducer_" + transducer + "_" + index}>
        <AhrefLink
          agentInput={{
            link: transducerLink(thing, transducer, location.pathname),
          }}
        >
          {" "}
          {transducer}
        </AhrefLink>{" "}
        not a visible stream
        <br />
      </div>
    );
    //    return;
  }

  if (data && data[transducer] && visibleStreams.includes(transducer)) {
    return (
      <>
        <AhrefLink
          agentInput={{
            link: transducerLink(thing, transducer, location.pathname),
          }}
        >
          {" "}
          {transducer}
        </AhrefLink>
        <Stream
          key={transducer}
          hide={true}
          quantity={{
            units: "A",
            amount: data && data[transducer],
          }}
          transducer={data[transducer]}
          agentInput={{
            visible: visibleStreams.includes(transducer),
          }}
        />
      </>
    );
  }
  if (data && data[transducer] && !isNaN(data[transducer])) {
    return (
      <div key={"transducer_" + transducer + "_" + index}>
        <AhrefLink
          agentInput={{
            link: transducerLink(thing, transducer, location.pathname),
          }}
        >
          {" "}
          {transducer}
        </AhrefLink>{" "}
        : {data[transducer]}
        <br />
      </div>
    );
    //    return;
  }

  if (data && data[transducer] && isText(data[transducer])) {
    return (
      <div key={"transducer_" + transducer + "_" + index}>
        <AhrefLink
          agentInput={{
            link: transducerLink(thing, transducer, location.pathname),
          }}
        >
          {" "}
          {transducer}
        </AhrefLink>{" "}
        : {data[transducer]}
        <br />
      </div>
    );
    //    return;
  }

  if (data && data[transducer] && isText(data[transducer]?.amount)) {

const tl = transducerLink(thing, transducer, location.pathname);
    return (
      <div key={"transducer_" + transducer + "_" + index}>
{/*
{transducer}
{location.pathname}
{tl}
*/}
        <AhrefLink
          agentInput={{
            link: tl,
          }}
        >
          {" "}
          {transducer}
        </AhrefLink>{" "}
        : {data[transducer].amount}
        <br />
      </div>
    );
    //    return;
  }



  if (true && data && isText(transducer) && data[transducer]) {
    return (
      <>
        {/*                    TRANDUCER {transducer}*/}
        <AhrefLink
          agentInput={{
            link: transducerLink(thing, transducer, location.pathname),
          }}
        >
          {" "}
          {transducer}
        </AhrefLink>
        <br />
        <DynamicComponent
          is={capitalizeFirstLetter(transducer)}
          channel={"image"}
          user={null}
          //thing={data.thing}

          thing={thing}
          //agentInput={agentInput}
          onThingReport={(r) => handleThingReport(r)}
          datagram={thing}
          agentInput={{ ...agentInput, data: data }}
        />
      </>
    );
  }

  if (data && data[transducer]) {
    return (
      <div key={"transducer_" + transducer + "_" + index}>
        <AhrefLink
          agentInput={{
            link: transducerLink(thing, transducer, location.pathname),
          }}
        >
          {transducer}
        </AhrefLink>{" "}
        not readable - {JSON.stringify(data[transducer])}
        <br />
      </div>
    );
  }

  return (
    <div key={"transducer_" + transducer + "_" + index}>
      <AhrefLink
        agentInput={{
          link: transducerLink(thing, transducer, location.pathname),
        }}
      >
        {transducer}
      </AhrefLink>{" "}
      not readable - X
      <br />
    </div>
  );
}

export default Transducer;
