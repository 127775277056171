import React from 'react';
import { useNavigate } from 'react-router-dom';

const AhrefLink = ({ agentInput, children }) => {
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault(); // Prevent the default anchor behavior
    navigate(agentInput.link); // Use navigate to change the URL

// It's a href link.
// So we expect a jump.
  window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <a href={agentInput.link} onClick={handleClick}>
      {children}
    </a>
  );
};

export default AhrefLink;
