import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { findUUIDPositions, isValidUUID, hasUUID } from "../util/uuid.js";
import { findTokenPositions } from "../util/token.js";
import { findTextPositions } from "../util/text.js";

import "../index.css";

import useHybridEffect from "../useHybridEffect.js";
import useHistory from "../useHistory.js";

import { makeStyles } from "@mui/styles";

import { minMaxData, minMaxTicks } from "../util/data.js";

import StrategyVsBenchmarkChart from "../components/StrategyVsBenchmarkChart.js";

import {
  Typography,
  //  Avatar,
  //  ListItemAvatar,
  Box,
} from "@mui/material";

import {
  //  Button,
  TextField,
  //  IconButton,
  ListItem,
  ListItemText,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";

import {
  //AddCircleOutlineRounded,
  //DeleteOutlineRounded,
  Edit,
} from "@mui/icons-material";

import Forget from "../components/Forget.js";

import Event from "../components/Event.js";

import TraceCircle from "../components/TraceCircle.js";
import Stream from "../components/Stream.js";
import BubbleLevel from "../components/BubbleLevel.js";
import Inclinometer from "../components/Inclinometer.js";

import Magnetometer from "../components/Magnetometer.js";

import MotionReference from "../components/MotionReference.js";

import Ping from "../components/Ping.js";
import Button from "../components/Button.js";

import Transducers from "../components/Transducers.js";

//import { getSnapshot } from "../util/database.js";

//import { zuluTime as bananaTime } from "stack-js/src/util/time";
//import { deduplicateThingsBySubject } from "stack-js/src/util/stack";

import {
  zuluTimeDifferenceMilliseconds,
  zuluTime,
  humanRuntime,
  convertFromMilliseconds,
  convertToMilliseconds,
extractDuration,
  extractDurations,
} from "../util/time.js";
// convertToMilliseconds(timeString)

import {
  sortThingsByAt,
  extractUuid,
  parsePing,
  prefixText,
extractReference,
//extractDuration,
} from "../util/text.js";

import { atSpread } from "../util/data.js";

import useSnapshot from "../useSnapshot.js";

/*
    deleteSnapshot: deleteSnapshot,
    setSnapshot: saveSnapshot,
    snapshot,
    flag,
    snapshotRunTime,
    snapshotRunAt,
    snapshotInterval,
*/

import useThingReport from "../useThingReport.js";

import { useSwipeable } from "react-swipeable";

//import { devFlag, debugFlag } from "../util/dev.js";
import { devFlag, debugFlag } from "../util/dev.js";

import { useTheme } from "@mui/material/styles";

import { extractPeriod } from "../util/text.js";

//const debugFlag = true;

// Refactor to pass this in thing as variables.
const engineState = process.env.REACT_APP_ENGINE_STATE;
//const snapshotInterval = process.env.REACT_APP_SNAPSHOT_INTERVAL

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}
/*
function extractPeriod(text) {
  console.log("History extractPeriod text", text);
  if (text == null) {
    return null;
  }
  const x = text.split(".json");

  var parts = x[0].split("-");
  var result = parts[parts.length - 1]; // Or parts.pop();

  return result;
}
*/
function deduplicateThingsBySubject(things) {
  //  const subjects = things.map((o) => o.subject);
  const subjects = things.map((o) => {
    return;
    JSON.stringify(o);
  });

  const deduplicatedThings = things.filter(
    (t, index) => !subjects.includes(JSON.stringify(t), index + 1)
  );

  return deduplicatedThings;
}

function timeStamp() {
  var date = Date.now();
  return date.toString();
}

function getPollIntervalFromPeriod(periodText) {
  return Math.floor(convertToMilliseconds(extractPeriod(periodText)) / 2);
}
/*
function extractReference(text) {

    const hr = text
      .replace("history/", "")
      .replace("history-", "")
      .replace("history ", "");

return hr;
}
*/
/*
function extractDuration(text) {

    var tempR = text
      .replace("transducers-", "")
      .replace("history/", "")
      .replace("history-", "")
      .replace("history ", "");

    //const r = tempR;
    //console.log("History tempR", tempR);
    const i = tempR.split("-");
    console.log("History tempR i", tempR, i);

    var tempTextIntervalArray = extractDurations(i);
    if (
      Array.isArray(tempTextIntervalArray) &&
      tempTextIntervalArray.length > 0
    ) {
      var tempTextInterval = tempTextIntervalArray.slice(-1)[0];
    }
return tempTextInterval;

}
*/
function Events({ thing, agentInput }) {
  const navigate = useNavigate();
  const histories = {};

  const datagram = thing;
  //  const { showLive } = props;
  const showLive = true;

  const [subject, setSubject] = useState();

  const [windowIndex, setWindowIndex] = useState();
  const [tracePoints, setTracePoints] = useState([]);

  const [ref, setRef] = useState();
  const [historyRef, setHistoryRef] = useState();
  const [text, setText] = useState();
  const [resolution, setResolution] = useState();
  const [amount, setAmount] = useState();
  const [textInterval, setTextInterval] = useState();

  const [domain2, setDomain2] = useState();

  const [flavour, setFlavour] = useState();

  useHybridEffect(() => {
    if (agentInput == null) {
      return;
    }
  }, [agentInput]);

  useHybridEffect(() => {
    if (datagram == null) {
      return;
    }

    if (datagram.subject) {
      setSubject(datagram.subject);
    }

    console.log("History datagram", datagram);
  }, [datagram]);

  useEffect(() => {
    if (subject == null) {
      return;
    }

    console.debug("History subject", subject);

   var tempR = subject;

if (hasUUID(subject)) {

} else {
    tempR = tempR
      .replace("transducers-", "");
}

    tempR = tempR
      .replace("history/", "")
      .replace("history-", "")
      .replace("history ", "");
/*
    const r = tempR;
    //console.log("History tempR", tempR);
    const i = tempR.split("-");
    console.log("History tempR i", tempR, i);

    var tempTextIntervalArray = extractDurations(i);
    if (
      Array.isArray(tempTextIntervalArray) &&
      tempTextIntervalArray.length > 0
    ) {
      var tempTextInterval = tempTextIntervalArray.slice(-1)[0];
    }
*/
const tempTextInterval = extractDuration(subject);
    setTextInterval(tempTextInterval);

//setTextInterval(extractDuration(subject));

    if (textInterval !== true) {
      tempR = tempR.replace("-" + tempTextInterval, "");
    }

    console.log("History tempR textInterval", tempR, tempTextInterval);

    const maxInterval = 1000;

    try {
      var interval = true;
      // Convert ms s and minutes and hours to milliseconds

      interval = convertToMilliseconds(tempTextInterval);
      setPeriod(interval);
    } catch (error) {
      console.error("History convertToMilliseconds", error);
      interval = 1000;
    }

    setSnapshotInterval(interval);

    console.debug("History interval", tempR, tempTextInterval, interval);

    setRef(tempR);
/*
    const hr = subject
      .replace("history/", "")
      .replace("history-", "")
      .replace("history ", "");
*/
const hr = extractReference(subject);

    setHistoryRef(hr);

    if (hr == null) {
      return;
    }

    // Lowest resolution allowed is 10 minutes.

    var hrTemp = hr;
    //if (tempTextInterval === "1d" || tempTextInterval === "20m" || tempTextInterval === "30m") {
    if (tempTextInterval === "1d") {
      hrTemp = hr.replace("-" + tempTextInterval, "-10m");
    }

    setHistoryTo(webPrefix + "history/" + hrTemp + ".json");

    console.debug("History subject", subject);
    const subjectTokens = subject.split("-");
    console.debug("History parts", subjectTokens[subjectTokens.length - 1]);

    setResolution(subjectTokens[subjectTokens.length - 1]);

    const tokenPositions = findTokenPositions(subjectTokens, "snapshot");

    console.log("History tokenPositions", subjectTokens, tokenPositions);

    if (tokenPositions.length === 1) {
      const startIndex = tokenPositions[0];
      const t = subjectTokens.slice(startIndex + 1, subjectTokens.length - 1);
      const f = t.join(" ");
      setText(f);
      // setText(subjectTokens[tokenPositions[0]]]);

      return;
    }

    setText(subjectTokens[subjectTokens.length - 2]);
  }, [subject]);

  const webPrefix = process.env.REACT_APP_WEB_PREFIX;

  const [snapshotTo, setSnapshotTo] = useState();
  const [historyTo, setHistoryTo] = useState();

  const [snapshotInterval, setSnapshotInterval] = useState();

  const [period, setPeriod] = useState();

//  const periodIntervals = ["1s", "5s", "1m", "5m", "10m"];
  const periodIntervals = ["1m", "5m", "10m"];

  //  const periodIntervals = ["1h"];

  let periods = [];
  periods = periodIntervals.map((periodInterval) => {
    return null;
  });

  //  let histories = [];

  //  histories = periodIntervals.map((periodInterval) => {
  //    return null;
  //  });

  periodIntervals.map((periodInterval) => {
    histories[extractPeriod(periodInterval)] = { period: periodInterval };
    //setHistories(histories);
    //    return null;
  });

  // So here should listen to the environments statement
  // on what is an appropriate maximum poll rate.

  const {
    snapshot: data,
    flag: snapshotFlag,
    snapshotRunTime: snapshotRunTime,
    //    snapshotRunAt,
    snapshotRunAt: nowRunAt,
  } = useSnapshot(snapshotTo, convertToMilliseconds(extractPeriod("1s")), thing); // Choose lowest of periods in data
  //  } = useSnapshot(snapshotTo, "now");
  //  } = useSnapshot(snapshotTo, 1000);

/*
  histories["now"] = {
    history: data,
    period: "now",
    flag: snapshotFlag,
    runtime: snapshotRunTime,
    runAt: nowRunAt,
  };
*/
  // Not sure we need thingReport.
  // Check and see where thing and thingReport are being used here.

  // thingReport is a way to pass the processing of this component UPWARD.
  // And DOWNWARD.

  const {
    thingReport,
    flag: thingReportFlag,
    //    thingReportGetTime: thingReportGetTime,
 // } = useThingReport(snapshotTo, 1000);
} = useThingReport(snapshotTo, null);
  // This next history makes sure we have the 10m history set in historyTo.

  /*
  const {
    snapshot: history,
    flag: historyFlag,
    snapshotRunTime: historyRunTime,
  } = useSnapshot(historyTo, convertToMilliseconds(extractPeriod("10m")));
*/

  // 1000 points in each snapshot
  // So need a 1000 points to fill in each inter

  if (ref && typeof ref !== "undefined") {
    console.log("History ref", ref);

    periods = periodIntervals.map((periodInterval) => {
      return linkHistory("transducers-" + ref + "-" + periodInterval);
    });

    if (hasUUID(ref)) {
      //    period1 = linkHistory(ref + "-" + "1s");
      //    period2 = linkHistory(ref + "-" + "1m");
      //    period3 = linkHistory(ref + "-" + "30m");
      //    period4 = linkHistory(ref + "-" + "1h");

      periods = periodIntervals.map((periodInterval) => {
        return linkHistory(ref + "-" + periodInterval);
      });
    }
  }
console.log("History periods", periods);
  // Refactor this as useSnapshots or useHistories.
  const { historyPoints: historyPoints } =
    useHistory(periods);

  useHybridEffect(() => {
    if (datagram == null) {
      return;
    }
    // Extract uuid from datagram.
    // To provide access to the snapshot.
    // Consider: Can refactor this code into useSnapshot().
    console.debug("History datagram", snapshotTo, datagram.subject);
    const uuid = extractUuid(datagram.subject);

    var to = webPrefix + "/snapshot/" + uuid + "/hey.json";
    if (uuid === false) {
      to = webPrefix + "/snapshot.json";
    }

    setSnapshotTo(to);
  }, [datagram]);

  const [flag, setFlag] = useState();
  //  const [historyPoints, setHistoryPoints] = useState([]);

  const config = {
    delta: 10, // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: false, // prevents scroll during swipe (*See Details*)
    trackTouch: true, // track touch input
    trackMouse: false, // track mouse input
    rotationAngle: 0, // set a rotation angle
    swipeDuration: Infinity, // allowable duration of a swipe (ms). *See Notes*
    touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
  };

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      console.debug("User Swiped test");
      handleSwipe(eventData);

      console.debug("User Swiped!", eventData);
    },
    ...config,
  });

  const availableWindows = ["", "1m", "2m", "10m", "15m", "30m", "1h"];

  const [reply, setReply] = useState("");

  const [open, setOpen] = useState(false);

  const replyAgentDialog = (thing) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useHybridEffect(() => {
    console.log("History hybrideffect data");
    if (data == null) {
      return;
    }

    if (ref == null) {
      return;
    }

    console.log("History data", snapshotTo, data);
    setAmount(
      data &&
        data.transducers &&
        data.transducers[ref] &&
        data.transducers[ref].amount
    );
  }, [data]);

  function concatenateArrays(arrays) {
    return arrays
      .filter((array) => array !== undefined)
      .reduce((result, array) => result.concat(array), []);
  }

  function deprecate_extractHistory(h) {
    var hist = false;

    if (h.agent_input) {
      if (Array.isArray(h.agent_input)) {
        console.debug("History set history from history.agent_input");
        hist = h.agent_input;
      }
    }

    if (h.thingReport && h.thingReport.history) {
      console.debug("History set history from history.thingReport.history");
      hist = h.thingReport.history;
    }

    return hist;
  }

  function linkHistory(hr) {
    return webPrefix + "history/" + hr + ".json";
  }

  function humanTime(timestamp) {
    const ts = new Date();
    return ts.toISOString();
  }

  function fromName() {
    if (datagram === undefined) {
      return "Agent";
    }

    if (datagram && datagram.from === undefined) {
      return "Agent";
    }

    return datagram.from;
  }

  const startTime = new Date();
//  const [voltPoints, setVoltPoints] = useState([]);
  const [tracePeriod, setTracePeriod] = useState();

  function callBack() {
    console.debug("Agent callBack called.");
  }

  function handleSwipe(e) {
    console.debug("User Swiped e.dir", e.dir);

    if (e.dir === "Left") {
      const w = windowIndex + 1;
      setWindowIndex(w);

      console.debug("User Swiped Left");
      if (thingReport) {
        thingReport(w);
      }
    }

    if (e.dir === "Right") {
      console.debug("History User Swiped  Right");

      const w = windowIndex - 1;
      if (w >= 0) {
        setWindowIndex(w);

        if (thingReport) {
          thingReport(w);
        }
      }
    }
  }
const [benchmarkPoints, setBenchmarkPoints] = useState([]);
  useHybridEffect(() => {
    console.debug("History historyPoints", historyPoints);
    // Bin history points in to cycle.
    console.debug("History textInterval", textInterval);

    var cycleMilliseconds = 1000 * 24 * 60 * 60;

    if (textInterval === "1d") {
    }

    if (textInterval === "1y") {
      cycleMilliseconds = 1000 * 24 * 365 * 60 * 60;
    }

    var cycleStartDate = new Date();
    cycleStartDate.setHours(24, 0, 0, 0);

    // Set to midnight local time.

    //const s = atSpread(historyPoints);

    const [min, max] = minMaxData(historyPoints);
    //setFilteredDataSpread(s);
    const a = minMaxTicks(min, max, 5);
    //console.debug("Trace filteredData a", a);

    setDomain2(a);
    console.log("cycleStartDate", cycleStartDate);
    const cycleRunAt = zuluTime(cycleStartDate);
    console.debug("History cycleRunAt", cycleRunAt);
    const cycleStartMilliseconds = cycleStartDate.getTime();

    let sortedHistoryPoints = sortThingsByAt(historyPoints);

const bp = sortedHistoryPoints.reverse().map((p)=>{
console.debug("History historyPoints p",p);
  var date = new Date(p.at);
  var seconds = parseInt(date.getTime() / 1000);
  //console.log("History seconds", seconds);
  return {date:seconds, benchmarkValue:p.amount, portfolioValue:p.amount};

});



setBenchmarkPoints(bp);

    const cyclePoints = sortedHistoryPoints.map((historyPoint) => {
      var cyclePoint = {};
      const ageMilliseconds = zuluTimeDifferenceMilliseconds(
        historyPoint.at,
        cycleRunAt
      );

/*
  const data = item.strategyVsBenchmark?.map(entry => ({
    date: new Date(entry.date * 1000).toISOString().split("T")[0], // Convert timestamp to dat>
    Benchmark: entry.benchmarkValue,
    Strategy: entry.portfolioValue,
  }));
*/


      const cycleIndex = Math.floor(ageMilliseconds / cycleMilliseconds);

      const key = cycleIndex === 0 ? "amount" : "amount" + cycleIndex;
      cyclePoint[key] = historyPoint.amount;

      const cycleAgeMilliseconds =
        ageMilliseconds - cycleIndex * cycleMilliseconds;

      const x = cycleStartMilliseconds - cycleAgeMilliseconds;

      const at = zuluTime(new Date(x));

      cyclePoint["at"] = at;
      return cyclePoint;
    });

    setTracePoints(cyclePoints);
  }, [historyPoints, textInterval]);

  const deleteButton = (
    <Forget uuid={datagram && datagram.uuid} callBack={callBack} />
  );

  if (ref == null) {
    return <>NO REF</>;
  }

  if (agentInput?.flavour == "line") {
    //return (<>hey{JSON.stringify(histories['10m'])}</>);

    return (
      <>
LINE
      </>
    );
  }

  return (
    <>

      {thing && thing.subject && !thing.subject.includes("history") && (
        <>
          <Button
            variant="url"
            thing={{
              subject: prefixText(subject, ""),
              agentInput: "Go To History",
            }}
          >
            {subject}
          </Button>
          <br />
        </>
      )}

{/*  item,
  startDate,
  endDate,
  matchesSM,
  simple = false,
*/}





      {debugFlag && (
        <>
          <br />
          <div>SUBJECT {subject}</div>
          <div>
            FLAG {flag} COLOUR
            <br />
          </div>
        </>
      )}

<>EVENTS
{/*JSON.stringify(data)*/}
{data && data.error && data.error.message}

{data && data.events && data.events.map((x)=>{

return (<>
{/*
{x.uuid}<br />
{x.subject}<br />
*/}
<Event thing={thing} agentInput={{...agentInput,event:x}} />
</>);

}) }


    </>
</>
  );
}

export default Events;
/*
    flag: snapshotFlag,
    snapshotRunTime: snapshotRunTime,
//    snapshotRunAt,
    snapshotRunAt: nowRunAt,
*/
