import React, { useState, useEffect } from "react";

import "../index.css";
import {
  Typography,
  //  Avatar,
  //  ListItemAvatar,
  Box,
} from "@mui/material";

import useHybridEffect from "../useHybridEffect.js";

import {
  zuluTimeDifferenceMilliseconds,
  zuluTime,
  humanAge,
  //  humanRuntime,
  //  convertFromMilliseconds,
  //  convertToMilliseconds,
  //  extractDurations,
} from "../util/time.js";

import {
  Button,
  TextField,
  IconButton,
  ListItem,
  ListItemText,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";

import {
  //AddCircleOutlineRounded,
  //DeleteOutlineRounded,
  Edit,
} from "@mui/icons-material";

import Forget from "../components/Forget.js";
import Trace from "../components/Trace.js";
import Stream from "../components/Stream.js";
import BubbleLevel from "../components/BubbleLevel.js";
import Inclinometer from "../components/Inclinometer.js";

import Magnetometer from "../components/Magnetometer.js";

import MotionReference from "../components/MotionReference.js";

import Ping from "../components/Ping.js";
//import { getSnapshot } from "../util/database.js";

import AlarmDisplay from "../components/AlarmDisplay.js";
import ExpanderCollapser from "../components/ExpanderCollapser.js";


import useSnapshot from "../useSnapshot.js";

import { isText, extractUuid, extractNuuid, getSlug } from "../util/text.js";

import { devFlag, debugFlag } from "../util/dev.js";

//import { useSwipeable } from "react-swipeable";

const { REACT_APP_SNAPSHOT } = process.env;

// refactor as

const engineState = process.env.REACT_APP_ENGINE_STATE;
const defaultSnapshotInterval = process.env.REACT_APP_SNAPSHOT_INTERVAL;

function Alarms({ thing, agentInput }) {
  //const visibleStreams = ['speed_in_knots','speed_in_cables','transducer-throllad0'];

  const { visible: visibleStreams } = agentInput;

  const datagram = thing;

  const agent_input = agentInput;
  const webPrefix = agentInput;
  //const [flag, setFlag] = useState();
  //const [requestedAt, setRequestedAt] = useState();
  const [reply, setReply] = useState("");
  const [snapshotInterval, setSnapshotInterval] = useState(
    defaultSnapshotInterval
  );

  const defaultToSnapshot = REACT_APP_SNAPSHOT;
  const [toSnapshot, setToSnapshot] = useState(defaultToSnapshot);

  const { snapshot, flag, snapshotRunTime } = useSnapshot(toSnapshot);

  //const [data, setData] = useState({
  //  thing: { uuid: "X" },
  //  thingReport: { sms: "No response. Yet." },
  //});
  const [data, setData] = useState();

  const [open, setOpen] = useState(false);

  const replyAgentDialog = (thing) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  /*
  useHybridEffect(() => {
    if (agentInput.transducers == null) {
      return;
    }

    setData({ transducers: agentInput.transducers });



  }, [agentInput && agentInput.transducers]);
*/
  useHybridEffect(() => {
    if (agentInput.data == null) {
      return;
    }

    if (agentInput.data && agentInput.data.alarms) {
      console.log("Alarms", agentInput.data.alarms);
      setData(agentInput.data.alarms);
    }
  }, [agentInput && agentInput.data]);

  function humanTime(timestamp) {
    const ts = new Date();
    return ts.toISOString();
  }

  function fromName() {
    if (datagram === undefined) {
      return "Agent";
    }

    if (datagram && datagram.from === undefined) {
      return "Agent";
    }

    return datagram.from;
  }

  function timeStamp() {
    var date = Date.now();
    return date.toString();
  }

  const [ampDataPointer, setAmpDataPointer] = useState(0);
  const [ampPoints, setAmpPoints] = useState([]);
  const startTime = new Date();
  const [voltPoints, setVoltPoints] = useState([]);
  const [tracePeriod, setTracePeriod] = useState();

  function handleChangeStream(c) {
    console.log("Alarm handleChangeStream c", c);
  }

  useEffect(() => {
    console.log("Alarm data", data);
  }, [data]);

  function callBack() {
    console.log("Agent callBack called.");
  }

  return (
    <>
      {/* Handle the transducers field special */}
      ALARMS
      <br />
      {data && (
        <>
<AlarmDisplay agentInput={{alarms:data}} />
<ExpanderCollapser>
<pre>{JSON.stringify(data, null, 2)}</pre>
</ExpanderCollapser>
{/*
          {Object.keys(data).map((alarmNumber, index) => {
            //            console.log("Alarm alarm", alarm);
            const alarm = data[alarmNumber];

            //         if (visibleStreams.includes(transducer)) {
            return (
              <div key={"alarm_" + index}>
                {alarmNumber} {"NOT OK"} {alarm.ack ? "ACK" : ""}
                <br />
                {alarm.alarm_at && humanAge(alarm.alarm_at)}{" "}
                {alarm.ack_at && humanAge(alarm.ack_at)} <br />
                {JSON.stringify(data[alarmNumber])}
                <br />
              </div>
            );


          })}
*/}

        </>
      )}
    </>
  );
}

export default Alarms;
