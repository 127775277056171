/*
export function minMaxData(data, field) {

if (field == null) {field = 'amount';}

console.debug("Trace ySeriesData data", data);
    var yVals = data.map(function (val) {
//      return val.amount;
return val.amount;
    });

    var minY = Math.min.apply(Math, yVals);
    var maxY = Math.max.apply(Math, yVals);
const numberOfYTicks = 5;

return [minY, maxY];

}
*/

export function insertNullObjects(data, subwedges = null) {
  if (data == null) {
    return false;
  }
  //return data;
  const newArray = [];
  //console.log("insertNullObjects data", data);
  for (let i = 0; i < data.length; i++) {
    console.log("TraceCircle insertNullObjects data[i]", data[i]);
    newArray.push(data[i]);

    if (i > 0 && data[i + 1]) {
      const currentTime = data[i].x;
      const nextTime = data[i + 1].x;

      const timeDifference = currentTime - nextTime;
      //console.log("timeDifference data[i]", timeDifference, data[i]);

      if (timeDifference > 3600000 * subwedges) {
        // 1 hour in milliseconds
        const averageTime = (currentTime + nextTime) / 2;
        newArray.push({ x: averageTime, y: null });
      }
    }
  }

  //newArray.push(data[data.length]);

  return newArray;
}

export function minMaxData(data, field) {
  if (field == null) {
    field = "amount";
  }

  console.debug("Trace ySeriesData data", data);

  var yVals = data
    .map(function (val) {
      if (val == null) {
        return null;
      }

      return val[field];
    })
    .filter(function (value) {
      return value !== undefined && value !== null && !isNaN(value);
    });

  if (yVals.length === 0) {
    // Handle the case where all values are NaN, undefined, or null
    return [0, 0];
  }

  var minY = Math.min.apply(Math, yVals);
  var maxY = Math.max.apply(Math, yVals);
  const numberOfYTicks = 5;

  return [minY, maxY];
}

function largestFactorLessThan5(n) {
  const factors = [10, 5, 2, 1];
  for (const factor of factors) {
    if ((n - factor) % 10 === 0) {
      return factor;
    }
  }
  return 1;
}

function findClosestMultiple(n) {
console.log("data findClosestMultiple n", n);

  const closestMultiple1 = Math.pow(10, Math.floor(Math.log10(n)));
  const closestMultiple2 = closestMultiple1 * 2;
  const closestMultiple5 = closestMultiple1 * 5;
  const closestMultiple2d5 = closestMultiple1 * 2.5;
  const closestMultiple10 = closestMultiple1 * 10;
  const closestMultiple25 = closestMultiple1 * 25;


const multiples1 = [    closestMultiple1,
    closestMultiple2,
    closestMultiple5,
    closestMultiple2d5, closestMultiple10,closestMultiple25
  ];

const multiples = multiples1.sort(function(a, b) {
  return a - b;
});

var smallest = null;

multiples.map((m)=>{

//return abs(m) - abs(n)
//const distance = Math.abs(m) - Math.abs(n);
//console.log("data findClosestMultiple m", m);

//console.log("data findClosestMultiple distance", distance);

if (m >= n && smallest == null) {smallest = m;}

});

//if (multiples.includes(n)) {return n;}
console.log("data findClosestMultiple n", n);
console.log("data findClosestMultiple multiples", multiples);
console.log("data findClosestMultiple smallest", smallest);
return smallest;
//return Math.max(...multiples);
/*
  return Math.max(

    closestMultiple1,
    closestMultiple2,
    closestMultiple5,
    closestMultiple25
  );
*/
}

function findValidFactor(factor) {
  const divisors = [10, 5, 2, 1];
  for (const divisor of divisors) {
    if (factor % divisor === 0) {
      return divisor;
    }
  }
  return factor;
}

export function minMaxTicks(min, max, numberOfTicks) {
  if (max == null || min == null || numberOfTicks < 2) {
    return [];
  }

  const y = [];
  const tickRange = Math.abs(max - min);

  //const largestFactor = largestFactorLessThan5(Math.abs(tickRange));
  const requestedInterval = tickRange / numberOfTicks;
  const tickInterval = findClosestMultiple(requestedInterval);

  //const tickInterval = tickRange / ((numberOfTicks - 1) * adjustedFactor);
  //const tickInterval = adjustedFactor;
  console.debug("data minMaxTicks min", min);

  console.debug("data minMaxTicks max", max);

  console.debug("data minMaxTicks tickRange", tickRange);

  console.debug("data minMaxTicks numberOfTicks", numberOfTicks);

  console.debug("data minMaxTicks requestedInterval", requestedInterval);

  console.debug("data minMaxTicks tickInterval", tickInterval);

  const newMin = roundDownToTickInterval(min, tickInterval);

  const decimalPlaces = Math.ceil(Math.log(1 / tickInterval)) - 1;

  console.log("data minMaxTicks decimalPlaces", decimalPlaces);

  const newMax = roundUpToTickInterval(max, tickInterval);

  console.log("data minMaxTicks newMax", newMax);

  // Recompute with new min and new max
  const tickRange1 = Math.abs(newMax - newMin);
  console.log("data minMaxTicks tickRange1", tickRange1);

  //const largestFactor = largestFactorLessThan5(Math.abs(tickRange));
  const requestedInterval1 = tickRange1 / numberOfTicks;

  console.log("data minMaxTicks requestedInterval1", requestedInterval1);

  const tickInterval1 = findClosestMultiple(requestedInterval1);

  console.log("data minMaxTicks tickInterval1", tickInterval1);

  const newMin1 = roundDownToTickInterval(min, tickInterval1);

  for (let i = 0; i < numberOfTicks; i++) {
    const tickValue = newMin1 + i * tickInterval1;
    y.push(tickValue);
  }

  //y.push(newMax);
  y.sort();

  console.log("data minMaxTicks y", y);
  return y;
  if (!Number.isFinite(decimalPlaces)) {
    return y;
  }
  if (decimalPlaces < 0) {
    return y;
  }

  const y2 = y.map((a) => {
    return a.toFixed(decimalPlaces);
  });

  console.log("data minMaxTicks y2", y2);

  return y2;
}

function roundDownToTickInterval(n, tickInterval) {
  return Math.floor(n / tickInterval) * tickInterval;
}

function roundUpToTickInterval(n, tickInterval) {
  return Math.ceil(n / tickInterval) * tickInterval;
}

export function atSpread(data) {
  if (data == null) {
    return;
  }
  if (Array.isArray(data) && data.length === 0) {
    return;
  }
  const first = new Date(data[0].at);
  const last = new Date(data[data.length - 1].at);

  const spreadEvent = last - first > 0 ? last - first : first - last;

  // setFirstAt(data[0].at);
  //setLastAt(data[data.length - 1].at);
  return {
    firstAt: data[0].at,
    lastAt: data[data.length - 1].at,
    spread: spreadEvent,
  };
}

export function makeTicks(inputData) {
  console.log("data makeTicks inputData", inputData);

  if (inputData == null) {
    return [];
  }
  if (inputData === []) {
    return [];
  }

  //const data = inputData.map((x)=>{return {y:x}});
  const data = processData(inputData);

  console.log("data makeTicks data", data);

  var minY = Math.min.apply(Math, data);
  var maxY = Math.max.apply(Math, data);
  console.log("data makeTicks minY", minY);
  console.log("data makeTicks maxY", maxY);

  //    const [min, max] = minMaxData(filteredData);
  //console.debug("Trace filteredData", filteredData, minY, maxY);
  //setFilteredDataSpread(s);
  const a = minMaxTicks(minY, maxY, 5);

  //return a;
  console.log("data makeTicks a", a);

  // const mergedArray = [].concat(...data);

  //console.log("TraceCircle mergedArray", mergedArray);
  //const mergedArray = conditionedData.slice(-1);

  //    console.log("dataTraceCircle mergedArray", mergedArray);

  //    const [min, max] = minMaxData(data);
  //    const b = minMaxTicks(min, max, 5);

  //console.log("data makeTicks b", b);

  return a;
}

export function conditionData(data, subwedges = null) {
  if (data == null) {
    return;
  }
  if (data.length === 0) {
    return;
  }
  console.log("TraceCircle data", data);
  // Convert raw data to the desired format
  // Little bit hairy this, because the first key
  // is amount. So has no index.

  const newData = [];
  data.forEach((obj) => {
    Object.entries(obj).forEach(([key, value]) => {
      if (key.startsWith("amount")) {
        var seriesIndex = parseInt(key.replace("amount", ""));

        if (key === "amount") {
          seriesIndex = 0;
        }

        if (!newData[seriesIndex]) {
          newData[seriesIndex] = [];
        }

        const t = new Date(obj.at).getTime();
        newData[seriesIndex].push({ x: t, y: value });
      }
    });
  });

  newData.reverse();

  const newArray = newData.map((n) => {
    //return n;
    return insertNullObjects(n, subwedges);
  });
  console.log("TraceCircle newArray", newArray);

  return newData;
}

export function processData(data, subwedges = null) {
  if (data == null) {
    return;
  }
  if (data.length === 0) {
    return;
  }
  console.log("TraceCircle data", data);
  // Convert raw data to the desired format
  // Little bit hairy this, because the first key
  // is amount. So has no index.

  const newData = [];
  data.forEach((obj) => {
    Object.entries(obj).forEach(([key, value]) => {
      if (key.startsWith("amount")) {
        var seriesIndex = parseInt(key.replace("amount", ""));

        if (key === "amount") {
          seriesIndex = 0;
        }

        //         if (!newData[seriesIndex]) {
        //           newData[seriesIndex] = [];
        //         }

        //         const t = new Date(obj.at).getTime();
        newData.push(value);
      }
    });
  });

  newData.reverse();

  const newArray = newData.map((n) => {
    //return n;
    return insertNullObjects(n, subwedges);
  });
  console.log("TraceCircle newArray", newArray);

  return newData;
}

// https://stackoverflow.com/questions/35279319/js-getting-value-of-object-w>
export // https://stackoverflow.com/questions/35279319/js-getting-value-of-object-w>
function getObjectValueByPartialName(obj, name) {
  if (typeof obj !== "object") {
    throw new Error("object is required!");
  }
  for (var prop in obj) {
    if (prop.indexOf(name) === 0) {
      return obj[prop];
    }
  }
  return "no such property!";
}


export  var deepDiffMapper = (function () {
    return {
      VALUE_CREATED: "created",
      VALUE_UPDATED: "updated",
      VALUE_DELETED: "deleted",
      VALUE_UNCHANGED: "unchanged",
      map: function (obj1, obj2) {
        if (this.isFunction(obj1) || this.isFunction(obj2)) {
          throw "Invalid argument. Function given, object expected.";
        }
        if (this.isValue(obj1) || this.isValue(obj2)) {
          return {
            type: this.compareValues(obj1, obj2),
            data: obj1 === undefined ? obj2 : obj1,
          };
        }

        var diff = {};
        for (var key in obj1) {
          if (this.isFunction(obj1[key])) {
            continue;
          }

          var value2 = undefined;
          if (obj2[key] !== undefined) {
            value2 = obj2[key];
          }

          diff[key] = this.map(obj1[key], value2);
        }
        for (var key in obj2) {
          if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
            continue;
          }

          diff[key] = this.map(undefined, obj2[key]);
        }

        return diff;
      },
      compareValues: function (value1, value2) {
        if (value1 === value2) {
          return this.VALUE_UNCHANGED;
        }
        if (
          this.isDate(value1) &&
          this.isDate(value2) &&
          value1.getTime() === value2.getTime()
        ) {
          return this.VALUE_UNCHANGED;
        }
        if (value1 === undefined) {
          return this.VALUE_CREATED;
        }
        if (value2 === undefined) {
          return this.VALUE_DELETED;
        }
        return this.VALUE_UPDATED;
      },
      isFunction: function (x) {
        return Object.prototype.toString.call(x) === "[object Function]";
      },
      isArray: function (x) {
        return Object.prototype.toString.call(x) === "[object Array]";
      },
      isDate: function (x) {
        return Object.prototype.toString.call(x) === "[object Date]";
      },
      isObject: function (x) {
        return Object.prototype.toString.call(x) === "[object Object]";
      },
      isValue: function (x) {
        return !this.isObject(x) && !this.isArray(x);
      },
    };
  })();



 export const getChangedLeaves = (obj) => {
    const result = {};

    const traverse = (currentObj) => {
      for (const key in currentObj) {
        if (currentObj.hasOwnProperty(key)) {
          const value = currentObj[key];

          // Check if the value is an object and has a type property
          if (typeof value === 'object' && value !== null && 'type' in value) {
            if (value.type !== 'unchanged') {
              // If it's not unchanged, add it to the result
              result[key] = value;
            }
          } else {
            // If it's a nested object, recurse into it
            traverse(value);
          }
        }
      }
    };

    traverse(obj);
    return result;
  };
