import React, { useState } from 'react';

// Sample styles for tabs and table
const styles = {
  tab: {
    display: 'inline-block',
    padding: '10px',
    margin: '5px',
    backgroundColor: '#f0f0f0',
    border: '1px solid #ccc',
    cursor: 'pointer',
  },
  table: {
    borderCollapse: 'collapse',
    marginTop: '20px',
  },
  cell: {
    border: '1px solid #ccc',
    padding: '10px',
    textAlign: 'center',
  },
};

const AlarmDisplay = ({ thing, agentInput }) => {
  const { alarms } = agentInput;
  const [indexCode, setIndexCode] = useState('000'); // Initialize index code

  // Function to handle tab click
  const handleTabClick = (tabIndex) => {
    setIndexCode(`${tabIndex}${indexCode[1]}${indexCode[2]}`); // Update first digit
  };

  // Function to handle cell click
  const handleCellClick = (rowIndex, colIndex) => {
    setIndexCode(`${indexCode[0]}${rowIndex}${colIndex}`); // Update second and third digits
  };

  // Function to render the alarm table
  const renderAlarmTable = () => {
    const rows = Array.from({ length: 10 }, (_, rowIndex) => (
      <tr key={rowIndex}>
        {Array.from({ length: 10 }, (_, colIndex) => {
          const alarmCode = `${indexCode[0]}${rowIndex}${colIndex}`; // Use current index code
          const alarmStatus = alarms[alarmCode] || 'UNKNOWN';
          return (
            <td key={colIndex} style={styles.cell} onClick={() => handleCellClick(rowIndex, colIndex)}>
              {alarmStatus}
            </td>
          );
        })}
      </tr>
    ));

    // Render the last row with tabs
    const tabRow = (
      <tr key="tabs">
        {Array.from({ length: 10 }, (_, index) => {
          const alarmCode = `${index}99`; // Use the last row index for tabs
          const alarmStatus = alarms[alarmCode] || 'UNKNOWN';
          return (
            <td key={index} style={styles.cell} onClick={() => handleTabClick(index)}>
              {alarmStatus}
            </td>
          );
        })}
      </tr>
    );

    return (
      <table style={styles.table}>
        <tbody>
          <tr>
            <td style={styles.cell}>{indexCode}</td>
            {Array.from({ length: 9 }, (_, index) => (
              <td key={index + 1} style={styles.cell}></td>
            ))}
          </tr>
          {rows}
          {tabRow}
        </tbody>
      </table>
    );
  };

  // Function to render tabs
  const renderTabs = () => {
    return (
      <div>
        {Array.from({ length: 10 }, (_, index) => (
          <div key={index} style={styles.tab} onClick={() => handleTabClick(index)}>
            Tab {index}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <h1>{thing}</h1>
      {renderAlarmTable()}
      {renderTabs()}
    </div>
  );
};

export default AlarmDisplay;
