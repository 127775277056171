import { useState, useEffect } from "react";

import { getWebJson } from "./util/database.js";
import { humanTime, zuluTime, extractDuration } from "./util/time.js";
import { concatenateArrays } from "./util/array.js";
import useHybridEffect from "./useHybridEffect.js";
//import useDeepCompareEffect from "use-deep-compare-effect";

import {
  sortThingsByAt,
  deduplicateThingsByHaystack,
  isNumeric,
  getSlug,
  diffText,
  //  extractUuid,
  parsePing,
  extractReference,
  //  prefixText,
} from "./util/text.js";

import { hasUUID } from "./util/uuid.js";

function extractTransducer(text) {
  const tokens = getSlug(text).split("-");

  return tokens[tokens.length - 3];
}

const defaultSnapshotInterval = process.env.REACT_APP_SNAPSHOT_INTERVAL;
const webPrefix = process.env.REACT_APP_WEB_PREFIX;
const history = {};
var histories = {};
/*
  function concatenateArrays(arrays) {
    return arrays
      .filter((array) => array !== undefined)
      .reduce((result, array) => result.concat(array), []);
  }
*/
function extractHistory(h) {
  var hist = false;

  if (h.agent_input) {
    if (Array.isArray(h.agent_input)) {
      console.debug("useHistory History set history from history.agent_input");
      hist = h.agent_input;
    }
  }

  if (h.thingReport && h.thingReport.history) {
    console.debug("History set history from history.thingReport.history");
    hist = h.thingReport.history;
  }

  return hist;
}

function processHistory(hist) {
  if (hist == null) {
    return;
  }

  const isArrayEmpty = Array.isArray(hist) && hist.length === 0;
  const isArrayNotEmpty = Array.isArray(hist) && hist.length > 0;

  if (!Array.isArray(hist)) {
    return;
  }

  console.debug("History hist", hist);
  //    const hist = history.agent_input;
  const p = hist.map((h) => {
    let amount = null;

console.log("useHistory h.event", h.event);


    if (h && h.event) {
      amount = false;
    }

    if (h && h.event && isNumeric(h.event)) {
console.log("useHistory isNumeric h.event", h.event);
      amount = h.event;
    }

    if (typeof h.event === "string" || h.event instanceof String) {
      amount = parseFloat(h.event);
    }

    if (h.event && h.event.amount) {
      amount = parseFloat(h.event.amount);
    }

if (h && h.event && (typeof(h.event) === "number")) {

console.log("useHistory number h.event", h.event);

amount = h.event;

}


    if (h.event && h.event.data) {
      const pingArray = parsePing(h.event.data);

      const f = {
        name: pingArray.host,
        student: 24,
        fees: 1,
        value: pingArray.amount,
        amount: pingArray.amount,
        amount2: pingArray.amount2,
        amount3: pingArray.amount3,
        at: h.eventAt,
      };

      return f;
    }

    // Add item to it
    const f = {
      name: "asdf",
      student: 24,
      fees: 1,
      value: amount,
      amount: amount,
      amount2: amount,
      amount3: amount,
      at: h.eventAt,
    };

    return f;
  });
  return p;
}

function linkHistory(hr) {
  return webPrefix + "history/" + hr + ".json";
}

export default function useHistory(input, inputSnapshotPollInterval) {
  //const to = input;
  /*
  const [snapshotInterval, setSnapshotInterval] = useState(
    inputSnapshotPollInterval == null
      ? defaultSnapshotInterval
      : inputSnapshotPollInterval
  );
*/
  const [snapshotInterval, setSnapshotInterval] = useState();
  const [flag, setFlag] = useState();

  const [history, setHistory] = useState([]);
  const [historyPoints, setHistoryPoints] = useState([]);

  const [snapshotRunTime, setSnapshotRunTime] = useState();
  const [snapshotRunAt, setSnapshotRunAt] = useState();
  const [snapshotResults, setSnapshotResults] = useState([]);
  const [sequentialErrorCount, setSequentialErrorCount] = useState();

  useEffect(() => {
    if (inputSnapshotPollInterval == null) {
      return;
    }

    if (inputSnapshotPollInterval == "now") {
      setSnapshotInterval(defaultSnapshotInterval);
      return;
    }

    setSnapshotInterval(inputSnapshotPollInterval);
  }, [inputSnapshotPollInterval]);

const [priorTransducer, setPriorTransducer] = useState();

  useHybridEffect(() => {
    console.debug("useSnapshot hybrideffect input", input);

    if (input == null) {
      console.debug("useHistory hybrideffore input is null", input);

      return;
    }

    //    getSnapshot();
histories = {};
    if (Array.isArray(input)) {

const tr = extractTransducer(input[0]);

if (tr !== priorTransducer) {
setHistoryPoints([]);
setPriorTransducer(tr);

}

      input.map((i) => {
    console.log("useHistory getHistory i", i);
//const ref = i.replace(".json","");
    const ref = extractTransducer(i);

//if (histories && typeof histories === 'object' && Object.keys(histories).length > 0 && histories.hasOwnProperty(ref)) {
//    console.log("useHistory getHistory ref", histories[ref]);
//}


        getHistory(i).then((r2) => {
          if (r2?.error) {
            console.error("useHistory getHistory error", r2?.error);
            return;
          }

//    histories[i] = history;


          addHistory({ link: i, history: r2, refreshedAt: zuluTime() });
        });
      });
    }
  }, [input]);

  useEffect(() => {
    //if (typeof snapshotInterval !== 'number') {
    //  console.error('snapshotInterval should be a valid number.');
    //  return;
    //}
    return;
    if (input == null) {
      return;
    }
    const intervalValue =
      typeof snapshotInterval === "number"
        ? snapshotInterval
        : defaultSnapshotInterval;

    console.debug(
      "useSnapshot interval inputSnapshotPollInterval",
      inputSnapshotPollInterval
    );
    console.debug("useSnapshot interval snapshotInterval", snapshotInterval);

    console.debug("useSnapshot interval intervalValue", intervalValue);

    console.debug(
      "useSnapshot interval input snapshotInterval",
      input,
      snapshotInterval,
      defaultSnapshotInterval
    );


// Comment this ut 8-dec-2024. no variable. probably not doing miuch
//    getHistory();



    if (snapshotInterval === false) {
      // false = call once.
      return;
    }

    const interval = setInterval(() => {
      console.debug(
        "useSnapshot interval called inputSnapshotPollInterval snapshotInterval intervalValue input",
        inputSnapshotPollInterval,
        snapshotInterval,
        intervalValue,
        input
      );
      //getSnapshot();
    }, intervalValue); // 20 Hz was 200.

    return () => clearInterval(interval);
  }, [input, snapshotInterval]);
  /*
function processHistory(history) {

     // if (!history.hasOwnProperty(h)) {
     //   return;
     // }
     // if (!history[h].hasOwnProperty("history")) {
     //   return;
     // }

      const h2 = extractHistory(history);
      console.log("History h2", h2);
      const p = processHistory(h2);
return p
;
}
*/
  //const t= [];
  const [lastHistoryLink, setLastHistoryLink] = useState();
//  setLastHistoryLink(ref);
  function addHistory(history) {
    const t = [];
    //   console.log("extractedHistory history", history);
    //   const hist = Object.keys(history).forEach((h, i) => {
    //if (h== null) {return;}
    //  console.log("History h", h);

    const ref = extractTransducer(history.link);

    console.log("useHistory addHistory ref", ref);
    //setLastHistoryLink(ref);

    console.log("useHistory addHistory history", history.history);
    histories[history.link] = history;
    //    if (!history.hasOwnProperty(h)) {
    //      return;
    //    }
    //    if (!history[h].hasOwnProperty("history")) {
    //      return;
    //    }

    const h2 = extractHistory(history.history);
    console.log("useHistory addHistory h2", h2);
    const p = processHistory(h2);

    if (p == null) {
      return;
    }

//    if (lastHistoryLink !== ref) {
//      console.log("useHistory ref change", ref);
//setLastHistoryLink(ref);
//    }
    /*
if (lastHistoryLink !== ref) {


    setHistoryPoints(current=> {

return sortThingsByAt(deduplicateThingsByHaystack([ ...p]))

});

setLastHistoryLink(ref);
return;




}
*/
    setHistoryPoints((current) => {
      return sortThingsByAt(deduplicateThingsByHaystack([...current, ...p]));
    });
  }

  useHybridEffect(() => {
    console.debug("useSnapshot hybrideffect snapshot");
    if (history == null) {
      return;
    }

    if (snapshotResults == null) {
      console.debug("useSnapshot snapshotResults undefined");
      setSnapshotResults([{ ...history, snapshotAt: zuluTime() }]);

      return;
    }

    console.log("useSnapshot history", history);

    const count = 0;

    for (const snapshotResult of snapshotResults.reverse()) {
      if (snapshotResult.error) {
        count += 1;
      }
      if (!history.error) {
        break;
      }
      console.log(snapshotResult);
    }
    setSequentialErrorCount(count);
  }, [history]);

  useEffect(() => {
    console.log("useSnapshot sequentialErrorCount", sequentialErrorCount);
  }, [sequentialErrorCount]);

function isNullOrEmptyObject(histories) {
    return histories === null || (typeof histories === 'object' && Object.keys(histories).length === 0);
}

  function getHistory(period) {
//if (period == null) {
//return;}
console.log("useHistory getHistory period", period);

if (!isNullOrEmptyObject(histories)) {

//if (histories[period.link]) {
console.log("useHistory getHistory histories", histories);
//console.log("useHistory getHistory histories", histories[period.replace(".json","")]);
}


// Don't call for something if we don't need it. Check here.

//}
    console.debug("useSnapshot getHistory input", input);

    const startTime = new Date();
    if (flag === "red") {
      console.debug("useSnapshot getHistory flag", flag);
      //      return Promise.reject("Red flag");
      return Promise.resolve({ error: { message: "Red flag" } });
    }

    if (input == null) {
      console.debug("useSnapshot getHistory input null", input);
      return Promise.resolve({ error: { message: "Null input" } });

      //      return Promise.reject("Null input");;
    }

    if (period == null) {
      return Promise.resolve({ error: { message: "Null period" } });
    }

    //    const url = input;

    // Test modifying the url.
    // Some stacks may only expect a /
    const url = period.replace("/history-", "/history/");

    var u = url;

    if (hasUUID(url)) {
      const u = url.replace("-snapshot-", "-snapshot-transducers-");
    }
    console.debug("useHistory url u", u);
    return getWebJson(u, "")
      .then((result) => {
        console.debug("useHistory getHistory result", url, result);
        if (!result) {
          return Promise.resolve({ error: { message: "no result" } });
        }

        if (result && result.thingReport === false) {
          // No thing report. Do not update snapshot.
          return Promise.resolve({ error: { message: "no thing report" } });
        }

        if (result && result.thingReport && result.thingReport.snapshot) {
          //         setHistory(result.thingReport.snapshot);
          //return (result.thingReport.snapshot);
        } else {
          // Failback situation where thingReport format not found.
          //         setHistory(result);
          //return (result);
        }
        console.debug("useHistory setSnapshot");
        // dev flag available not available
        setFlag("green");
        const endTime = new Date();
        setSnapshotRunTime(endTime - startTime);
        setSnapshotRunAt(zuluTime(endTime));
        return result;
      })
      .catch((error) => {
        //console.log("History history getSnapshot error", error);
        console.error("useHistory getHistory error", error);
        setFlag("yellow");
        return { ...history, error };
        //return;
      });
  }

  /*
  const saveHistorySnapshot = (userSnapshot) => {
    console.log("useSnapshot saveSnapshot userSnapshot", userSnapshot);
    setHistory(userSnapshot);
  };
*/
  /*
  const deleteSnapshot = (userSnapshot) => {
    // Leave no rubbish behind.
    setSnapshot(false);
  };
*/

  useHybridEffect(() => {
    if (historyPoints == null) {
      return;
    }

    console.log("useHistory historyPoints", historyPoints);
  }, [historyPoints]);

  return {
    histories,
    historyPoints,
    flag,
  };
}
