import React, { useEffect, useState } from "react";

import { devFlag, debugFlag } from "../util/dev.js";

import Thing from "./Thing.js";
//import ThingContainer from "./ThingContainer.js";
import ThingButton from "./Button.js";

import Requests from "./Requests.js";

import Token from "./Token.js";
import Identity from "./Identity.js";
import Button from "./Button.js";
import Response from "./Response.js";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";

import { createThing } from "../util/database.js";

import useToken from "../useToken.js";
import useIdentity from "../useIdentity.js";
import useInput from "../useInput.js";
import useThings from "../useThings.js";
import useThing from "../useThing.js";

import { extractUuid, extractNuuid, getSlug } from "../util/text.js";
import { isValidUUID } from "../util/uuid.js";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import useHybridEffect from "../useHybridEffect.js";

const defaultWebPrefix = process.env.REACT_APP_WEB_PREFIX;

export default function ThingPage(props) {
  const webPrefix = process.env.REACT_APP_WEB_PREFIX;
  const apiPrefix = process.env.REACT_APP_API_PREFIX;

  const [uuid, setUuid] = useState();

  const pathname = window.location.pathname.replace(/\//, "");

  const [status, setStatus] = useState();
  const [response, setResponse] = useState();

  const reg =
    /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/g;

  const uuids = pathname.match(reg);

  const { username, token, isValidToken, getToken, setToken, deleteToken } =
    useToken();

  const { identity, setIdentity, deleteIdentity } = useIdentity();
  const { input, setInput, deleteInput } = useInput();

 // const { thing, setThing } = useThing({ uuid: uuid, subject:pathname, variables:{visible:true} });
 const { thing, setThing } = useThing({ uuid: uuid, variables:{pathname:pathname, visible:true} });
  const { things } = useThings({...thing, variables:{...thing?.variables,visible:true}} );

  const createdAt = Date.now();

  const [devStack, setDevStack] = useState();

  const [play, setPlay] = useState();

useEffect(()=>{

console.log("ThingPage uuid", uuid);

}, [uuid]);

  function handleCollectionChange(things) {
    return;
    //   setThings(things);
    if (things && things[0] && things[0].uuid) {
      console.debug("App setUuid", things[0].uuid);
      setUuid(things[0].uuid);
      return;
    }

    const u = uuidv4();
    setUuid(u);
  }

  useHybridEffect(() => {
    if (uuids == null) {
      return;
    }
    console.debug("ThingPage uuids", uuids);
  }, [uuids]);

  useEffect(() => {
    console.log("ThingPage token isValidToken", token, isValidToken);
  }, [token, isValidToken]);

  useHybridEffect(() => {
    if (things == null) {
      return;
    }

    console.debug("ThingPage pathname", pathname);

    //console.log("ThingPage pathnameEffect things", things);
    const uuidPathname = extractUuid(pathname);
if (uuidPathname == null) {
} else {
setUuid(uuidPathname);
}
    console.debug("ThingPage uuidPathname", uuidPathname);

    console.debug("ThingPage things", things);

    var thingMatch = null;
    things.forEach((t) => {
      if (t.uuid == null) {
        return;
      }
      //console.log("ThingPage xxx t.uuid uuidPathname", t.uuid, uuidPathname);
      if (t.uuid === uuidPathname) {
        thingMatch = t;
      }
    });

    if (thingMatch !== null) {
      console.debug("ThingPage matched uuid", thingMatch);
      setThing(thingMatch);
      setPlay(false);
      return;
    }

    const nuuidPathname = extractNuuid(pathname);

    things.forEach((t) => {
      if (t.uuid == null) {
        return;
      }

      if (t.uuid.slice(0, 4) === nuuidPathname) {
        thingMatch = t;
      }
    });

    if (thingMatch !== null) {
      console.debug("ThingPage matched nuuid", thingMatch);
      setThing(thingMatch);
      setPlay(false);
      return;
    }

    const subjectPathname = pathname;
    console.debug("ThingPage nuuidPathname", nuuidPathname);

    var subjectMatch = null;
    things.forEach((t) => {
      if (t.subject == null) {
        return;
      }
      if (getSlug(t.subject) === getSlug(pathname)) {
        subjectMatch = t;
      }
    });

    if (subjectMatch !== null) {
      console.debug("ThingPage matched subject", subjectMatch);
      setThing(subjectMatch);
      setPlay(false);
      return;
    }

    // Added extracted uuid here 8 March 2024 for behaviour review.

//    setThing({ uuid: uuidPathname, subject: pathname });
//    setThing({ uuid: uuidPathname });



  }, [pathname, things]);

  useHybridEffect(() => {
    console.log("ThingPage thing", thing);
  }, [thing]);

  useHybridEffect(() => {
    const uuidPathname = extractUuid(pathname);

    let d = {
      uuid: uuidPathname,
    };

const redpandaFlag = true;
if (redpandaFlag) {

    d = {...d,
      to: "agent",
            subject: pathname,
      webPrefix: webPrefix,
    };
}

    console.log("ThingPage uuidPathname", uuidPathname);
    if (isValidUUID(uuidPathname)) {
      console.log("ThingPage using provided uuid");
      // HERE
      //      d.uuid = uuidPathname;

      // Not sure about this. But it is needed to pass more complicated strings in.
      // Will have to see that it works in the cases where the thing must pull back a subject
      if (thing.uuid === uuidPathname) {
      }

      if (thing.uuid !== uuidPathname) {
        //d.subject = "Not found";
        //d.uuid = false;
      }


//            d.subject = thing.subject;

      if (thing.uuid !== uuidPathname) {
          setThing(d);
          setPlay(false);
      }


    } else {
      console.log("ThingPage No Uuid uusing provided url");

      const datagram = {
        //         index: 20,
        to: "localhost",
        from: thing && thing.uuid,
        subject: pathname,
        //          priority: "routine",
        //          createdAt: Date.now(),
        //          uuid: uuidv4(),
        //          input: "InputText",
      };

      //            d.uuid = uuidv4();
      d.subject = pathname;
      setThing(d);
      setPlay(false);

      return;

      console.log("ThingPage token isValidToken", token, isValidToken);
      let tokent = null;
      if (isValidToken === true) {
        tokent = token;
      }

      // No UUID in pathname.
      // Either things or pathname has changed.

      // Need to do a get thing by subject here.

      console.log("ThingPage tokent", tokent);
      createThing(defaultWebPrefix, datagram, tokent, "B")
        .then((result) => {
          console.log("ThingPage createThing result", result);

          if (result.hasOwnProperty("error")) {
            setResponse((response) => {
              return response + result.error.message;
            });

            d.subject = pathname;
            setThing(d);
            setPlay(false);

            return;
          }

          setResponse((response) => {
            return response + "Text sent successfully. ";
          });

          // Generate and attempt to setThing.
          // Because there is no valid UUID in the url.

          d.uuid = uuidv4(); // local stack can assign uuids. Other stacks will accept them o>
          d.subject = pathname;

          // Keep track of the provided non-UUID URL for this "session" as thing.

          setThing(d);
          setPlay(false);

          console.log("ThingPage createThing " + d.uuid.slice(0, 4));
        })
        .catch((error) => {
          setStatus("error");
          console.error("ThingPage createThing error", error);
          setResponse((response) => {
            return response + error.message;
          });
        });
    }

    //    setThing(d);
    //    setPlay(false);
  }, [pathname]);

  function handleThingReport(t) {
    console.log("ThingPage handleThingReport", t);
    if (t == null) {
      return;
    }
    if (t.data == null) {
      return;
    }
    setThing(t.data.datagram);
  }

  if (pathname == null) {
    return null;
  }

  return (
    <>
<Requests />
      {debugFlag && <>DEBUG</>} {devFlag && <>DEV</>}{" "}
      {/*JSON.stringify(isValidToken)*/}
      {debugFlag && (
        <>
          JSON.stringify(thing)
          <br />
        </>
      )}
      {debugFlag && status && (
        <>
          {status}
          <br />
        </>
      )}
      {/*<br />
      {debugFlag && response}*/}
      {debugFlag && (
        <Response thing={thing} agentInput={{ response: response }} />
      )}
      {/*{thing && thing.subject*/}

{isValidToken && (
      <ThingButton
        thing={{
          ...thing,
        }}
        agentInput={{ text: "Start New Thing", link: "/thing" }}
        onThingReport={() => {}}
      />)}

      {thing && (
        <Thing
          key={thing.uuid}
          flavour={"item"}
          //            uuid={thing.uuid}
          thing={{...thing, variables:{...thing?.variables,visible:true, 

            agent: "channel-token",
            variable: "hello",
            open: { isOpen: true, canOpen: false },
            fold: { canFold: false },
            play: play,
poll:{interval:490000}}}}
          //        datagram={{...thing, pollInterval:20000}}
          canOpen={false}
          canFold={false}
          open={true}
          play={play}
          deprecatevariables={{
            agent: "channel-token",
            variable: "hello",
            open: { isOpen: true, canOpen: false },
            fold: { canFold: false },
            play: play,
visible:true,
poll:{interval:480000},
          }}
          agentInput={{ collection: true }}
          onThingReport={(t) => handleThingReport(t)}
        />
      )}
      <br />
      {/*
      {isValidToken === false ? "TOKEN NOT VALID" : "VALID TOKEN"}
      <br />
*/}
    </>
  );
}
