import React, {useEffect, useState} from 'react';

const TickMonitor = ({ currentInterval }) => {
const width = 100;
const height = 10;
const buffer = 2
    const [maxInterval, setMaxInterval] = useState(0);
    const [normalizedLength, setNormalizedLength] = useState(0);

    useEffect(() => {
        // Update the maximum interval if the current interval is larger
        if (currentInterval > maxInterval) {
            setMaxInterval(currentInterval);
        }
    }, [currentInterval, maxInterval]);

    useEffect(() => {
        // Normalize the current interval to the maximum interval
        if (maxInterval > 0) {
            setNormalizedLength((currentInterval / maxInterval) * (width - 2*buffer)); // 48 is the length of the SVG
        }
    }, [currentInterval, maxInterval]);

    return (
        <svg width={width} height={height}>
            <line
                x1={buffer}
                y1={height / 2} // Centered vertically
                x2={buffer + normalizedLength}
                y2={height / 2} // Centered vertically
                stroke="red"
                strokeWidth="4"
            />
        </svg>
    );


};

export default TickMonitor;
