import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import TextField from "@mui/material/TextField";

import useHybridEffect from "../useHybridEffect.js";
import { useNavigate } from "react-router-dom";

import useThing from "../useThing.js";

import { hasUUID } from "./../util/uuid.js";

import { devFlag, debugFlag } from "../util/dev.js";
import { getSlug } from "./../util/text.js";

import { compressText } from "stack-js/src/util/text";

const engineState = process.env.REACT_APP_ENGINE_STATE;

export default function Subject({
  thing: inputThing,
  agentInput,
  onThingReport,
}) {
  const { thing, updateThing } = useThing(inputThing);
  const navigate = useNavigate();
  const textInput = React.useRef(null);

  const pathname = window.location.pathname.replace(/\//, "");

  const timeoutPeriod = 500;

  const [s, setS] = useState("null");
  const [defaultSubject, setDefaultSubject] = useState("default");

  const [response, setResponse] = useState("");
  const [status, setStatus] = useState("idle");

  useHybridEffect(() => {
    if (inputThing == null) {
      return;
    }
    console.debug("Subject inputThing", inputThing);

    var tempSubject = inputThing.subject;
    if (inputThing.subject == null) {
      tempSubject = "";
    }
    console.debug("Subject inputThing", inputThing);
    setDefaultSubject(tempSubject);

    setS(tempSubject);
    textInput.current.value = tempSubject;
  }, [inputThing]);

  function handleSubjectSubmit(ev) {
    console.debug(`Subject handleSubjectSubmit onKeyDown ${ev.key}`);
    if (ev.key === "Enter") {
      ev.preventDefault();

      // Do code here
      const s = ev.target.value;

      console.debug("Subject handleSubjectSubmit thing s", thing, s);
      setStatus("saving");
      console.debug("Subject handleSubjectSubmit inputThing", inputThing);

      if (thing && thing.uuid == null) {
        console.debug("Subject handleSubjectSubmit no uuid do navigate");

        document.title = "Stackr - " + s;
        window.history.replaceState(null, "New Page Title", "/" + getSlug(s));
        //          navigate("/" + getSlug(s));
        return;
      }

      // Not ready to test this as uuid: thing.
      // Probably should be.

      updateThing({ uuid: inputThing.uuid, subject: s })
        .then((result) => {
          console.debug("Subject handleSubmit updateThing result", result);
          //addMessage("Item handleToggleItem update thing " + inputThing.subject);
          onThingReport(result);
          if (result && result.error && result.error.message) {
            console.error(
              "Subject handleSubmit result.error.message",
              result.error.message
            );
            setResponse((response) => {
              return response + result.error.message;
            });

            return;
          }
          setStatus("synced");
          setResponse((response) => {
            return response + "Update thing. ";
          });

          console.log("Subject handleSubjectSubmit result data", result.data);
          console.log("Subject handleSubjectSubmit result", result);

          // If the subject update has a uuid in it, then update the url.
          // Because ... it is a different thing.

          //                  navigate("/" + "login");
          if (hasUUID(s)) {
            console.debug("Subject handleSubjectSubmit subject hasUUIDs", s);
            navigate("/" + getSlug(s));
          }

          if (!hasUUID(pathname) && !hasUUID(s)) {
            console.debug(
              "Subject handleSubjectSubmit no UUID on pathname and no UUIDS",
              s
            );
            navigate("/" + getSlug(s));
          }
          document.title = "Stackr - " + s;
        })
        .catch((error) => {
          setResponse((response) => {
            return response + "Error";
          });
          //setError(error.message);
          console.error("Subject handleSubjectSubmit updateThing error", error);
        });
    }
  }

  // Look for a space
  // Then send what you have.
  // This will send token by token. Which is probably okay.
  useEffect(() => {
    if (s == null) {
      setDefaultSubject("");
      return;
    }
    if (s === "") {
      setDefaultSubject("");
      return;
    }
    console.debug("Subject s", s);
    setDefaultSubject(s);
  }, [s]);

  // Apply a 2 second "settle" to the subject line.
  // After this time the request is sent.
  useEffect(() => {
    const timer = setTimeout(() => {
      //      setSubject(s);
      console.log("Subject 2 second settle");
      updateThing({ uuid: inputThing.uuid, subject: s })
        .then((result) => {
          console.log("Subject timer result", result);
          onThingReport(result);
        })
        .catch((error) => {
          console.error("Subject timer error", error);
        });
    }, 2000);
    return () => clearTimeout(timer);
  }, [s]);

  useEffect(() => {
    if (s == null) {
      return;
    }

    if (s.endsWith(" ")) {
      //      setSubject(s);
      console.log("Subject space settle");
      updateThing({ ...thing, subject: s });
    }
  }, [s]);

  function subjectChange(e) {
    var d = e.target.value;
    console.log("Subject subjectChange", d);
    setS(d);

    // Allow two seconds for subject to settle.
    //    setSubject(d);

    //setThing(thing.uuid, {subject:d}, token);
  }

  return (
    <>
      {debugFlag && <>{thing.subject}</>}
      {agentInput && agentInput.edit && (
        <>
          <TextField
            //              error = {validation.validator(variableType,subject)}
            //disabled={agentInput && !agentInput.edit}
            variant="filled"
            margin="normal"
            //         label={thing?.subject?.length > 0 ? "" : "subject"}
            label={"subject"}
            type="text"
            fullWidth
            name="updateSubject"
            defaultValue={defaultSubject}
            //        value={s}
            onChange={subjectChange}
            inputRef={textInput}
            onKeyDown={(ev) => {
              handleSubjectSubmit(ev);
              /*
          console.log(`Subject onKeyDown ${ev.key}`);
          if (ev.key === "Enter") {
            setSubject(ev.target.value);
            // Do code here
            ev.preventDefault();
          }
*/
            }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              style: { fontSize: "1.5rem" }, // Adjust the font size here
            }}
          />
    <div
      style={{
        minHeight: '40px', // Set a minimum height for the block
        maxHeight: '40px', // Optional: Set a maximum height if needed
        overflow: 'hidden', // Prevent overflow if content exceeds the height
  //      border: '1px solid #ccc', // Optional: Add a border for visibility
  //      padding: '10px', // Optional: Add padding for spacing
      }}
    >
          <div>{thing.subject !== defaultSubject && <>Not synced</>}</div>

          {debugFlag && (
            <>
              {" "}
              <div>
                SUBJECT {thing.subject}
                <br />S {s}
                <br />
                DEFAULT SUBJECT {defaultSubject}
                <br />
                {status}
                <br />
              </div>
            </>
          )}
          <div>{response}</div>

          <br />
</div>
        </>
      )}

      {/*TITLE*/}
      {agentInput && !agentInput.edit && (
        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "11rem",
          }}
          ref={textInput}
        >
          {compressText(defaultSubject)}
        </div>
      )}
    </>
  );
}

Subject.propTypes = {
  subject: PropTypes.func.isRequired,
};
