import React, { useState } from 'react';

const ExpanderCollapser = ({ children, initialExpanded = false }) => {
  const [isExpanded, setIsExpanded] = useState(initialExpanded);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // Check if children is empty
  if (!children) {
    return null; // or return <></> for an empty fragment
  }

  return (
    <div>
      <div style={{ display: isExpanded ? 'block' : 'none' }}>
        {children}
      </div>
      <button onClick={toggleExpand} style={{ marginTop: '10px' }}>
        {isExpanded ? 'See less' : 'See more'}
      </button>
    </div>
  );
};

export default ExpanderCollapser;
