import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App.js";
import { ThemeProvider } from "@mui/system";
import theme from "./util/theme.js";
import * as serviceWorker from "./serviceWorker.js";

import { createRoot } from "react-dom/client";

import Thing from "./components/Thing.js";
import Web from "./components/Web.js";
export {Web, Thing}



// replace console.* for disable log on production

// This feels like good practice.
console.debug("Debug logging turned off.");
    console.debug = () => {};

if (process.env.REACT_APP_CONSOLE == 'no') {

if (process.env.REACT_APP_CONSOLE === "dev") {
  // do nothing with the console
} else {
  if (
    process.env.REACT_APP_ENGINE_STATE === "production" ||
    process.env.REACT_APP_ENGINE_STATE === "pre_launch"
  ) {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
    console.info = () => {};
  }

  if (process.env.REACT_APP_CONSOLE === "no_debug") {
    //console.log = () => {};
    //console.error = () => {};
    console.debug = () => {};
    //console.info = () => {};
  }

  if (process.env.REACT_APP_CONSOLE === "only_debug") {
    console.log = () => {};
    console.error = () => {};
    //console.debug = () => {};
    console.info = () => {};
  }

  if (process.env.REACT_APP_CONSOLE === "only_log") {
    //console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
    console.info = () => {};
  }
}

}



const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

//ReactDOM.render(
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
  //  document.getElementById("root")
);

/*
ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
</ThemeProvider>,
  document.getElementById("root")
);
*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
