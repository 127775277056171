import React, { useMemo, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { findUUIDPositions, isValidUUID, hasUUID } from "../util/uuid.js";
import { findTokenPositions } from "../util/token.js";
import { findTextPositions } from "../util/text.js";
import { timeStamp } from "../util/time.js";


import "../index.css";

import useHybridEffect from "../useHybridEffect.js";
import useHistory from "../useHistory.js";

import { makeStyles } from "@mui/styles";

import { minMaxData, minMaxTicks } from "../util/data.js";

import StrategyVsBenchmarkChart from "../components/StrategyVsBenchmarkChart.js";

import {
  Typography,
  //  Avatar,
  //  ListItemAvatar,
  Box,
} from "@mui/material";

import {
  //  Button,
  TextField,
  //  IconButton,
  ListItem,
  ListItemText,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";

import {
  //AddCircleOutlineRounded,
  //DeleteOutlineRounded,
  Edit,
} from "@mui/icons-material";

import Forget from "../components/Forget.js";

import Quantity from "../components/Quantity.js";

import Trace from "../components/Trace.js";
import TraceCircle from "../components/TraceCircle.js";
import Stream from "../components/Stream.js";
import BubbleLevel from "../components/BubbleLevel.js";
import Inclinometer from "../components/Inclinometer.js";

import Magnetometer from "../components/Magnetometer.js";

import MotionReference from "../components/MotionReference.js";

import Ping from "../components/Ping.js";
import Button from "../components/Button.js";

import Transducers from "../components/Transducers.js";

import ExpanderCollapser from "../components/ExpanderCollapser.js";


import {
  zuluTimeDifferenceMilliseconds,
  zuluTime,
  humanRuntime,
  convertFromMilliseconds,
  convertToMilliseconds,
  extractDuration,
  extractDurations,
} from "../util/time.js";

import {
  sortThingsByAt,
  extractUuid,
  parsePing,
  prefixText,
  extractReference,
  //extractDuration,
} from "../util/text.js";

import { atSpread } from "../util/data.js";

import useSnapshot from "../useSnapshot.js";

/*
    deleteSnapshot: deleteSnapshot,
    setSnapshot: saveSnapshot,
    snapshot,
    flag,
    snapshotRunTime,
    snapshotRunAt,
    snapshotInterval,
*/

//import useThingReport from "../useThingReport.js";

import { useSwipeable } from "react-swipeable";

//import { devFlag, debugFlag } from "../util/dev.js";
import { devFlag, debugFlag } from "../util/dev.js";

import { useTheme } from "@mui/material/styles";

import { extractPeriod } from "../util/text.js";

import { useLocation } from "react-router-dom";

//const debugFlag = true;

// Refactor to pass this in thing as variables.
const engineState = process.env.REACT_APP_ENGINE_STATE;
//const snapshotInterval = process.env.REACT_APP_SNAPSHOT_INTERVAL

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}
/*
function extractPeriod(text) {
  console.log("History extractPeriod text", text);
  if (text == null) {
    return null;
  }
  const x = text.split(".json");

  var parts = x[0].split("-");
  var result = parts[parts.length - 1]; // Or parts.pop();

  return result;
}
*/
function deduplicateThingsBySubject(things) {
  //  const subjects = things.map((o) => o.subject);
  const subjects = things.map((o) => {
    return;
    JSON.stringify(o);
  });

  const deduplicatedThings = things.filter(
    (t, index) => !subjects.includes(JSON.stringify(t), index + 1)
  );

  return deduplicatedThings;
}

function deprecate_timeStamp() {
  var date = Date.now();
  return date.toString();
}

function getPollIntervalFromPeriod(periodText) {
  return Math.floor(convertToMilliseconds(extractPeriod(periodText)) / 2);
}

function History({ thing, agentInput }) {
  const navigate = useNavigate();
  const location = useLocation();
//  const histories = {};
const [histories, setHistories] = useState();
  const datagram = thing;
  //  const { showLive } = props;
  const showLive = true;

  const [subject, setSubject] = useState();

  const [windowIndex, setWindowIndex] = useState();
  const [tracePoints, setTracePoints] = useState([]);

  const [ref, setRef] = useState();
  const [historyRef, setHistoryRef] = useState();
  const [text, setText] = useState();
  const [resolution, setResolution] = useState();
  const [amount, setAmount] = useState();
  const [textInterval, setTextInterval] = useState();

  const [domain2, setDomain2] = useState();

  const [flavour, setFlavour] = useState();


// Okay so we see a change in the browser url
// Which is because something in the client has updated it.

// What then?

// THis might need some debouncing, delay afterload.
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    // This effect runs only once when the component mounts
    setIsMounted(true);
  }, []);


  useEffect(() => {

if (!isMounted) {return;}

    //window.alert("hey " + JSON.stringify(location.pathname));
console.log("History location pathname", location.pathname);
    let text;
    if (location.pathname.startsWith("/")) {
      text = location.pathname.substring(1); // Remove the first character (the slash)
    }
    setSubject(text);
    setTracePoints([]);

  }, [location.pathname]);



useHybridEffect(() => {
console.log("History thing", thing);
  if (thing == null) {return;}
if (thing?.subject) {
console.log("History thing subject", thing?.subject);
const link = 'history/' + thing?.subject
console.log("History thing link", link);
setSubject(link)

}

}, [thing]);

  useHybridEffect(() => {
    if (agentInput == null) {
      return;
    }


  }, [agentInput]);


  useHybridEffect(() => {
    if (datagram == null) {
      return;
    }

    if (datagram.subject) {
      setSubject(datagram.subject);
    }

    console.log("History datagram", datagram);
  }, [datagram]);

  useEffect(() => {
    if (subject == null) {
      return;
    }

    console.debug("History subject", subject);

    var tempR = subject;

    if (hasUUID(subject)) {
    } else {
      tempR = tempR.replace("transducers-", "");
    }

    tempR = tempR
      .replace("history/", "")
      .replace("history-", "")
      .replace("history ", "");

    const tempTextInterval = extractDuration(subject);
    setTextInterval(tempTextInterval);

    if (textInterval !== true) {
      tempR = tempR.replace("-" + tempTextInterval, "");
    }

    console.log("History tempR textInterval", tempR, tempTextInterval);

    const maxInterval = 1000;

    try {
      var interval = true;
      // Convert ms s and minutes and hours to milliseconds

      interval = convertToMilliseconds(tempTextInterval);
      setPeriod(interval);
    } catch (error) {
      console.error("History convertToMilliseconds", error);
      interval = 1000;
    }

    setSnapshotInterval(interval);

    console.debug("History interval", tempR, tempTextInterval, interval);

    setRef(tempR);
    /*
    const hr = subject
      .replace("history/", "")
      .replace("history-", "")
      .replace("history ", "");
*/
    const hr = extractReference(subject);

    setHistoryRef(hr);

    if (hr == null) {
      return;
    }

    // Lowest resolution allowed is 10 minutes.

    var hrTemp = hr;
    //if (tempTextInterval === "1d" || tempTextInterval === "20m" || tempTextInterval === "30m") {
    if (tempTextInterval === "1d") {
      hrTemp = hr.replace("-" + tempTextInterval, "-10m");
    }

    setHistoryTo(webPrefix + "history/" + hrTemp + ".json");

    console.debug("History subject", subject);
    const subjectTokens = subject.split("-");
    console.debug("History parts", subjectTokens[subjectTokens.length - 1]);

    setResolution(subjectTokens[subjectTokens.length - 1]);

    const tokenPositions = findTokenPositions(subjectTokens, "snapshot");

    console.log("History tokenPositions", subjectTokens, tokenPositions);

    if (tokenPositions.length === 1) {
      const startIndex = tokenPositions[0];
      const t = subjectTokens.slice(startIndex + 1, subjectTokens.length - 1);
      const f = t.join(" ");
      setText(f);
      // setText(subjectTokens[tokenPositions[0]]]);

      return;
    }

    setText(subjectTokens[subjectTokens.length - 2]);
  }, [subject]);

  const webPrefix = process.env.REACT_APP_WEB_PREFIX;
//  console.log("webPrefix", webPrefix);
  const [snapshotTo, setSnapshotTo] = useState();
  const [historyTo, setHistoryTo] = useState();

  const [snapshotInterval, setSnapshotInterval] = useState();

  const [period, setPeriod] = useState();

const [periodIntervals, setPeriodIntervals] = useState();

useEffect(() =>{
//  const periodIntervals = ["2m", "15m"];
setPeriodIntervals(["2m", "5m","15m"]);
}, []);

useEffect(() => {
if (periodIntervals == null) {return;}
  const newHistories = {};

  periodIntervals.forEach((periodInterval) => {
    newHistories[extractPeriod(periodInterval)] = { period: periodInterval };
  });
  setHistories(newHistories);
}, [periodIntervals]); // Re-run when periodIntervals changes

  // So here should listen to the environments statement
  // on what is an appropriate maximum poll rate.


const t = (snapshotInterval / (24 * 60 * 60)); // Calculate the time in milliseconds
//const timeString = t + "s"; // Convert to string with "s" appended

  const {
    snapshot: data,
    flag: snapshotFlag,
    snapshotRunTime: snapshotRunTime,
    //    snapshotRunAt,
    snapshotRunAt: nowRunAt,
  } = useSnapshot(
    snapshotTo,
//    convertToMilliseconds(extractPeriod("1s")),
//convertToMilliseconds(extractPeriod(t)),
t,
    thing
  ); // Choose lowest of periods in data
  //  } = useSnapshot(snapshotTo, "now");
  //  } = useSnapshot(snapshotTo, 1000);

  /*
  histories["now"] = {
    history: data,
    period: "now",
    flag: snapshotFlag,
    runtime: snapshotRunTime,
    runAt: nowRunAt,
  };
*/
  // Not sure we need thingReport.
  // Check and see where thing and thingReport are being used here.

  // thingReport is a way to pass the processing of this component UPWARD.
  // And DOWNWARD.
/*
  const {
    thingReport,
    flag: thingReportFlag,
    //    thingReportGetTime: thingReportGetTime,
    // } = useThingReport(snapshotTo, 1000);
  } = useThingReport(snapshotTo, null, thing);
*/
  // This next history makes sure we have the 10m history set in historyTo.

  /*
  const {
    snapshot: history,
    flag: historyFlag,
    snapshotRunTime: historyRunTime,
  } = useSnapshot(historyTo, convertToMilliseconds(extractPeriod("10m")));
*/

  // 1000 points in each snapshot
  // So need a 1000 points to fill in each inter

/*
  if (ref && typeof ref !== "undefined") {
    console.log("History ref", ref);

    periods = periodIntervals.map((periodInterval) => {
      return linkHistory("transducers-" + ref + "-" + periodInterval);
    });

    if (hasUUID(ref)) {
      //    period1 = linkHistory(ref + "-" + "1s");
      //    period2 = linkHistory(ref + "-" + "1m");
      //    period3 = linkHistory(ref + "-" + "30m");
      //    period4 = linkHistory(ref + "-" + "1h");

      periods = periodIntervals.map((periodInterval) => {
        return linkHistory(ref + "-" + periodInterval);
      });
    }
  }
*/




const periods = useMemo(() => {
console.log("History periods useMemo periodIntervals", periodIntervals);
      console.log("History periods useMemo ref", ref);

    if (ref && typeof ref !== "undefined") {

//  periods = periodIntervals.map((periodInterval) => {
//    return null;
//  });



      const newPeriods = periodIntervals.map((periodInterval) => {
        return linkHistory("transducers-" + ref + "-" + periodInterval);
      });

      if (hasUUID(ref)) {
        return periodIntervals.map((periodInterval) => {
          return linkHistory(ref + "-" + periodInterval);
        });
      }

      return newPeriods;
    }
    return [];
  }, [ref, periodIntervals]); // Re-compute when ref or periodIntervals change







  //console.log("History periods", periods);
  // Refactor this as useSnapshots or useHistories.
  const { histories: bananaHistories, historyPoints: historyPoints } = useHistory(periods);

  useHybridEffect(() => {
    if (datagram == null) {
      return;
    }
    // Extract uuid from datagram.
    // To provide access to the snapshot.
    // Consider: Can refactor this code into useSnapshot().
    console.debug("History datagram", snapshotTo, datagram);
    console.debug("History datagram", snapshotTo, datagram.subject);
    const uuid = extractUuid(datagram.subject);

    var to = webPrefix + "/snapshot/" + uuid + "/hey-history.json";
    if (uuid === false) {
      to = webPrefix + "/snapshot.json";
    }

    setSnapshotTo(to);
  }, [datagram]);


useEffect(()=>{

console.log("History snapshotTo", snapshotTo);

}, [snapshotTo]);
  const [flag, setFlag] = useState();
  //  const [historyPoints, setHistoryPoints] = useState([]);

  const config = {
    delta: 10, // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: false, // prevents scroll during swipe (*See Details*)
    trackTouch: true, // track touch input
    trackMouse: false, // track mouse input
    rotationAngle: 0, // set a rotation angle
    swipeDuration: Infinity, // allowable duration of a swipe (ms). *See Notes*
    touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
  };

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      console.debug("User Swiped test");
      handleSwipe(eventData);

      console.debug("User Swiped!", eventData);
    },
    ...config,
  });

  const availableWindows = ["", "1m", "2m", "10m", "15m", "30m", "1h"];

  const [reply, setReply] = useState("");

  const [open, setOpen] = useState(false);

  const replyAgentDialog = (thing) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useHybridEffect(() => {
    console.log("History hybrideffect data");
    if (data == null) {
      return;
    }

    if (ref == null) {
      return;
    }

    console.log("History data", snapshotTo, data);
    setAmount(
      data &&
        data.transducers &&
        data.transducers[ref] &&
        data.transducers[ref].amount
    );
  }, [data]);

  function concatenateArrays(arrays) {
    return arrays
      .filter((array) => array !== undefined)
      .reduce((result, array) => result.concat(array), []);
  }

  function deprecate_extractHistory(h) {
    var hist = false;

    if (h.agent_input) {
      if (Array.isArray(h.agent_input)) {
        console.debug("History set history from history.agent_input");
        hist = h.agent_input;
      }
    }

    if (h.thingReport && h.thingReport.history) {
      console.debug("History set history from history.thingReport.history");
      hist = h.thingReport.history;
    }

    return hist;
  }

  function linkHistory(hr) {
    return webPrefix + "history/" + hr + ".json";
  }

  function humanTime(timestamp) {
    const ts = new Date();
    return ts.toISOString();
  }

  function fromName() {
    if (datagram === undefined) {
      return "Agent";
    }

    if (datagram && datagram.from === undefined) {
      return "Agent";
    }

    return datagram.from;
  }

  const startTime = new Date();
  //  const [voltPoints, setVoltPoints] = useState([]);
  const [tracePeriod, setTracePeriod] = useState();

  function callBack() {
    console.debug("Agent callBack called.");
  }

  function handleSwipe(e) {
    console.debug("User Swiped e.dir", e.dir);

    if (e.dir === "Left") {
      const w = windowIndex + 1;
      setWindowIndex(w);

      console.debug("User Swiped Left");

//      if (thingReport) {
//        thingReport(w);
//      }
    }

    if (e.dir === "Right") {
      console.debug("History User Swiped  Right");

      const w = windowIndex - 1;
      if (w >= 0) {
        setWindowIndex(w);

//        if (thingReport) {
//          thingReport(w);
//        }
      }
    }
  }
  const [benchmarkPoints, setBenchmarkPoints] = useState([]);

const [introTrigger, setIntroTrigger] = useState();
const [outroTrigger, setOutroTrigger] = useState();

useEffect(()=>{

if (tracePoints == null) {setIntroTrigger(true); return;}

if (tracePoints && Array.isArray(tracePoints) && tracePoints.length > 0) {
  setOutroTrigger(false);
  setIntroTrigger(false);
}

if (tracePoints && Array.isArray(tracePoints) && tracePoints.length == 0) {setOutroTrigger(true);}

//if (tracePoints && Array.isArray(tracePoints)) {setIntroTrigger(false);}


//setOutroTrigger(false);
//setIntroTrigger(false);



},[tracePoints]);

  function extractIndex(k) {
    const y = Object.entries(k)[0][0].replace("amount", "");
    //console.log("History y", y);

    if (y == null || y === "") {
      return 0;
    }
    return parseInt(y);
  }

  useHybridEffect(() => {
    if (textInterval == null) {
      return;
    }
    console.debug("History historyPoints", historyPoints);
    // Bin history points in to cycle.
    console.debug("History textInterval", textInterval);
    const duration = convertToMilliseconds(textInterval);
    var cycleMilliseconds = duration;
    if (textInterval === "1d") {
      cycleMilliseconds = 1000 * 24 * 60 * 60;
    }

    if (textInterval === "1y") {
      cycleMilliseconds = 1000 * 24 * 365 * 60 * 60;
    }

    var cycleStartDate = new Date();
    cycleStartDate.setHours(24, 0, 0, 0);

    // Set to midnight local time.

    //const s = atSpread(historyPoints);

    const [min, max] = minMaxData(historyPoints);

    console.log("History min max", min, max);
    //setFilteredDataSpread(s);
    const a = minMaxTicks(min, max, 5);
    //console.debug("Trace filteredData a", a);

    setDomain2(a);
    console.log("cycleStartDate", cycleStartDate);
    const cycleRunAt = zuluTime(cycleStartDate);
    console.debug("History cycleRunAt", cycleRunAt);
    const cycleStartMilliseconds = cycleStartDate.getTime();

    let sortedHistoryPoints = sortThingsByAt(historyPoints);

    const bp = sortedHistoryPoints.reverse().map((p) => {
      console.debug("History historyPoints p", p);
      var date = new Date(p.at);
      var seconds = parseInt(date.getTime() / 1000);
      //console.log("History seconds", seconds);
      return {
        date: seconds,
        benchmarkValue: p.amount,
        portfolioValue: p.amount,
      };
    });

    setBenchmarkPoints(bp);

    const cyclePoints = sortedHistoryPoints.map((historyPoint) => {
      var cyclePoint = {};
      const ageMilliseconds = zuluTimeDifferenceMilliseconds(
        historyPoint.at,
        cycleRunAt
      );

      /*
  const data = item.strategyVsBenchmark?.map(entry => ({
    date: new Date(entry.date * 1000).toISOString().split("T")[0], // Convert timestamp to dat>
    Benchmark: entry.benchmarkValue,
    Strategy: entry.portfolioValue,
  }));
*/

      var cycleIndex = Math.floor(ageMilliseconds / cycleMilliseconds);
      console.log("History cycleIndex", cycleIndex);

      const key = cycleIndex === 0 ? "amount" : "amount" + cycleIndex;

      console.log("History key", key);

      cyclePoint[key] = historyPoint.amount;

      const cycleAgeMilliseconds =
        ageMilliseconds - cycleIndex * cycleMilliseconds;

      const x = cycleStartMilliseconds - cycleAgeMilliseconds;

      const at = zuluTime(new Date(x));

      cyclePoint["at"] = at;
      return cyclePoint;
    });

    //console.log("History cyclePoints", cyclePoints);

    // condition cycle points to have an "amount"
    // so check to see what the lowest cycle count is
    // then offest all cycle counts to that

    const x = cyclePoints.map((k) => {
      return extractIndex(k);
      /*
//console.log("History k",Object.entries(k).includes("amount"));
const y = Object.entries(k)[0][0].replace("amount","");
console.log("History y", y);

if (y == null || y === "") {return 0;}
return parseInt(y);
*/
      //console.log("History y", y);
      //return parseInt(k[0].replace("amount",""));

      //return y;
    });
    console.log("History x", Math.min(...x), Math.max(...x));

    const offset = Math.min(...x);

    //var list = {};

    const l = cyclePoints.map((k) => {
      const p = {};
      console.log("History k", k);
      const q = extractIndex(k);
      var i = q - offset;
      if (i == 0) {
        i = "";
      }
      p["amount" + i] = k[Object.entries(k)[0][0]];
      p["at"] = k["at"];
      return p;
    });

    console.log("History l", l);

    //    setTracePoints(cyclePoints);
    setTracePoints(l);
  }, [historyPoints, textInterval]);

  const deleteButton = (
    <Forget uuid={datagram && datagram.uuid} callBack={callBack} />
  );


const [hasRendered, setHasRendered] = useState();
/*
useEffect(()=>{

if (data == null) {return;}
if (Array.isArray(data) && data.length == 0) {return;}

console.log("StrategyBenchmarkChat data", data);
setHasRendered(true);

},[data]);
*/

useEffect(()=>{

if (historyPoints == null) {return;}
if (Array.isArray(historyPoints) && historyPoints.length == 0) {return;}

console.log("History historyPoints", historyPoints);
setHasRendered(true);

},[historyPoints]);






if (hasRendered == null) {return (<>...</>);}



  if (ref == null) {
    return <>NO REF</>;
  }

  if (agentInput?.flavour == "line") {
    //return (<>hey{JSON.stringify(histories['10m'])}</>);

    return (
      <>
        {/*           <div>STREAM</div> */}
        {debugFlag && <>{historyPoints.length}</>}
        <Stream
          thing={thing}
          hide={true}
          period={snapshotInterval}
          data={historyPoints} // Use this as it should be a singular array of t>
          quantity={{
            units: "A",
            amount:
              data &&
              data.transducers &&
              data.transducers[ref] &&
              data.transducers[ref].amount,
          }}
          transducer={data && data.transducers && data.transducers[ref]}
          agentInput={agentInput}
          //              period={100}
          //              domain={[-50, 50]}
        />
      </>
    );
  }

  return (
    <>
{/*<pre>{JSON.stringify(thing, null, 2)}</pre>*/}
      SUBJECT{' '}{subject}<br /> 
<ExpanderCollapser>
SNAPSHOT INTERVAL {humanRuntime(snapshotInterval)}<br/>

      {debugFlag && (
        <>
          DEV{" "}
          {thing &&
            thing.variables &&
            thing.variables.flag &&
            thing.variables.flag.dev}
        </>
      )}
      HISTORY SIZE {JSON.stringify(historyPoints).length}{' bytes'}
      <br />
      HISTORY COUNT {historyPoints.length}
      <br />
      {debugFlag && <> {JSON.stringify(thing)} </>}
      {/* PERIOD {period} */}
      {debugFlag && <div>HISTORY DEBUG</div>}
      {/*snapshotRunAt*/}
      {debugFlag && (
        <>
          {/*        {historyFlag}
          <br />
*/}
          {snapshotFlag}
          <br />
          {/*thingReportFlag*/}
          <br />
        </>
      )}
   {/*   {debugFlag && (
        <>
          TEXT {thingReport && thingReport.text}
          <br />
        </>
      )}
*/}
      {text} {resolution}
      <br />

</ExpanderCollapser>


      {/*
{thing?.subject}
*/}
      {/*THINGREPORT TEXT{" "}
      {data && data.thingReport && data.thingReport.text}
*/}
      {false && <>SUBJECT {subject}</>}
      {thing && thing.subject && !thing.subject.includes("history") && (
        <>
<pre>History subject {subject}</pre>
<pre>History snapshotTo {snapshotTo}</pre>

          <Button
            variant="url"
            thing={{
              subject: prefixText(subject, ""),
              agentInput: "Go To History",
            }}
agentInput={{text:"Go to History",link:"history/"+subject}}
          >
            {subject}
          </Button>

          <br />
        </>
      )}
      {debugFlag && (
        <>
          REF {ref}
          <br />
          HISTORYREF {historyRef}
          <br />
          HISTORYTO {historyTo}
          <br />
          SNAPSHOTTO {snapshotTo}
          <br />
          TEXT INTERVAL {textInterval}
          <br />
        </>
      )}
      {debugFlag && <>SNAPSHOT INTERVAL {snapshotInterval}</>}
      <br />
      {false && textInterval && <>TEXT INTERVAL</>}
      {false && textInterval && typeof textInterval === "string" && (
        <>TEXT INTERVAL IS STRING</>
      )}
      {textInterval &&
        typeof textInterval === "string" &&
        textInterval.includes("1d") && (
          <>
            <div>1 DAY RENDER</div>
            <TraceCircle
              agentInput={{
                outroTrigger:(tracePoints.length == 0),
                data: tracePoints,
                cycle: 1,
                wedges: 24,
                subwedges: 1,
              }}
            />
            <Trace
              thing={thing}
              data={tracePoints}
              cycle={1}
              domain={domain2}
            />
          </>
        )}
      {textInterval && textInterval === true && (
        <>
          <div>TRACE</div>
          <Trace data={historyPoints} cycle={1} />
{/*
          <Transducers
            thing={thing}
            agentInput={{
              ...agentInput,
              flavour: "buttons",
              transducers: data && data.transducers,
            }}
          />
*/}
        </>
      )}
      {textInterval &&
        typeof textInterval === "string" &&
        textInterval.includes("1y") && (
          <>
            <div>1 YEAR RENDER</div>
            <TraceCircle
              agentInput={{
                data: tracePoints,
                cycle: 1,
                wedges: 365,
                subwedges: 24,
              }}
            />
            <Trace data={tracePoints} cycle={1} domain={domain2} />
          </>
        )}
      {textInterval && textInterval === true && (
        <>
          <div>TRACE</div>
          <Trace data={historyPoints} cycle={1} />
        </>
      )}
      {/*  item,
  startDate,
  endDate,
  matchesSM,
  simple = false,
*/}
      <StrategyVsBenchmarkChart
        item={{ strategyVsBenchmark: benchmarkPoints, name: "trace" }}
        simple={true}
matchesSM={"off"}
      />
      {textInterval &&
        typeof textInterval === "string" &&
        !textInterval.includes("1d") && (
          <>
            {/* JSON.stringify(tracePoints) */}
            <div>STREAM</div>
            <Stream
              thing={thing}
              hide={true}
              period={snapshotInterval}
              data={historyPoints} // Use this as it should be a singular array of time points
              tracePoints={tracePoints}
              quantity={{
                units: "A",
                amount:
                  data &&
                  data.transducers &&
                  data.transducers[ref] &&
                  data.transducers[ref].amount,
              }}
              //              period={100}
              //              domain={[-50, 50]}
            />
          </>
        )}
      {/*
      <div>
        DATA TRANSDUCERS AMOUNT {ref}{" "}
        {amount}
      </div>
*/}
{/*
      <Quantity agentInput={{ text: ref + " " + amount }} />
*/}
{/*
      <DeprecateTransducers
        thing={thing}
        agentInput={{
          ...agentInput,
          flavour: "buttons",
          transducers: data && data.transducers,
        }}
      />
*/}
      {debugFlag && (
        <>
          <br />
          <div>SUBJECT {subject}</div>
          <div>
            FLAG {flag} COLOUR
            <br />
          </div>
        </>
      )}

      <div>
{/*
        SNAPSHOT
        <br />
        {snapshotRunTime}ms {"now"} {Math.round(1000 / snapshotRunTime, 1)}Hz
        {" size "}
        {JSON.stringify(data).length}
        {" runAt "}
        {nowRunAt}
        <br />
*/}
        {/*        HISTORY GET TIME {historyRunTime}ms {extractPeriod(periods[0])}{" "}
        {Math.round(1000 / historyRunTime, 1)}Hz
        <br />
*/}
        <>

<ExpanderCollapser>
          <pre>
histories
         {Object.keys(bananaHistories).map((history, index) => {
const o = bananaHistories[history];
return {...o,key:history, subject:o?.subject, t:o?.refreshedAt};

}).map((x)=>{return (<div>{/*{JSON.stringify(x, null, 2)}<br />*/}{ extractPeriod(x.key) + " " + x.t + " " +x?.link  } </div>)  })  }
</pre>
</ExpanderCollapser>

<pre>keys histories {Object.keys(histories).map((h)=>{

return JSON.stringify(histories[h]);
})}</pre>

{/*
<pre>bananaHistories {Object.keys(bananaHistories).map((h)=>{

return JSON.stringify(bananaHistories[h], null, 2);
})}</pre>
*/}

          <br />
          {histories && periods &&
            periods.map((p, i) => {
              return (
                <>
{/*
                  {extractPeriod(periods[i])}{" "}
                  {convertToMilliseconds(extractPeriod(periods[i]))}{" "}
                  {histories[extractPeriod(p)].runtime}ms{" "}
                  {Math.round(1000 / histories[extractPeriod(p)].runtime, 1)}Hz
                  {" size "}
*/}
               {/*   {JSON.stringify(histories[extractPeriod(p)].history).length} */}

{/*                  {" runAt "}
                  {histories[extractPeriod(p)].runAt}
                  <br />
*/}
                </>
              );
            })}
        </>
      </div>
    </>
  );
}

export default History;
/*
    flag: snapshotFlag,
    snapshotRunTime: snapshotRunTime,
//    snapshotRunAt,
    snapshotRunAt: nowRunAt,
*/
