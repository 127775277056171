import React, { useEffect, useState, memo } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Brush,
  Legend,
} from "recharts";
import { useMediaQuery } from "@mui/material";
import { Typography } from "@mui/material";

function CustomTooltip({ active, payload, label }) {
//console.log("label", label);
//if (label == null) {return (<></>)}
//const newLabel = new Date(label).toISOString();

//console.log("active", active);
//console.log("payload", payload);
//console.log("label", label);

if (payload && !Array.isArray(payload)) {return (<></>);}

if (payload.length == 0) {return (<></>);}
console.log("payload[0]",payload[0]);
    const dateEntry = payload[0].payload.isoDate; // Adjust this line based on your actual data structure
    const newLabel = new Date(dateEntry).toISOString();

  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "#fff",
          border: "1px solid #6366f1",
          padding: "10px",
          borderRadius: "15px",
        }}
      >
        <Typography variant="subtitle2" fontWeight="600">{`Date: ${newLabel}`}</Typography>
        {payload.map((entry, index) => (
          <Typography variant="subtitle1" key={index} style={{ color: entry.color }}>{`${
            entry.name
          }: ${Number(entry.value).toLocaleString(undefined, { maximumFractionDigits: 0 })}`}</Typography>
//}: ${new Date(entry.value).toISOString()}`}</Typography>
        ))}
      </div>
    );
  }

  return null;
}

//          }: ${Number(entry.value).toLocaleString(undefined, { maximumFractionDigits: 0 })}`}</Typography>

function makeTickFormat(value) {
let nf = new Intl.NumberFormat('en-US');
return nf.format(value);
//return value.toLocaleString;
//return (`${value}`);

//return (`$${value / 1000}k`);


}

const StrategyVsBenchmarkChart = memo(function StrategyVsBenchmarkChart({
  item,
  startDate,
  endDate,
  matchesSM,
  simple = false,
}) {

//const strategyName = "Benchmark (S&P 500)";
const strategyName = "NOT USED";

  const isMobile = useMediaQuery("(max-width:600px)");

  // Parse the data from Firebase
  const data = item.strategyVsBenchmark?.map(entry => ({
    date: new Date(entry.date * 1000).toISOString().split("T")[0], // Convert timestamp to date string
//    date: new Date(entry.date * 1000).toISOString(), // Convert timestamp to date string
isoDate: new Date(entry.date * 1000).toISOString(),
    Benchmark: entry.benchmarkValue,
    Strategy: entry.portfolioValue,
  }));

//useEffect(()=>{
//console.log("StrategyVsBenchmarkChart data[0]", data[0]);
//}, [data]);

  const chartHeight = isMobile ? 400 : 450; // Responsive chart height

  // Calculate ticks for the X axis to show only 4 values

const calculateTicks = (data, numTicks) => {
  if (!data || data.length === 0) return [];

  const dates = data.map(item => new Date(item.date));
  const minDate = new Date(Math.min(...dates));
  const maxDate = new Date(Math.max(...dates));
  const rangeInMs = maxDate - minDate;

  // Define tick intervals in milliseconds
  const intervals = [
    { label: '1m', value: 1 * 60 * 1000 },
    { label: '2m', value: 2 * 60 * 1000 },
    { label: '5m', value: 5 * 60 * 1000 },
    { label: '10m', value: 10 * 60 * 1000 },
    { label: '15m', value: 15 * 60 * 1000 },
    { label: '30m', value: 30 * 60 * 1000 },
    { label: '60m', value: 60 * 60 * 1000 },
    { label: '1h', value: 1 * 60 * 60 * 1000 },
    { label: '2h', value: 2 * 60 * 60 * 1000 },
    { label: '4h', value: 4 * 60 * 60 * 1000 },
    { label: '8h', value: 8 * 60 * 60 * 1000 },
    { label: '24h', value: 24 * 60 * 60 * 1000 },
    { label: '1d', value: 1 * 24 * 60 * 60 * 1000 },
    { label: '7d', value: 7 * 24 * 60 * 60 * 1000 },
    { label: '10d', value: 10 * 24 * 60 * 60 * 1000 },
    { label: '21d', value: 21 * 24 * 60 * 60 * 1000 },
    { label: '365d', value: 365 * 24 * 60 * 60 * 1000 },
    { label: '1y', value: 1 * 365 * 24 * 60 * 60 * 1000 },
  ];
const nowDate = new Date();

// Define increments
const increments = {
  second: 1000, // 1 second in milliseconds
  millisecond: 1, // 1 millisecond
  microsecond: 0.001 // 1 microsecond in milliseconds
};

// Create future dates
const futureDates = {
  second: new Date(nowDate.getTime() + increments.second),
  millisecond: new Date(nowDate.getTime() + increments.millisecond),
 microsecond: new Date(nowDate.getTime() + increments.microsecond)
};

// Format the output
const formatDate = (date) => {
  return date.toISOString().replace('Z', ''); // Remove the 'Z' at the end
};

  // Determine the appropriate interval based on the range
  let selectedInterval = intervals[0];
let ticks = [];
  for (const interval of intervals) {

    //ticks.push(currentTick.toISOString()); // Store as ISO string or format as needed
    const ageTick =  (new Date(formatDate(futureDates.second))).getTime() - interval.value;
    ticks.push((new Date(ageTick)).toISOString()); // Store as ISO string or format as needed

if (ageTick <= maxDate) {break;}

    if (rangeInMs <= interval.value * numTicks) {
      selectedInterval = interval;
      break;
    }
  }
console.log("ticks", ticks);
  // Generate ticks based on the selected interval
//  const ticks = [];
//  let currentTick = minDate;

//  while (currentTick <= maxDate) {
//    ticks.push(currentTick.toISOString()); // Store as ISO string or format as needed
//    currentTick = new Date(currentTick.getTime() + selectedInterval.value);
//  }

  return ticks;
};

  const workingCalculateTicks = (data, numTicks) => {
    const ticks = [];
    const dataLength = data?.length;
    const interval = Math.floor(dataLength / (numTicks - 1));
    
    for (let i = 0; i < numTicks; i++) {
      const tickIndex = i * interval;
      if (tickIndex < dataLength) {
        ticks.push(data[tickIndex].date);
      }
    }

    return ticks;
  };

  const merpnewCalculateTicks = (data, numTicks) => {
    const ticks = [];
    const dataLength = data?.length;
console.log("s data", data);
    const interval = Math.floor(dataLength / (numTicks - 1));
    
    for (let i = 0; i < numTicks; i++) {
      const tickIndex = i * interval;
      if (tickIndex < dataLength) {
        ticks.push(data[tickIndex].date);
      }
    }

    return ticks;
  };

const yIntervals = [
    1000, // 1 second
    60 * 1000, // 1 minute
    60 * 60 * 1000, // 1 hour
    4 * 60 * 60 * 1000, // 4 hours
    8 * 60 * 60 * 1000, // 8 hours
    24 * 60 * 60 * 1000, // 1 day
    7 * 24 * 60 * 60 * 1000 // 7 days
];

const newCalculateTicks = (data, intervals) => {
    const ticks = [];
    const dataLength = data?.length;

    if (!dataLength) return ticks;

    // Extract the start and end dates from the data
    const startDate = new Date(data[0].date);
    const endDate = new Date(data[dataLength - 1].date);
    const totalDuration = endDate - startDate; // Duration in milliseconds

    // Generate ticks based on the specified intervals
    yIntervals.forEach(interval => {
        let currentTick = new Date(startDate);
        while (currentTick <= endDate) {
            ticks.push(currentTick.toISOString().split('T')[0]); // Format as YYYY-MM-DD
            currentTick = new Date(currentTick.getTime() + interval);
        }
    });

    return [...new Set(ticks)]; // Remove duplicates if any
};


  const xAxisTicks = newCalculateTicks(data, 4);

const [hasRendered, setHasRendered] = useState();


const tickFormatter = (tickItem) => {
if (tickItem == "dataMax" || tickItem == "dataMin") {return;}
  // Create a new Date object from the tickItem
console.log("tickFormatter", tickItem);
  const date = new Date(tickItem);

  // Format the date as YYYY-MM-DD
  const shortISODate = date.toISOString().split('T')[0]; // "2024-08-24"

  // Alternatively, format as YYYY-MM-DDTHH:mm:ss
  // const shortISODateTime = date.toISOString().split('.')[0]; // "2024-08-24T00:00:00"

  return shortISODate; // or return shortISODateTime;
};



useEffect(()=>{

if (data == null) {return;}
if (Array.isArray(data) && data.length == 0) {return;}

console.log("StrategyBenchmarkChat data", data);
setHasRendered(true);

},[data]);

if (hasRendered == null) {return (<></>);}

// We may never have data.
// is data expected? Refactor around that perhaps.
  if (data == null) {

return (<></>);


/*
    return (
      <>
        <br />
        NULL XSERIESDATA {Array.isArray(data) && data.length}
        <br />
      </>
    );
*/
    //    return null;
  }


  return (
    <div style={{ width: "100%", height: `${chartHeight}px` }}>
<br />
{/*hasRendered {JSON.stringify(hasRendered)}*/}
{/*
<br />
<pre>{JSON.stringify(data, null, 2)}</pre>
merp
*/}
      {simple && matchesSM && matchesSM !== "off" && (
        <Typography variant="body2" textAlign="right">Simulated Growth of $10,000*</Typography>
      )}

      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            ticks={xAxisTicks}
// tickFormatter={(tickItem) => new Date(tickItem).getFullYear()}
            tickFormatter={tickFormatter}
            tick={{ fontSize: 12 }}
            domain={['dataMin', 'dataMax']}
          />
          <YAxis
            yAxisId="left"
            orientation="right"
//            tickFormatter={(value) => `$${value / 1000}k`}
            tickFormatter={(value) => makeTickFormat(value)}
            axisLine={false} // Removes the Y axis line
            tick={{ fontSize: 12 }}
            //tickLine={false} // Removes the Y axis ticks
          />

          <Tooltip content={<CustomTooltip />} />
          {!simple && (
            <Legend verticalAlign="top" align="left" height={36} />
          )}

          <Line
            yAxisId="left"
            type="monotone"
            dataKey="Benchmark"
            stroke="#32302f"
            name={strategyName}
            dot={false}
          />
      
          <Line
            yAxisId="left"
            type="monotone"
            dataKey="Strategy"
            stroke="#6366f1"
            strokeWidth="3"
            name={`${item.name} Algorithm`}
            dot={false}
          />

          {!simple && (
            <Brush
              dataKey="date"
              stroke="#8884d8"
              travellerWidth={8}
              startIndex={0}
              endIndex={data.length - 1}
            />
          )}
        </LineChart>
      </ResponsiveContainer>

      {simple && !matchesSM && matchesSM !== "off" && (
        <Typography variant="body2" textAlign="left">Simulated Growth of $10,000*</Typography>
      )}
    </div>
  );
});

export default StrategyVsBenchmarkChart;
