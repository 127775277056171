import React from 'react';

const Invites = ({ thing, agentInput }) => {

const invites = [{slug:"kokopelli-9ba5"},{slug:"events-vancouver-6f31"}];
  const handleButtonClick = (url) => {
    // Open the URL in a new tab
    window.open(url, '_blank');
  };

if (agentInput?.collection == null || agentInput?.collection === false) {return (<></>);}

  return (
    <div>
      <h2>Invites</h2>
      <div>
        {invites.map((invite, index) => (
          <button
            key={index}
            onClick={() => handleButtonClick("https://stackr.ca/subscriptions/"+invite.slug)}
            style={{ margin: '5px', padding: '10px' }}
          >
            {invite.slug}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Invites;
