import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToHash = () => {
  const location = useLocation(); // Get the current location from React Router
  const observerRef = useRef(null);
  const hasRendered = useRef(false); // Track if the component has rendered
  const shouldScroll = useRef(true); // Flag to determine if we should scroll
  const hasScrolled = useRef(false); // Track if scrolling has been completed

  useEffect(() => {
    const scrollToHash = () => {
      if (location.hash && !hasScrolled.current) { // Check if we haven't scrolled yet
        const element = document.getElementById(location.hash.substring(1));
        if (element) {
          // Calculate the position of the element
          const elementPosition = element.getBoundingClientRect().top + window.scrollY;
          const offset = 20; // Adjust this value to prevent overshooting
          window.scrollTo({
            top: elementPosition - offset,
            behavior: "smooth"
          });
          hasScrolled.current = true; // Mark that we have scrolled
          return true; // Successfully scrolled
        }
      }
      return false; // Scrolling failed
    };

    const checkElementAndScroll = (attempts) => {
      const elementFound = scrollToHash();
      if (!elementFound && attempts < 5) { // Default max attempts
        // Retry after a delay if the element is not found
        const timeoutId = setTimeout(() => checkElementAndScroll(attempts + 1), 100); // Default interval delay
        return () => clearTimeout(timeoutId);
      }
    };

    const waitForRenderToSettle = () => {
      // Start checking for the element after a short delay
      setTimeout(() => {
        if (shouldScroll.current && !hasScrolled.current) { // Check if we should scroll and haven't scrolled yet
          checkElementAndScroll(0); // Start checking from attempt 0
        }
      }, 100); // Adjust this delay as needed
    };

    // Initial wait for render to settle
    if (!hasRendered.current) {
      hasRendered.current = true; // Mark as rendered
      waitForRenderToSettle();
    } else {
      waitForRenderToSettle();
    }

    // Set up a MutationObserver to detect changes in the DOM
    observerRef.current = new MutationObserver(() => {
      if (shouldScroll.current && !hasScrolled.current) {
        waitForRenderToSettle();
      }
    });

    // Observe changes in the body or a specific container
    observerRef.current.observe(document.body, { childList: true, subtree: true });

    // Event listener for window focus
    const handleFocus = () => {
      shouldScroll.current = false; // Prevent scrolling on focus
    };

    // Event listener for link clicks
    const handleLinkClick = () => {
      shouldScroll.current = true; // Allow scrolling on link click
      hasScrolled.current = false; // Reset hasScrolled to allow scrolling again
    };

    // Attach focus event listener
    window.addEventListener('focus', handleFocus);
    // Attach click event listener to all links
    document.querySelectorAll('a').forEach(link => {
      link.addEventListener('click', handleLinkClick);
    });

    // Cleanup function
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      window.removeEventListener('focus', handleFocus);
      document.querySelectorAll('a').forEach(link => {
        link.removeEventListener('click', handleLinkClick);
      });
    };
  }, [location]); // Dependency on location to trigger effect on pathname or hash change
};

export default useScrollToHash;
