import React, { useState, useEffect } from "react";

import DynamicComponent from "../components/DynamicComponent.js";
import Snippet from "../components/Snippet.js";

import Block from "../components/Block.js";

import useHybridEffect from "../useHybridEffect.js";

import VisibilitySensor from "react-visibility-sensor";

import { styled } from "@mui/material/styles";

import Collapse from "@mui/material/Collapse";

import { IconButtonProps } from "@mui/material/IconButton";

import { createThing } from "../util/database.js";

import "../index.css";
import {
  Typography,
  //  Avatar,
  //  ListItemAvatar,
  Box,
} from "@mui/material";

import {
  Button,
  TextField,
  IconButton,
  ListItem,
  ListItemText,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";

import {
  Edit,
} from "@mui/icons-material";

import { v4 as uuidv4 } from "uuid";

import Forget from "../components/Forget.js";
import Trace from "../components/Trace.js";

import useSnapshot from "../useSnapshot.js";
import useToken from "../useToken.js";

import {
  isText,
  extractUuid,
  extractNuuid,
  getSlug,
  capitalizeFirstLetter,
} from "../util/text.js";

import { extractionDate } from "../util/time.js";

import { devFlag, debugFlag } from "../util/dev.js";

import { compressText, cleanUrl, extractUrl } from "stack-js/src/util/text";

function extractUrls(text) {

  var re =
    "/\b(https?|ftp|file://)?[-A-Z0-9+&@#/%?=~_|$!:,.;]*[A-Z0-9+&@#/%=~_|$]/i";

  console.log("extractUrls text", text);

  const x = text.match(re);
  //console.log("text x",text, x );
  if (x == null) {
    return false;
  }
  console.log("extractUrls text match", text.match(re));
  const url = text.match(re)[0];

  console.debug("extractUrl matches", url);
  return url;
}

const defaultWebPrefix = process.env.REACT_APP_WEB_PREFIX;

function sizeText(text) {
  return text.length;
}

const { REACT_APP_SNAPSHOT } = process.env;

const engineState = process.env.REACT_APP_ENGINE_STATE;
const defaultSnapshotInterval = process.env.REACT_APP_SNAPSHOT_INTERVAL;

function Web({ thing, agentInput }) {

  const { token } = useToken();

  const datagram = thing;

  const agent_input = agentInput;
  const webPrefix = agentInput;

  const [reply, setReply] = useState("");
  const [snapshotInterval, setSnapshotInterval] = useState(
    defaultSnapshotInterval
  );
  const [blocks, setBlocks] = useState([]);
  const [web, setWeb] = useState();

  const defaultToSnapshot = REACT_APP_SNAPSHOT.replace(".json", ".web-json");

  const [toSnapshot, setToSnapshot] = useState(defaultToSnapshot);

  const { snapshot, flag, snapshotRunTime } = useSnapshot(
    toSnapshot,
    null,
    thing
  );

  const [webExpanded, setWebExpanded] = useState(false);
  const [isWebExpandable, setIsWebExpandable] = useState();

  const handleWebExpandClick = () => {
    console.log("Thing handleWebExpandClick webExpanded", webExpanded);
    setWebExpanded(!webExpanded);
  };

  useEffect(() => {
    // Still working on this.
    // Needs to write state of the thing (expanded or not) to the thing.
    // But first need to tame the PUT requests.

    setIsWebExpandable(false);
  });

  useEffect(() => {
    console.log("Thing webExpanded", webExpanded);
  }, [webExpanded]);

  useEffect(() => {
    if (thing == null) {
      return;
    }
    if (thing.subject == null) {
      return;
    }

    const uuidPathname = extractUuid(thing.subject);

    if (uuidPathname === true) {
      return;
    }
    if (uuidPathname === false) {
      return;
    }

    setToSnapshot("https://stackr.ca/snapshot/" + uuidPathname + "/hey.json");
  }, [thing]);

  const [divList, setDivList] = useState([]);

  useHybridEffect(() => {
    if (agentInput == null) {
      return;
    }
    var blockCount = 0;
    var divBlocks = [];
    if (agentInput?.web) {
      let document = new DOMParser().parseFromString(
        agentInput?.web,
        "text/html"
      );

      var div = document.getElementById("contents");
      var divs = div.getElementsByTagName("p");
      var divArray = [];
      for (var i = 0; i < divs.length; i += 1) {
        var t = divs[i].innerHTML;
        console.log("ttt", t);

        //var url = extractUrl(t);
        //console.log("Web url", url);

        //var urls = extractUrls(t);
        //console.log("Web urls", urls);

        /*
      //  const d2 = extractUrl(d);
*/
        const s = sizeText(t);
        //       const ct = compressText(t);

        var html = t;
        var div = document.createElement("div");
        div.innerHTML = html;

        const cleanText = div.innerText; // Hello, World

        console.log("Web dash", cleanText);

        if (
          cleanText.includes("—-") ||
          cleanText.includes("---") ||
          cleanText === "—"
        ) {
          //          console.log("dashdash");

          //blocks[blockCount] = divArray;
          divBlocks.push(divArray);
          divArray = [];
          blockCount += 1;
          continue;
        }

        var urls = extractUrls(cleanText);
        console.log("Web urls", urls);

        var cleanedUrl = cleanUrl(urls);

        cleanText.replace(urls, cleanedUrl);

        const ct = compressText(cleanText);
        if (cleanText == null || cleanText == "") {
          continue;
        }

        const at = extractionDate(cleanText);
        const uuid = uuidv4();

        divArray.push({
          uuid: uuid,
          at: at,
          text: t,
          compressText: ct,
          cleanText: cleanText,
          size: s,
        });
      }

      divBlocks.push(divArray);

      console.log("Web document", document);
      console.log("Web document divArray", divArray);
      setWeb(agentInput?.web);

      setDivList(divArray);

      setBlocks(divBlocks);
      //setWeb(doc);
    }
  }, [agentInput]);

  useEffect(() => {
    console.log("Snapshot toSnapshot", toSnapshot);
  }, [toSnapshot]);

  //const [data, setData] = useState({
  //  thing: { uuid: "X" },
  //  thingReport: { sms: "No response. Yet." },
  //});
  const [data, setData] = useState();

  const [open, setOpen] = useState(false);

  const replyAgentDialog = (thing) => {
    setOpen(true);
  };
  /*
  const config = {
    delta: 10, // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: false, // prevents scroll during swipe (*See Details*)
    trackTouch: true, // track touch input
    trackMouse: false, // track mouse input
    rotationAngle: 0, // set a rotation angle
    swipeDuration: Infinity, // allowable duration of a swipe (ms). *See Notes*
    touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
  };

  const handlers = useSwipeable({
    onSwiped: (eventData) => console.log("User Swiped!", eventData),
    ...config,
  });
*/
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    console.log("Snapshot thing snapshot", thing, snapshot);

    if (snapshot && snapshot.thingReport) {
      if (snapshot.thingReport.snapshot) {
        console.log(
          "Snapshot setData snapshot.thingReport.snapshot",
          snapshot.thingReport.snapshot
        );
        setData(snapshot.thingReport.snapshot);
        return;
      }
    }

    if (snapshot.thingReport == null) {
      console.log("Snapshot setData snapshot", snapshot);
      setData(snapshot);
      return;
    }
    // Or perhaps don't refresh snapshot.Snapshot thing snapshot
    setData(true);
  }, [snapshot]);

  function humanTime(timestamp) {
    const ts = new Date();
    return ts.toISOString();
  }

  useEffect(() => {
    if (thing == null) {
      return;
    }
    console.log("Snapshot data", toSnapshot, data);
  }, [data]);

  useEffect(() => {
    if (thing == null) {
      return;
    }

    console.log("Snapshot thing", toSnapshot, thing.uuid, thing);
  }, [thing]);

  function handleThingReport(r) {
    r.preventDefault();
  }
  function deprecatehandleSnippetVisible(c, d) {
    console.log("Web handleSnippetVisible c", c);
    console.log("Web handleSnippetVisible d", d);
    /*
    createThing(defaultWebPrefix, { subject: d?.cleanText }, token, "Web")
      .then((r) => {
        console.log("Web createThing r", r);
      })
      .catch((e) => {
        console.error("Web createThing e", e);
      });
*/
  }

  function fromName() {
    if (datagram === undefined) {
      return "Agent";
    }

    if (datagram && datagram.from === undefined) {
      return "Agent";
    }

    return datagram.from;
  }

  function timeStamp() {
    var date = Date.now();
    return date.toString();
  }

  const [ampDataPointer, setAmpDataPointer] = useState(0);
  const [ampPoints, setAmpPoints] = useState([]);
  const startTime = new Date();
  const [voltPoints, setVoltPoints] = useState([]);
  const [tracePeriod, setTracePeriod] = useState();

  function handleChangeStream(c) {
    console.log("Snapshot handleChangeStream c", c);
  }

  useEffect(() => {
    console.log("Snapshot data", data);
  }, [data]);


  return (
    <>
      {blocks.map((b, i) => {

        // Cheap fix?
        // Or the start of inception.

        if (i > 0) {
          return (
            <Block
              thing={thing}
              agentInput={{
                blocks: [],
                block: b,
                hashLink: false,
                containBlock: true,
                compressSnippets: false,
              }}
            />
          );
        }

        return (
          <>
            {/*<pre>candidateDateText {candidateDateText}</pre>*/}
            {/*<pre>{JSON.stringify(candidateDateText, null, 2)}</pre>*/}
            <Block
              thing={thing}
              agentInput={{
                blocks: blocks,
                block: b,
                hashLink: true,
                containBlock: i != 0,
                compressSnippets: false,
              }}
            />

            <hr />
          </>
        );
      })}
<p/>
      {/*
      {divList.map((d) => {
return (<><Snippet thing={thing} agentInput={d} /></>);
      })}
*/}
    </>
  );


}

export default Web;
