import React, { useState, useEffect } from "react";

import useHybridEffect from "../useHybridEffect.js";

import "../index.css";
import {
  Typography,
  //  Avatar,
  //  ListItemAvatar,
  Box,
} from "@mui/material";

import {
  Button,
  TextField,
  IconButton,
  ListItem,
  ListItemText,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";

import {
  //AddCircleOutlineRounded,
  //DeleteOutlineRounded,
  Edit,
} from "@mui/icons-material";

import Forget from "../components/Forget.js";
import Trace from "../components/Trace.js";
import Stream from "../components/Stream.js";
import BubbleLevel from "../components/BubbleLevel.js";

import Barometer from "../components/Barometer.js";

import Inclinometer from "../components/Inclinometer.js";

import Magnetometer from "../components/Magnetometer.js";

import MotionReference from "../components/MotionReference.js";

import Ping from "../components/Ping.js";
import History from "../components/History.js";

import { getSnapshot } from "../util/database.js";

import useSnapshot from "../useSnapshot.js";

import ExpanderCollapser from "../components/ExpanderCollapser.js";

//import { useSwipeable } from "react-swipeable";

const webPrefix = process.env.REACT_APP_WEB_PREFIX;

function Weather(props) {
  const { datagram } = props;
  const { to } = datagram;

//Refactor to this style
const {thing, agentInput} = props;

  const [inertialReference, setInertialReference] = useState();

  const user_name = props.user_name; // TODO
  const agent_input = props.agent_input;
  const webPrefixTemp = agent_input;

  const webPrefix = webPrefixTemp.stack.url;

  const [reply, setReply] = useState("");

  const toSnapshot = webPrefix + thing.subject + ".json";


  const { snapshot, flag, snapshotRunTime, snapshotInterval } = useSnapshot(
    toSnapshot,
//    500
60000,
thing
// {...thing, variables:{...thing?.variables, visible:true}}
  );

  const [data, setData] = useState({
    thing: { uuid: "X" },
    thing_report: { status:"loading", sms: "No response. Yet." },
  });

  const [open, setOpen] = useState(false);

  const replyAgentDialog = (thing) => {
    setOpen(true);
  };

useHybridEffect(()=>{

setData(snapshot);

}, [snapshot]);



  const handleClose = () => {
    setOpen(false);
  };

  useHybridEffect(() => {
 console.log("Weather snapshot", snapshot);
if (snapshot == null) {return;}
    setData(snapshot);
  }, [snapshot]);

  function humanTime(timestamp) {
    const ts = new Date();
    return ts.toISOString();
  }

  function fromName() {
    if (datagram === undefined) {
      return "Agent";
    }

    if (datagram && datagram.from === undefined) {
      return "Agent";
    }

    return datagram.from;
  }

  function timeStamp() {
    var date = Date.now();
    return date.toString();
  }

  const [tracePeriod, setTracePeriod] = useState();

  function callBack() {
    console.log("Agent callBack called.");
  }

  const deleteButton = (
    <Forget uuid={datagram && datagram.uuid} callBack={callBack} />
  );

  /*

{"talker_identifier":"TH","type":"A","amount":"-10.833","units":"X","name":"AMP0","
sensor_id":"thamp0ax0"},"thvlt0ax1":{"talker_identifier":"TH","type":"A","amount":"12.950","units":"X","name":"VLT0","sensor_id":"thvlt0ax1"},"
thclb0ax2":{"talker_identifier":"TH","type":"A","amount":"1142011.6","units":"X","name":"CLB0","sensor_id":"thclb0ax2"},
"thamp1ax0":{"talker_identifier":"TH","type":"A","amount":"0.000","units":"X","name":"AMP1","sensor_id":"thamp1ax0"},
"thvlt1ax1":{"talker_identifier":"TH","type":"A","amount":"12.755","units":"X","name":"VLT1","sensor_id":"thvlt1ax1"},"thclb1ax2":
"talker_identifier":"TH","type":"P","amount":"1020.11","units":"B","name":"PRSA","sensor_id":"thprsapb0"},
"thtmpatc1":{"talker_identifier":"TH","type":"T","amount":"12.95","units":"C","name":"TMPA","sensor_id":"thtmpatc1"},"thhmdahp2":{"talker_identifier":"TH","type":"H","amount":"74.01","units":"P","name":"HMDA","sensor_id":"thhmdahp2"},"thgasaxx3":{"talker_identifier":"TH","type":"X","amount":"128.75","units":"X","name":"GASA","sensor_id":"thgasaxx3"}},"true_heading_in_degrees"

*/

  return (
    <>
      <div>WEATHER</div>
{/*<pre>{JSON.stringify(webPrefix)}</pre>*/}
{/*<pre>{JSON.stringify(thing, null,2)}</pre>*/}
<ExpanderCollapser>
<pre>{JSON.stringify(snapshot, null, 2)}</pre>
</ExpanderCollapser>
{/*<pre>{JSON.stringify(data, null,2)}</pre>*/}
{/* <><pre>
flag {flag} 
snapshotRunTime {snapshotRunTime}  
snapshotInterval {snapshotInterval} 
</pre>
</>*/}
{/*<pre>{JSON.stringify(thing?.variables, null, 2)}</pre>*/}
{/*     <div>toSnapshot{' '}{toSnapshot}</div> */}
      <div>
        {flag ? (<>WEATHER ATTENTION</>) : (<>WEATHER OK</>)}
        <br />
        GET TIME {snapshotRunTime}ms {Math.round(1000 / snapshotRunTime, 1)}Hz
        REQUEST INTERVAL {snapshotInterval}ms{" "}
        {Math.round(1000 / snapshotInterval, 1)}Hz
        <br />

{/* history/e30fe526-fa62-4584-b33b-f5997296fb57-snapshot-temperature-1d */}
HISTORY TEXT
<br />
        <History
          user={null}
          //thing={data.thing}
          thing={{ ...datagram, subject: "e30fe526-fa62-4584-b33b-f5997296fb57-snapshot-temperature-24h" }}
          agentInput={{...agentInput, webPrefix:webPrefix}}
          agent_input={webPrefix}
        />

        <History
          user={null}
          //thing={data.thing}
          thing={{ ...datagram, subject: "e30fe526-fa62-4584-b33b-f5997296fb57-snapshot-windspeed-24h" }}
          agentInput={{...agentInput, webPrefix:webPrefix}}
          agent_input={webPrefix}
        />

        <History
          user={null}
          //thing={data.thing}
          thing={{ ...datagram, subject: "e30fe526-fa62-4584-b33b-f5997296fb57-snapshot-pressure-24h" }}
          agentInput={{...agentInput, webPrefix:webPrefix}}
          agent_input={webPrefix}
        />
{/*

        <History
          user={null}
          //thing={data.thing}
          thing={{ ...datagram, subject: "9ba5e434-4db3-4220-b386-eb88f0ab090c-snapshot-transducers-prse-24h" }}
//          datagram={{ ...datagram, subject: "e30fe526-fa62-4584-b33b-f5997296fb57-snapshot-temperature-1h" }}
          agentInput={{...agentInput, webPrefix:webPrefix}}
          agent_input={webPrefix}
        />




        PRESSURE
        <History
          user={null}
          //thing={data.thing}
          datagram={{ ...datagram, subject: "transducers-thprsapb0-1h" }}
          agent_input={webPrefix}
        />
        <Barometer
          pressure={
            data &&
            data.transducers &&
            data.transducers.thprsapb0 &&
            data.transducers.thprsapb0.amount
          }
        />
*/}
{/*
        {data && data.transducers && (
          <>
            <br />
            BATTERY PRESSURE{" "}
            {data &&
              data.transducers &&
              data.transducers.thprsapb0 &&
              data.transducers.thprsapb0.amount}{" "}
            mBar
            <br />
            BATTERY TEMPERATURE{" "}
            {data &&
              data.transducers &&
              data.transducers.thtmpatc1 &&
              data.transducers.thtmpatc1.amount}
            {"°C "}
            <br />
            BATTERY HUMIDITY{" "}
            {data &&
              data.transducers &&
              data.transducers.thhmdahp2 &&
              data.transducers.thhmdahp2.amount}
            {"% "}
            <br />
          </>
        )}
*/}
        <br />
      </div>
    </>
  );
}

export default Weather;
