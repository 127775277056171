import React, { memo, useState, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "./ItemTypes.js";
//import { Thing } from "./../components/Thing.js";
import Thing from "./../../src/components/Thing.js";

//import FirebaseStorageImage from "./../components/FirebaseStorageImage";
import { Grid, Box } from "@mui/material/";
import VisibilitySensor from "react-visibility-sensor";
//import arrow from "./../images/arrow.svg";

import LazyLoad from "react-lazyload";

import { devFlag, debugFlag } from "../util/dev.js";

const style = {
  // boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  // marginRight: '1rem',
  // backgroundColor: 'white',
  //  cursor: 'move',
  // display: 'flex',
  // alignSelf: 'flex-end',
  // textAlign: 'right',
  // height:'100%'
};

export const Card = memo(function Card({
  //export const Card = (function Card({
  id,
  card,
  token,
  text,
  flipCard,
  spawnCard,
  openCard,
  foldCard,
  moveCard,
  deleteCard,
  findCard,
  updateCard,
}) {
  const [cardVisible, setCardVisible] = useState();

  const originalIndex = findCard(id).index; //index
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.CARD,
      item: { id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveCard(droppedId, originalIndex);
        }
      },
    }),
    [id, originalIndex, moveCard]
  );
  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.CARD,
      canDrop: () => false,
      hover({ id: draggedId }) {
        if (draggedId !== id) {
          const { index: overIndex } = findCard(id);
          moveCard(draggedId, overIndex);
        }
      },
    }),
    [findCard, moveCard]
  );
  const opacity = isDragging ? 0 : 1;

  function handleFlipCard(e) {
    //    props.onCardChange(e);
    flipCard(id);
  }
  function handleChange(e) {
    console.log("handleChange", e);
    if (e === "spawn") {
      spawnCard(id, originalIndex);
    }

    if (e === "forget") {
      deleteCard(id);
    }

    if (e === "open") {
      openCard(id);
    }

    if (e === "flip") {
      flipCard(id);
    }

    if (e === "fold") {
      foldCard(id);
    }
  }
  function handleOpenCard(e) {
    //    props.onCardChange(e);
    openCard(id);
  }

  function handleFlipCard(e) {
    //    props.onCardChange(e);
    flipCard(id);
  }

  function handleFoldCard(e) {
    foldCard(id);
  }

  function handleThingReport(r) {
    //r.preventDefault();

    updateCard(r);
  }

  function handleDelete() {
    //ole.log("Saw delete index", id)
    deleteCard(id);
  }

  useEffect(() => {
    console.log("Card cardVisible", card.uuid, cardVisible);
  }, [cardVisible]);

  if (card && card.open && card.open === "open") {
    return (
      <Grid
        item
        xs={12}
        sm={12}
        ref={(node) => drag(drop(node))}
        style={{ ...style, opacity }}
      >
        <Box
          style={{
            border: "1px solid #1d7d1d",
            borderRadius: "8px",
            padding: "4px",
            height: "100%",
            display: "flex",
            cursor: "move",
          }}
        >
          {card && card.open}

          <Thing
            //uuid={card.uuid}
            token={token}
            //            thing={card}
            webPrefix={card.webPrefix}
            onChange={(e) => handleChange(e)}
            onThingReport={(r) => handleThingReport(r)}
            thing={{
              ...card,
              variables: {
                ...card.variables,
                card: { visible: cardVisible },
                visible: cardVisible,
              },
              pollInterval:
                card && card.pollInterval && cardVisible
                  ? 5 * 60000
                  : //                  ? card.pollInterval
                    false,
            }}
            cardVisible={cardVisible} // testing
          />
        </Box>
      </Grid>
    );
  }

  return (
    <VisibilitySensor
      partialVisibility={true}
      onChange={(isVisible) => {
        console.log("Visibility change", isVisible);
        setCardVisible(isVisible);
      }}
    >
      <Grid
        item
        xs={12}
        sm={3}
        ref={(node) => drag(drop(node))}
        //        style={{ ...style, opacity }}
      >
        {debugFlag && <div>I am {cardVisible ? "visible" : "invisible"}</div>}
        <Box
          style={{
            border: "1px solid #d3d3d3",
            borderRadius: "8px",
            padding: "4px",
            height: "100%",
            display: "flex",
            cursor: "move",
            position: "relative", // Added positioning for the score
            //width:"100%"
            width: "calc(100% - 2px)", // Adjusted width to accommodate the border and padding
            boxSizing: "border-box", // Added box-sizing property
            marginLeft: "1px", // Added margin to create space between adjacent components
            marginRight: "1px", // Added margin to create space between adjacent components
          }}
        >
          {/*<LazyLoad style={{display:"flex"}} >*/}
          <Thing
            key={card.uuid}
            //          to={card.to}
            //          subject={card.subject}
            //          createdAt={card.createdAt}
            //uuid={card.uuid}
            //          input={card.input}
            token={token}
            thing={{
              ...card,
              variables: {
                ...card.variables,
                card: { visible: cardVisible },
                visible: true,
                //cardVisible
              },
              pollInterval:
                card && card.pollInterval && cardVisible
                  ? 5 * 60000
                  : //                  ? card.pollInterval
                    false,
            }}
            webPrefix={card.webPrefix}
            onChange={(e) => handleChange(e)}
            onThingReport={(r) => handleThingReport(r)}
            cardVisible={cardVisible} // testing
          />

          {card &&
            "score" in card && ( // Render the score if it exists
              <div
                style={{
                  position: "absolute",
                  bottom: "8px",
                  right: "8px",
                  fontWeight: "bold",
                  fontSize: "32px",
                  color: "lightgray",
                  lineHeight: 1,
                  zIndex: 1, // Ensure the score is displayed above the Thing component
                }}
              >
                {card.score}
              </div>
            )}

          {card &&
            "duplicates" in card &&
            card.duplicates.length > 1 && ( // Render the score if it exists
              <div
                style={{
                  position: "absolute",
                  bottom: "8px",
                  left: "8px",
                  fontWeight: "bold",
                  fontSize: "32px",
                  color: "lightgray",
                  lineHeight: 1,
                  zIndex: 1, // Ensure the score is displayed above the Thing component
                }}
              >
                {card.duplicates.length > 1 ? card.duplicates.length - 1 : ""}
              </div>
            )}

          {/*</LazyLoad>*/}
        </Box>
      </Grid>
    </VisibilitySensor>
  );
});
