  const unitNames = ['d', 'h', 'm', 's', 'ms', 'µs', 'ps'];

  const timeUnitSuffixes = ['y', 'd', 'h', 'm', 's', 'ms', 'µs', 'ps'];

/*
  const unitsInMilliseconds = {
    'd': 24 * 60 * 60 * 1000,
    'hr': 60 * 60 * 1000,
    'min': 60 * 1000,
    's': 1000,
    'ms': 1,
    'µs': 0.001,
    'ps': 0.000001,
    // Add more units as needed
  };
*/
  const unitsInMilliseconds = {
    'ms': 1,
    's': 1000,
    'm': 60 * 1000,
    'h': 60 * 60 * 1000,
    'd': 24 * 60 * 60 * 1000,
    'y': true,
    'µs': 0.001,
    'ps': 0.000001,
    // Add more units as needed
  };

const zulutimeRegex = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/g;

// Think about this.
// Changine 11 digit numbers in text likely to replace time. Can validate that if spotted.
// At least if their clock is running close to the same rate.[0-9]{1,4}/[0-9]{1,2}/[0-9]{1,2} [0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2} regex
const posixtimeRegex = /\d{10}/g;


export function replacePosixtimes(text, replacementText) {

return text.replace(posixtimeRegex, replacementText);

}

export function replaceZulutimes(text, replacementText) {

return text.replace(zulutimeRegex, replacementText);
}

export function humanTime(at) {
  var d = new Date();

  if (at !== undefined) {
    var epochtime = Date.parse(at);
    d = new Date(epochtime);
  }

  //if (!timestamp) {return true;}
  //  const ts = new Date(timestamp * 1000);
  return d.toLocaleString();
  //  return ts.toISOString();
}

export function spliceArrayByDate(dataArray, splittingDate) {
  const beforeSplitting = [];
  const afterSplitting = [];

  for (const item of dataArray) {
    const itemDate = new Date(item.at);
    const splittingDateObj = new Date(splittingDate);

    if (itemDate <= splittingDateObj) {
      beforeSplitting.push(item);
    } else {
      afterSplitting.push(item);
    }
  }

  return [beforeSplitting, afterSplitting];
}

export function convertFromMilliseconds(durationInMilliseconds, minimumDurationInMilliseconds = 500) {
//export function convertFromMilliseconds(durationInMilliseconds) {

  if (minimumDurationInMilliseconds && durationInMilliseconds < minimumDurationInMilliseconds) {
    durationInMilliseconds = minimumDurationInMilliseconds;
  }
/*
  const unitsInMilliseconds = {
    'd': 24 * 60 * 60 * 1000,
    'hr': 60 * 60 * 1000,
    'min': 60 * 1000,
    's': 1000,
    'ms': 1,
    'µs': 0.001,
    'ps': 0.000001,
    // Add more units as needed
  };
*/

//  const unitNames = ['d', 'h', 'm', 's', 'ms', 'µs', 'ps'];
  for (const unit of unitNames) {
    if (durationInMilliseconds >= unitsInMilliseconds[unit]) {
      const value = durationInMilliseconds / unitsInMilliseconds[unit];
      const roundedValue = roundToClosest(value, [1, 2, 5]);
      return `${roundedValue.toFixed(0)}${unit}`;
    } 
  }

  return '0ms';
}

function roundToClosest(value, allowedRounds) {
  const closest = allowedRounds.reduce((prev, curr) =>
    (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev)
  );
  return closest;
}

export function convertToMilliseconds(timeString) {

if (timeString == null) {return true;}

  const [value, unit] = parseTimeString(timeString);

/*
  const unitsInMilliseconds = {
    'ms': 1,
    's': 1000,
    'm': 60 * 1000,
    'h': 60 * 60 * 1000,
    'd': 24 * 60 * 60 * 1000,
    'y': true,
    'µs': 0.001,
    'ps': 0.000001,
    // Add more units as needed
  };
*/

  const lowercaseUnit = unit.toLowerCase();
  if (unitsInMilliseconds.hasOwnProperty(lowercaseUnit)) {
    return value * unitsInMilliseconds[lowercaseUnit];
  } else {
    throw new Error('Invalid unit');
  }
}

export function parseTimeString(timeString) {

if (timeString == null) {
    throw new Error('Empty time string');

}

//const parts = timeString.split(" ");
//if (parts.count == 2) {
  var unitString = "x";
  var numericValue = parseFloat(timeString);
  if (isNaN(numericValue)) {

const parts = timeString.split(" ");
if (parts.count == 2) {

if (isNaN(parts[0])) {
    throw new Error('Invalid time string');

}
}
numericValue =  parts[0];
unitString = parts[1];
return [numericValue, unitString];
//    throw new Error('Invalid time string');
  }




  unitString = timeString.replace(/[0-9.]/g, '');
  return [numericValue, unitString];
}

export function humanPosixTime(posixSecondsAt) {
  //var d = new Date();
  var d = new Date(posixSecondsAt * 1000);

  if (posixSecondsAt == null) {
    d = new Date();
  }
  return d.toLocaleString();
}

export function timeStamp() {
  var date = Date.now();
  return date.toString();
}

// at firebase object
export function age(at) {
  if (at === null) {
    return null;
  }

  return Date.now() - at;
}

export function zuluTextSpread(zuluA, zuluB) {
  if (zuluA === undefined) {
    return;
  }
  if (zuluB === undefined) {
    return;
  }

  // Remove .000 milliseconds

  const conditionedZuluA = zuluA.replace(".000", "");
  const conditionedZuluB = zuluB.replace(".000", "");

  const partsA = conditionedZuluA.split("T");
  const partsB = conditionedZuluB.split("T");

  if (partsA[0] === partsB[0]) {
    return conditionedZuluA + " to " + partsB[1];
  }

  return conditionedZuluA + " to " + conditionedZuluB;
  //      {lastAt}
  //      {" to "}
  //      {firstAt}
}

export function ageMinutes(at) {
  const milliseconds = age(at);
  const minutes = Math.floor(milliseconds / (60 * 1000));
  return minutes;
}

export function humanAge(at) {
  const a = new Date(at);
  var milliseconds = age(a);
  var seconds = Math.floor(milliseconds / 1000);

  if (seconds > 60 * 60) {
    return humanTime(at);
  }

  if (seconds > 60) {
    return Math.floor(seconds / 60) + " minutes ago.";
  }

  if (seconds > 60) {
    return Math.floor(seconds / 60) + " minute ago.";
  }

  if (seconds > 1) {
    return Math.floor(seconds) + " seconds ago.";
  }

  return Math.floor(seconds) + " second ago.";
}

export function humanRuntime(runTimeMilliseconds, lens, inputPostFix) {
  var sign = Math.sign(runTimeMilliseconds);

  //const a = new Date(at);
  var milliseconds = Math.abs(runTimeMilliseconds);
  //var seconds = Math.floor(milliseconds/1000);
  var seconds = Math.abs(milliseconds / 1000);
  var postFix = "";
  if (lens === "text") {
    sign = 1;

    postFix = " ago";
    if (inputPostFix) {
      postFix = " " + inputPostFix;
    }
  }

  //return seconds;

  if (seconds > 24 * 60 * 60 * 7 * 2) {
    return sign * Math.floor(seconds / (24 * 60 * 60 * 7)) + " weeks" + postFix;
  }

  if (seconds > 24 * 60 * 60 * 7) {
    return sign * Math.floor(seconds / (24 * 60 * 60 * 7)) + " week" + postFix;
  }

  if (seconds > 2 * 24 * 60 * 60) {
    return sign * Math.floor(seconds / (24 * 60 * 60)) + " days" + postFix;
  }

  if (seconds > 24 * 60 * 60) {
    return sign * Math.floor(seconds / (24 * 60 * 60)) + " day" + postFix;
  }

  if (seconds > 2 * 60 * 60) {
    return sign * Math.floor(seconds / (60 * 60)) + " hours" + postFix;
  }

  if (seconds > 60 * 60) {
    return sign * Math.floor(seconds / (60 * 60)) + " hour" + postFix;
  }

  if (seconds > 2 * 60) {
    return sign * Math.floor(seconds / 60) + " minutes." + postFix;
  }

  if (seconds > 60) {
    return sign * Math.floor(seconds / 60) + " minute." + postFix;
  }

  if (milliseconds > 2 * 1000) {
    return sign * Math.floor(seconds) + " seconds" + postFix;
  }

  if (milliseconds > 999) {
    return sign * Math.floor(seconds) + " second" + postFix;
  }

  if (milliseconds > 1) {
    return sign * Math.floor(milliseconds) + " milliseconds" + postFix;
  }

  return sign * Math.floor(milliseconds) + " ms" + postFix;
}

export function zuluTime(at) {
  var d = new Date();

  if (at !== undefined) {
    var epochtime = Date.parse(at);
    d = new Date(epochtime);
  }

  return d.toISOString();
}

export function zuluTimeDifferenceMilliseconds(atA, atB) {
  //var d = new Date();

  //if (at !== undefined) {
  const epochtimeA = Date.parse(atA);
  const a = new Date(epochtimeA);
  //}

  const epochtimeB = Date.parse(atB);
  const b = new Date(epochtimeB);

  const differenceMilliseconds = b.getTime() - a.getTime();

  return differenceMilliseconds;
}

/*
export function extractDurations(tokens) {
  const timeUnitSuffixes = ['y', 'd', 'h', 'm', 's', 'ms', 'µs', 'ps'];
  
  return tokens.filter(token => {
    const lastChar = token[token.length - 1];
    return timeUnitSuffixes.includes(lastChar);
  });
}
*/


export function extractDurations(tokens) {
//  const timeUnitSuffixes = ['y', 'd', 'h', 'm', 's', 'ms', 'µs', 'ps'];

  return tokens.filter((token) => {
    const matches = token.match(/(\d+)([a-zA-Z]+)/);
    var numberComponent = null;
    var characterComponent = "";
    if (matches) {
      numberComponent = matches[1];
      characterComponent = matches[2];
    }

    // Check if the token ends only with one of the time unit suffixes
    let endsOnlyWithSuffix = false;
    for (const suffix of timeUnitSuffixes) {
      if (token.toLowerCase().endsWith(suffix)) {
        // Check if the token ends *only* with this suffix
        const trimmedToken = token.slice(0, -suffix.length);
        if (trimmedToken.match(/\D/)) {
          // If there are non-digit characters in the trimmed token, it's not a>
          endsOnlyWithSuffix = false;
        } else {
          endsOnlyWithSuffix = true;
        }
      }
    }
    return endsOnlyWithSuffix;
  });
}



export function extractDuration(text) {

    var tempR = text
      .replace("transducers-", "")
      .replace("history/", "")
      .replace("history-", "")
      .replace("history ", "");

    //const r = tempR;
    //console.log("History tempR", tempR);
    const i = tempR.split("-");
    console.log("History tempR i", tempR, i);

    var tempTextIntervalArray = extractDurations(i);
    if (
      Array.isArray(tempTextIntervalArray) &&
      tempTextIntervalArray.length > 0
    ) {
      var tempTextInterval = tempTextIntervalArray.slice(-1)[0];
    }
return tempTextInterval;

}



export function extractionDate(text) {
//console.log("extractionDate text", text);
    var candidateDateText = null;
    var candidateDateTexts = [];

    // Check if the input text is provided
    if (text) {
        // Split the text by periods to get candidate date texts
        candidateDateTexts = text.split(".");
    }

    // Step through candidateDateTexts until extractDate(candidateDateText[i]) is not null
    for (var i = 0; i < candidateDateTexts.length; i++) {
        candidateDateText = candidateDateTexts[i].trim(); // Trim whitespace
const strippedText = candidateDateText.replace(/<[^>]*>/g, '');
        var extractedDate = extractDate(strippedText);

        // If a valid date is found, return it
        if (extractedDate) {
//console.log("extractionDate extractedDate", extractedDate);
            return extractedDate;
        }
    }

//return candidateDateTexts;

    // If no valid date is found, return null
    return null;
}

function parseDateString(dateString) {
    const tokens = dateString.trim().split(/\s+/);
    const monthNames = {
        january: 0, february: 1, march: 2, april: 3,
        may: 4, june: 5, july: 6, august: 7,
        september: 8, october: 9, november: 10, december: 11
    };

    let time, day, month, year;

    // Assign values based on the number of tokens
    if (tokens.length === 4) {
        [time, day, month, year] = tokens;
    } else if (tokens.length === 3) {
        [day, month, year] = tokens;
    } else if (tokens.length === 2) {
        [month, year] = tokens;
    } else if (tokens.length === 1) {
        [year] = tokens;
    } else {
        console.log("Invalid date format");
        return null;
    }

    // Convert month name to number
    month = month ? monthNames[month.toLowerCase()] : null;
    year = year ? parseInt(year, 10) : null;
    day = day ? parseInt(day, 10) : null;

    // Create a date object
    const date = new Date(year, month !== undefined ? month : 0, day || 1);
return date;
 //   return { time, day, month, year, timestamp: date.getTime() };
}

function deprecateparseFlexibleDate(dateString) {
    // Normalize the input by trimming whitespace and converting to lowercase
    const normalizedDateString = dateString.trim().toLowerCase();

    // Regular expression to match various month formats (full and abbreviated)
    const regex = /^(jan(?:uary)?|feb(?:ruary)?|mar(?:ch)?|apr(?:il)?|may|jun(?:e)?|jul(?:y)?|aug(?:ust)?|sep(?:tember)?|oct(?:ober)?|nov(?:ember)?|dec(?:ember)?)\s+(\d{4})$/;

    const match = normalizedDateString.match(regex);

    if (match) {
        const month = match[1]; // Extract the month
        const year = match[2];   // Extract the year

        // Create a mapping of month names to their respective indices
        const monthMap = {
            jan: 0, feb: 1, mar: 2, apr: 3, may: 4, jun: 5,
            jul: 6, aug: 7, sep: 8, oct: 9, nov: 10, dec: 11
        };

        // Get the month index from the mapping
        const monthIndex = monthMap[month.slice(0, 3)];

        // Return a new Date object for the first day of the month
        return new Date(year, monthIndex);
    } else {
return true;
//        throw new Error("Invalid date format. Please use a valid 'Month Year' format.");
    }
}

export function extractDate(candidateDateText) {

//var candidateDateText = striptags(candidateDateText);




if (candidateDateText == null) {return null;}
if (candidateDateText === "") {return null;}
//var candidateDateText = "July 1944";
console.log("extractDate candidateDateText", candidateDateText);
var dateText = null;



try {
/*
const t = parseDateString(candidateDateText);
console.log("extractDate t", t);
if (t !== true && t !== null) {
const dt = t.toISOString();

console.log("extractDate dt", dt);


return dt;}
*/
const timestamp = Date.parse(candidateDateText);

//return "foo"+timestamp+"bar";
if (isNaN(timestamp)) {
//return "merp";
const t = parseDateString(candidateDateText);
console.log("extractDate t", t);
if (t !== true && t !== null) {
const dt = t.toISOString();

console.log("extractDate dt", dt);


return dt;}



}


if (!isNaN(timestamp)) {
//return "bar";
  const date = new Date(timestamp);

// Parse the date string into a Date object
  dateText = date.toISOString();
console.log("extractDate dateText", dateText);
return dateText;
}




} catch(error) {
console.log("extractDate error", error);
return null;
}




}

/*
export function extractionDate(text) {
    var candidateDateText = null;
    var candidateDateTexts = [];

    // Check if the input text is provided
    if (text) {
        // Split the text by periods to get candidate date texts
        candidateDateTexts = text.split(".");
    }

    // Step through candidateDateTexts until extractDate(candidateDateText[i]) >
    for (var i = 0; i < candidateDateTexts.length; i++) {
        candidateDateText = candidateDateTexts[i].trim(); // Trim whitespace
        var extractedDate = extractDate(candidateDateText);

        // If a valid date is found, return it
        if (extractedDate) {
            return extractedDate;
        }
    }

    // If no valid date is found, return null
    return null;
}

export function extractDate(candidateDateText) {
    var dateText = null;

    // Regular expression to match common date formats
    const dateRegex = /(\d{1,2}[\/-]\d{1,2}[\/-]\d{2,4}|\d{4}[\/-]\d{1,2}[\/-]\d{1,2}|\d{1,2} \w+ \d{2,4})/;
    const match = candidateDateText.match(dateRegex);

    if (match) {
        const dateString = match[0];
        const timestamp = Date.parse(dateString);

        if (!isNaN(timestamp)) {
            const date = new Date(timestamp);
            dateText = date.toISOString();
            return dateText;
        }
    }

    return null;
}
*/
