import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import TextField from "@mui/material/TextField";

import useHybridEffect from "../useHybridEffect.js";
import { useNavigate } from "react-router-dom";

import useThing from "../useThing.js";

import { hasUUID } from "./../util/uuid.js";

import { devFlag, debugFlag } from "../util/dev.js";
import { getSlug } from "./../util/text.js";

const engineState = process.env.REACT_APP_ENGINE_STATE;

export default function Response({
  thing: inputThing,
  agentInput,
  onThingReport,
}) {
  const { thing, updateThing } = useThing(inputThing);
  const navigate = useNavigate();
  const textInput = React.useRef(null);

  const pathname = window.location.pathname.replace(/\//, "");

  const timeoutPeriod = 500;

  const [s, setS] = useState("null");
  const [defaultSubject, setDefaultSubject] = useState("default");

  const [response, setResponse] = useState("");

  const [defaultResponse, setDefaultResponse] = useState();
  const [r, setR] = useState("");

  const [status, setStatus] = useState("idle");

  useEffect(() => {
    console.log("Response response", response);
    const intervalId = setInterval(() => {
      console.log("Response response interval called");
      const sentences = response.split(".");
      console.log("Response response sentences", sentences);

      if (sentences.length > 1) {
        sentences.shift(); // Remove the first sentence
        const newText = sentences.join(". ");
        setResponse(newText);
      }
    }, 5000);

    // Cleanup the interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, [response]);

  useHybridEffect(() => {
    if (agentInput == null) {
      return;
    }
    console.log("Response agentInput", agentInput);
    //var tempResponse = "";
    var tempResponse = agentInput.response;
    if (agentInput.response == null) {
      tempResponse = "";
    }
    //console.log("Subject inputThing", inputThing);
    setDefaultResponse(tempResponse);

    setR(tempResponse);

    setResponse(tempResponse);
    //    textInput.current.value = tempResponse;
  }, [agentInput]);

  /*
  useHybridEffect(() => {
    if (inputThing == null) {
      return;
    }
    console.log("Subject inputThing", inputThing);

    var tempSubject = inputThing.subject;
    if (inputThing.subject == null) {
      tempSubject = "";
    }
console.log("Subject inputThing", inputThing);
    setDefaultSubject(tempSubject);

    setS(tempSubject);
    textInput.current.value = tempSubject;
  }, [inputThing]);
*/

  // Look for a space
  // Then send what you have.
  // This will send token by token. Which is probably okay.
  useEffect(() => {
    if (s == null) {
      setDefaultSubject("");
      return;
    }
    if (s === "") {
      setDefaultSubject("");
      return;
    }
    console.log("Subject s", s);
    setDefaultSubject(s);
  }, [s]);

  return (
    <>
      RESPONSE
      <br />
      {response}
      <br />
      {debugFlag && (
        <>
          {" "}
          <div>
            RESPONSE {agentInput.response}
            <br />R {r}
            <br />
            DEFAULT RESPONSE {defaultResponse}
            <br />
          </div>
        </>
      )}
    </>
  );
}

//Response.propTypes = {
//  agentInput: PropTypes.func.isRequired,
//  thing: PropTypes.func.isRequired,
//};
