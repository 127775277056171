import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";

import "../index.css";
import {
  Typography,
  //  Avatar,
  //  ListItemAvatar,
  Box,
} from "@mui/material";

import useHybridEffect from "../useHybridEffect.js";

import {
  Button,
  TextField,
  IconButton,
  ListItem,
  ListItemText,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { devFlag, debugFlag } from "../util/dev.js";

import Frequency from "../components/Frequency.js";

//import useThing from "../useThing.js";

import { humanTime, humanRuntime, zuluTextSpread } from "../util/time.js";

import { useSwipeable } from "react-swipeable";

import { minMaxData, minMaxTicks, makeTicks } from "../util/data.js";

function CustomTooltip({ active, payload, label }) {
  if (active && payload && payload.length) {
    var d = new Date(label);
    //var t = d.toISOString();
    var t = d.toISOString().replace(/.\d+Z$/g, "Z");

    return (
      <div
        style={{
          backgroundColor: "#fff",
          border: "1px solid #6366f1",
          padding: "10px",
          borderRadius: "15px",
        }}
      >
        <Typography variant="subtitle2" fontWeight="600">{`${t}`}</Typography>
        {payload.map((entry, index) => (
          <Typography
            variant="subtitle1"
            key={index}
            style={{ color: entry.color }}
          >{`${entry.name}: ${Number(entry.value).toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })}`}</Typography>
        ))}
      </div>
    );
  }

  return null;
}

// https://stackoverflow.com/questions/35279319/js-getting-value-of-object-with-key-starting-with-a-string
function getObjectValueByPartialName(obj, name) {
  if (typeof obj !== "object") {
    throw new Error("object is required!");
  }
  for (var prop in obj) {
    if (prop.indexOf(name) === 0) {
      return obj[prop];
    }
  }
  return "no such property!";
}

function TraceTest(props) {
  const { agentInput, data, timeType, period } = props;

const [fontSize, setFontSize] = useState(12);
  // const shade = true;

  //const agentInput = props?.agentInput;
  //const data = props?.data;
  //const timeType = props?.data;
  //const period = props?.data;

  const [timeSeriesData, setTimeSeriesData] = useState();
  const [xSeriesData, setXSeriesData] = useState();
  const [ySeriesData, setYSeriesData] = useState();

  const [yMean, setYMean] = useState();

  const [ySeriesMax, setYSeriesMax] = useState(30);

  const [currentTime, setCurrentTime] = useState();
  const [spread, setSpread] = useState();

  const [horizonTime, setHorizonTime] = useState();

  const [filteredData, setFilteredData] = useState();
  const [filteredDataSpread, setFilteredDataSpread] = useState();

  const yTicks = [12, 13, 14, 15];

  const [firstAt, setFirstAt] = useState();
  const [lastAt, setLastAt] = useState();

  const availableWindows = ["", "1m", "2m", "10m", "15m", "30m", "1h"];

  const maxCycles = 100;
  const cycles = Array.from(Array(maxCycles), (_, x) => x);

const updateFontSize = () => {
const width = window.innerWidth;

if (width < 600) {
setFontSize(11);
} else if (width < 1200) {
setFontSize(14);
} else {
setFontSize(14);
}

};

/*
useEffect(()=>{
updateFontSize();
window.addEventListener('resize', updateFontSize);
return () => window.removeEventListener('resize', updateFontSize);
},[]);
*/

  useEffect(() => {
    console.log("Trace period", period);
  }, [period]);

function parseTimeInterval(interval) {
    const unit = interval.slice(-1);
    const value = parseInt(interval.slice(0, -1), 10);
    
    switch (unit) {
        case 's': return value * 1000; // seconds
        case 'm': return value * 1000 * 60; // minutes
        case 'h': return value * 1000 * 60 * 60; // hours
        case 'd': return value * 1000 * 60 * 60 * 24; // days
        case 'w': return value * 1000 * 60 * 60 * 24 * 7; // weeks
        case 'M': return value * 1000 * 60 * 60 * 24 * 30; // months (approx)
        case 'y': return value * 1000 * 60 * 60 * 24 * 365; // years (approx)
        default: throw new Error("Invalid time interval");
    }
}

  function atSpread(data) {
    if (data == null) {
      return;
    }
    if (Array.isArray(data) && data.length === 0) {
      return;
    }
    const first = new Date(data[0].at);
    const last = new Date(data[data.length - 1].at);

    const spreadEvent = last - first > 0 ? last - first : first - last;

    setFirstAt(data[0].at);
    setLastAt(data[data.length - 1].at);
    return spreadEvent;
  }

function getValidTickSpacings() {
    return [
        "1s", "2s", "5s", "10s", "15s", "30s", "60s",
        "1m", "2m", "5m", "10m", "20m", "30m", "60m",
        "1h", "2h", "4h", "8h", "24h", "1d", "7d", "10d",
        "21d", "28d", "1M", "2M", "4M", "6M", "12M", "1y"
    ].map(parseTimeInterval);
}


// Function to round to the nearest valid tick spacing
function roundToNearestTickSpacing(value) {
    const validIntervals = [
        "1s", "2s", "5s", "10s", "15s", "30s", "60s",
        "1m", "2m", "5m", "10m", "20m", "30m", "60m",
        "1h", "2h", "4h", "8h", "24h", "1d", "7d", "10d",
        "21d", "28d", "1M", "2M", "4M", "6M", "12M", "1y"
    ];

    const milliseconds = validIntervals.map(parseTimeInterval);
    let closest = milliseconds[0];

    for (let ms of milliseconds) {
        if (Math.abs(ms - value) < Math.abs(closest - value)) {
            closest = ms;
        }
    }

    return closest;
}

  useHybridEffect(() => {
    if (data == null) {
      return;
    }
    if (Array.isArray(data) && data.length === 0) {
      return;
    }

    console.log("Trace data", data);

    const first = new Date(data[0].at);
    const last = new Date(data[data.length - 1].at);
    const spreadEvent = atSpread(data);

    setFirstAt(data[0].at);
    setLastAt(data[data.length - 1].at);


    const numberOfTicks = 4;
    //const maxTickSpacing = spreadEvent / numberOfTicks;


/*
// Calculate the total time span in milliseconds
const totalTimeSpan = last.getTime() - first.getTime();

// Calculate ideal tick spacing
let idealTickSpacing = totalTimeSpan / numberOfTicks;

// Get all valid tick spacings
const validIntervals = getValidTickSpacings();

// Find the largest valid tick spacing that is less than or equal to the ideal tick spacing
let maxTickSpacing = validIntervals[0]; // Start with the smallest valid interval

for (let interval of validIntervals) {

    if (interval <= idealTickSpacing) {
        maxTickSpacing = interval; // Update to the largest valid interval found
    } else {
console.log("Trace interval", interval);
        break; // Stop once we exceed the ideal spacing
    }
}
console.log("maxTickSpacing", maxTickSpacing);
var x = [];
var d2 = 0;
var i = 0;

while (d2 < last.getTime()) {
    d2 = first.getTime() + i * maxTickSpacing;
    if (d2 > last.getTime()) {
        break;
    }
    x.push((new Date(d2)).toISOString());
    i++;
}
console.log("Trace x", x);
    //setXSeriesData(x);
    //return;
    //if (x.length > 1) {
console.log("Trace timeType", timeType);
    if (timeType === "four hourly") {
      setXSeriesData(x);
    } else if (timeType === "continuum") {
      setXSeriesData([]);
    } else {
      // Current default is 4 hourly
      setXSeriesData(x);
    }
*/
    //return;

    //} else {
    //setXSeriesData([]);
    //}
    //console.log("Trace xSeriesData x", x);

    /*
const m = calculateMean(t);
console.log("Trace calculateMean m t", m, t);

const t2 = t.filter((tThing)=>{
//return false;
if (tThing>100) {return false;}
return true;
});
*/
    console.log("Trace data", data);

    const t = data.map((d) => {
      var cycle = 1; // 1 day
      //const milliseconds = new Date(d.at).getTime() -  new Date().getTi>
      //const cycleCount = (milliseconds / (cycle * 60 * 60 * 24 * 1000));

      //
      //d['amount'+cycleCount] = d;

      return { ...d, time: new Date(d.at).getTime() };
    });

    const filteredT = t;

    console.log("Trace timeSeriesData t", t);
    //   setTimeSeriesData(t);
    setTimeSeriesData(t);
    setSpread(spreadEvent);

    console.log("Trace data 2", data);


const a= makeTicks(data);

    console.debug("Trace filteredData a", a);

    //if (y.length > 4) {
    //}

    //const yy = y.filter((yData)=>{

    //if (yData > 20) {return false;}
    //return true;

    //});
    setYSeriesData(a);
    return;
    /*
    console.log("Trace ySeriesData y", y);
    //    setYSeriesData(y);
    if (ySeriesData == null) {
      setYSeriesData([]);
    }
*/
    //}
  }, [data]);

  useEffect(() => {
    updateTime();

    const interval = setInterval(() => {
      updateTime();
    }, 10000); // 20 Hz was 200.

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (filteredData == null) {
      return;
    }
    console.log("Trace filteredData", filteredData);
    const s = atSpread(filteredData);

    const [min, max] = minMaxData(filteredData);

//    const [minDate, maxDate] = minMaxDate(filteredData);

    console.debug("Trace filteredData", filteredData, min, max);
    setFilteredDataSpread(s);
    const a = minMaxTicks(min, max, 5);
    console.debug("Trace filteredData a", a);



console.log("Trace filteredData", filteredData);







// Extract min and max dates from the data array
const dates = data.map(item => new Date(item.at));

const minDate = new Date(Math.min(...dates));
const maxDate = new Date(Math.max(...dates));

// Calculate the total time span in milliseconds
const totalTimeSpan = maxDate.getTime() - minDate.getTime();
console.log("Trace totalTimeSpan", totalTimeSpan);
// Calculate ideal tick spacing for 6 ticks
const numberOfTicks = 6;
let idealTickSpacing = totalTimeSpan / numberOfTicks;

// Get all valid tick spacings
const validIntervals = getValidTickSpacings();

// Find the largest valid tick spacing that is less than or equal to the ideal tick spacing
let maxTickSpacing = validIntervals[0]; // Start with the smallest valid interval

for (let interval of validIntervals) {
    if (interval <= idealTickSpacing) {
        maxTickSpacing = interval; // Update to the largest valid interval found
    } else {
        break; // Stop once we exceed the ideal spacing
    }
}


let nextInterval = null;
if (maxTickSpacing !== null) {
    for (let interval of validIntervals) {
        if (interval > maxTickSpacing) {
            nextInterval = interval; // Found the next interval
            break; // Stop once we find the first one greater than maxTickSpacing
        }
    }
}


// Generate tick timestamps
const ticks = [];


//let d2 = minDate.getTime();
let d2 = getStartOfPeriod(minDate, period);
//let result = getStartOfPeriod(minDate, period);

// Accessing d2 through the result object
//let d2 = new Date(result);

/*
// Get the timestamp of minDate
let minTimestamp = minDate.getTime();

// Calculate the start of the current period
let startOfPeriod = Math.floor(minTimestamp / period) * period;

// Create a new Date object for the start of the period
let periodStartDate = new Date(startOfPeriod);

// Get the timestamp
let d2 = periodStartDate.getTime();
*/



let i = 0;

while (i < numberOfTicks) {
//    ticks.push(new Date(d2).toISOString()); // Store the tick as an ISO string
    ticks.push(new Date(d2).getTime()); // Store the tick as an ISO string
    d2 += nextInterval; // maxTickSpacing; // Increment by the tick spacing
    i++;
}





console.log("Trace ticks", ticks);


      setXSeriesData(ticks);


    //    setYSeriesData(a);
  }, [filteredData, period]);

useEffect(()=>{

console.log("xSeriesData", xSeriesData);

},[xSeriesData]);




function getStartOfPeriod(minDate, period) {


    // Get the timestamp of minDate in UTC
    let minTimestampUTC = minDate.getTime();
// + minDate.getTimezoneOffset() * 60000;


    // Calculate the number of complete periods that have passed
    let completePeriods = Math.floor(minTimestampUTC / period);
    
    // Calculate the start of the nearest prior period
    let p = completePeriods * period + minDate.getTimezoneOffset() * 60000;
    
    return p;
}





  function calculateMean(grades) {
    if (grades == null) {
      return true;
    }

    const total = grades.reduce((acc, c) => acc + c, 0);
    return total / grades.length;
  }

  function updateTime() {
    const x = new Date().getTime();
    setCurrentTime(x);

    const x2 = x - period;
    setHorizonTime(x2);
    //setXMax=
  }
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date

  //return (<>NOTHING</>);
  //        <LineChart data={data} margin={{ right: 300 }}>
  // https://stackoverflow.com/questions/50078787/recharts-set-y-axis-range
  // <YAxis type="number" domain={[dataMin => (0 - Math.abs(dataMin)), dataMax => (dataMax * 2)]} />



// Format function for X-axis ticks
function formatXAxis(tickItem) {
    const ts = new Date(tickItem);
    const options = { hour: '2-digit', minute: '2-digit', hour12: false }; // 24-hour format
    return ts.toLocaleTimeString([], options); // Return formatted time (HH:mm)
}

  //  return <>Blank Trace 2</>;

  //  const xAxisDomain = [currentTime - period, currentTime]; // Calculate the start and end of the desired period
  useEffect(() => {
    if (timeSeriesData == null) {
      return;
    }
    const currentTime2 = new Date();
    //  const period = 60 * 1000; // 30 days in milliseconds

    //const ChartComponent = () => {
    //  const xAxisDomain = [currentTime2 - period, currentTime2]; // Calculate the start and end of the desired period

    //const startTime = currentTime2 - period;
    const startTime = currentTime2.getTime() - period; // Convert current time to timestamp

    console.log("Trace startTime", startTime);

    console.log("Trace timeSeriesData", timeSeriesData);

    let f = timeSeriesData.filter((item) => {
      console.debug("Trace item", item);
      return item.time >= startTime;
    });

    if (typeof period !== "number" || isNaN(period)) {
      f = timeSeriesData;
    }

    setFilteredData(f);
  }, [timeSeriesData]);
  // const filteredDataSpread = atSpread(filteredData);



const [hasRendered, setHasRendered] = useState();

useEffect(()=>{

if (data == null) {return;}
if (Array.isArray(data) && data.length == 0) {return;}

console.log("StrategyBenchmarkChat data", data);
setHasRendered(true);

},[data]);

return (<>FLOOOP - TraceTest</>);

if (hasRendered == null) {return (<></>);}



// Don't render until the x axis is at least ready.
  if (xSeriesData == null) {
return (<></>);

    return (
      <>
        <br />
        NULL XSERIESDATA {Array.isArray(data) && data.length}
        <br />
      </>
    );
    //    return null;
  }
  //return (<>FLERPLE
  //{props.domain == null ? "NULL DOMAIN" : "DOMAIN"}
  //{JSON.stringify(ySeriesData)}</>);

  return (
    <>
      {agentInput?.flavour !== "line" && (
        <>
          <br />
          {debugFlag && (
            <>
              TRACE
              <br />
            </>
          )}
          {/*      SPREAD{" "}{zuluTextSpread(firstAt, lastAt)}{" "} */}
          PERIOD {period == null ? "24 HRS" : humanRuntime(period) } <br />
          {debugFlag && (
            <>
              HORIZON TIME {horizonTime}
              <br />
            </>
          )}
          {debugFlag && (<>RUNTIME {humanRuntime(spread)} <br /></>)}
          {Array.isArray(data) && data.length}
          {" events"}
          <br />
          {debugFlag && (
            <>
              {firstAt}
              <br />
              {lastAt}
              <br />
            </>
          )}
        </>
      )}
      {/*      {"EVENTS "}{Array.isArray(data) && data.length} */}
      {/* {humanRuntime(spread)}
      <br />
*/}
      {/*      PERIOD {period}
      <br />*/}
      <Box>
        <div style={{ width: "100%" }}>
          {/*        <ResponsiveContainer width="100%" aspect={3}> */}
          <ResponsiveContainer aspect={3}>
            {/*   <LineChart data={timeSeriesData}> */}
            <LineChart data={filteredData}>
              <XAxis
fontSize={fontSize}
                interval={0}
                ticks={xSeriesData.sort()}
                dataKey="time"
                    domain={["dataMin", currentTime]}
                //domain={xAxisDomain}
                //                domain={["dataMin", "dataMax"]}
///                domain={[horizonTime, currentTime]}
//                domain={[(currentTime - 1000000), currentTime]}

                type="number"
                tick={true}
                tickFormatter={formatXAxis}
              />{" "}
              {props.domain && <>PROPS DOMAIN</>}
              {props.domain && (
                <YAxis
fontSize={fontSize}
                  interval={0}
                  tick={true}
                  allowDecimals={true}
                  //ticks={yTicks}
                  ticks={ySeriesData.sort()}
                  tickFormatter={(value) =>
                    new Intl.NumberFormat("en", {
                      notation: "compact",
                      compactDisplay: "short",
                    }).format(value)
                  }
                  domain={props.domain}
                ></YAxis>
              )}
              {props.domain === undefined && <>PROPS DOMAIN NOT DEFINED</>}
              {props.domain == null && (
                <YAxis
fontSize={fontSize}
                  interval={0}
                  tick={true}
                  ticks={ySeriesData.sort()}
                  type="number"
                  //ticks={yTicks}
                  //                tickFormatter={(value) =>
                  //                  new Intl.NumberFormat("en", {
                  //                    notation: "compact",
                  //                    compactDisplay: "short",
                  //                  }).format(value)
                  //                }
                  domain={[
                    (dataMin) => {
                      return 1.0 * dataMin; // With large numbers this can be a problem.
                    },
                    (dataMax) => {
                      return 1.0 * dataMax;
                    },
                  ]}
                ></YAxis>
              )}
              {/*    <Legend /> */}
              {/*   <Tooltip /> */}
              {/*  <Line type="monotone" stroke="#8884d8" dataKey="amount" strokeWidth={2}
                        stroke="black" activeDot={{ r: 8 }} /> */}
              {false &&
                data &&
                data[0] &&
                data[0].amounts &&
                data[0].amounts[0] && (
                  <Line
                    isAnimationActive={false}
                    dataKey="amount2"
                    stroke="grey"
                    strokeWidth={4}
                    dot={false}
                  />
                )}
              {false &&
                data &&
                data[0] &&
                data[0].amounts &&
                data[0].amounts[2] && (
                  <Line
                    isAnimationActive={false}
                    dataKey="amount3"
                    stroke="grey"
                    strokeWidth={4}
                    dot={false}
                  />
                )}
              {data &&
                data.length > 0 &&
                Object.keys(data[0]).map((q) => {
                  if (q.startsWith("amount")) {
                    const x = q.replace("amount", "");

                    if (x === "") {
                      return;
                    }
                    if (!Number.isInteger(parseInt(x))) {
                      return;
                    }

                    return (
                      <Line
                        key={"trace_" + x}
                        isAnimationActive={false}
                        dataKey={"amount" + x}
                        stroke="grey"
                        strokeWidth={4}
                        dot={false}
                      />
                    );

                    console.log("Trace q", data[0][q]);
                  }
                  //return (<>x</>);
                })}
              <Tooltip content={<CustomTooltip />} />
              {/*              <Tooltip /> */}
              {cycles.reverse().map((n) => {
                return (
                  <Line
                    key={"trace" + "_" + "cycle" + "_" + n}
                    isAnimationActive={false}
                    dataKey={"amount" + n}
                    stroke={hexShade(n, 1)}
                    //        stroke="grey"
                    strokeWidth={4}
                    dot={false}
                  />
                );
              })}
              <Line
                isAnimationActive={false}
                dataKey="amount"
                stroke="red"
                strokeWidth={4}
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        {/*
      <ResponsiveContainer width="100%" height="100%">
        <LineChart width={300} height={100} data={data}>
          <Line type="monotone" dataKey="pv" stroke="#8884d8" strokeWidth={2} />
        </LineChart>
      </ResponsiveContainer>
*/}

        {agentInput?.flavour !== "line" && (
          <>
            {/* <br />
            FILTERED DATA SPREAD {humanRuntime(filteredDataSpread)}{" "}
            {Array.isArray(filteredData) && filteredData.length}
            {" events"}
            <br />
            {period !== null && <>PERIOD {humanRuntime(period)}</>}
            <p />
*/}
          </>
        )}
      </Box>
    </>
  );
}

function hexShade(n, maxN) {
  //const percent = n / maxN;
  const darkestShade = 60;
  const lightestShade = 180;
  const factorShade = 20;
  var conditionN = n * factorShade + darkestShade;

  if (conditionN > lightestShade) {
    conditionN = lightestShade;
  }
  //conditionN = 0; // balck
  //conditionN = 125; //grey
  //conditionN = 255; //clear/white
  //conditionN = 180;

  const hexString = conditionN.toString(16);
  return "#" + hexString + hexString + hexString;
}

export default TraceTest;
