import axios from "axios";
import { zuluTime, zuluTimeDifferenceMilliseconds } from "./time.js";
import { getSlug, extractUuid } from "./text.js";

import { readToken } from "./../useToken.js";

const apiPrefix = process.env.REACT_APP_API_PREFIX;

const defaultSnapshotInterval = process.env.REACT_APP_SNAPSHOT_INTERVAL;

const snapshotCacheTimeMilliseconds = defaultSnapshotInterval; //1;

axios.defaults.headers = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
};

// requestManager.js
let allowRequests = true;

let allowRepeatRequests = false;
let requestCounts = {}; // Object to track request counts by URL

let requestsDisabled = false;

let timeoutId = null;

export var txData = 0;
export var rxData = 0;
export var rxErrorCount = 0;

export var txCount = 0;
export var rxCount = 0;
export var txErrorCount = 0;

export var txBytes = 0;
export var rxBytes = 0;

export var databaseStatistics = {};
const isRunning = false;
const quotaBytes = 20000000;

function conditionResData(d) {

console.log("conditionResData d",d);

if (typeof d === 'string' || d instanceof String) {
// it's a string

      let thingyRaw = d.replace(/^[^{]+|[^}]+$/g, "");
      let j = JSON.parse(thingyRaw);
return j;
}

 //     let thingy = res.data;
return d;


}

const toggleRequestsDisabled = () => {
  requestsDisabled = !requestsDisabled; // Toggle the requests disabled state
};

const setRequestState = (state) => {
  allowRequests = state;
};
/*
const setAllowRepeatRequests = (state) => {
  allowRepeatRequests = state;
};
*/

const disableRequestsAfter = (seconds) => {
  if (timeoutId) {
    clearTimeout(timeoutId); // Clear any existing timeout
  }
  if (seconds !== null) { // Check if seconds is not null
    timeoutId = setTimeout(() => {
      setRequestState(false); // Disable requests after the specified time
    }, seconds * 1000); // Convert seconds to milliseconds
  }
};

/*
const disableRequestsAfter = (seconds) => {
  if (timeoutId) {
    clearTimeout(timeoutId); // Clear any existing timeout
  }
  timeoutId = setTimeout(() => {
    setRequestState(false);
  }, seconds * 1000); // Convert seconds to milliseconds
};
*/
/*
const disableRepeatRequestsAfter = (seconds) => {
  if (timeoutId) {
    clearTimeout(timeoutId); // Clear any existing timeout
  }
  timeoutId = setTimeout(() => {
    setAllowReportRequests(false);
  }, seconds * 1000); // Convert seconds to milliseconds
};
*/

//const canMakeRequest = () => {
//  return allowRequests;
//};

const canMakeRequest = (url) => {
  // Allow the first request unconditionally
  if (!requestCounts[url]) {
    return true;
  }
  // Allow subsequent requests based on allowRepeatRequests
  return allowRequests && allowRepeatRequests;
};

const incrementRequestCount = (url) => {
  if (!requestCounts[url]) {
    requestCounts[url] = 0; // Initialize count for the URL
  }
  requestCounts[url] += 1; // Increment request count for the URL
};


export const requestManager = {
  setRequestState,
  //setAllowRepeatRequests,
  disableRequestsAfter,
  canMakeRequest,
  incrementRequestCount,
};


function analyzeChannel(r, channel) {
  const bytes = getSizeInBytes(r.data + r.headers);

  if (channel === "tx") {
    txCount += 1;
    txBytes += bytes;
  }

  if (channel === "rx") {
    rxCount += 1;
    rxBytes += bytes;
  }

  if (r && r.data && r.data.uuid) {
    if (databaseStatistics[r.data.uuid] == null) {
      databaseStatistics[r.data.uuid] = {
        txCount: 0,
        txBytes: 0,
        rxCount: 0,
        rxBytes: 0,
        rxErrorCount: 0,
        txErrorCount: 0,
      };
    }

    if (channel === "tx") {
      databaseStatistics[r.data.uuid].txCount =
        databaseStatistics[r.data.uuid].txCount + 1;
      databaseStatistics[r.data.uuid].txBytes =
        databaseStatistics[r.data.uuid].txBytes + bytes;
    }

    if (channel === "rx") {
      databaseStatistics[r.data.uuid].rxCount =
        databaseStatistics[r.data.uuid].rxCount + 1;
      databaseStatistics[r.data.uuid].rxBytes =
        databaseStatistics[r.data.uuid].rxBytes + bytes;
    }
  }
}

// Create a local non-persistent session cache.
// To track requests and responses.
const stack = {};

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    //const dataSize = JSON.stringify(config.data).length;
    //const headerSize = JSON.stringify(config.headers).length;

    //txData += dataSize + headerSize;
    txCount += 1;
    const bytes = getSizeInBytes(config.data + config.headers);
    txBytes += bytes;

    if (config && config.data && config.data.uuid) {
      if (databaseStatistics[config.data.uuid] == null) {
        databaseStatistics[config.data.uuid] = {
          txCount: 0,
          txBytes: 0,
          rxCount: 0,
          rxBytes: 0,
          rxErrorCount: 0,
          txErrorCount: 0,
        };
      }

      databaseStatistics[config.data.uuid].txCount =
        databaseStatistics[config.data.uuid].txCount + 1;
      databaseStatistics[config.data.uuid].txBytes =
        databaseStatistics[config.data.uuid].txBytes + bytes;
    }
    console.debug("database request config", config);
    // Do something before request is sent
    return config;
  },
  function (error) {
    console.error("database request error", error);

    txErrorCount += 1;

    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    //    console.log("database response", response);
    //    console.log("database response.data.uuid", response.data.uuid);

    //rxData += response.data.length + response.headers.length;

    //if (response && response.data && response.headers) {
    //const dataSize = JSON.stringify(response.data).length;
    //const headerSize = JSON.stringify(response.headers).length;

    //rxData += dataSize + headerSize;
    //}

    rxCount += 1;
    const bytes = getSizeInBytes(response.data + response.headers);
    rxBytes += bytes;

    if (response && response.data && response.data.uuid) {
      if (databaseStatistics[response.data.uuid] == null) {
        databaseStatistics[response.data.uuid] = {
          txCount: 0,
          txBytes: 0,
          rxCount: 0,
          rxBytes,
          rxErrorCount: 0,
          txErrorCount: 0,
        };
      }
      databaseStatistics[response.data.uuid].rxCount =
        databaseStatistics[response.data.uuid].rxCount + 1;
      databaseStatistics[response.data.uuid].rxBytes =
        databaseStatistics[response.data.uuid].rxBytes + bytes;
    }

    console.debug("database response response", response);

    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    rxErrorCount += 1;
    console.error("database response error rxErrorCount", error, rxErrorCount);

    //if (response && response.data && response.data.uuid) {
    //if (databaseStatistics[response.data.uuid] == null) {databaseStatistics[response.data.uuid] = {txCount:0, txBytes:0, rxCount:0, rxBytes, rxErrorCount:0, txErrorCount:0};}
    //databaseStatistics[response.data.uuid].rxErrorCount = databaseStatistics[response.data.uuid].rxErrorCount + 1;

    //}

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

const getSizeInBytes = (obj) => {
  let str = null;
  if (typeof obj === "string") {
    // If obj is a string, then use it
    str = obj;
  } else {
    // Else, make obj into a string
    str = JSON.stringify(obj);
  }
  // Get the length of the Uint8Array
  const bytes = new TextEncoder().encode(str).length;
  return bytes;
};

export function Get(thing) {
  if (thing == null) {
    console.error("database Get No Thing provided");
    return Promise.resolve({ error: { message: "No Thing provided." } });
  }
  if (thing.subject == null) {

    console.error("database Get "+(thing && thing.uuid) +" No subject provided");
    return Promise.resolve({ error: { message: "No subject provided." } });
  }
  if (txBytes + rxBytes > quotaBytes) {
    console.error("database Get "+(thing && thing.uuid) +" Quota exceeded");

    return Promise.resolve({ error: { message: "Quota exceeded." } });
  }

  console.debug("Axios call " + thing.subject);

  const webPrefix = process.env.REACT_APP_WEB_PREFIX;
  const apiPrefix = process.env.REACT_APP_API_PREFIX;

const url = webPrefix + thing.subject + ".json";

//allowRequests
  if (!canMakeRequest(url)) {
    console.error("Database canMakeRequest not true.", url);

    return Promise.resolve({ error: { message: "Url request not allowed." } });
  }

incrementRequestCount(url);


  axios
    .get(webPrefix + thing.subject + `.json`)
    .then((res) => {
      let thingy = conditionResData(res.data);
      console.debug("database Get thingy", thing);
      return thingy;
    })
    .catch((error) => {
      console.error("Database error", error);
    });
}

/*

    var u = webPrefix + subject + `.json`;
    // Do we need to get a snapshot?
    if (to === "snapshot") {
      u = webPrefix + "snapshot.json";
    }

*/

export function createThing(webPrefix, datagram, token,text) {
  if (datagram == null) {
    return Promise.resolve({ error: "No datagram provided." });
  }
  if (datagram.subject == null) {
    return Promise.resolve({ error: "No subject provided." });
  }
  if (txBytes + rxBytes > quotaBytes) {
    return Promise.resolve({ error: { message: "Client quota exceeded." } });
  }

  if (datagram && datagram.uuid && datagram.uuid !== null) {
    return Promise.resolve({ error: { message: "Non-null UUID provided in datagram. Use setThing." } });
  }

//console.log("database createThing letterIndex", text);
  console.debug("database createThing datagram", datagram);

  const tokenResponse = readToken(token);
  console.debug("database token tokenResponse", token, tokenResponse);

  if (tokenResponse.isValidToken === false) {
    return Promise.resolve({ error: { message: "Token not valid." } });
  }

//allowRequests
//  if (!allowRequests) {
//    console.error("Database allowrequests not true.");
//    return Promise.resolve({ error: { message: "Request not allowed." } });
//  }

  // Set createThing browndog endpoint
  const u = apiPrefix + "/thing/";


//allowRequests
  if (!canMakeRequest(u)) {
    console.error("Database canMakeRequest not true.", u);

    return Promise.resolve({ error: { message: "Url request not allowed." } });
  }

incrementRequestCount(u);




  // Set createThing browndog endpoint
//  const u = apiPrefix + "/thing/";
  console.debug("database createThing u", u);
  return axios
    .post(u, datagram, {
      headers: {
        Authorization: "my secret token",
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      let thingy = conditionResData(res.data);
      console.debug("database createThing uuid " +thingy.uuid.slice(0,4)  + " " + text +" " + thingy.thing.subject);
      return thingy;
    })
    .catch((error) => {
      //return {error:true, message:error.data.message};
      console.log("database createThing u", u);
      console.error("database createThing error", error);
      var apiErrorMessage = "Problem creating Thing.";
//apiErrorMessage = JSON.stringify(error);
if (error && error.message) {

apiErrorMessage = error.message;
console.error("database createThing error message", error.message);

}

      if (
        error &&
        error.response &&
        error.response.data      ) {
        apiErrorMessage = error.response.data;
      }

      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        apiErrorMessage = error.response.data.message;
      }
      return { error: { message: apiErrorMessage } };
    });
}

export function setThing(uuid, datagram, token) {
  if (datagram == null) {
    return Promise.resolve({ error: { message: "No datagram provided." } });
  }
  if (uuid == null) {
    return Promise.resolve({ error: { message: "No uuid provided." } });
  }
  if (txBytes + rxBytes > quotaBytes) {
    return Promise.resolve({
      error: { message: "Client default quota exceeded." },
    });
  }

  const tokenResponse = readToken(token);
  console.debug("database token tokenResponse", token, tokenResponse);

  if (tokenResponse.isValidToken === false) {
    return Promise.resolve({ error: { message: "Token not valid." } });
  }

//allowRequests
//  if (!allowRequests) {
//    console.error("Database allowrequests not true.");

//    return Promise.resolve({ error: { message: "Request not allowed." } });
//  }



  console.debug("database setThing datagram", datagram);
  console.debug("database setThing token", token);

  const u = apiPrefix + "/thing/" + uuid;


//allowRequests
  if (!canMakeRequest(u)) {
    console.error("Database canMakeRequest not true.", u);

    return Promise.resolve({ error: { message: "Url request not allowed." } });
  }

incrementRequestCount(u);


  // Set createThing browndog endpoint



  console.debug("database setThing u", u);

  return axios
    .put(u, datagram, {
      headers: {
        Authorization: "my secret token",
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      //let thingy = data;
      console.debug("database setThing", u, res);
      return res;
    })
    .catch((error) => {
      console.error("database setThing u error", u, error);
      return { thingReport: { error: error } };
    });
}

// Not tested.
export function getThing(datagram, token) {
  if (datagram == null) {
    return Promise.resolve({ error: { message: "No datagram provided." } });
  }
  if (datagram.uuid == null) {
    return Promise.resolve({ error: { message: "No uuid provided." } });
  }
  if (txBytes + rxBytes > quotaBytes) {
    return Promise.resolve({ error: { message: "Quota exceeded." } });
  }

  const tokenResponse = readToken(token);
  if (tokenResponse.isValidToken === false) {
    return Promise.resolve({ error: { message: "Token not valid." } });
  }

//allowRequests
//  if (!allowRequests) {
//    console.error("Database allowrequests not true.");

//    return Promise.resolve({ error: { message: "Request not allowed." } });
//  }



  console.debug("database getThing datagram", datagram);
  console.debug("database getThing token", token);

  const u = apiPrefix + "/thing/" + datagram.uuid;

  console.debug("database getThing u", u);


//allowRequests
  if (!canMakeRequest(u)) {
    console.error("Database canMakeRequest not true.", u);

    return Promise.resolve({ error: { message: "Url request not allowed." } });
  }

incrementRequestCount(u);


  return axios
    .get(u, {
      headers: {
        Authorization: "my secret token",
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      //let thingy = data;
      console.debug("database getThing", u, res);
      return res;
    })
    .catch((error) => {
      console.error("database getThing u error", u, error);
      return { thing: {uuid:null, from:"from",to:"to", subject: "hello"}, error:{message:"Get Thing error."}, thingReport: { error: error } };
    });
}

export function forgetThing(datagram, token) {
  if (datagram == null) {
    return Promise.resolve({ error: { message: "No datagram provided." } });
  }
  console.debug("database forgetThing datagram", datagram);
  if (txBytes + rxBytes > quotaBytes) {
    return Promise.resolve({ error: { message: "Quota exceeded." } });
  }

//allowRequests
//  if (!allowRequests) {
//    console.error("Database allowrequests not true.");

//    return Promise.resolve({ error: { message: "Request not allowed." } });
//  }




  const u = apiPrefix + "/thing/" + datagram.uuid;



//allowRequests
  if (!canMakeRequest(u)) {
    console.error("Database canMakeRequest not true.", u);

    return Promise.resolve({ error: { message: "Url request not allowed." } });
  }

incrementRequestCount(u);



  return axios
    .delete(u, {
      headers: {
        Authorization: "my secret token",
        "x-access-token": token,
        //        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      //let thingy = res.data;
      console.debug("database forgetThing", res);
      return conditionResData(res.data);
      //     return thingy;
    })
    .catch((error) => {
      console.error("database forgetThing error", error);
      //return true;
      return { error: { message: "database forgetThing error" } };

    });
}

export function getWebJson(webPrefix, token) {
  console.debug("database getWebJson", webPrefix, token);
  if (txBytes + rxBytes > quotaBytes) {
    return Promise.resolve({ error: { message: "Quota exceeded." } });
  }

  return getSnapshot(webPrefix, token);
}

export function getSnapshot(webPrefix, token) {
console.debug("database getSnapshot webPrefix token", webPrefix, token);
  if (!webPrefix) {
    return Promise.reject();
  }
  if (txBytes + rxBytes > quotaBytes) {
    return Promise.resolve({ error: { message: "Quota exceeded." } });
  }

//allowRequests
//  if (!allowRequests) {
 //   console.error("Database allowrequests not true.");

//    return Promise.resolve({ error: { message: "Request not allowed." } });
 // }



  var u = webPrefix;



//allowRequests
  if (!canMakeRequest(u)) {
    console.error("Database canMakeRequest not true.", u);

    return Promise.resolve({ error: { message: "Url request not allowed." } });
  }

incrementRequestCount(u);


  //if (!u.endsWith('snapshot.json')) {
  // u = webPrefix + "snapshot.json";
  //}
  const slug = getSlug(u);


console.debug("database getSnapshot u slug", u, slug, stack[slug]);
//console.log("database getSnapshot stack", stack);
  if (stack[slug]) {
    const nowAt = zuluTime();

    const refreshAge = zuluTimeDifferenceMilliseconds(stack[slug].refreshedAt, nowAt);

console.debug("database getSnapshot slug refreshAge snapshotcachetimemilliseconds", slug, refreshAge, snapshotCacheTimeMilliseconds);

const cacheFlag = false;
//const cacheFlag = true;
    if (cacheFlag && refreshAge < snapshotCacheTimeMilliseconds) {
      console.info(
        "database getSnapshot slug cache valid",
        u,
        slug,
        refreshAge + "ms",
        stack[slug].refreshedAt,
        stack[slug]
      );
      return Promise.resolve(stack[slug]);
    }
  }


if (stack[slug] && stack[slug].requestedAt) {

//    const requestAge = zuluTimeDifferenceMilliseconds(stack[slug].requestedAt, nowAt);
// Check whether a request has been sent.
//console.log("database getSnapshot requestAge refreshAge", requestAge, refreshAge);
//if (requestAge < refreshAge) {
// Request pending. Return cache.
console.debug("database getSnapshot slug requestedAt",slug);
return Promise.resolve(stack[slug]);

}


  console.debug("database getSnapshot slug cache stale", u, slug);

    stack[slug] = { ...stack[slug], requestedAt: zuluTime() };



  return axios
    .get(u, {
      headers: {
        //  'Access-Control-Allow-Origin': '*',
        //  'Access-Control-Allow-Headers': '*',
        Authorization: "my secret token",
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {

console.log("database res.data", res.data);

   //   let thingyRaw = res.data.replace(/^[^{]+|[^}]+$/g, "");
   //   let thingy = JSON.parse(thingyRaw);


      let thingy = conditionResData(res.data);


console.log("database thingy", thingy);



      stack[slug] = { ...thingy, error: null, refreshedAt: zuluTime() };

      console.debug("database getSnapshot thingy", thingy);
      return thingy;
    })
    .catch((error) => {
      stack[slug] = { error: error, refreshedAt: zuluTime() };

      console.error("database getSnapshot error", error);
    });
}

// This below code is not used by useThingReport.
// useThingReport makes a direct get call for the slugified json endpoint
// ie localhost/rocky-road

// Refactor to move the getThingReport code out of useThingReport and put it here.
// One place for database calls.

export function getThingReport(datagram, token) {

console.debug("database getThingReport datagram token", datagram, token);

//allowRequests
//  if (!allowRequests) {
//    console.error("Database allowrequests not true.");

//    return Promise.resolve({ error: { message: "Request not allowed." } });
//  }



  if (txBytes + rxBytes > quotaBytes) {
    return Promise.resolve({ error: { message: "Quota exceeded." } });
  }

  console.debug("database getThingReport datagram", datagram);
  const webPrefix = process.env.REACT_APP_WEB_PREFIX;

//  const u = webPrefix + "/api/whitefox/message";
//const u = webPrefix + apiPrefix.replace("/api/browndog/", "/api/whitefox/message");

const u ="http://localhost/api/whitefox/message";


//allowRequests
  if (!canMakeRequest(u)) {
    console.error("Database canMakeRequest not true.", u);

    return Promise.resolve({ error: { message: "Url request not allowed." } });
  }

incrementRequestCount(u);




  console.debug("database getThingReport stack", stack);
  if (stack[datagram.uuid]) {
    console.debug(
      "database getThingReport cache",
      stack[datagram.uuid].refreshedAt
    );
  }

  return axios
    .post(u, datagram, {
      headers: {
        Authorization: "my secret token",
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      let thingy = conditionResData(res.data);

      stack[thingy.uuid] = { ...thingy, refreshedAt: zuluTime() };

      console.debug("database getThingReport", u, datagram, thingy);
      return thingy;
    })
    .catch((error) => {
      console.error("database getThingReport error", u, datagram, error);
      return { thingReport: { message: "message", error: error } };
    });
}



// Cache object to store the responses
const cache = {};

export function getThings(prefix = null, token = null, cacheTime = 20000) {
  console.debug("database getThings called");
  if (txBytes + rxBytes > quotaBytes) {
    return Promise.resolve({ error: { message: "Quota exceeded." } });
  }

  const tokenResponse = readToken(token);
  console.debug("database getThings tokenResponse", tokenResponse);
  if (tokenResponse.isValidToken === false) {
    return Promise.resolve({ error: { message: "Token not valid." } });
  }

//allowRequests
  if (!allowRequests) {
    console.error("Database allowrequests not true.");

    return Promise.resolve({ error: { message: "Request not allowed." } });
  }



  console.debug("database getThings prefix token", prefix, token);
  var url = apiPrefix + "things/";
  if (prefix !== null) {
    url = prefix + "things/";
  }


//allowRequests
  if (!canMakeRequest(url)) {
    console.error("Database canMakeRequest not true.", url);

    return Promise.resolve({ error: { message: "Url request not allowed." } });
  }

incrementRequestCount(url);




// Override the snapshot interval for getThings.
// getThings is expensive call.
// Need to recognize fingerprint here.
// Not url.

var cacheTime2 = cacheTime;
//cacheTime2 = 120000;

  // Check if the response is cached and not expired
  const cachedResponse = cache[url];
  if (cachedResponse && Date.now() - cachedResponse.timestamp < cacheTime2) {
    console.debug("database getThings returning cached response", url, cachedResponse.data);
    return Promise.resolve(cachedResponse.data);
  }

// browndog reads id in token submitted as x-access-token.

  return axios
    .post(url, {subject:"*"}, {
      headers: {
        Authorization: "my secret token",
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      let thingy = conditionResData(res.data);
      console.debug("database getThings axios url res", url, res);
      console.debug("database getThings axios url res.data", url, res.data);

      // Cache the response
      cache[url] = {
        data: thingy,
        timestamp: Date.now(),
      };

      return thingy;
    })
    .catch((error) => {

if (error?.code) {
console.error("database getThings axios url error code", error?.code);

} else {

      console.error("database getThings axios url error", url, error);
}
      return { things: [] };
    });
}

//}

export function makeObservable(target) {
  let listeners = []; // initial listeners can be passed an an argument aswell
  let value = target;

  function get() {
    return value;
  }

  function set(newValue) {
    if (value === newValue) return;
    value = newValue;
    listeners.forEach((l) => l(value));
  }

  function subscribe(listenerFunc) {
    listeners.push(listenerFunc);
    return () => unsubscribe(listenerFunc); // will be used inside React.useEffect
  }

  function unsubscribe(listenerFunc) {
    listeners = listeners.filter((l) => l !== listenerFunc);
  }

  return {
    get,
    set,
    subscribe,
  };
}
