import React, {useEffect, useState} from 'react';

const TickBarMonitor = ({ tick, tickMax, bar, barMax }) => {
/*
    const [angle, setAngle] = useState(0);

    useEffect(() => {
        const newAngle = (bar + (tick + 1) / tickMax) / barMax * 360;
        setAngle(newAngle);
    }, [tick, tickMax, bar, barMax]);
*/
    const radians = (bar * tickMax + tick + 1) / (barMax * tickMax + 1) * 2 * Math.PI;

    // Calculate the position of the circle
    const radius = 15; // Radius from the center
    const circleRadius = 8; // Radius of the circle (5px diameter)

    const cx = 25 + radius * Math.cos(radians); // Center X + offset
    const cy = 25 + radius * Math.sin(radians); // Center Y + offset


    return (
        <svg width="50" height="50">
            <circle cx={cx} cy={cy} r={circleRadius} fill="grey" />
        </svg>
    );
};

// Export the SvgCircle component as default
export default TickBarMonitor;
