import { zuluTimeDifferenceMilliseconds,convertToMilliseconds } from "./time.js";


//const {
//  authAuthenticateSubscriber,
//  loginStack,
//  hasAccessToken,
//  readToken,
//} = require("stack-js/src/util/stack");
import {extractUrl,cleanUrl} from "stack-js/src/util/text";

var slugify = require("slugify");

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function transducerLink(t, transducer, url) {
if (transducer == null) {return true;}
  //  if (t == null) {
  //    return "/snapshot";
  //  }
console.log("text.js transducerLink transducer", transducer);
const uuid = extractUuid(url);

if (uuid == null || uuid === false) {
 //   if (t?.uuid == null) {
      return "/history/" + "transducers-" + transducer.toLowerCase() + "-1d";
    }

if (uuid) {
if (url.toLowerCase().includes("-snapshot-transducers-")) {
    return (
      "/history/" +
uuid +
 //     t.uuid +
      "-snapshot-transducers-" +
      transducer.toLowerCase() +
      "-1d"
    );
}

if (url.toLowerCase().includes("-snapshot-")) {
    return (
      "/history/" +
uuid +
 //     t.uuid +
      "-snapshot-" +
      transducer.toLowerCase() +
      "-1d"
    );
}



}


  }



export function oldtransducerLink(t, transducer, url) {

if (transducer == null) {return;}
console.log("Transducer transducerLink t", t);
// http://localhost:3000/history/8afaf195-a30f-4c6f-ab16-316680e118c3-snapshot-throttled-1d

//const updatedUrl = location.pathname.replace(/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})-snapshot-[a-zA-Z0-9]+/, `$1-snapshot-${transducer}`);

//const agents = "snapshot"; // This can be any string
    const agents = ["snapshot", "transducers"];

// Use a regular expression to find the pattern and replace the last part with the value of transducer
//const updatedUrl = url.replace(/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})-(.+?)-[a-zA-Z0-9]+/, `$1-${agents}-${transducer.toLowerCase()}`);




 // Create a regex pattern to match any combination of the agents
    const agentsPattern = agents.join('|'); // Join agents with | to create a regex OR pattern

    // Use a regular expression to find the pattern and replace it
    const updatedUrl = url.replace(
        new RegExp(`([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})-(${agentsPattern})(?:-(${agentsPattern}))?-[a-zA-Z0-9]+`),
        (match, uuid, agentsPart) => {
            // Return the UUID, the matched agents, and the new transducer
            return `${uuid}-${agentsPart}-${transducer.toLowerCase()}`;
        }
    );






return updatedUrl;


}
export const diffText = (diffMe, diffBy) => diffMe?.split(diffBy).join('');

function compressText(text) {
  if (text == null) {
    return "";
  }
  console.debug("compressText text", text);
  var maxLength = 78;

  if (text.length <= maxLength) {
    return text;
  }

  const l = text.length;
  //  var compressedText = text.replace(/\s+/g, " ").substring(0, maxLength);
  var compressedText = text.substring(0, maxLength);

  console.debug("compressText compressedText", compressedText);

  compressedText = compressedText.substr(
    0,
    Math.min(compressedText.length, compressedText.lastIndexOf(" ")),
  );

  console.debug("compressText compressedText recut", compressedText);
  console.debug(
    "compressText extractUrl compressedText",
    extractUrl(compressedText),
  );
  console.debug("compressText extractUrl text", extractUrl(text));

  var isSliced = false;

  const lCompressed = compressedText.length;

  if (lCompressed !== l) {
    isSliced = true;
  }

  if (extractUrl(compressedText) === false && extractUrl(text) !== false) {
    compressedText = compressedText + " " + extractUrl(text);
  }

  //  const lCompressed = compressedText.length;

  if (isSliced) {
    compressedText = compressedText + " [...]";
  }

  console.debug("compressText result", compressedText);

  return compressedText;
}



export function extractUuid(input) {
  if (input == null) {
    return true;
  }
  const uuids = extractUuids(input);
  if (uuids == null) {
    return false;
  }

  if (uuids.length === 1) {
    return uuids[0];
  }

  return false;
}

export function extractReference(text) {

    const hr = text
      .replace("history/", "")
      .replace("history-", "")
      .replace("history ", "");

return hr;
}
/*
export function extractDuration(text) {

    var tempR = text
      .replace("transducers-", "")
      .replace("history/", "")
      .replace("history-", "")
      .replace("history ", "");

    //const r = tempR;
    //console.log("History tempR", tempR);
    const i = tempR.split("-");
    console.log("History tempR i", tempR, i);

    var tempTextIntervalArray = extractDurations(i);
    if (
      Array.isArray(tempTextIntervalArray) &&
      tempTextIntervalArray.length > 0
    ) {
      var tempTextInterval = tempTextIntervalArray.slice(-1)[0];
    }
return tempTextInterval;

}
*/

export function extractPeriod(text) {
  //console.log("History extractPeriod text", text);
  if (text == null) {
    return null;
  }
  const x = text.split(".json");

  var parts = x[0].split("-");
  var result = parts[parts.length - 1]; // Or parts.pop();

try {
const i = convertToMilliseconds(result);
  if (isNaN(i)) {return false;}

} catch(e) {
return false;
}


  return result;
}

/*
function extractPeriod(text) {
  console.log("History extractPeriod text", text);
  if (text == null) {
    return null;
  }
  const x = text.split(".json");

  var parts = x[0].split("-");
  var result = parts[parts.length - 1]; // Or parts.pop();

  return result;
}
*/


export const extractNumberTokens = (text, options) => {
  let numbers;
  if (!text || typeof text !== "string") {
    return [];
  }

  numbers = text.match(/(-\d+|\d+)(,\d+)*(\.\d+)*/g);

  return numbers;
};

export const extractMixedTokens = (text, options) => {
  var parts = [];
  if (Array.isArray(text)) {
    parts = text;
  } else {
    parts = text.split(" ");
  }

  return parts.filter((part) => {
    return isMixed(part);
  });
};

export function isAlpha(str) {
  var code, i, len;

  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i);
    if (
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123)
    ) {
      // lower alpha (a-z)
      return false;
    }
  }
  return true;
}

export function isAlphaNumeric(str) {
  var code, i, len;

  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i);
    if (
      !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123)
    ) {
      // lower alpha (a-z)
      return false;
    }
  }
  return true;
}

export function isNumeric(token) {
  if (typeof token != "string") return false; // we only process strings!
  return (
    !isNaN(token) && !isNaN(parseFloat(token)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not >
  ); // ...and ensure strings of whitespace fail
}

/*
function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
} 
*/

export function isMixed(token) {
  if (isAlphaNumeric(token) && !isAlpha(token) && !isNumeric(token)) {
    return true;
  }
  return false;
}

export function extractAlphaTokens(text) {
  var parts = text.split(" ");

  return parts.filter((part) => {
    return isAlpha(part);
  });
}


export function sortThingsByAt(things) {

  const copyOfThings = [...things]; // Create a copy of the array
  const sortedThings = copyOfThings.sort((a, b) => new Date(b.at) - new Date(a.at));
  return sortedThings;

} 


export function deduplicateThingsByHaystack(things) {

//  const subjects = things.map((o) => o.subject);
  const subjects = things.map((o) => {return 
JSON.stringify(o)
});


  const deduplicatedThings = things.filter(
    (t, index) => !subjects.includes(JSON.stringify(t), index + 1)
  );

  return deduplicatedThings;
}




// Sort high to low score
export function sortThingsByScore(things) {
  //if (items === undefined) {return [];}
  //if (items.length === 0) {return [];}
  const sortedThings = things.sort(function (a, b) {
    return b.score - a.score;
  });

  return sortedThings;
}

// Sort high to low score
export function sortThingsByAge(things, order) {
  const sortedThings = things.sort(function (a, b) {
    if (order === "ascending") {
      return zuluTimeDifferenceMilliseconds(a.createdAt, b.createdAt);
    }
    return zuluTimeDifferenceMilliseconds(b.createdAt, a.createdAt);
  });

  return sortedThings;
}

export function scoreThings(things, slugTitle) {
  if (!Array.isArray(things)) {
    return true;
  }
  const scoredThings = things.map((thing) => {
    return {
      ...thing,
      score: scoreThing(thing, slugTitle),
    };
  });

  return scoredThings;
}

export function scoreThing(thing, inputClusterTokens) {
  const text = thing.subject + " " + thing.uuid;

  if (text == null) {
    return true;
  }

  const defaultKeywords = "";

  //    const conditionedClusterTokens = getSlug(inputClusterTokens).replace("-"," ");

  const conditionedClusterTokens = getSlug(inputClusterTokens);
  const clusterTokens = conditionedClusterTokens
    ? conditionedClusterTokens.toLowerCase().split("-")
    : defaultKeywords.toLowerCase().split(" ");
  //console.log("text scoreThing", text, inputClusterTokens, conditionedClusterTokens, clusterTokens);

  const conditionedText = getSlug(text);
  const titleTokens = conditionedText.toLowerCase().split("-");

  let score = titleTokens.length;
  let count = 0;

  const clusterUuidTokens = extractUuids(clusterTokens.join(" "));
  const titleUuidTokens = extractUuids(titleTokens.join(" "));

  //console.log("text scoreThing uuid", clusterUuidTokens, titleUuidTokens);

  if (clusterUuidTokens !== null) {
    clusterUuidTokens.forEach((clusterUuidToken) => {
      if (titleUuidTokens == null) {
        return;
      }
      titleUuidTokens.forEach((titleUuidToken) => {
        if (titleUuidToken === clusterUuidToken) {
          count += count + 10;
        }
      });
    });
  }

  if (clusterUuidTokens && clusterUuidTokens.includes(thing.uuid)) {
    count += count + 100;
  }

  const clusterNumberTokens = extractNumberTokens(clusterTokens.join(" "));
  const titleNumberTokens = extractNumberTokens(titleTokens.join(" "));

  //extractMixedTokens

  const clusterMixedTokens = extractMixedTokens(clusterTokens.join(" "));
  const titleMixedTokens = extractMixedTokens(titleTokens.join(" "));

  if (clusterNumberTokens !== null && titleNumberTokens !== null) {
    clusterNumberTokens.map((word) => {
      if (titleNumberTokens.includes(word.toLowerCase())) {
        count += 1;
      }
      return true;
    });

    titleNumberTokens.map((word) => {
      if (clusterNumberTokens.includes(word.toLowerCase())) {
        count += 1;
      }
      return true;
    });
  }

  if (clusterMixedTokens !== null && titleMixedTokens !== null) {
    clusterMixedTokens.map((word) => {
      if (titleMixedTokens.includes(word.toLowerCase())) {
        count += 5;
      }
      return true;
    });

    titleMixedTokens.map((word) => {
      if (clusterMixedTokens.includes(word.toLowerCase())) {
        count += 5;
      }
      return true;
    });
  }

  if (clusterTokens.length === 1) {
    if (titleTokens.includes(clusterTokens[0].toLowerCase())) {
      count += 1;
    }
  }

  clusterTokens.map((word) => {
    if (titleTokens.includes(word.toLowerCase())) {
      count += 1;
    }
    return true;
  });

  titleTokens.map((word) => {
    if (clusterTokens.includes(word.toLowerCase())) {
      count += 1;
    }
    return true;
  });

  // Weight matches in first three tokens of title
  clusterTokens.map((word) => {
    if (titleTokens.length === 0) {
      return true;
    }

    if (word.toLowerCase() === titleTokens[0].toLowerCase()) {
      count += 1;
    }

    if (titleTokens.length === 1) {
      return true;
    }

    if (word.toLowerCase() === titleTokens[1].toLowerCase()) {
      count += 1;
    }

    if (titleTokens.length === 2) {
      return true;
    }

    if (word.toLowerCase() === titleTokens[2].toLowerCase()) {
      count += 1;
    }
    return true;
  });

  score = count;

  // NEXT STEP EXTRACT MIXEDs.

  //const score = LevenshteinDistance(keywords, text);
  //console.log(text, keywords, score);

  return score;
}

/*
  let mixeds;
  if (!text || typeof text !== 'string') {
    return [];
  }
console.log("mixeds text", text);
  mixeds = text.match(/^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]+$/g);
console.log("mixeds",mixeds);
  return mixeds;
*/
//};

export function extractUuids(input) {
  const pattern = /[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}/g;

  return input.match(pattern);
}

export function extractNuuids(input) {
  const pattern = /[0-9a-f]{4}/g;

  return input.match(pattern);
}

export function extractNuuid(input) {
  if (input == null) {
    return true;
  }
  const nuuids = extractNuuids(input);
  if (nuuids == null) {
    return false;
  }

  if (nuuids.length === 1) {
    return nuuids[0];
  }

  return false;
}

export function replaceUuids(input) {
  if (input == null) {
    return true;
  }
  const pattern = /[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}/g;

  var result = input.replace(pattern, function (match, token) {
    return "x";
    //    return data[token];
  });
  return result;
  //  return input.match(pattern);
}

export function getSlug(text) {
  if (text == null) {
    return "";
  }

  const rawSlug = text
    .toLowerCase()
    .replace("+", " ")
    .replace("-", " ")
    .replace(/[^\w ]+/g, " ")
    .replace(/ +/g, "-");

  const first = rawSlug.charAt(0);
  const last = rawSlug.charAt(rawSlug.length - 1);
  var conditionedSlug = rawSlug;

  if (last === "-") {
    conditionedSlug = conditionedSlug.slice(0, -1);
  }
  if (first === "-") {
    conditionedSlug = conditionedSlug.slice(1);
  }
  //console.log("conditionedSlug", conditionedSlug);
  return conditionedSlug;
}

export function parsePing(p) {
  //    const ps = [];
  if (!p) {
    return;
  }

  //console.log("text parsePing", p);
  //   ping.map((p) => {
  if (!p) {
    return;
  }
  // Parse ping return.
  // Identity and read.
  const pingArray = p.split("=");
  if (!pingArray[1]) {
    return;
  }
  const pingArray2 = pingArray[1].split("/");
  const measure =
    parseFloat(pingArray2[0]) +
    parseFloat(pingArray2[1]) +
    parseFloat(pingArray2[2]);

  //console.log("text parsePing pingArray", pingArray);
  const parts = pingArray[0].split(" ");

  const ps = {
    host: parts[1],
    amount: parseFloat(pingArray2[0]),
    amount2: parseFloat(pingArray2[1]),
    amount3: parseFloat(pingArray2[2]),
  };
  //    });

  return ps;
}

export function prefixText(text, prefix) {
  if (text == null) {
    return text;
  }

  if (text.startsWith(prefix)) {
    return text;
  }

  if (text.startsWith("/" + prefix)) {
    return text;
  }

  if (text.startsWith("/" + prefix + "/")) {
    return text;
  }

  if (text.startsWith(prefix + "/")) {
    return text;
  }

  return prefix + text;
}

export function isText(x) {
  return Object.prototype.toString.call(x) === "[object String]";
}

export function hasText(haystack, needle) {

if (needle == null) {return false;}
//console.log("hasText haystack needle", haystack, needle);
  const parts = getSlug(haystack.toLowerCase()).split("-");

//console.log("hasText haystack parts needle", parts, needle);


  const response = parts.reduce((accumulator, current) => {
    if (accumulator === true) {
      return accumulator;
    }

    if (current === needle.toLowerCase()) {
      return true;
    }

// Introduce similar token test here.
const x = jaro_winkler.distance(current, needle);
//console.log("jaro winkler distance", current, needle, x);
if (x>0.9) {
return true;
}


  }, false);

//  console.log("hasText haystack needle response", haystack, needle, response);
  return response;
}




var jaro_winkler = {};

/* JS implementation of the strcmp95 C function written by
Bill Winkler, George McLaughlin, Matt Jaro and Maureen Lynch,
released in 1994 (http://web.archive.org/web/20100227020019/http://www.census.gov/geo/msb/stand/strcmp.c).

a and b should be strings. Always performs case-insensitive comparisons
and always adjusts for long strings. */
jaro_winkler.distance = function(a, b) {

  if (!a || !b) { return 0.0; }

  a = a.trim().toUpperCase();
  b = b.trim().toUpperCase();
  var a_len = a.length;
  var b_len = b.length;
  var a_flag = []; var b_flag = [];
  var search_range = Math.floor(Math.max(a_len, b_len) / 2) - 1;
  var minv = Math.min(a_len, b_len);

  // Looking only within the search range, count and flag the matched pairs. 
  var Num_com = 0;
  var yl1 = b_len - 1;
  for (var i = 0; i < a_len; i++) {
    var lowlim = (i >= search_range) ? i - search_range : 0;
    var hilim  = ((i + search_range) <= yl1) ? (i + search_range) : yl1;
    for (var j = lowlim; j <= hilim; j++) {
      if (b_flag[j] !== 1 && a[j] === b[i]) {
        a_flag[j] = 1;
        b_flag[i] = 1;
        Num_com++;
        break;
      }
    }
  }

  // Return if no characters in common
  if (Num_com === 0) { return 0.0; }

  // Count the number of transpositions
  var k = 0; var N_trans = 0;
  for (var i = 0; i < a_len; i++) {
    if (a_flag[i] === 1) {
      var j;
      for (j = k; j < b_len; j++) {
        if (b_flag[j] === 1) {
          k = j + 1;
          break;
        }
      }
      if (a[i] !== b[j]) { N_trans++; }
    }
  }
  N_trans = Math.floor(N_trans / 2);

  // Adjust for similarities in nonmatched characters
  var N_simi = 0; var adjwt = jaro_winkler.adjustments;
  if (minv > Num_com) {
    for (var i = 0; i < a_len; i++) {
      if (!a_flag[i]) {
        for (var j = 0; j < b_len; j++) {
          if (!b_flag[j]) {
            if (adjwt[a[i]] === b[j]) {
              N_simi += 3;
              b_flag[j] = 2;
              break;
            }
          }
        }
      }
    }
  }

  var Num_sim = (N_simi / 10.0) + Num_com;

  // Main weight computation
  var weight = Num_sim / a_len + Num_sim / b_len + (Num_com - N_trans) / Num_com;
  weight = weight / 3;

  // Continue to boost the weight if the strings are similar
  if (weight > 0.7) {
    // Adjust for having up to the first 4 characters in common
    var j = (minv >= 4) ? 4 : minv;
    var i;
    for (i = 0; (i < j) && a[i] === b[i]; i++) { }
    if (i) { weight += i * 0.1 * (1.0 - weight) };

    // Adjust for long strings.
    // After agreeing beginning chars, at least two more must agree
    // and the agreeing characters must be more than half of the
    // remaining characters.
    if (minv > 4 && Num_com > i + 1 && 2 * Num_com >= minv + i) {
      weight += (1 - weight) * ((Num_com - i - 1) / (a_len * b_len - i*2 + 2));
    }
  }

  return weight
  
};

// The char adjustment table used above
jaro_winkler.adjustments = {
  'A': 'E',
  'A': 'I',
  'A': 'O',
  'A': 'U',
  'B': 'V',
  'E': 'I',
  'E': 'O',
  'E': 'U',
  'I': 'O',
  'I': 'U',
  'O': 'U',
  'I': 'Y',
  'E': 'Y',
  'C': 'G',
  'E': 'F',
  'W': 'U',
  'W': 'V',
  'X': 'K',
  'S': 'Z',
  'X': 'S',
  'Q': 'C',
  'U': 'V',
  'M': 'N',
  'L': 'I',
  'Q': 'O',
  'P': 'R',
  'I': 'J',
  '2': 'Z',
  '5': 'S',
  '8': 'B',
  '1': 'I',
  '1': 'L',
  '0': 'O',
  '0': 'Q',
  'C': 'K',
  'G': 'J',
  'E': ' ',
  'Y': ' ', 
  'S': ' '
}
