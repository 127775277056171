import React, { useRef, useState, useEffect } from "react";

import Snippet from "../components/Snippet.js";
import DynamicComponent from "../components/DynamicComponent.js";
import useHybridEffect from "../useHybridEffect.js";

import VisibilitySensor from "react-visibility-sensor";

import { styled } from "@mui/material/styles";

import Collapse from "@mui/material/Collapse";

import { IconButtonProps } from "@mui/material/IconButton";

import { createThing } from "../util/database.js";

import { extractionDate, humanTime } from "../util/time.js";

import { useLocation } from "react-router-dom";

import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

import useScrollToHash from "../useScrollToHash.js"; // Import the custom hook

import "../index.css";
import {
  Typography,
  //  Avatar,
  //  ListItemAvatar,
  Box,
} from "@mui/material";

import {
  Button,
  TextField,
  IconButton,
  ListItem,
  ListItemText,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { Edit } from "@mui/icons-material";

import Forget from "../components/Forget.js";

import Web from "../components/Web.js";
import ExpanderCollapser from "../components/ExpanderCollapser.js";

import useToken from "../useToken.js";

import {
  isText,
  extractUuid,
  extractNuuid,
  getSlug,
  capitalizeFirstLetter,
} from "../util/text.js";

import { devFlag, debugFlag } from "../util/dev.js";

import {
  compressText,
  cleanUrl,
  extractUrl,
  extractUrls,
} from "stack-js/src/util/text";

function sizeText(text) {
  return text.length;
}

function Block({ thing, agentInput }) {
  const location = useLocation();

  useScrollToHash(location);

  const hasRendered = useRef(false);

  const { token, isValidToken } = useToken();

  const datagram = thing;

  const [web, setWeb] = useState();
  const [snippet, setSnippet] = useState();
  const [compressedSnippet, setCompressedSnippet] = useState();

  const [webExpanded, setWebExpanded] = useState(false);
  const [isWebExpandable, setIsWebExpandable] = useState();

  const [compressSnippets, setCompressSnippets] = useState();

  const handleWebExpandClick = () => {
    console.log("Thing handleWebExpandClick webExpanded", webExpanded);
    setWebExpanded(!webExpanded);
  };

  const maxAttempts = 10; // Maximum number of attempts to find the element
  const intervalDelay = 100; // Delay between attempts in milliseconds

  useEffect(() => {
    // Still working on this.
    // Needs to write state of the thing (expanded or not) to the thing.
    // But first need to tame the PUT requests.

    setIsWebExpandable(false);
  });

  useEffect(() => {
    console.log("Thing webExpanded", webExpanded);
  }, [webExpanded]);

  function isUpperCase(str) {
    return str === str.toUpperCase();
  }

  const [containBlock, setContainBlock] = useState();

  useHybridEffect(() => {
    if (agentInput == null) {
      return;
    }
    if (agentInput.block == null) {
      return;
    }

    if (agentInput?.containBlock) {
      console.log("Block agentInput", agentInput);
      setContainBlock(agentInput?.containBlock);
    }

    //   if (isUpperCase(agentInput.block[0].text)) {

    if (
      agentInput.block &&
      Array.isArray(agentInput.block) &&
      agentInput.block.length > 0 &&
      isUpperCase(agentInput.block[0].text)
    ) {
      setCompressSnippets(false);
      return;
    }

    setCompressSnippets(agentInput?.compressSnippets);
  }, [agentInput]);

  const [open, setOpen] = useState(false);

  const replyAgentDialog = (thing) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function humanTime(timestamp) {
    const ts = new Date();
    return ts.toISOString();
  }

  const [maxSnippets, setMaxSnippets] = useState();

  const [inceptionMessage, setInceptionMessage] = useState();

  const firstNonNullAt = (innerArray) => {
    // Check if innerArray is defined and is an array
    if (!Array.isArray(innerArray)) {
      return undefined; // or return null, or any default value you prefer
    }

    return innerArray.find(
      (block) => block.at !== null && isValidDate(block.at)
    )?.at;
  };

  useEffect(() => {
    console.log("Block containBlock", containBlock);
    if (containBlock == null) {
      return;
    }

    if (containBlock) {
      if (agentInput?.maxSnippets) {
        setMaxSnippets(agentInput?.maxSnippets);
        return;
      }

      setMaxSnippets(3);
      return;
    }

    setMaxSnippets(agentInput.block.length);
  }, [containBlock]);

  const [blockDates, setBlockDates] = useState();
  // const [unusedBlockDates, setUnusedBlockDates] = useState();

  useHybridEffect(() => {
    if (agentInput?.blocks == null) {
      return;
    }
    const bs = agentInput?.blocks;

    const filteredBlocks = conditionBlocks(bs);



    const blockAts = filteredBlocks.map((b) => {
      return b;
    });

    /*
    const blockAts = agentInput?.blocks.map((b) => {
      return b;
    });
*/
    const firstNonNullAts = blockAts.map(
      (innerArray) => innerArray.find((block) => block.at !== null)?.at // Use optional chaining to handle cases where no non-null 'at' is found
    );

    //console.log("Block firstNonNullAts", firstNonNullAts);
    // Step 2: Filter out null values and remove duplicates
    //const uniqueEntries = Array.from(new Set(firstNonNullAts.filter(item => item.at !== null)));

    //    setBlockDates(firstNonNullAts);
    // ALl elements except first to prevent item inception.

    // Remove the first element and filter out non-unique entries
    //const uniqueEntries = firstNonNullAts.filter((item, index, arr) => {
    //  return arr.indexOf(item) === index; // Keep only the first occurrence
    //});

    //    setBlockDates(uniqueEntries.slice(1));

    //const textArray = firstNonNullAts.filter(item => item !== null);

    // Remove non-unique elements

    //const uniqueEntries = textArray.filter(item =>
    //  textArray.indexOf(item) === textArray.lastIndexOf(item)
    //);

    //setBlockDates(uniqueEntries.slice(1)) ;

    setBlockDates(firstNonNullAts.slice(1));

    //setUnusedBlockDates(firstNonNullAts);
  }, [agentInput?.blocks]);

  const toggleContainBlock = () => {
    setContainBlock(!containBlock);
    console.log("Block toggleContainBlock");
  };

  const flag = false;

  if (blockDates == null) {
    return <></>;
  }

  const insertedBlockDates = new Set();

  const isValidDate = (dateString) => {
    // Regular expression to match a valid ISO 8601 date format
    const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/;

    // Check if the dateString matches the expected format
    if (!iso8601Regex.test(dateString)) {
      return false;
    }

    const date = new Date(dateString);
    return !isNaN(date.getTime()) && dateString === date.toISOString();
  };

  function conditionBlocks(tempBlocks) {
    const filteredBlocks = tempBlocks.filter((block) => {
      return block?.at;
    });


//    filteredBlocks.sort((a, b) => new Date(a.at) - new Date(b.at));

filteredBlocks.sort((a, b) => {
    if (a.at === null) return 1; // Move `a` to the end
    if (b.at === null) return 1; // Move `b` to the end
    return new Date(a.at) - new Date(b.at); // Normal date comparison
});


    return filteredBlocks;
  }

  // Assumes date ordered.
  function getFirstMatchingBlockByDate(blocks, dateToTest) {
    if (dateToTest == null) {
      return false;
    }

    if (blocks == null) {
      return true;
    }
    if (Array.isArray(blocks) && blocks.length == 0) {
      return false;
    }

    //const filteredBlocks = blocks;
    // Filter out objects with null or invalid dates
    //const filteredBlocks = blocks.filter(block => {return (block?.at)});
    //filteredBlocks.sort((a, b) => new Date(a.at) - new Date(b.at));

    // Sort blocks by provided date...
    /*
blocks.sort((a, b) => {
    if (a.at === null) return 1; // Move `a` to the end
    if (b.at === null) return -1; // Move `b` to the end
    return new Date(a.at) - new Date(b.at); // Normal date comparison
});
*/

    const filteredBlocks = conditionBlocks(blocks);
    filteredBlocks.shift();
    //return "hello";
    // Iterate through each second-level array in the data
    for (const block of filteredBlocks) {
      // Check if the block is an array
      if (!Array.isArray(block)) {
        console.error("Expected block to be an array", block);
        continue; // Skip to the next block if it's not an array
      }

      for (const snippet of block) {
        if (snippet?.at == null) {
          continue;
        }
        if (!isValidDate(snippet?.at)) {
          continue;
        }
        if (snippet.at === dateToTest) {
          return block; // Return the first matching block
        }
      }
    }
    return null; // Return null if no match is found
  }

  // Test for child of child?
  //const datex = firstNonNullAt(agentInput?.blocks[0]);
  //const datey = firstNonNullAt(agentInput?.block);

  //if (datex === datey && agentInput?.inception === false) {return (<>inception detected</>);}

  if (agentInput?.inception == false) {
    // Should not render the same thing!
    //HERE
    const date = firstNonNullAt(agentInput?.block);

    return (
      <>
        {/*<pre>inception false</pre>*/}
        <pre>{inceptionMessage}</pre>
        {agentInput.block.slice(0, maxSnippets).map((d, i) => {
          return (
            <>
              {/*   <pre>{JSON.stringify(blockDates, null, 2)}</pre> */}
  {/*            {date == null && <>NULL DATE. </>}
              {snippet?.at && <>{snippet.at}</>}
*/}
              {/*<pre>{JSON.stringify(d, null, 2)}</pre> */}
              <Snippet
                thing={thing}
                agentInput={{ ...d, compressSnippet: compressSnippets }}
              />
            </>
          );
        })}
{/*
        <Typography
          style={{
            color: "lightgray",
            fontSize: "14px",
            fontFamily: "Arial, sans-serif",
            fontWeight: 400,
          }}
        >
          {date && date.replace(".000Z","Z")}
        </Typography>
*/}
        {agentInput.block.length >= maxSnippets &&
          agentInput?.hideButton !== true && (
            <button onClick={toggleContainBlock}>
              {containBlock ? "Show More" : "Show Less"}
            </button>
          )}
      </>
    );
  }

  if (agentInput?.hashLink === false) {
    const date = firstNonNullAt(agentInput?.block);

    const block = agentInput?.block;

    //if (date == null) {return (<>UNDEFINED DATE</>)}
    // A block without a date.
    // Let's try showing it where it needs to be seen.
    if (date == null) {
      return (
        <>
          <Block
            thing={thing}
            agentInput={{
              blocks: [block],
              block: block,
              maxSnippets: 3,
              containBlock: true,
              //titleLinkOnly: true,
              compressSnippets: false,
              inception: false,
              hideButton: false,
            }}
          />
        </>
      );
    }

    return (
      <>
        <Block
          thing={thing}
          agentInput={{
            blocks: [block],
            block: block,
            maxSnippets: 1,
            containBlock: true,
            //titleLinkOnly: true,
            compressSnippets: false,
            inception: false,
            hideButton: true,
          }}
        />

        <Link to={"#" + date}>Go to {date.slice(0, 10)}</Link>
      </>
    );

    return <>HASHLINK FALSE</>;
  }

  return (
    <>
      {agentInput.block.slice(0, maxSnippets).map((d, index) => {
        // Get the current snippet date
        const currentDateStr = d.at;
        const currentDateValid = isValidDate(currentDateStr);
        const currentDate = currentDateValid ? new Date(currentDateStr) : null;

        // Create an array to hold the block elements to be displayed
        const blocksToInsert = [];

        // If the current date is valid, check for block dates before it
        if (currentDateValid) {
          blockDates.forEach((blockDate) => {
            const blockDateObj = new Date(blockDate);
            // If the block date is less than the current snippet date
            if (
              blockDateObj < currentDate &&
              !insertedBlockDates.has(blockDate)
            ) {
              insertedBlockDates.add(blockDate); // Mark this block date as inserted

              var block = null;
              block = getFirstMatchingBlockByDate(
                agentInput?.blocks,
                blockDate
              );
              if (block?.at) {
                blocksToInsert.push(
                  <>
                    {/*<pre>block {JSON.stringify(block,0,2)}</pre>*/}

                    <div id={blockDate}>
                      {/*<pre>#{blockDate} (block)</pre>*/}
                    </div>
                    {/*<pre>{blockDate}</pre>
                  <pre>A currentDateValid {JSON.stringify(block)}</pre>*/}
                    {/*                  <ExpanderCollapser> */}
                    <Block
                      thing={thing}
                      agentInput={{
                        blocks: [block],
                        block: block,
                        maxSnippets: 7,
                        containBlock: true,
                        compressSnippets: false,
                        inception: false,
                      }}
                    />
                    {/*<pre>block {JSON.stringify(block,null,2)}</pre>*/}
                    {/*          </ExpanderCollapser> */}
                    {/*
                  <Snippet
                    id={blockDate}
                    thing={thing}
                    agentInput={{
                      cleanText: "BLOCK A " + blockDate,
                      compressSnippet: false,
                    }}
                  />
*/}
                  </>
                );
              }
            }
          });
        }

        // Handle the case where the current date is invalid or null
        if (!currentDateValid) {
          // Find the last valid date before the current snippet
          let previousValidDate = null;
          for (let i = index - 1; i >= 0; i--) {
            const prevSnippetDateStr = agentInput.block[i].at;
            if (isValidDate(prevSnippetDateStr)) {
              previousValidDate = new Date(prevSnippetDateStr);
              break;
            }
          }

          // Find the next valid date after the current snippet
          let nextValidDate = null;
          for (let i = index + 1; i < agentInput.block.length; i++) {
            const nextSnippetDateStr = agentInput.block[i].at;
            if (isValidDate(nextSnippetDateStr)) {
              nextValidDate = new Date(nextSnippetDateStr);
              break;
            }
          }

          // Insert block dates between the previous and next valid dates
          blockDates.forEach((blockDate, i) => {
            const blockDateObj = new Date(blockDate);
            // If the block date is greater than the previous valid date and less than the next valid date
            if (
              previousValidDate &&
              nextValidDate &&
              blockDateObj > previousValidDate &&
              blockDateObj < nextValidDate &&
              !insertedBlockDates.has(blockDate)
            ) {
              insertedBlockDates.add(blockDate); // Mark this block date as inserted

              const block = getFirstMatchingBlockByDate(
                agentInput?.blocks,
                blockDate
              );

              blocksToInsert.push(
                <>
                  {/*                  <div id={blockDate}>*/}
                  {/* <pre>#{blockDate} (block)</pre> */}
                  {/*                  </div>*/}
                  {/*         <pre>B currentDate not valid {JSON.stringify(block)}</pre> */}
                  <Block
                    thing={thing}
                    agentInput={{
                      blocks: [block],
                      block: block,
                      maxSnippets: 7,
                      containBlock: true,
                      compressSnippets: false,
                      inception: false,
                    }}
                  />
                </>
              );
            }
          });
        }

        return (
          <React.Fragment key={d.uuid}>
{/*<pre>{JSON.stringify(d?.uuid, null, 2)}</pre>*/}
            {/* Render the blocks found before the current snippet */}
            {blocksToInsert}

            {!(d?.at == null) && agentInput?.hashLink ? (
              <div id={d.at}>{/*<pre>#{d.at} snippet</pre>*/}</div>
            ) : (
              <></>
            )}
            {/*         <pre>Snippet {JSON.stringify(d)}</pre>*/}
            <Snippet
              thing={thing}
              agentInput={{ ...d, compressSnippet: compressSnippets }}
            />
          </React.Fragment>
        );
      })}

      {/* After all snippets, render any remaining blocks */}
      {(() => {
        const remainingBlocks = [];
        const lastSnippetIndex = Math.min(
          maxSnippets - 1,
          agentInput.block.length - 1
        );
        const lastSnippetDateStr =
          lastSnippetIndex >= 0 ? agentInput.block[lastSnippetIndex].at : null;
        const lastSnippetDateValid =
          lastSnippetDateStr && isValidDate(lastSnippetDateStr);
        const lastSnippetDate = lastSnippetDateValid
          ? new Date(lastSnippetDateStr)
          : null;

        if (lastSnippetDate) {
          blockDates.forEach((blockDate) => {
            const blockDateObj = new Date(blockDate);
            // If the block date is after the last snippet date and hasn't been inserted
            if (
              blockDateObj > lastSnippetDate &&
              !insertedBlockDates.has(blockDate)
            ) {
              insertedBlockDates.add(blockDate); // Mark this block date as inserted

              remainingBlocks.push(
                <Snippet
                  thing={thing}
                  agentInput={{
                    cleanText: "REMAINING BLOCK " + blockDate,
                    compressSnippet: false,
                  }}
                />
              );
            }
          });
        }

        return remainingBlocks;
      })()}

      {agentInput.block.length >= maxSnippets && (
        <>
          {/*<pre>Link goes here</pre>
<pre>{JSON.stringify(agentInput?.block,0,2)}</pre>*/}
          <pre>{firstNonNullAt(agentInput?.block)}</pre>

          <Link to={"#" + firstNonNullAt(agentInput?.block)}>
            Go to {firstNonNullAt(agentInput?.block).slice(0, 10)}
          </Link>
        </>
      )}
    </>
  );


}

export default Block;
