import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import useHybridEffect from "../useHybridEffect.js";

import { createThing } from "../util/database.js";

import {
  Button as MuiButton,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { replaceUuids } from "../util/text.js";
import useToken from "../useToken.js";
import { useNavigate } from "react-router-dom";

const defaultWebPrefix = process.env.REACT_APP_WEB_PREFIX;

export default function Amount({ thing, agentInput, onThingReport }) {
  const navigate = useNavigate();
  const { isValidToken, token } = useToken();

  const [navigationActive, setNavigationActive] = useState(false);

  const pathname = window.location.pathname.replace(/\//, "");

  const [text, setText] = useState();
  const [subject, setSubject] = useState();

  // A thing can have variables. A datagram cannot.

  const [link, setLink] = useState();

  const [disabled, setDisabled] = useState();

  useHybridEffect(() => {
    console.log("Button pathname", pathname, link);
    console.log("Button agentInput", agentInput);

    if (agentInput && agentInput.hasOwnProperty('disabled')) {
if (agentInput.disabled === false) {
      setDisabled(false);
return;
    }
}
    if (pathname == null) {
      return;
    }

    console.log("Button isValidToken", isValidToken);

    if (!isValidToken) {
      setDisabled(true);
      return;
    }

    if (isValidToken) {
      setDisabled(false);
    }

    if (pathname === link) {
      setDisabled(true);
    }
  }, [pathname, link, isValidToken, agentInput]);

  useHybridEffect(() => {
    if (agentInput == null) {
      return;
    }

    if (
      typeof agentInput === "object" &&
      !Array.isArray(agentInput) &&
      agentInput !== null
    ) {
      if (agentInput.link) {
        console.log("agentInput.link", agentInput.link);

        setLink(agentInput.link);
      }
      if (agentInput.text) {
        setText(agentInput.text);
      }
    }
  }, [agentInput]);

useEffect(()=>{

console.log("Button disabled", disabled);

}, [disabled]);

  function handleClick(t) {
    console.log("handleClick", agentInput);

    // This is a bit deep here. And doesn't allow for Button reuse.
    onThingReport(t);

    if (
      agentInput &&
      agentInput.text &&
      agentInput.text === "Deprecated Add Thing"
    ) {
      const datagram = { ...thing };
      console.log("ThingPage tokent", token);
      createThing(defaultWebPrefix, datagram, token, "B")
        .then((result) => {
          console.log("Button handleClick createThing result", result);

          if (result.hasOwnProperty("error")) {
            return;
          }

          navigate("/" + "thing/" + result.uuid);
        })
        .catch((error) => {
          //         setStatus("error");
          console.error("ThingPage createThing error", error);
          //         setResponse((response) => {
          //         return response + error.message;
// })
        });
    }

    setNavigationActive(true);
    //navigate(link);
  }

  useEffect(() => {
    if (link == null) {
      return;
    }

    if (navigationActive === true) {
      navigate(link);
    }
  }, [link, navigationActive]);

  return (
    <>
      {/*
      <div onClick={() => 
{
if (disabled) {return;}
navigate("/"+subject)
}
}>
*/}
      {/* Provide the hint of a link in browser. */}
      {/* <a href={subject} disabled="disabled" > */}

      <div style={{padding: "0px 4px 4px 0px", backgroundColor: "#BCC6CC", display: "inline-block"}}>
        {/*        <MuiButton variant="url" component={Link} to={link} disabled={disabled} onClick={handleClick}>*/}
{/*        <MuiButton variant="url" disabled={disabled} onClick={handleClick}> */}
          {/*     <MaterialUiButton disabled={disabled} type="submit"> */}
          {text}
{/*        </MuiButton> */}
      </div>
      {/*</a>*/}
      {/*</div>*/}
    </>
  );
}
