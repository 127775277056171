import React, { useState, useEffect } from "react";
import { devFlag, debugFlag } from "../util/dev.js";

import { Link } from "react-router-dom";
import { setThing } from "../util/database.js";

import {
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  Switch,
} from "@mui/material";

import { replaceUuids } from "../util/text.js";
import useThing from "../useThing.js";
import useMessages from "../useMessages.js";

import useToken from "../useToken.js";

import { useNavigate } from "react-router-dom";

import useHybridEffect from "../useHybridEffect.js";

const blue = {
  500: "#007FFF",
};

const grey = {
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
};

export default function Not({ thing: inputThing, agentInput }) {



  const { isValidToken, token } = useToken();

//  const [navigationActive, setNavigationActive] = useState(false);

  const pathname = window.location.pathname.replace(/\//, "");




  const { thing, updateThing } = useThing(inputThing);

  const [not, setNot] = useState();

  const [link, setLink] = useState();


  const [status, setStatus] = useState();

  const [disabled, setDisabled] = useState();
  const [response, setResponse] = useState("");
  const { addMessage } = useMessages();

  const [syncState, setSyncState] = useState();

  //const itemSyncState = (item !== undefined) && (item === (thing && thing.variables && thing.variables.item))
  //  ? "Synced"
  //  : "Not synced";
  //const itemSyncState = syncState();

useEffect(()=>{

console.log("Not thing", thing, inputThing);

},[thing]);

  const getSyncState = () => {
    var itemSyncState = "Not synced";

    if (thing == null && inputThing == null) {
      return "No thing or inputThing.";
    }

    if (thing == null) {
      itemSyncState =
        not !== undefined &&
        not ===
          (inputThing && inputThing.variables && inputThing.variables.not)
          ? "Synced with inputThing thing null"
          : "Not synced with inputThing thing null";
      return itemSyncState;
    }

    if (thing.hasOwnProperty("variables")) {
      if (thing.variables.hasOwnProperty("item")) {
        itemSyncState =
          not !== undefined &&
          not === (thing && thing.variables && thing.variables.not)
            ? "Synced with thing"
            : "Not synced with Thing";

        return itemSyncState;
      }
    }

    return "Share";
  }

//useEffect(()=>{
//console.log("Not itemSyncState", itemSyncState);
//},[itemSyncState]);

  useHybridEffect(() => {
    if (inputThing == null) {
      return;
    }

    console.debug("Not inputThing", inputThing);
    /*
      if (thing.subject) {
        // pre-recognition
        //setSubject(inputThing.subject);
        //setText(replaceUuids(inputThing.agentInput));
      }
*/
    if (inputThing && inputThing.variables && inputThing.variables.not) {
      console.log("Item Thing variables", inputThing.variables);

      if (not == null) {
        setNot(inputThing.variables.not);
      }
    }

  }, [inputThing]);


  useHybridEffect(() => {
    console.log("Button pathname", pathname, link);
    console.log("Button agentInput", agentInput);

    if (agentInput && agentInput.hasOwnProperty('disabled')) {
if (agentInput.disabled === false) {
      setDisabled(false);
return;
    }
}
    if (pathname == null) {
      return;
    }

    console.log("Button isValidToken", isValidToken);

    if (!isValidToken) {
      setDisabled(true);
      return;
    }

    if (isValidToken) {
      setDisabled(false);
    }

    if (pathname === link) {
      setDisabled(true);
    }
  }, [pathname, link, isValidToken, agentInput]);




  function handleToggleItem(e) {
    console.log("Not handleToggleItem e target checked", e.target.checked);
    console.debug("Not handleToggleItem thing", thing);

    setNot(e.target.checked);

    // Review this
    // May not need to use the useThing hook.
    setStatus("saving");
    return updateThing({ ...inputThing, variables: { not: e.target.checked } })
      .then((result) => {
        //addMessage("Item handleToggleItem update thing " + inputThing.subject);

        if (result && result.error && result.error.message) {
          setResponse((response) => {
            return response + result.error.message;
          });

          return;
        }
        setStatus("synced");
        setResponse((response) => {
          return response + "Update thing. ";
        });

        console.log(
          "Not handleToggleItem updateThing result data",
          result.data
        );
        console.log("Not handleToggleItem updateThing result", result);
      })
      .catch((error) => {
        setResponse((response) => {
          return response + "Error";
        });
        //setError(error.message);
        console.error("Not handleToggleItem updateThing error", error);
      });
  }

  if (thing == null) {
    return null;
  }

  return (
    <>
      <div>
{debugFlag && (<>NOT</>)}
{debugFlag &&(<>        UUID {thing && thing.uuid}
        THING {JSON.stringify(thing)}
        THING VARIABLES {JSON.stringify(thing.variables)}
        <br />
</>)}
{debugFlag && (<>{disabled ? "DISABLED" : "NOT DISABLED"}</>)}
{/*JSON.stringify(thing)*/}{" "}
{debugFlag && JSON.stringify(inputThing?.variables)}
{" "}
{debugFlag && inputThing?.uuid}
{" "}
{debugFlag && inputThing?.nomFrom}
{!disabled && inputThing && inputThing.uuid && (<>
NOTSWITCH
        <Switch disabled={disabled} checked={not} onChange={handleToggleItem} color="primary" />

        {getSyncState()}
        <br />
        {response}
        <br />
</>)}
      </div>
    </>
  );
}
