import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
} from "@mui/material";

import Invite from "./Invite";

const Invites = ({ thing, agentInput }) => {
  //  const invites = [{ slug: "kokopelli-9ba5" }, { slug: "events-vancouver-6f31" }];

  const handleButtonClick = (url) => {
    // Open the URL in a new tab
    window.open(url, "_blank");
  };

  const [invites, setInvites] = useState();
  const [inviteSlugs, setInviteSlugs] = useState();

  const postInvite = async () => {
    try {
      const response = await fetch(
        "https://stackr.ca/api/greywolf/getInvites",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ subject: thing?.subject }),
          cache: "no-store", // Prevent caching
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Response data:", data);
      setInviteSlugs(data?.data?.invites);
    } catch (error) {
      console.error("Error posting invite:", error);
    }
  };

  useEffect(() => {
    if (inviteSlugs == null) {
      return;
    }

    const i = inviteSlugs.map((s) => {
      if (s?.slug) {
        return s;
      }

      return { slug: s };
    });

    setInvites(i);
  }, [inviteSlugs]);

  useEffect(() => {
    console.log("Invites");
    // Call the postInvite function when the component mounts
    postInvite();
  }, [thing]); // Dependency array includes 'thing' to re-run if it changes

  const [isHovered, setIsHovered] = useState(false);

  const styles = {
    card: {
      width: "100%",
      //      maxWidth: 600,
      margin: "16px auto",
      //margin:'auto',
      padding: 0,
      boxShadow: "none",
      //      border: isHovered ? '5px solid orange' : '5px solid rgba(255, 165, 0, 0.2)',
      backgroundColor: isHovered
        ? "5px solid orange"
        : "5px solid rgba(255, 165, 0, 0.2)",
      transition: "border-color 0.3s ease",
      display: "flex",
      position: "relative",
      overflow: "visible", // Prevent overflow
    },
    yellowBox: {
      flex: "50%", // Take up 50% of the width of the card
      backgroundColor: "yellow",
      opacity: 0.5,
      //      display: 'none', // Initially hidden
    },
    cardContent: {
      flex: "1", // Take up the remaining space
      padding: 2,
    },
  };

  if (
    invites == null ||
    agentInput?.collection == null ||
    agentInput?.collection === false
  ) {
    return <></>;
  }

  return (
    <>
      <Box
        sx={styles.card}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Box sx={styles.cardContent}>
          <Typography variant="body1" component="div" gutterBottom>
            Join our community today and unlock a world of exclusive perks!
            Choose your subscription and enjoy tailored benefits designed just
            for you!
          </Typography>

          <Grid container spacing={2} justifyContent="flex-start">
            {" "}
            {/* Left justify buttons */}
            {invites.map((invite, index) => (
              <Grid item key={index}>
                {" "}
                {/* Allow buttons to size based on content */}
                <Invite thing={null} agentInput={{ invite: invite }} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Invites;
