import React, { useState, useEffect } from "react";

import Snippet from "../components/Snippet.js";
import DynamicComponent from "../components/DynamicComponent.js";
import useHybridEffect from "../useHybridEffect.js";

import VisibilitySensor from "react-visibility-sensor";

import { styled } from "@mui/material/styles";

//import Button from "@mui/material/Button";

import Collapse from "@mui/material/Collapse";
//import Card from "@mui/material/Card";
//import CardHeader from "@mui/material/CardHeader";
//import CardMedia from "@mui/material/CardMedia";

import { IconButtonProps } from "@mui/material/IconButton";

import { createThing } from "../util/database.js";

import { extractionDate } from "../util/time.js";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "../index.css";
import {
  Typography,
  //  Avatar,
  //  ListItemAvatar,
  Box,
} from "@mui/material";

import {
  Button,
  TextField,
  IconButton,
  ListItem,
  ListItemText,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { Edit } from "@mui/icons-material";

import Forget from "../components/Forget.js";

import useSnapshot from "../useSnapshot.js";
import useToken from "../useToken.js";

import {
  isText,
  extractUuid,
  extractNuuid,
  getSlug,
  capitalizeFirstLetter,
} from "../util/text.js";

import { devFlag, debugFlag } from "../util/dev.js";

import {
  compressText,
  cleanUrl,
  extractUrl,
  extractUrls,
} from "stack-js/src/util/text";

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} size="large" />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface WebExpandMoreProps extends IconButtonProps {
  webExpand: boolean;
}

const WebExpandMore = styled((props: WebExpandMoreProps) => {
  const { webExpand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, webExpand }) => ({
  transform: !webExpand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function sizeText(text) {
  return text.length;
}

function Block({ thing, agentInput }) {
  const { token, isValidToken } = useToken();

  const datagram = thing;

  const [web, setWeb] = useState();
  const [snippet, setSnippet] = useState();
  const [compressedSnippet, setCompressedSnippet] = useState();

  const [webExpanded, setWebExpanded] = useState(false);
  const [isWebExpandable, setIsWebExpandable] = useState();

  const [compressSnippets, setCompressSnippets] = useState();

  const handleWebExpandClick = () => {
    console.log("Thing handleWebExpandClick webExpanded", webExpanded);
    setWebExpanded(!webExpanded);
  };

  useEffect(() => {
    // Still working on this.
    // Needs to write state of the thing (expanded or not) to the thing.
    // But first need to tame the PUT requests.

    setIsWebExpandable(false);
  });

  useEffect(() => {
    console.log("Thing webExpanded", webExpanded);
  }, [webExpanded]);

  function isUpperCase(str) {
    return str === str.toUpperCase();
  }

  const [containBlock, setContainBlock] = useState();

  useHybridEffect(() => {
    if (agentInput == null) {
      return;
    }
    if (agentInput.block == null) {
      return;
    }

    if (agentInput?.containBlock) {
      console.log("Block agentInput", agentInput);
      setContainBlock(agentInput?.containBlock);
    }

    if (isUpperCase(agentInput.block[0].text)) {
      setCompressSnippets(false);
      return;
    }

    setCompressSnippets(agentInput?.compressSnippets);
  }, [agentInput]);

  const [open, setOpen] = useState(false);

  const replyAgentDialog = (thing) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function humanTime(timestamp) {
    const ts = new Date();
    return ts.toISOString();
  }

  const [maxSnippets, setMaxSnippets] = useState();

  //let maxSnippets = agentInput.block.length;
  //if (compressBlocks) {maxSnippets = 3;}

  useEffect(() => {
    console.log("Block containBlock", containBlock);
    if (containBlock == null) {
      return;
    }

    if (containBlock) {
      setMaxSnippets(3);
      return;
    }

    setMaxSnippets(agentInput.block.length);
  }, [containBlock]);

  const [blockDates, setBlockDates] = useState();
// const [unusedBlockDates, setUnusedBlockDates] = useState();


  useHybridEffect(() => {
    if (agentInput?.blocks == null) {
      return;
    }

    const blockAts = agentInput?.blocks.map((b) => {
      return b;
    });

    const firstNonNullAts = blockAts.map(
      (innerArray) => innerArray.find((block) => block.at !== null)?.at // Use optional chaining to handle cases where no non-null 'at' is found
    );

    setBlockDates(firstNonNullAts);
//setUnusedBlockDates(firstNonNullAts);
  }, [agentInput?.blocks]);

  const toggleContainBlock = () => {
    setContainBlock(!containBlock);
    console.log("Block toggleContainBlock");
  };

  const flag = false;


if (blockDates == null) {return (<></>)};


    const insertedBlockDates = new Set();

/*
const isValidDate = (dateString) => {
  const date = new Date(dateString);
  return !isNaN(date.getTime()) && dateString === date.toISOString();
};
*/

const isValidDate = (dateString) => {
  // Regular expression to match a valid ISO 8601 date format
  const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/;

  // Check if the dateString matches the expected format
  if (!iso8601Regex.test(dateString)) {
    return false;
  }

  const date = new Date(dateString);
  return !isNaN(date.getTime()) && dateString === date.toISOString();
};















return (
  <>
    {agentInput.block.slice(0, maxSnippets).map((d, index) => {
      // Get the current snippet date
      const currentDateStr = d.at;
      const currentDateValid = isValidDate(currentDateStr);
      const currentDate = currentDateValid ? new Date(currentDateStr) : null;

      // Create an array to hold the block elements to be displayed
      const blocksToInsert = [];

      // If the current date is valid, check for block dates before it
      if (currentDateValid) {
        blockDates.forEach((blockDate) => {
          const blockDateObj = new Date(blockDate);
          // If the block date is less than the current snippet date
          if (blockDateObj < currentDate && !insertedBlockDates.has(blockDate)) {
            insertedBlockDates.add(blockDate); // Mark this block date as inserted

            blocksToInsert.push(
              <Snippet thing={thing} agentInput={{ cleanText: ("BLOCK " + blockDate), compressSnippet: false }} />
            );
          }
        });
      }

      // Handle the case where the current date is invalid or null
      if (!currentDateValid) {
        // Find the last valid date before the current snippet
        let previousValidDate = null;
        for (let i = index - 1; i >= 0; i--) {
          const prevSnippetDateStr = agentInput.block[i].at;
          if (isValidDate(prevSnippetDateStr)) {
            previousValidDate = new Date(prevSnippetDateStr);
            break;
          }
        }

        // Find the next valid date after the current snippet
        let nextValidDate = null;
        for (let i = index + 1; i < agentInput.block.length; i++) {
          const nextSnippetDateStr = agentInput.block[i].at;
          if (isValidDate(nextSnippetDateStr)) {
            nextValidDate = new Date(nextSnippetDateStr);
            break;
          }
        }

        // Insert block dates between the previous and next valid dates
        blockDates.forEach((blockDate) => {
          const blockDateObj = new Date(blockDate);
          // If the block date is greater than the previous valid date and less than the next valid date
          if (previousValidDate && nextValidDate &&
              blockDateObj > previousValidDate && blockDateObj < nextValidDate &&
              !insertedBlockDates.has(blockDate)) {
            insertedBlockDates.add(blockDate); // Mark this block date as inserted

            blocksToInsert.push(
              <Snippet thing={thing} agentInput={{ cleanText: ("BLOCK " + blockDate), compressSnippet: false }} />
            );
          }
        });
      }

      return (
        <React.Fragment key={d.at}>
          {/* Render the blocks found before the current snippet */}
          {blocksToInsert}
          <pre>{JSON.stringify(d.at, null, 2)}</pre>
          <Snippet
            thing={thing}
            agentInput={{ ...d, compressSnippet: compressSnippets }}
          />
        </React.Fragment>
      );
    })}

    {/* After all snippets, render any remaining blocks */}
    {(() => {
      const remainingBlocks = [];
      const lastSnippetIndex = Math.min(maxSnippets - 1, agentInput.block.length - 1);
      const lastSnippetDateStr = lastSnippetIndex >= 0 ? agentInput.block[lastSnippetIndex].at : null;
      const lastSnippetDateValid = lastSnippetDateStr && isValidDate(lastSnippetDateStr);
      const lastSnippetDate = lastSnippetDateValid ? new Date(lastSnippetDateStr) : null;

      if (lastSnippetDate) {
        blockDates.forEach((blockDate) => {
          const blockDateObj = new Date(blockDate);
          // If the block date is after the last snippet date and hasn't been inserted
          if (blockDateObj > lastSnippetDate && !insertedBlockDates.has(blockDate)) {
            insertedBlockDates.add(blockDate); // Mark this block date as inserted

            remainingBlocks.push(
              <Snippet thing={thing} agentInput={{ cleanText: ("REMAINING BLOCK " + blockDate), compressSnippet: false }} />
            );
          }
        });
      }

      return remainingBlocks;
    })()}

    {agentInput.block.length >= maxSnippets && (
      <button onClick={toggleContainBlock}>
        {containBlock ? "Show More" : "Show Less"}
      </button>
    )}
  </>
);





















return (
  <>

    {agentInput.block.slice(0, maxSnippets).map((d, index) => {
      // Get the current snippet date
      const currentDateStr = d.at;
      const currentDateValid = isValidDate(currentDateStr);
      const currentDate = currentDateValid ? new Date(currentDateStr) : null;

      // Get the previous snippet date if it exists
      const previousDateStr = index > 0 ? agentInput.block[index - 1].at : null;
      const previousDateValid = previousDateStr && isValidDate(previousDateStr);
      const previousDate = previousDateValid ? new Date(previousDateStr) : null;

      // Create an array to hold the block elements to be displayed
      const blocksToInsert = [];

      // Check for block dates between the previous and current snippet dates
      if (previousDate && currentDateValid) {
        blockDates.forEach((blockDate) => {
          const blockDateObj = new Date(blockDate);
          // If the block date is between the previous and current snippet dates
          if (blockDateObj > previousDate && blockDateObj < currentDate) {
            // Only add if it hasn't been added before
            if (!insertedBlockDates.has(blockDate)) {
              insertedBlockDates.add(blockDate); // Mark this block date as inserted


              blocksToInsert.push(
<><Snippet thing={thing} agentInput={{cleanText:("BLOCK " + blockDate), compressSnippet:false}} />
</>

              );



            }
          }
        });
      }

      return (
        <React.Fragment key={d.at}>
          {/* Render the blocks found before the current snippet */}
          {blocksToInsert}
 {/*         <pre>{JSON.stringify(blockDates, null, 2)}</pre> */}
          <pre>{JSON.stringify(d.at, null, 2)}</pre>
          <Snippet
            thing={thing}
            agentInput={{ ...d, compressSnippet: compressSnippets }}
          />
        </React.Fragment>
      );
    })}

    {/* After all snippets, render any remaining blocks */}
    {(() => {
      const remainingBlocks = [];
      const lastSnippetIndex = Math.min(maxSnippets - 1, agentInput.block.length - 1);
      const lastSnippetDateStr = lastSnippetIndex >= 0 ? agentInput.block[lastSnippetIndex].at : null;
      const lastSnippetDateValid = lastSnippetDateStr && isValidDate(lastSnippetDateStr);
      const lastSnippetDate = lastSnippetDateValid ? new Date(lastSnippetDateStr) : null;

      if (lastSnippetDate) {
        blockDates.forEach((blockDate) => {
          const blockDateObj = new Date(blockDate);
          // If the block date is after the last snippet date and hasn't been inserted
          if (blockDateObj > lastSnippetDate && !insertedBlockDates.has(blockDate)) {
            insertedBlockDates.add(blockDate); // Mark this block date as inserted

            remainingBlocks.push(<>
<Snippet thing={thing} agentInput={{cleanText:("REMAINING BLOCK " + blockDate), compressSnippet:false}} />
</>            );





          }
        });
      }

      return remainingBlocks;
    })()}


{/*<pre>{JSON.stringify(agentInput?.blocks, null, 2)}</pre>*/}


    {agentInput.block.length >= maxSnippets && (
      <button onClick={toggleContainBlock}>
        {containBlock ? "Show More" : "Show Less"}
      </button>
    )}
  </>
);





  return (
    <>
      {/*compressSnippets ? "COMPRESS" : "DO NOT COMPRESS"*/}

      {agentInput.block.slice(0, maxSnippets).map((d) => {
        return (
          <>
            <pre>{JSON.stringify(blockDates, null, 2)}</pre>

            <pre>{JSON.stringify(d, null, 2)}</pre>

            <Snippet
              thing={thing}
              agentInput={{ ...d, compressSnippet: compressSnippets }}
            />
          </>
        );
      })}

      {agentInput.block.length >= maxSnippets && (
        <button onClick={toggleContainBlock}>
          {containBlock ? "Show More" : "Show Less"}
        </button>
      )}
    </>
  );
}

export default Block;
