import { useState, useEffect, useRef } from "react";

import { getWebJson } from "./util/database.js";
import { humanTime, zuluTime } from "./util/time.js";

import { getSlug } from "./util/text.js";
import useHybridEffect from "./useHybridEffect.js";
//import useDeepCompareEffect from "use-deep-compare-effect";

import { replaceUuids } from "./util/uuid";
import { replaceZulutimes, replacePosixtimes } from "./util/time";

import { diffText } from "./util/text";
//import {replaceUuids} from "stack-js/util/uuid";
const defaultSnapshotInterval = parseInt(
  process.env.REACT_APP_SNAPSHOT_INTERVAL
);

function detemporalizeStructuredText(text) {
  var slug = getSlug(text);
  //console.log("useSnapshot slug", slug);
  var tokens = slug.split("-");

  const bob = tokens.map((token, i) => {
    //console.log("useSnapshot token", token, i);

    // dev get this so the At has to be at the end of the string.

    if (
      token.includes("time") ||
      token.includes("at") ||
      token.includes("At") ||
      token.includes("Time")
    ) {
      // Might need to be a clear to make sure everything stays in its place as
      // more and more Ats or Times are added.

      tokens[i] = "";
      tokens[i + 1] = "";
    }
  });

  return tokens.join("-");

  //return text;
}

//const diffText = (diffMe, diffBy) => diffMe.split(diffBy).join('')

function usePrior(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default function useSnapshot(input, inputSnapshotPollInterval, thing) {
  //const to = input;
  /*
  const [snapshotInterval, setSnapshotInterval] = useState(
    inputSnapshotPollInterval == null
      ? defaultSnapshotInterval
      : inputSnapshotPollInterval
  );
*/
  const [snapshotInterval, setSnapshotInterval] = useState();
  const [flag, setFlag] = useState();

  const [changeCount, setChangeCount] = useState(0);

  const [unchangedCount, setUnchangedCount] = useState(0);

  const [snapshot, setSnapshot] = useState({
    thing: { uuid: "X" },
    thingReport: { sms: "No snapshot response. Yet." },
  });

  const priorSnapshot = usePrior(snapshot);

  const [snapshotRunTime, setSnapshotRunTime] = useState();
  const [snapshotRunAt, setSnapshotRunAt] = useState();
  const [snapshotResults, setSnapshotResults] = useState([]);
  const [sequentialErrorCount, setSequentialErrorCount] = useState();

  //const [similarity, setSimilarity] = useState();
  //const [count, setCount] = useState();

  useEffect(() => {
    if (inputSnapshotPollInterval === null) {
      setSnapshotInterval(null);
      return;
    }

if (inputSnapshotPollInterval == null) {return;}

    if (inputSnapshotPollInterval == "now") {
      setSnapshotInterval(defaultSnapshotInterval);
      return;
    }

    setSnapshotInterval(inputSnapshotPollInterval);
  }, [inputSnapshotPollInterval]);

  useHybridEffect(() => {
    console.debug("useSnapshot hybrideffect input", input);

    if (input == null) {
      console.debug("useSnapshot hybrideffore input is null", input);

      return;
    }
    getSnapshot();
  }, [input]);

  useEffect(() => {
    console.debug(
      "useSnapshot inputSnapshotPollInterval",
      inputSnapshotPollInterval
    );
  }, [inputSnapshotPollInterval]);

  useEffect(() => {
    console.debug("useSnapshot init");
  }, []);

  useEffect(() => {
    //if (typeof snapshotInterval !== 'number') {
    //  console.error('snapshotInterval should be a valid number.');
    //  return;
    //}
    if (input == null) {
      return;
    }
    const intervalValue =
      typeof snapshotInterval === "number"
        ? snapshotInterval
        : defaultSnapshotInterval;

    console.debug(
      "useSnapshot interval inputSnapshotPollInterval",
      inputSnapshotPollInterval
    );
    console.debug("useSnapshot interval snapshotInterval", snapshotInterval);

    console.debug("useSnapshot interval intervalValue", intervalValue);

    console.debug(
      "useSnapshot interval input snapshotInterval",
      input,
      snapshotInterval,
      defaultSnapshotInterval
    );
    getSnapshot();

    if (snapshotInterval === false) {
      // false = call once.
      return;
    }

    const interval = setInterval(() => {
      console.debug(
        "useSnapshot interval called inputSnapshotPollInterval snapshotInterval intervalValue input",
        inputSnapshotPollInterval,
        snapshotInterval,
        intervalValue,
        input
      );
      getSnapshot();
    }, intervalValue); // 20 Hz was 200.

    return () => clearInterval(interval);
  }, [input, snapshotInterval]);

  useEffect(() => {
    console.log("useSnapshot snapshotInterval", snapshotInterval);
  }, [snapshotInterval]);

  useEffect(() => {
    console.log(
      "useSnapshot changeCount unchangedCount",
      changeCount,
      unchangedCount
    );
  }, [changeCount, unchangedCount]);

  const [requestCountHistory, setRequestCountHistory] = useState([]);
  useEffect(() => {
    setRequestCountHistory((requestCountHistory) => [
      ...requestCountHistory,
      unchangedCount,
    ]);

    setUnchangedCount(0);
  }, [changeCount]);

  useHybridEffect(() => {
    if (requestCountHistory == null) {
      return;
    }
    //if (unchangedCount > 10) {

    console.log("useSnapshot requestCountHistory", requestCountHistory);

    if (snapshotInterval == null) {
// don't do anything
      // Let's back off.
      //setSnapshotInterval(defaultSnapshotInterval);
      return;
    }

    if (unchangedCount == 0) {
      setSnapshotInterval((snapshotInterval) => {
        const x = parseInt(snapshotInterval / 2);

        console.log("useSnapshot x", x);
        console.log(
          "useSnapshot x defaultSnapshotInterval",
          defaultSnapshotInterval
        );

        if (x < parseInt(defaultSnapshotInterval)) {
          console.log(
            "useSnapshot x < defaultSnapshotInterval",
            defaultSnapshotInterval
          );
          return defaultSnapshotInterval;
        }

        console.log("useSnapshot x > defaultSnapshotInterval", x);

        return x;

        //return parseInt(snapshotInterval / 2);
      });
    }

    setSnapshotInterval((snapshotInterval) => {
      const y = snapshotInterval * 2;
      // New setting maximum period.
      if (y > 15000) {
        return 15000;
      }
      return y;
      //return (snapshotInterval * 2)
    });

    //}
  }, [requestCountHistory]);

  useEffect(() => {
    console.log("useSnapshot snapshotInterval", snapshotInterval);
  }, [snapshotInterval]);

  useHybridEffect(() => {
    console.debug("useSnapshot hybrideffect snapshot");
    if (snapshot == null) {
      return;
    }

    console.log("useSnapshot snapshotResults", snapshotResults);

    if (snapshot == null) {
      return;
    }

    if (snapshotResults.length == 0) {
      console.debug("useSnapshot snapshotResults undefined");
      setSnapshotResults([{ ...snapshot, snapshotAt: zuluTime() }]);

      return;
    }

    console.log("useSnapshot snapshot", snapshot);
    console.log("useSnapshot snapshot uuid", snapshot.uuid);

    console.log("useSnapshot snapshot thing", snapshot?.thing);
    console.log("useSnapshot snapshot thingReport", snapshot?.thingReport);

    console.log("useSnapshot snapshot thing subject", snapshot?.thing?.subject);

    let uuidMatch = snapshotResults.find((o) => o.uuid === snapshot.uuid);

    if (uuidMatch == null) {
      // Not recognized
    }

    let subjectMatch = snapshotResults.find(
      (o) => o?.thing?.subject === snapshot?.thing?.subject
    );

    if (subjectMatch == null) {
      // subject not recognized.
    }

    console.log("useSnapshot snapshot subjectMatch", subjectMatch);
    // Is a new uuid if found.
    // Maybe should not be.

    console.log(
      "useSnapshot snapshot thingReport web",
      snapshot?.thingReport?.web
    );
    //  First one will be the first load snapshot.

    console.log("useSnapshot snapshotResults[0]", snapshotResults[0]);

    // Is the new snapshot materiallt the same to the first snapshot.
    // No numbers changing etc. Apart from ones the stack might have generated.

    // So compare JSON. stringify with uuids and zulus removed.

    const referenceString = JSON.stringify(snapshotResults[0]);
    const testString = JSON.stringify(snapshot);

    const reference1 = replaceUuids(referenceString, "X");
    const test1 = replaceUuids(testString, "X");

    const reference2 = replaceZulutimes(reference1, "T");
    const test2 = replaceZulutimes(test1, "T");

    const reference3 = replacePosixtimes(reference2, "P");
    const test3 = replacePosixtimes(test2, "P");

    //console.log("useSnapshot x reference", reference);
    //console.log("useSnapshot x test3", detemporalizeStructuredText(test3));
    //console.log("useSnapshot x reference3", detemporalizeStructuredText(reference3));

    const test4 = detemporalizeStructuredText(test3);
    const reference4 = detemporalizeStructuredText(reference3);

    // Seen a change. Update the reference.
    if (test4 !== reference4) {
      //console.log("useSnapshot test4", test4);
      //console.log("useSnapshot reference4", reference4);

      const diff = getSlug(diffText(reference4, test4));
      if (diff == "error-null" || diff == "") {
        // Ignore. Do not change state.
        // May be a few of these strings...
        setUnchangedCount((unchangedCount) => unchangedCount + 1);
        return;
      }

      console.log("useSnapshot diff", diff);

      setChangeCount((changeCount) => changeCount + 1);
      setSnapshotResults([{ ...snapshot, snapshotAt: zuluTime() }]);
    }

    let count = 0;

    for (const snapshotResult of snapshotResults.reverse()) {
      if (snapshotResult.error) {
        count += 1;
      }
      if (!snapshot.error) {
        break;
      }
      console.log(snapshotResult);
    }
    setSequentialErrorCount(count);
  }, [snapshot]);

  useEffect(() => {
    console.log("useSnapshot sequentialErrorCount", sequentialErrorCount);
  }, [sequentialErrorCount]);

  function getSnapshot() {
    if (thing?.variables?.visible !== true) {
      console.debug(
        "useThingReport getSnapshot thing variables visible",
        thing?.variables?.visible
      );
      console.log(
        "Thing " + thing?.uuid + " " + thing?.subject + " is not visible"
      );
      return Promise.resolve({ error: { message: "Thing is not visible" } });
    }

console.log("useSnapshot getSnapshot snapshotRunAt", snapshotRunAt);

    // Too soon?
    if (snapshotRunAt) {
      const ageMilliseconds = new Date() - new Date(snapshotRunAt);
console.log("useSnapshot ageMilliseconds", ageMilliseconds);
      if (ageMilliseconds < 60000) {
        console.log("useSnapshot getSnapshot too soon");
        return;
      }
    }

console.log("useSnapshot getSnapshot snapshotInterval", snapshotInterval);
if (snapshotInterval == null) {
// Then we should only snapshot once.
if (snapshot) {

console.log("useSnapshot getSnapshot null interval already got snapshot",snapshot);
return;
}


}

    //        setSnapshotRunAt(zuluTime(endTime));

    console.debug("useSnapshot getSnapshot input", input);

    const startTime = new Date();
    if (flag === "red") {
      console.debug("useSnapshot getSnapshot flag", flag);
      return;
    }

    if (input == null) {
      console.debug("useSnapshot getSnapshot input null", input);

      return;
    }

    //    const url = input;

    // Test modifying the url.
    // Some stacks may only expect a /
    const url = input.replace("/history-", "/history/");

    console.log("useSnapshot url", url);
    return getWebJson(url, "")
      .then((result) => {
        console.debug("useSnapshot getSnapshot result", url, result);
        if (!result) {
          return true;
        }

        if (result && result.thingReport === false) {
          // No thing report. Do not update snapshot.
          return false;
        }

        if (result && result.thingReport && result.thingReport.snapshot) {
          setSnapshot(result.thingReport.snapshot);
        } else {
          // Failback situation where thingReport format not found.

if (result?.error) {
          setSnapshot((prevSnapshot)=>({...prevSnapshot, error:result?.error}));
}

        }
        console.debug("useSnapshot setSnapshot");
        // dev flag available not available
        setFlag("green");
        const endTime = new Date();
        setSnapshotRunTime(endTime - startTime);
        setSnapshotRunAt(zuluTime(endTime));
        //setSimilarity({count:count});

        return result;
      })
      .catch((error) => {
        //console.log("History history getSnapshot error", error);
        console.error("useSnapshot getSnapshot error", error);
        setFlag("yellow");
        return { ...snapshot, error };
        //return;
      });
  }

  const saveSnapshot = (userSnapshot) => {
    console.log("useSnapshot saveSnapshot userSnapshot", userSnapshot);
    setSnapshot(userSnapshot);
  };

  const deleteSnapshot = (userSnapshot) => {
    // Leave no rubbish behind.
    setSnapshot(false);
  };

  return {
    deleteSnapshot: deleteSnapshot,
    setSnapshot: saveSnapshot,
    snapshot,
    priorSnapshot,
    flag,
    snapshotRunTime,
    snapshotRunAt,
    snapshotInterval,
  };
}
